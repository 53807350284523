<template>
  <div>
    <div class="row">
      <v-col cols="12">
        <v-card class="rounded-lg" flat>
          <v-container class="pa-8" fluid>
            <v-row class="pb-5">
              <v-col class="d-flex align-center">
                <p class="mb-0 fz-21 font-weight-bold">Manage Ticket </p>
                <v-spacer />
                <v-btn
                  depressed
                  class="secondary ml-7"
                  to="/manage-ticket/create-ticket"
                  ><span class="fz-14">Create Ticket</span></v-btn
                >
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="3">
                <v-select
                  placeholder="Organization"
                  outlined
                  :items="projects"
                  item-text="name"
                  item-value="name"
                ></v-select>
              </v-col>
              <v-col cols="3">
                <v-select
                  placeholder="Project"
                  outlined
                  :items="projects"
                  item-text="name"
                  item-value="name"
                ></v-select>
              </v-col>
              <v-col cols="3">
                <v-select
                  placeholder="Type"
                  outlined
                  :items="severities"
                ></v-select>
              </v-col>
              <v-col cols="3">
                <v-select
                  placeholder="Status"
                  outlined
                  :items="status"
                ></v-select>
              </v-col>
            </v-row>
            <v-data-table
              v-if="tickets.length"
              :headers="table.headers"
              :items="tickets"
              :items-per-page="5"
              class="elevation-0"
              :server-items-length="pagination.count"
              :options.sync="options"
              hide-default-footer
              item-key="createddate"
            >
              <template v-slot:item.status="{ item }">
                <!-- <p :class="item.status.replaceAll(' ', '')">
                  {{ item.status }}
                </p> -->
                  <v-select
                    height="50"
                    :items="selectStatus"
                    v-model="item.status"
                    item-text="text"
                    item-value="value"
                    placeholder="Select Status"
                    hide-details=""
                    outlined
                    :menu-props="{ contentClass: item.status }"
                  ></v-select>
              </template>
              <template v-slot:item.project="{ item }">
                <p>
                  {{ item.project.name }}
                </p>
              </template>
              <template v-slot:item.created_at="{ item }">
                <p>
                  {{ $moment(new Date(item.created_at)).format("DD/MM/YYYY h:mm A") }}
                </p>
              </template>

               <template v-slot:item.action="{ item }">
                <popupquote
                  v-model="item.open"
                  :offsetTop="20"
                  :documentid="'popup' + item.id"
                  offset-y
                  allow-overflow
                >
                  <template v-slot:activator="{ on, attrs }">
                    <label
                      v-bind="attrs"
                      class="primary--text linkpointer"
                      style="position: relative; white-space: nowrap"
                      @click="
                        ($event) => {
                          on.click($event);
                          show($event, item);
                        }
                      "
                      >More
                      <v-icon
                        :class="{
                          rotate: item.open,
                        }"
                        :id="'popup' + item.id"
                        color="primary"
                        >mdi-chevron-down</v-icon
                      >
                    </label>
                  </template>
                  <v-list>
                    <v-list-item
                      @click="
                        () => {
                          memberselectedvalue = item;
                          opendialogedituser = true;
                        }
                      "
                    >
                      <v-list-item-title>Edit</v-list-item-title>
                    </v-list-item>
                   
                    <v-list-item
                      style="min-height: 1px; margin-left: -16px; margin-right: -16px"
                    >
                      <v-divider style="min-width: 160px" />
                    </v-list-item>
                    <v-list-item
                      @click="
                        () => {
                          memberselected = item;
                          opendialogdeleteuser = true;
                        }
                      "
                    >
                      <v-list-item-title class="error--text"
                        >Delete</v-list-item-title
                      >
                    </v-list-item>
                  </v-list>
                </popupquote>
              </template>

              <template v-slot:footer="{ props }">
                <custom-footer-datatable
                  @input="
                    (val) => {
                      options = val;
                    }
                  "
                  :props="props"
                />
              </template>
            </v-data-table>

            <v-simple-table
              v-if="!tickets.length"
              style="border: 1px solid #cad6e1"
            >
              <thead>
                <tr>
                  <th>Subjet</th>
                  <th>Project</th>
                  <th>Ticket ID</th>
                  <th>PIC</th>
                  <th>Created Date</th>
                  <th>Status</th>
                </tr>
              </thead>

              <tbody>
                <tr>
                  <td colspan="6" class="py-8">
                    <v-row align="center" justify="center">
                      <div class="text-center w-50">
                        <h3>No Ticket</h3>
                      </div>
                    </v-row>

                    <v-row>
                      <v-col class="d-flex flex-row justify-center">
                        <router-link to="/ticket/create-ticket">
                          <v-btn
                            depressed
                            class="secondary"
                            height="55"
                            width="465"
                            ><span class="fz-14">Create Ticket</span></v-btn
                          >
                        </router-link>
                      </v-col>
                    </v-row>
                  </td>
                </tr>
              </tbody>
            </v-simple-table>
          </v-container>
        </v-card>
      </v-col>
    </div>
  </div>
</template>

<script>
import ticket from "./ticket";
import { onMounted, reactive, ref, watch } from "@vue/composition-api";
import {
  useNamespacedActions,
  useNamespacedState,
} from "vuex-composition-helpers";
import { TICKET } from "./namespace";
import { PROJECT } from "../project/namespace";

export default {
  setup() {
    const { items } = ticket();

    const table = reactive({
      headers: [
        { text: "Subject", value: "subject" },
        { text: "Project", value: "project" },
        { text: "PIC", value: "pic" },
        { text: "Type", value: "ticket_type" },
        { text: "Ticket ID", value: "id" },
        { text: "Created date", value: "created_at" },
        { text: "Status", value: "status" },
        { text: "Action", value: "action" },
      ],
    });
    const { fetchtickets } = useNamespacedActions(TICKET, ["fetchtickets"]);
    const { tickets, pagination } = useNamespacedState(TICKET, [
      "tickets",
      "pagination",
    ]);
    const { fetchprojects } = useNamespacedActions(PROJECT, ["fetchprojects"]);
    const { projects } = useNamespacedState(PROJECT, ["projects"]);
    onMounted(() => {
      fetchprojects();
      fetchtickets();
    });
    const severities = ref(["Normal", "Important", "Crucial"]);
    const status = ref([
      "Resolved",
      "In Progress",
      "Waiting Response",
      "Declined",
    ]);
    const {
      validateprivilages,
      validateprivilagesync,
    } = useNamespacedActions("HOMEPAGE", [
      "validateprivilages",
      "validateprivilagesync",
    ]);
    const options = ref({});
    watch(
      options,
      (val) => {
        fetchtickets(val);
      },
      { deep: true }
    );

    const selectStatus = ref([
      {
        text: "Resolved",
        value: "Resolved",
      },
      {
        text: "In Progress",
        value: "In Progress",
      },
      {
        text: "Waiting Response",
        value: "Waiting Response",
      },
      {
        text: "DecLined",
        value: "DecLined",
      },
      {
        text: "Open",
        value: "Open",
      },
    ]);

    return {
      selectStatus,
      options,
      validateprivilages,
      validateprivilagesync,
      status,
      severities,
      projects,
      tickets,
      items,
      table,
      pagination,
    };
  },
};
</script>

<style scoped>
.v-btn {
  height: 45px !important;
}

.title {
  font-style: normal;
  font-weight: bold;
  font-size: 21px;
  line-height: 140%;
  color: #556272;
}

.title-info {
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 140%;
  color: #a5b3bf;
}
.Resolved {
  color: #27ae60;
}
.close {
  color: #a5b3bf;
}
.open {
  color: #eb5757;
}


</style>