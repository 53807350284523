import { ActionContext, Module } from 'vuex'
import axios from '@/lib/axios'

interface State {
  cpumetrics: Array<any>,
  rammetrics: Array<any>,
  inputoutput: Array<any>,
  inputin: Array<any>,
  diskread: Array<any>,
  diskwrite: Array<any>,
}
const store:Module<State, any> = {
  namespaced: true,
  state:{
      cpumetrics:[],
      rammetrics:[],
      inputoutput:[],
      inputin:[],
      diskread:[],
      diskwrite:[],
  },
  mutations:{
      setcpu: (state: State, payload: any) => {state.cpumetrics = payload},
      setram: (state: State, payload: any) => state.rammetrics = payload,

      setinputoutput: (state: State, payload: any) => state.inputoutput = payload,
      setinputin: (state: State, payload: any) => state.inputin = payload,

      setdiskread: (state: State, payload: any) => state.diskread = payload,
      setdiskwrite: (state: State, payload: any) => state.diskwrite = payload,
  },
  actions:{
      getinstancemetricssocket:async(context:ActionContext<State,any>, payload: any)=>{
          if(payload.status == 'stop'){
              axios.instance.get(`metric/all/${payload.id}`,{params:{start: payload.range.start, stop: payload.range.end}}).then((response)=>{
                  const data = response.data.data;
                  context.commit('setcpu', data[0]);
                  context.commit('setram', data[1]);
                  context.commit('setinputoutput', data[2]);
                  context.commit('setinputin', data[3]);
                  context.commit('setdiskread', data[4]);
                  context.commit('setdiskwrite', data[5]);
              })
          }
          const ws = new WebSocket(`${process.env.VUE_APP_WS_BASE_URL_METRIC}/${payload.type}/${payload.id}`);
          if(payload.type=='cpu'){
              ws.onmessage = (res) => {
                  if (window.location.pathname !== payload.path || payload.range) {
                      ws.send('Connection Closed')
                  } else {
                      const cpu = JSON.parse(res.data);
                      context.commit('setcpu', cpu)
                  }
              }
              ws.onopen = () => {
                  console.log('Metric CPU Connected.')
              }
              ws.onclose = () => {
                  console.log('Metric CPU Disconnected.')
              }
          }

          if(payload.type=='ram'){
              ws.onmessage = (res) => {
                  if (window.location.pathname !== payload.path || payload.range) {
                      ws.send('Connection Closed')
                  } else {
                      const ram = JSON.parse(res.data);
                      context.commit('setram', ram)
                  }
              }
              ws.onopen = () => {
                  console.log('Metric Ram Connected.')
              }
              ws.onclose = () => {
                  console.log('Metric Ram Disconnected.')
              }
          }

          if(payload.type=='network-out'){
              ws.onmessage = (res) => {
                  if (window.location.pathname !== payload.path || payload.range) {
                      ws.send('Connection Closed')
                  } else {
                      const data = JSON.parse(res.data);
                      context.commit('setinputoutput', data)
                  }
              }
              ws.onopen = () => {
                  console.log('Metric Network out Connected.')
              }
              ws.onclose = () => {
                  console.log('Metric Network out Disconnected.')
              }
          }

          if(payload.type=='network-in'){
              ws.onmessage = (res) => {
                  if (window.location.pathname !== payload.path || payload.range) {
                      ws.send('Connection Closed')
                  } else {
                      const data = JSON.parse(res.data);
                      context.commit('setinputin', data)
                  }
              }
              ws.onopen = () => {
                  console.log('Metric Network in Connected.')
              }
              ws.onclose = () => {
                  console.log('Metric Network in Disconnected.')
              }
          }

          if(payload.type=='disk-read'){
              ws.onmessage = (res) => {
                  if (window.location.pathname !== payload.path || payload.range) {
                      ws.send('Connection Closed')
                  } else {
                      const data = JSON.parse(res.data);
                      context.commit('setdiskread', data)
                  }
              }
              ws.onopen = () => {
                  console.log('Metric Disk Read Connected.')
              }
              ws.onclose = () => {
                  console.log('Metric Disk Read Disconnected.')
              }
          }

          if(payload.type=='disk-write'){
              ws.onmessage = (res) => {
                  if (window.location.pathname !== payload.path || payload.range) {
                      ws.send('Connection Closed')
                  } else {
                      const data = JSON.parse(res.data);
                      context.commit('setdiskwrite', data)
                  }
              }
              ws.onopen = () => {
                  console.log('Metric Disk Write Connected.')
              }
              ws.onclose = () => {
                  console.log('Metric Disk Write Disconnected.')
              }
          }
      },
  }
}

export default store