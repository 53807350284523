import manageticket from './manageticket.vue'
import page from './page.vue'
import create from './create.vue'
import history from './history.vue'
import { TICKET } from './namespace'
import { iVue } from '../type'
import store from './store'
import beforeenterpermission from '../../lib/beforeenterpermission'
export default {
    install(Vue: iVue) {
        Vue.addModules({ [TICKET]: store })
        Vue.addRoutetoDashboard({
            path: 'manage-ticket',
            component: page,
            beforeEnter: (to, from, next) => {
                beforeenterpermission(Vue, next, 'Ticket', 'disable', false)().then(() => {
                    next()
                }).catch(() => {
                    if (from.path == '/') {
                        next('/')
                    }
                })
            },
            meta: {
                breadcrumb: {
                    to: '/manage-ticket',
                    text: 'Ticket'
                },
            },
            children: [
                {
                    path: '/',
                    component: manageticket,

                },

                {
                    path: 'create-ticket',
                    component: create,
                    meta: {
                        breadcrumb: {
                            to: '/manage-ticket/create-ticket',
                            text: 'Create Ticket'
                        }
                    },
                    beforeEnter: (to, from, next) => {
                        beforeenterpermission(Vue, next, 'Ticket', 'editor', true)().then(() => {
                            next()
                        }).catch(() => {
                            if (from.path == '/') {
                                next('/')
                            }
                        })
                    },
                },
                {
                    path: 'history',
                    component: history,
                    meta: {
                        breadcrumb: {
                            to: '/manage-ticket/history',
                            text: 'Ticket History'
                        }
                    }
                }
            ]

        })

    }
}