var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('div',{staticClass:"row"},[_c('v-col',{attrs:{"cols":"12"}},[_c('v-card',{staticClass:"rounded-lg",attrs:{"flat":""}},[_c('v-container',{staticClass:"pa-8",attrs:{"fluid":""}},[_c('v-row',{staticClass:"pb-5"},[_c('v-col',{staticClass:"d-flex align-center"},[_c('p',{staticClass:"mb-0 fz-21 font-weight-bold"},[_vm._v("Open Support Ticket")]),_c('v-spacer'),_c('v-btn',{staticClass:"secondary ml-7",attrs:{"depressed":"","to":"/ticket/create-ticket"}},[_c('span',{staticClass:"fz-14"},[_vm._v("Create Ticket")])])],1)],1),_c('v-row',[_c('v-col',{attrs:{"cols":"4"}},[_c('v-select',{attrs:{"placeholder":"Project","outlined":"","items":_vm.projects,"item-text":"name","item-value":"id","flat":"","clearable":"","hide-details":""},model:{value:(_vm.projectselected),callback:function ($$v) {_vm.projectselected=$$v},expression:"projectselected"}})],1),_c('v-col',{attrs:{"cols":"4"}},[_c('v-select',{attrs:{"placeholder":"Request Type","outlined":"","items":_vm.requestsItem,"item-text":"name","item-value":"id","flat":"","clearable":"","hide-details":""},model:{value:(_vm.typeselected),callback:function ($$v) {_vm.typeselected=$$v},expression:"typeselected"}})],1),_c('v-col',{attrs:{"cols":"4"}},[_c('v-select',{attrs:{"placeholder":"Status","outlined":"","items":_vm.statusitem,"item-text":"name","item-value":"id","flat":"","clearable":"","hide-details":""},model:{value:(_vm.statusselected),callback:function ($$v) {_vm.statusselected=$$v},expression:"statusselected"}})],1)],1),(_vm.tickets.length)?_c('v-data-table',{staticClass:"elevation-0",attrs:{"headers":_vm.table.headers,"items":_vm.tickets,"items-per-page":10,"server-items-length":_vm.pagination.count,"options":_vm.options,"hide-default-footer":"","item-key":"createddate"},on:{"update:options":function($event){_vm.options=$event}},scopedSlots:_vm._u([{key:"item.subject",fn:function(ref){
var item = ref.item;
return [_c('a',{on:{"click":function () {
                    _vm.$router.push(("/ticket-detail/" + (item.id)));
                  }}},[_vm._v(" "+_vm._s(item.subject)+" ")])]}},{key:"item.project_name",fn:function(ref){
                  var item = ref.item;
return [(item.project_name !== '')?_c('div',[_vm._v(" "+_vm._s(item.project_name)+" ")]):_c('div',[_vm._v(" - ")])]}},{key:"item.created_at",fn:function(ref){
                  var item = ref.item;
return [_c('p',{staticClass:"ml-3 mt-4"},[_vm._v(" "+_vm._s(_vm.$moment(new Date(item.created_at)).format("DD/MM/YYYY"))+" ")])]}},{key:"item.status",fn:function(ref){
                  var item = ref.item;
return [(item.status === 'Open')?_c('span',{staticClass:"cyan--text"},[_vm._v(_vm._s(item.status))]):_vm._e(),(item.status === 'Resolved')?_c('span',{staticClass:"green--text"},[_vm._v(_vm._s(item.status))]):_vm._e(),(item.status === 'Rejected')?_c('span',{staticClass:"red--text"},[_vm._v(_vm._s(item.status))]):_vm._e(),(item.status === 'Onhold')?_c('span',{},[_vm._v(_vm._s(item.status))]):_vm._e(),(item.status === 'In Progress')?_c('span',{staticClass:"grey--text"},[_vm._v(_vm._s(item.status))]):_vm._e(),(item.status === 'Closed')?_c('span',{staticClass:"primary--text"},[_vm._v(_vm._s(item.status))]):_vm._e(),(item.status === 'Assigned')?_c('span',{},[_vm._v(" "+_vm._s(item.status))]):_vm._e(),(item.status === 'Released Onhold')?_c('span',{},[_vm._v(" "+_vm._s(item.status))]):_vm._e(),(item.status === '')?_c('span',{},[_vm._v(" - ")]):_vm._e()]}},{key:"footer",fn:function(ref){
                  var props = ref.props;
return [_c('custom-footer-datatable',{attrs:{"props":props},on:{"input":function (val) {
                    _vm.options = val;
                  }}})]}}],null,false,2717925479)}):_vm._e(),(!_vm.tickets.length)?_c('v-simple-table',{staticStyle:{"border":"1px solid #cad6e1"}},[_c('thead',[_c('tr',[_c('th',[_vm._v("Subjet")]),_c('th',[_vm._v("Ticket ID")]),_c('th',[_vm._v("Created Date")]),_c('th',[_vm._v("Status")])])]),_c('tbody',[_c('tr',[_c('td',{staticClass:"py-8",attrs:{"colspan":"4"}},[_c('v-row',{attrs:{"align":"center","justify":"center"}},[_c('div',{staticClass:"text-center w-50"},[_c('h3',[_vm._v("No Ticket")])])]),_c('v-row',[_c('v-col',{staticClass:"d-flex flex-row justify-center"},[_c('router-link',{attrs:{"to":"/ticket/create-ticket"}},[_c('v-btn',{staticClass:"secondary",attrs:{"depressed":"","height":"55","width":"465"}},[_c('span',{staticClass:"fz-14"},[_vm._v("Create Ticket")])])],1)],1)],1)],1)])])]):_vm._e()],1)],1)],1)],1)])}
var staticRenderFns = []

export { render, staticRenderFns }