import axios from '@/lib/axios'
import { ActionContext, Module } from 'vuex'
interface State {
    limitservices: any,
}
const store: Module<State, any> = {
    namespaced: true,
    state: {
        limitservices:{
            instance_usage: null,
            storage_usage: null,
            network_usage: null
        }
    },
    getters:{
        instance_usage : (state: State)=> state.limitservices.instance_usage,
        storage_usage: (state: State)=>state.limitservices.storage_usage,
        network_usage: (state: State)=>state.limitservices.network_usage,
        unlimited: (state: State)=>state.limitservices.unlimited
    },
    mutations:{
        setlimitservices : (state: State, payload: any)=>state.limitservices = payload
    },
    actions:{
        fetchlimitservice: async(context: ActionContext<State, any>, payload: any)=>{
            console.log(payload);
            if(payload=="" || payload==undefined ){
                return axios.instance.get('/user/usage').then(response=>{
                    const {data} = response.data
                    context.commit('setlimitservices',data)
                })
            }else{
                return axios.instance.get('/user/usage?project_id='+payload).then(response=>{
                    const {data} = response.data
                    context.commit('setlimitservices',data)
                })
            }
           
        }
    }
    
}

export default store
