







































import Vue from "vue";
import { useDialog } from "@/composable/usedialog";
import {
  useNamespacedActions,
  useNamespacedMutations,
} from "vuex-composition-helpers";
import { SUPERADMINMANAGEOS } from "./namespace";
const module = Vue.extend({
  props: ["value", "item"],
  setup(props: any, context) {
    const { dialog } = useDialog(props, context);
    const { deleteVersion } = useNamespacedActions(SUPERADMINMANAGEOS, [
      "deleteVersion",
    ]);
    const { setopendialogcommonsuccess } = useNamespacedMutations("DASHBOARD", [
      "setopendialogcommonsuccess",
    ]);

    const Destroy = async () => {
      return await deleteVersion(props.item).then(() => {
        context.emit("deleted");
        dialog.value = false;
        setopendialogcommonsuccess(true);
      });
    };
    return {
      dialog,
      Destroy,
    };
  },
});
export default module;
