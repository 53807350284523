<template>
  <v-menu offset-y>
    <template v-slot:activator="{ on, attrs }">
      <v-btn
        class="primary--text"
        color="white"
        elevation="0"
        v-bind="attrs"
        v-on="on"
      >
        More <v-icon>mdi-chevron-down</v-icon>
      </v-btn>
    </template>

    <v-list>
      <v-list-item>
        <v-list-item-title>
          <span class="cursor-pointer primary--text" @click="$emit('detail')">
            <b>Detail</b>
          </span>
        </v-list-item-title>
      </v-list-item>

      <v-list-item>
        <v-list-item-title>
          <span
            class="cursor-pointer primary--text"
            @click="$emit('editPopupOn')"
          >
            <b>Edit</b>
          </span>
        </v-list-item-title>
      </v-list-item>

      <v-list-item>
        <v-list-item-title>
          <span class="cursor-pointer error--text" @click="$emit('delete')">
            <b>Delete</b>
          </span>
        </v-list-item-title>
      </v-list-item>
    </v-list>
  </v-menu>
</template>

<script>
export default {
  props: ["id"],
};
</script>
