import { render, staticRenderFns } from "./manageSales.vue?vue&type=template&id=537a1226&scoped=true&"
import script from "./manageSales.vue?vue&type=script&lang=js&"
export * from "./manageSales.vue?vue&type=script&lang=js&"
import style0 from "./manageSales.vue?vue&type=style&index=0&id=537a1226&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "537a1226",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VCard } from 'vuetify/lib/components/VCard';
import { VDataTable } from 'vuetify/lib/components/VDataTable';
import { VSwitch } from 'vuetify/lib/components/VSwitch';
installComponents(component, {VCard,VDataTable,VSwitch})
