import axios from '@/lib/axios'
import {ActionContext, Module} from 'vuex'
import libProject from '@/lib/project'
interface State {
    vpcs: [],
    vpc: {},
    isLoading: Boolean,
}

function sortByName( a:any, b:any ) {
    if ( a.name < b.name ) return -1
    if ( a.name > b.name ) return 1
    return 0;
}

const store: Module<State,any>= {
    namespaced: true,
    state: {
        vpcs: [],
        vpc: {},
        isLoading: false,
    },
    mutations:{
        setvpcs : (state: State, payload:any) => state.vpcs = payload,
        setVPC : (state: State, payload:any) => state.vpc = payload,
        setIsLoading : (state: State, payload:any) => state.isLoading = payload
    },
    actions:{
        CREATE_VPC: async(context: ActionContext<State, any>, payload:any) => {
            try {
                context.commit('setIsLoading', true)
                const activeProjectID = await libProject.getActiveProjectID()
                if (!activeProjectID) {
                    context.commit('setIsLoading', false)
                    context.dispatch('HOMEPAGE/showErrorToast', 'Could not find project data.', { root: true })
                }
                payload.project_id = activeProjectID
                const response = await axios.instance.post('/network/vpc', payload)
                context.commit('setIsLoading', false)
                return response.data
            } catch (e) {
                let errorMessage = 'A problem encountered while creating VPC'
                const error = e.response.data.data
                if (error && error.includes('Service activation')) errorMessage = error
                if (error && error.includes('vpc voucher has reached limit')) errorMessage = 'Router quota of trial voucher has reached the limit'
                // if (e && e.response && e.response.data && e.response.data.data.includes('Your balance not enough')) {
                //     context.commit('DASHBOARD/setopendialogbalance', true, { root: true })
                // }

                context.commit('setIsLoading', false)
                context.dispatch('HOMEPAGE/showErrorToast', errorMessage, { root: true })
            }
        },
        UPDATE_VPC: async(context: ActionContext<State, any>, payload:any) => {
            try {
                context.commit('setIsLoading', true)
                const { id } = payload
                const response = await axios.instance.put(`/network/vpc/${id}`, payload)
                context.dispatch('HOMEPAGE/showSuccessToast', 'VPC Network has been successfully updated.', { root: true })
                context.commit('setIsLoading', false)
                return response.data
            } catch (e) {
                context.commit('setIsLoading', false)
                context.dispatch('HOMEPAGE/showErrorToast', 'VPC Network failed to update.', { root: true })
            }
        },
        fetchvpcs: async(context: ActionContext<State, any>, payload:any)=>{
            let data : any = []
            const activeProjectID = await libProject.getActiveProjectID()
            if (!activeProjectID) context.dispatch('HOMEPAGE/showErrorToast', 'Could not find project data.', { root: true })

            const response:any = await axios.instance.get(`/network/vpc/project/${activeProjectID}`)
            if(response.status === 200) {
                response.data.data.map((item:any) => data.push(item))
                data.sort(sortByName)
                context.commit('setvpcs', data)
            }
            return data
        },
        DELETE_VPC: async(context: ActionContext<State, any>, payload:any)=>{
            try {
                context.commit('setIsLoading', true)
                const { id, resources }:any = payload
                if(resources > 0) return context.dispatch('HOMEPAGE/showErrorToast', 'VPC Network is being used. Destroy all resources within first and please try again.', {root: true})
                const response:any = await axios.instance.delete(`/network/vpc/delete/${id}`)
                if(response.status === 200) {
                    context.dispatch('fetchvpcs')
                    context.dispatch('HOMEPAGE/showSuccessToast', 'VPC Network has been successfully deleted', {root: true})
                    context.commit('setIsLoading', false)
                } context.commit('setIsLoading', false)
                return response.data
            } catch (e) {
                const errorMessage = e.response.data.data
                if(errorMessage.includes('SubnetInUse')) context.dispatch('HOMEPAGE/showErrorToast', 'VPC Network is being used. Destroy all resources within first and please try again.', {root: true})
                else context.dispatch('HOMEPAGE/showErrorToast', 'Failed to delete VPC Network', {root: true})
                context.commit('setIsLoading', false)
            }
        },
        DETAIL_VPC: async(context: ActionContext<State, any>, payload:any)=>{
            const { id }:any = payload
            const response:any = await axios.instance.get(`/network/vpc/detail/${id}`)
            if(response.status === 200) {
                context.commit('setVPC', response.data.data)
                return response.data
            } else return context.dispatch('HOMEPAGE/showErrorToast', 'Failed to get VPC Network data', {root: true})
        },
    }
}
export default store