


























































































































































































import dialogeditorganization from "./editorganization.dialog.vue";
import memberlistcard from "./memberlist.card.vue";
import { useMember } from "./usemember";
import { defineComponent, reactive, ref } from "@vue/composition-api";
import dialogUpgradePremium from "./dialogUpgradePemium.vue";
import dialogUpgradePermiumSucces from "./dialogUpgradePremiumSuccess.vue";
import Vue from "vue";
import { useNamespacedActions } from "vuex-composition-helpers";
import { ORGANIZATION } from "./namespace";
export default defineComponent({
  components: {
    dialogeditorganization,
    memberlistcard,
    dialogUpgradePremium,
    dialogUpgradePermiumSucces,
  },
  setup() {
    let currentOrg = ref({});
    const setCurrentOrg = (payload: any) => {
      currentOrg.value = payload;
    };
    const { fetchorganizationByUser } = useNamespacedActions(ORGANIZATION, [
      "fetchorganizationByUser",
    ]);
    const {
      validateprivilages,
      validateprivilagesync,
    } = useNamespacedActions("HOMEPAGE", [
      "validateprivilages",
      "validateprivilagesync",
    ]);
    return {
      validateprivilages,
      validateprivilagesync,
      fetchorganizationByUser,
      setCurrentOrg,
      ...useMember(),
      dialogaddmember: ref(""),
      dialogdeletemember: ref(false),
      dialogeditemember: ref(false),
      currentOrg,
      opendialogupgradepremium: ref(false),
      opendialogupgradepremiumsuccess: ref(false),
    };
  },
  data() {
    return {
      dialogedit: false,
      dialog: false,
      headers: [
        {
          text: "Name",
          value: "name",
        },
        {
          text: "Project",
          value: "project",
        },
        {
          text: "Role",
          value: "role",
        },
        {
          text: "Status",
          value: "status",
        },
        {
          text: "Action",
          value: "action",
          width: "150px",
        },
      ],
    };
  },
  computed: {
    service_type() {
      return localStorage.getItem('service_type')
    }
  },
  methods: {
    uppercaseFirstLetter(string: String) {
      return string.charAt(0).toUpperCase() + string.slice(1)
    }
  },
  created() {
    this.fetchorganizationByUser()
      .then((data: any) => {
        this.setCurrentOrg(data);
      })
      .catch(() => {
        this.$router.replace("/notfound");
      });
    this.fetchmembers();
  },
});
