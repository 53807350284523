








































































































































import { onMounted, ref, watch } from "@vue/composition-api";
const {useCommitment} =  require('./useCommitment.js')
import Vue from "vue";
import { IDRformat } from "../../lib/formatter";
import {
  useNamespacedActions,
  useNamespacedMutations,
  useNamespacedState,
} from "vuex-composition-helpers";
import { SUPERADMINMANAGEORG } from "../superadmin-manageorganization/namespace";
import DialogAddCommitment from "./DialogAddCommitment.vue";
import DialogApproveCommitment from "./DialogApproveCommitment.vue";
import { SUPERADMINCOMMITMENT } from "./namespace";
import moment from 'moment'

export default Vue.extend({
  components: {
    DialogAddCommitment,
    DialogApproveCommitment,
  },
  setup() {
    const Composable = { ...useCommitment() }
    const {
        pagination, 
        options, 
        search,
        fetchorganizations,
        organizations,
        isLoading,
        filterOrganization, 
        searchorganization,
        commitments,
        GET_ALL_COMMITMENTS,
        SELECT_COMMITMENT,
    } = Composable
    const headers = [
      {
        text: "Organization",
        value: "organization",
      },
      {
        text: "Project Name",
        value: "project",
      },
      {
        text: "Fee",
        value: "fee",
      },
      {
        text: "Discount",
        value: "discount",
      },
      {
        text: "Duration",
        value: "duration",
      },
      {
        text: "Status",
        value: "status",
      },
      {
        text: "Date",
        value: "date",
      },
      {
        text: "Action",
        value: "action",
        align: "center",
      },
    ];
    onMounted(async () => {
      fetchorganizations();
      GET_ALL_COMMITMENTS();
    });
    const orgselected = ref();
    let searchtimeout: any = setTimeout(() => {}, 500);
    watch(search, (val) => {
      clearTimeout(searchtimeout);
      searchtimeout = setTimeout(() => {
        options.value.page = 1;
        options.value.search = val;
      }, 500)
    })
    
    return {
      moment,
      SELECT_COMMITMENT,
      IDRformat,
      search,
      orgselected,
      pagination,
      options,
      openDialogApprove: ref(false),
      opendialogadd: ref(false),
      opendialogedit: ref(false),
      headers,
      fetchorganizations,
      commitments,
      organizations,
      isLoading,
      filterOrganization, 
      searchorganization,
      GET_ALL_COMMITMENTS,
    };
  },
});
