<template>
  <div>
    <v-card flat class="rounded-lg pa-0">
      <v-card-text class="pa-7">
        <v-row>
          <v-col class="d-flex flex-row align-center">
            <v-icon class="mr-4" style="font-size: 55px" color="#F1F2F2"
              >mdi-circle</v-icon
            >
            <div>
              <div class="headline font-weight-bold">{{ project.name }}</div>
              <p style="font-size: 12px" class="font--text mb-0">
                Update your project information under Settings
              </p>
            </div>
            <v-spacer />
            <v-btn
              class="secondary"
              height="50"
              width="150"
              depressed
              to="settings"
              append
            >
              <span>Project Setting</span>
            </v-btn>
          </v-col>
        </v-row>
      </v-card-text>
    </v-card>
    <div style="height: 30px; width: auto" />
    <v-card flat class="rounded-lg pa-7">
      <div class="headline font-weight-bold font--text mb-4">Instance</div>

      <instance />
      <!-- <div class="headline font-weight-bold font--text">Object Storage</div>

      <objectstorage /> -->

      <div class="headline font-weight-bold font--text  mb-4">Storage</div>
      <storage />

      <div class="headline font-weight-bold font--text  mb-4">Image</div>

      <resourceimages />
    </v-card>
  </div>
</template>

<script>
import storage from "../organization/resource/storage";
// import objectstorage from "../organization/resource/objectstorage";
import instance from "../organization/resource/instance";
import resourceimages from "../organization/resource/images";
import { ref, onMounted, reactive } from "@vue/composition-api";
import {
  useNamespacedState,
  useNamespacedActions,
  useNamespacedMutations,
} from "vuex-composition-helpers";
import { SUPERADMIN } from "../superadmin/namespace";
export default {
  props: ["project"],
  components: {
    storage,
    // objectstorage,
    instance,
    resourceimages,
  },
  setup() {
    const loading = ref(true);

    const instance = reactive({
      headers: [
        { text: "Name", value: "name" },
        { text: "IP Address", value: "ip" },
        { text: "Region", value: "region" },
        { text: "Created Date", value: "createddate" },
        { text: "Tags", value: "tags" },
        { text: "Action", sortable: false, align: "center", value: "action" },
      ],
    });

    const object_storages = reactive({
      headers: [
        { text: "Name", value: "name" },
        { text: "Size", value: "size" },
        { text: "Created Date", value: "createddate" },
        { text: "Action", sortable: false, align: "center", value: "action" },
      ],
    });

    const storages = reactive({
      headers: [
        { text: "Name", value: "name" },
        { text: "Region", value: "region" },
        { text: "Size", value: "size" },
        { text: "Instance Name", value: "intance" },
        { text: "Created Date", value: "created" },
        { text: "Action", value: "action", sortable: false },
      ],
      data: [],
    });

    const images = reactive({
      headers: [
        { text: "Name", value: "name" },
        { text: "Size", value: "size" },
        { text: "Created Date", value: "created" },
        { text: "Action", value: "action", sortable: false },
      ],
      data: [],
    });

    const { fetchObjectStorages } = useNamespacedActions("OBJECTSTORAGE", [
      "fetchObjectStorages",
    ]);

    const { fetchinstances } = useNamespacedActions("INSTANCE", [
      "fetchinstances",
    ]);

    const { setCurrentProj } = useNamespacedMutations(SUPERADMIN, [
      "setCurrentProj",
    ]);

    onMounted(() => {
      fetchObjectStorages(),
        fetchinstances(),
        setTimeout(() => {
          loading.value = false;
        }, 3000);
    });
    return {
      loading,
      instance,
      storages,
      images,
      object_storages,
      ...useNamespacedState("OBJECTSTORAGE", ["objectStorages"]),
      ...useNamespacedState("INSTANCE", ["instances"]),
    };
  },
};
</script>

<style scoped>
.table-title {
  font-family: Fira Sans;
  font-style: normal;
  font-weight: bold;
  font-size: 21px;
  line-height: 140%;
  color: #556272;
  padding-top: 30px;
}

.table-status {
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 22px;
  text-transform: capitalize;
  color: #a5b3bf;
  padding-top: 5px;
  padding-bottom: 20px;
}
</style>