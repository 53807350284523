import { render, staticRenderFns } from "./InvoiceDuePeriod.vue?vue&type=template&id=90e61ee2&scoped=true&"
import script from "./InvoiceDuePeriod.vue?vue&type=script&lang=js&"
export * from "./InvoiceDuePeriod.vue?vue&type=script&lang=js&"
import style0 from "./InvoiceDuePeriod.vue?vue&type=style&index=0&id=90e61ee2&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "90e61ee2",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VBtn } from 'vuetify/lib/components/VBtn';
installComponents(component, {VBtn})
