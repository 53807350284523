


































import { useDialog } from '@/composable/usedialog';
import Vue from 'vue'
const module =  Vue.extend({
  props: ["value",'message','title'],
  setup(props, context){
      const {dialog} = useDialog(props, context) 
    return{
      dialog
    }
  },
});
export default module
