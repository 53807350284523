import { INSTANCE } from '../namespace'
import { MANAGEINSTANCEREGION } from '@/modules/superadmin-manageflavors/namespace'
import {
    useNamespacedActions,
    useNamespacedState,
  } from "vuex-composition-helpers";
import { ref, computed, watch} from  '@vue/composition-api'
// const { required } = require("@vuelidate/validators");
// const useVuelidate = require("@vuelidate/core").default;
export const useRegion = ()=>{
    const regionidselected = ref('');
    const { fetchRegions: fetchregions } = useNamespacedActions(MANAGEINSTANCEREGION, ['fetchRegions'])
    const { regions } = useNamespacedState(MANAGEINSTANCEREGION, ['regions'])
    const selectregions = computed(() => {
        return regions.value.map((e) => ({
          text: e.name,
          value: e,
        }));
      });
    
    const regionselected = ref('')
    const selectedRegion = ref(null)

    // watch(selectregions, () =>{
    //   regionidselected.value = selectregions.value.filter(r => r.value == 'TKP')[0].value
    //   regionselected.value = regions.value.filter(r => r.region == 'TKP')[0]
    //   selectedRegion.value = regions.value.filter(r => r.region == 'TKP')[0]
    // })

    return {
        selectedRegion,
        regionidselected,
        fetchregions,
        regions,
        selectregions,
        regionselected,
    }
}