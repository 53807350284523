var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-card',{staticClass:"rounded-lg pa-4 mt-3",attrs:{"flat":""}},[_c('v-card-text',{staticClass:"d-flex flex-row align-center"},[_c('v-row',[_c('v-col',{staticClass:"d-flex flex-row align-center"},[_c('p',{staticClass:"mb-0 font-weight-bold fz-21"},[_vm._v("Manage Region Instance")]),_c('v-spacer'),_c('div',{staticStyle:{"height":"auto","width":"30px"}}),_c('v-btn',{staticClass:"secondary",attrs:{"id":"CreateNewType","depressed":"","height":"50","width":"150"},on:{"click":function () {
                _vm.selectedRegion = null
                _vm.openDialogAdd = true
              }}},[_c('span',{staticClass:"fz-14"},[_vm._v("Create New Region")])])],1)],1)],1),_c('v-card-text',[_c('v-row',[_c('v-col',[_c('v-data-table',{attrs:{"id":"my-table","hide-default-footer":"","headers":_vm.headers,"items-per-page":_vm.regionPaginator.limit,"search":_vm.search,"items":_vm.regions,"options":_vm.options},on:{"update:options":function($event){_vm.options=$event}},scopedSlots:_vm._u([{key:"item.description",fn:function(ref){
              var item = ref.item;
return [_vm._v(" "+_vm._s(item.description ? item.description : '-')+" ")]}},{key:"item.cpu_limit",fn:function(ref){
              var item = ref.item;
return [_c('span',{class:{'error--text': item.cpu_usage >= item.cpu_limit}},[_vm._v(" "+_vm._s(item.cpu_limit ? ((item.cpu_usage) + "/" + (item.cpu_limit)) : '-')+" ")])]}},{key:"item.gpu_limit",fn:function(ref){
              var item = ref.item;
return [_c('span',{class:{'error--text': item.gpu_usage >= item.gpu_limit}},[_vm._v(" "+_vm._s(item.gpu_limit ? ((item.gpu_usage) + "/" + (item.gpu_limit)) : '-')+" ")])]}},{key:"item.ram_limit",fn:function(ref){
              var item = ref.item;
return [_c('span',{class:{'error--text': item.ram_usage >= item.ram_limit}},[_vm._v(" "+_vm._s(item.ram_limit ? ((item.ram_usage) + "/" + (item.ram_limit)) : '-')+" ")])]}},{key:"item.action",fn:function(ref){
              var item = ref.item;
return [_c('span',{staticClass:"primary--text pr-3 edit",on:{"click":function () {
                  _vm.selectedRegion = item
                  _vm.openDialogAdd = true
                }}},[_vm._v("Edit ")]),_c('span',{staticClass:"error--text pr-3 delete ml-4",on:{"click":function () {
                  _vm.selectedRegion = item
                  _vm.openDialogDelete = true
                }}},[_vm._v("Delete ")])]}},{key:"footer",fn:function(ref){
                var props = ref.props;
return [_c('custom-footer-datatable',{attrs:{"props":props},on:{"input":function (val) {
                    _vm.options = Object.assign({}, _vm.options, val);
                  }}})]}}])})],1)],1)],1),_c('dialogAdd',{attrs:{"selectedRegion":_vm.selectedRegion},on:{"create":function (payload) { return _vm.doCreate(payload); },"update":function (payload) { return _vm.doUpdate(payload); }},model:{value:(_vm.openDialogAdd),callback:function ($$v) {_vm.openDialogAdd=$$v},expression:"openDialogAdd"}}),_c('dialogDelete',{attrs:{"selectedRegion":_vm.selectedRegion},on:{"delete":function (payload) { return _vm.doDelete(payload); }},model:{value:(_vm.openDialogDelete),callback:function ($$v) {_vm.openDialogDelete=$$v},expression:"openDialogDelete"}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }