





















































import Vue from 'vue'
import { useDialog } from '@/composable/usedialog'
import { useNamespacedActions } from 'vuex-composition-helpers'

const module =  Vue.extend({
  props: ["value",'selectedsecurity'],
  setup(props: any, context){
    const { dialog } = useDialog(props, context)
    const { DELETE_SECURITY } = useNamespacedActions('SECURITY', ['DELETE_SECURITY']) 
    return {
      Delete: async () => {
        await DELETE_SECURITY({ id: props.selectedsecurity.id })
      },
      dialog
    }
  },
});
export default module
