<template>
  <v-dialog max-width="500" v-model="dialog">
    <v-card class="pt-7">
      <v-card-text>
        <div class="headline font-weight-bold font--text">Add Sales</div>

        <p class="mt-4">
          <b>Full Name</b>
        </p>

        <v-text-field
          v-model="fullname"
          :error-messages="fullname ? '' : fullname_error"
          outlined
          placeholder="Enter Full Name"
        />

        <p class="mt-4">
          <b>Phone Number</b>
        </p>

        <v-text-field
          v-mask="[
            /[1-9]/,
            /[0-9]/,
            /[0-9]/,
            ' ',
            /[0-9]/,
            /[0-9]/,
            /[0-9]/,
            ' ',
            /[0-9]/,
            /[0-9]/,
            /[0-9]/,
            ' ',
            /[0-9]/,
            /[0-9]/,
            /[0-9]/,
          ]"
          prefix="+62"
          v-model="phone_number"
          :error-messages="phone_number ? '' : phone_number_error"
          outlined
          placeholder="Enter Phone Number"
        />

        <p class="mt-4">
          <b>Email</b>
        </p>

        <v-text-field
          type="email"
          v-model="email"
          :error-messages="email ? '' : email_error"
          outlined
          placeholder="Enter Email"
        />

        <p class="mt-4">
          <b>Description</b>
        </p>

        <v-text-field
          v-model="description"
          :error-messages="description ? '' : description_error"
          outlined
          placeholder="Enter Description"
        />

        <v-row class="mt-8">
          <v-col>
            <v-btn
              depressed
              height="45"
              block
              class="accent"
              @click="$emit('input', false)"
              :disabled="loading"
            >
              Cancel

              <!-- loading indicator -->
              <beat-loader
                v-if="loading"
                :loading="loading"
                :color="'white'"
                :size="'10px'"
                class="ml-2"
              />
            </v-btn>
          </v-col>

          <v-col>
            <v-btn
              depressed
              height="45"
              block
              class="secondary"
              @click="submit"
              :disabled="loading"
            >
              Add

              <!-- loading indicator -->
              <beat-loader
                v-if="loading"
                :loading="loading"
                :color="'white'"
                :size="'10px'"
                class="ml-2"
              />
            </v-btn>
          </v-col>
        </v-row>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>
import { computed } from "@vue/composition-api";
import api from "@/lib/api";

export default {
  props: ["value", "item"],
  setup(props, { emit }) {
    const dialog = computed({
      get: () => props.value,
      set: (val) => {
        emit("input", val);
      },
    });

    return {
      dialog,
    };
  },
  data() {
    return {
      loading: false,

      // fields
      fullname: "",
      fullname_error: "",
      phone_number: "",
      phone_number_error: "",
      email: "",
      email_error: "",
      description: "",
      description_error: "",
    };
  },
  methods: {
    async submit() {
      const requiredFields = [
        "fullname",
        "phone_number",
        "email",
        "description",
      ];

      // clear errors
      requiredFields.forEach((field) => {
        if (!this[field]) {
          this[field + "_error"] = "";
        }
      });

      const noEmptyField = requiredFields.every((field) => this[field]);

      if (noEmptyField) {
        this.loading = true;

        try {
          const { fullname, phone_number, email, description } = this;

          const payload = {
            fullname,
            phone_number,
            email,
            description,
          };

          await api.POST("/superadmin/sales", payload);

          this.$emit("input", false);
          this.$emit("update");

          this.$store.dispatch(
            "HOMEPAGE/showSuccessToast",
            "Data successfully submitted",
            { root: true }
          );
        } catch (err) {
          let errmsg = "Sorry, an error occurred while submitting data";
          const thereIsErrorMessage = err && err.response && err.response.data;

          if (thereIsErrorMessage && err.response.data.message) {
            errmsg = err.response.data.message;
          }

          if (
            thereIsErrorMessage &&
            err.response.data.data !== "record not found"
          ) {
            this.$store.dispatch("HOMEPAGE/showErrorToast", errmsg);
          }
        }

        this.loading = false;
      } else {
        requiredFields.forEach((field) => {
          if (!this[field]) {
            this[field + "_error"] = "Value is required";
          }
        });

        this.$store.dispatch(
          "HOMEPAGE/showErrorToast",
          "There is an empty field or error field"
        );
      }
    },
  },
};
</script>
