<template>
  <div>
    <div class="row">
      <v-col cols="12">
        <v-card class="rounded-lg" flat>
          <v-container class="pa-8">
            <v-row>
              <v-col cols="8">
                <div class="fz-21 font-weight-bold font--text">
                  Create Ticket asd
                </div>
              </v-col>
            </v-row>

            <v-row>
              <v-col cols="6">
                <v-text-field
                  maxlength="50"
                  v-model="subject"
                  outlined
                  placeholder="Enter Subject Ticket"
                  :error-messages="
                    $v.subject.$errors.length
                      ? $v.subject.$errors[0].$message
                      : ''
                  "
                  required
                ></v-text-field>
              </v-col>
            </v-row>

            <v-row>
              <v-col class="d-flex flex-row">
                <v-card
                  height="80"
                  min-width="372"
                  outlined
                  class="rounded-lg pl-2"
                  flat
                  :style="{
                    border: type == '1' ? '1px solid #2C94D2' : '',
                  }"
                  @click="
                    () => {
                      type = '1';
                      getReqType();
                    }
                  "
                >
                  <div class="d-flex pa-1 align-center">
                    <div>
                      <v-radio-group v-model="type" class="mr-3 ml-1">
                        <v-radio value="1"></v-radio>
                      </v-radio-group>
                    </div>
                    <div>
                      <p class="fz-16 font-weight-bold mb-2">
                        Account and Billing Support
                      </p>
                      <p class="fz-12 accent--text mb-0">
                        Assistance with account and billing related inquires
                      </p>
                    </div>
                  </div>
                </v-card>
                <div style="height: auto; width: 40px" />
                <v-card
                  outlined
                  height="80"
                  min-width="372"
                  flat
                  class="rounded-lg pl-2"
                  :style="{
                    border: type == '2' ? '1px solid #2C94D2' : '',
                  }"
                  @click="
                    () => {
                      type = '2';
                      getReqType();
                    }
                  "
                >
                  <div class="d-flex pa-1 align-center">
                    <div>
                      <v-radio-group v-model="type" class="mr-3 ml-1">
                        <v-radio value="2"></v-radio>
                      </v-radio-group>
                    </div>
                    <div>
                      <p class="fz-16 font-weight-bold mb-2">
                        Technical Support
                      </p>
                      <p class="fz-12 accent--text mb-0">
                        Service related technical issues
                      </p>
                    </div>
                  </div>
                </v-card>
              </v-col>
            </v-row>
            <br />
            <v-divider />
            <br />
            <v-row>
              <v-col cols="6">
                <div class="fz-20 font-weight-bold font--text">
                  Ticket Details
                </div>

                <br />
                <h4 class="pt-3">Project Name*</h4>
                <v-select
                  placeholder="Choose Project Name"
                  :items="projects"
                  v-model="project"
                  item-text="name"
                  return-object
                  outlined
                  single-line
                  append-icon="mdi-chevron-down"
                  :error-messages="
                    $v.project.$errors.length
                      ? $v.project.$errors[0].$message
                      : ''
                  "
                ></v-select>

                <h4 class="pt-3">Description</h4>
                <v-textarea
                  v-model="limittype"
                  outlined
                  name="input-7-4"
                  placeholder="Enter Description"
                  :error-messages="
                    $v.limittype.$errors.length
                      ? $v.limittype.$errors[0].$message
                      : ''
                  "
                ></v-textarea>

                <h4 class="pt-3">Request Type</h4>
                <v-select
                  placeholder="Choose Request Type"
                  :items="requestItemFilter"
                  v-model="request"
                  item-text="name"
                  item-value="id"
                  outlined
                  single-line
                  append-icon="mdi-chevron-down"
                  :error-messages="
                    $v.request.$errors.length
                      ? $v.request.$errors[0].$message
                      : ''
                  "
                ></v-select>

                <h4 class="pt-3">Site</h4>
                <v-select
                  placeholder="Choose Site"
                  :items="sitesitem"
                  v-model="website"
                  item-text="name"
                  item-value="id"
                  outlined
                  single-line
                  append-icon="mdi-chevron-down"
                  :error-messages="
                    $v.website.$errors.length
                      ? $v.website.$errors[0].$message
                      : ''
                  "
                ></v-select>
              </v-col>
            </v-row>

            <v-row>
              <v-col cols="6">
                <v-btn to="/ticket" class="accent" dark depressed block
                  ><span class="fz-14">Cancel</span></v-btn
                >
              </v-col>
              <v-col cols="6">
                <v-btn depressed class="secondary" block @click="submitTicket()"
                  ><span class="fz-14">Submit</span></v-btn
                >
              </v-col>
            </v-row>
          </v-container>
        </v-card>
      </v-col>
    </div>
  </div>
</template>

<style scoped>
.v-btn {
  height: 55px !important;
}
</style>

<script>
import ticket from "./ticket";
import { onMounted, ref } from "@vue/composition-api";
import useVuelidate from "@vuelidate/core";
import { required } from "@vuelidate/validators";
import localstorage from "@/lib/localstorage";
import { TICKET } from "./namespace";
import {
  useNamespacedActions,
  useNamespacedState,
} from "vuex-composition-helpers";
import { PROJECT } from "../project/namespace";

export default {
  setup(props, context) {
    const test = ref("");
    const test2 = ref("");
    const types = ref(["Billing", "Technical"]);
    const loadingselectrequest = ref(false);
    const severities = ref([
      {
        text: "Low",
        value: "low",
      },
      {
        text: "Normal",
        value: "normal",
      },
      {
        text: "Medium",
        value: "medium",
      },
      {
        text: "High",
        value: "high",
      },
    ]);

    function check1() {
      test.value = "checked";
      test2.value = "";
    }

    function check2() {
      test.value = "";
      test2.value = "checked";
    }

    const type = ref("");
    const limittype = ref("");
    const subject = ref("");
    const project = ref("");
    const request = ref("");
    const requestItemFilter = ref([]);
    const serviceCategory = ref(1);
    const website = ref(2);
    const status = ref("");
    const impact = ref(3);
    const urgency = ref(3);
    const prioritys = ref("");
    const category = ref("");
    const subcategory = ref("");
    const resultPri = ref("");
    const resultPriorityId = ref("");
    const locals = JSON.parse(localstorage.getItem("currentOrg"));
    const user_id = locals.user_id;
    const customer_name = locals.name;
    // console.log('Org ID : ' + locals.id)
    // console.log('User ID : ' + user_id)

    const $v = useVuelidate(
      {
        subject: { required: required },
        type: { required: required },
        limittype: { required: required },
        project: { required: required },
        request: { required: required },
        serviceCategory: { required: required },
        website: { required: required },
        impact: { required: required },
      },
      {
        subject,
        type,
        limittype,
        project,
        request,
        serviceCategory,
        website,
        impact,
      }
    );
    const severity = ref("");
    const { addticket } = useNamespacedActions(TICKET, ["addticket"]);
    function submitTicket() {
      Priority();
      $v.value.$touch();
      if ($v.value.$errors.length) return;
      const Value = {
        organization_id: locals.id,
        project_id: project.value.id,
        project_name: project.value.name,
        user_id: user_id,
        subject: subject.value,
        description: limittype.value,
        customer_name: customer_name,
        ticket_type_id: parseInt(type.value),
        request_type_id: request.value,
        impact_id: impact.value,
        urgency_id: urgency.value,
        priority_id: resultPriorityId.value,
        status_id: 5,
        category_id: 1,
        subcategory_id: 1,
        item_id: 1,
        site_id: website.value,
        service_category_id: serviceCategory.value,
      }; //
      addticket(Value).then(() => {
        context.root.$router.replace("/ticket");
      }).catch((err) => {
        let errmsg = "Sorry, an error occurred while submitting data";
        const thereIsErrorMessage = err && err.response && err.response.data;

        if (thereIsErrorMessage && err.response.data.data) {
          errmsg = err.response.data.data;
        }

        if (
          thereIsErrorMessage &&
          err.response.data.data !== "record not found"
        ) {
          this.$store.dispatch("HOMEPAGE/showErrorToast", errmsg);
        }
      });
    }
    const { fetchprojects } = useNamespacedActions(PROJECT, ["fetchprojects"]);
    const { projects } = useNamespacedState(PROJECT, ["projects"]);

    const {
      fetchrequest,
      fetchstatus,
      fetchsites,
      fetchimpact,
      fetchurgency,
      fetchcategory,
      fetchsubcategory,
      fetchitem,
      fetchpriority,
      fetchServiceCategory,
    } = useNamespacedActions(TICKET, [
      "fetchrequest",
      "fetchstatus",
      "fetchsites",
      "fetchimpact",
      "fetchurgency",
      "fetchcategory",
      "fetchsubcategory",
      "fetchitem",
      "fetchpriority",
      "fetchServiceCategory",
    ]);
    const {
      requestsItem,
      statusitem,
      sitesitem,
      impactitem,
      urgencyitem,
      priorityitems,
      categoryitem,
      subcategoryitem,
      items,
      serviceCategoryItem,
    } = useNamespacedState(TICKET, [
      "requestsItem",
      "statusitem",
      "sitesitem",
      "impactitem",
      "urgencyitem",
      "categoryitem",
      "subcategoryitem",
      "items",
      "priorityitems",
      "serviceCategoryItem",
    ]);

    const priItem = priorityitems.value;

    const Priority = () => {
      // console.log(priorityitems.value);
      priorityitems.value.map((x) => {
        if (x.impact_id == impact.value && x.urgency_id == urgency.value) {
          resultPriorityId.value = x.id;
        }
      });
    };
    const getReqType = () => {
      const resultTe = requestsItem.value.filter(
        (x) => x.ticket_type_id == type.value
      );
      return (requestItemFilter.value = resultTe);
    };

    onMounted(() => {
      fetchprojects();
      fetchrequest();
      fetchstatus();
      fetchsites();
      fetchimpact();
      fetchurgency();
      fetchpriority();
      fetchcategory();
      fetchsubcategory();
      fetchitem();
      fetchServiceCategory();
    });
    return {
      priorityitems,
      prioritys,
      resultPri,
      resultPriorityId,
      categoryitem,
      category,
      items,
      subcategoryitem,
      subcategory,
      urgencyitem,
      urgency,
      impactitem,
      impact,
      sitesitem,
      website,
      requestsItem,
      request,
      statusitem,
      status,
      projects,
      project,
      $v,
      subject,
      types,
      type,
      severities,
      severity,
      limittype,
      priItem,
      check1,
      check2,
      test,
      test2,
      submitTicket,
      Priority,
      serviceCategoryItem,
      serviceCategory,
      getReqType,
      requestItemFilter,
    };
  },
};
</script>

<style lang="scss" scoped>
.v-card {
  ::v-deep .v-card--link::before {
    background: none;
  }
}

.title {
  font-style: normal;
  font-weight: bold;
  font-size: 21px;
  line-height: 140%;
  color: #556272;
}

.title-info {
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 140%;
  color: #a5b3bf;
}
h4 {
  padding-bottom: 10px;
}

input[type="radio"] {
  width: 30px;
  height: 30px;
}

.v-input {
  ::v-deep .v-text-field__details .v-messages__wrapper :not(.error--text) {
    z-index: -1;
    width: 100px;
  }
}
</style>
