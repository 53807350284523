import axios from '@/lib/axios'

const store = {
    namespaced: true,
    state:{
        voucher: {},
        vouchers: [],
        pagination: {},
        isLoading: false,
    },
    mutations:{
        setIsLoading: (state, payload) => state.isLoading = payload,
        setVoucher: (state, payload) => state.voucher = payload,
        setVouchers: (state, payload) => state.vouchers = payload,
        setPagination: (state, payload) => state.pagination = payload,
    },
    actions:{
        fetchVouchers: async({ commit, dispatch}, payload) => {
            const response = await axios.instance.get(`/user/org-voucher`)
            commit('setVouchers', response.data.data.filter(x => x.voucher.id))               
            return response
        },
        claimVoucher: async({ commit, dispatch}, code) => {
            try {
                commit('setIsLoading', true)
                const response = await axios.instance.get(`/user/voucher-claim/${code}`)
                commit('setIsLoading', false) 
                dispatch('HOMEPAGE/showSuccessToast', 'Voucher has been successfully claimed!', { root: true }) 
                dispatch('fetchVouchers') 
                return response.data
            } catch (e) {
                let errorMessage = 'A problem encountered while claiming voucher.'
                const error = e.response.data.data
                if (error.includes('voucher code is not valid')) errorMessage = 'Invalid voucher code!'
                else if (error.includes('voucher has already redeemed')) errorMessage = 'You already redeemed this voucher.'

                commit('setIsLoading', false) 
                dispatch('HOMEPAGE/showErrorToast', errorMessage, { root: true }) 
            }
        },
    }
}

export default store