import { useNamespacedActions, useNamespacedState } from 'vuex-composition-helpers'
export const useVoucher = () => {
    
    const { 
        fetchVouchers,
        claimVoucher,
    } = useNamespacedActions('VOUCHER', [
        'fetchVouchers',
        'claimVoucher',
    ])
    
    return {
        ...useNamespacedState('VOUCHER', [
            'vouchers',
            'voucher',
            'isLoading',
        ]),
        fetchVouchers,
        claimVoucher,
    }
}