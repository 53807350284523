<template>
  <v-card-text>
    <p v-if="loading" class="text-center">
      <beat-loader color="gray" size="10px" class="mr-2" />
    </p>

    <template v-else>
      <!-- success message -->
      <successmessage v-if="success" />

      <!-- form -->
      <template v-else>
        <Choice
          v-if="!type && personalStatus"
          :type="type"
          v-on:changeType="changeType($event)"
        />

        <NewSignupForm
          v-if="type"
          :loading="loading"
          :user-type="type"
          @changeSuccess="success = true"
        />
      </template>

      <!-- tnc popup -->
      <Tncpopup v-if="data" v-model="tnc" :data="data" />
    </template>
  </v-card-text>
</template>

<script>
import Choice from "@/modules/signup/choice.vue";
import Successmessage from "@/modules/signup/successmessage.vue";
import Tncpopup from "./tncpopup.vue";
import api from "@/lib/api";
import localstorage from "@/lib/localstorage";
import NewSignupForm from "./new-signup-form.vue";

export default {
  components: {
    Choice,
    Successmessage,
    Tncpopup,
    NewSignupForm,
  },
  data() {
    return {
      tnc: false,
      success: false,
      loading: false,
      data: null,
      personalStatus: false,
      type: null,
      npwpDummy: "",
    };
  },
  methods: {
    changeType(char) {
      this.type = char;
    },
    async getEula() {
      var res = await api.GET("/useradmin/eula?form_name=Sign Up Form");
      if (res.code === 200) {
        this.data = res.data;
        this.tnc = true;
      }
    },
    async getPersonalStatus() {
      this.loading = true;

      try {
        const res = await api.GET("/user/personal-status");
        this.personalStatus = res.data.personal_active;
        this.type = !res.data.personal_active ? "C" : null;
        localstorage.setItem("personalStatus", res.data.personal_active);
      } catch (err) {
        let errmsg = "Sorry, an error occurred while displaying data";
        const thereIsErrorMessage = err && err.response && err.response.data;

        if (thereIsErrorMessage && err.response.data.message) {
          errmsg = err.response.data.message;
        }

        if (
          thereIsErrorMessage &&
          err.response.data.data !== "record not found"
        ) {
          this.$store.dispatch("HOMEPAGE/showErrorToast", errmsg);
        }
      }

      this.loading = false;
    },
  },
  mounted() {
    this.getPersonalStatus();
  },
};
</script>
