import { iVue } from '../type'
import manageorganization from './manageorganization.vue'
import { GODMODEMANAGEORG, SUPERADMINMANAGEORG } from './namespace'
import store from './store'
import OrganizationDetail from './organization-detail.vue'
import MainOrganizationDetail from './main.organization-detail.vue'
import AddProject from "./addProject.vue";
import AddProjectMain from "./addproject.main.vue";
import ProjectDetailsNew from "./projectdetailsnew.vue";
import ProjectEdit from "./projectedit.vue";
import ProjectDetail from './projectdetails.vue'
import ProjectDetailMain from './projectdetail.main.vue'
import ProjectSettings from '../project/setting.vue'
import Page from '@/components/page.vue'


export default {
  install(Vue: iVue) {
    Vue.addRoutetoDashboard({
      path: '/manage-organization',
      component: Page,
      meta: {
        breadcrumb: {
          text: 'Manage Organization',
          to: '/manage-organization',
        },
      },
      children: [
        {
          path: '',
          component: manageorganization,
        },
        {
          path: '/organization-detail/:organizationid',

          component: MainOrganizationDetail,
          meta: {
            breadcrumb: {
              text: 'Organization Detail',
              to: '/organization-detail/:organizationid'
            }
          },
          children: [
            {
              path: '', component: OrganizationDetail,
              name: 'organization-detail-orgnizationid'
            },
            {
              path: "project/add",
              component: AddProjectMain,
              meta: {
                breadcrumb: {
                  text: "Add Project",
                  to: "/organization-detail/:organizationid/project/add",
                },
              },
              children: [
                {
                  path: "",
                  component: AddProject,
                  name: "organization-detail-orgnizationid-addproject",
                },
              ],
            },
            {
              path: 'project/:project', component: ProjectDetailMain,
              meta: {
                breadcrumb: {
                  text: ':project',
                  to: '/organization-detail/:organizationid/project/:project'
                }
              },
              children: [
                {
                  path: '', component: ProjectDetail,
                  name: 'organization-detail-orgnizationid-projectname',
                  
                },
                {
                  path: "detail",
                  component: ProjectDetailsNew,
                  name:
                    "organization-detail-orgnizationid-projectname-detail",
                  meta: {
                    breadcrumb: {
                      text: "Detail",
                      to:
                        "/organization-detail/:organizationid/project/:project/detail",
                    },
                  },
                },
                {
                  path: "edit",
                  component: ProjectEdit,
                  name:
                    "organization-detail-orgnizationid-projectname-edit",
                  meta: {
                    breadcrumb: {
                      text: "Edit",
                      to:
                        "/organization-detail/:organizationid/project/:project/edit",
                    },
                  },
                },
                {
                  path: 'settings', component: ProjectSettings,
                  name: 'organization-detail-orgnizationid-projectname-settings',
                  meta: {
                    breadcrumb: {
                      text: 'Settings',
                      to: '/organization-detail/:organizationid/project/:project/settings'
                    }
                  },
                }
                
              ]
            }
          ]
        }
      ]
    })
    Vue.addModules({ [SUPERADMINMANAGEORG]: store })
    Vue.addModules({ [GODMODEMANAGEORG]: store })
  }
}