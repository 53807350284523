

































import { defineComponent } from "@vue/composition-api";

const module = defineComponent({
  props: {
    value: {
      type: Boolean,
      default: false,
    },
  },
});
export default module;
