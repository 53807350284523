<template>
  <v-dialog v-model="dialog">
    <v-card class="pt-7">
      <v-card-text>
        <v-data-table
          hide-default-footer
          :headers="tblHeaders"
          :items-per-page="10"
          :items="item"
          class="mt-8"
        >
          <template v-slot:item.service_name="{ item }">
            {{ item.name || "N/A" }}
          </template>

          <template v-slot:item.specification="{ item }">
            <!-- {{ item.package.vcpu }} vCPU / {{ item.package.ram }} GB Memory -->
            {{ item.specification }}
          </template>

          <template v-slot:item.price="{ item }">
            IDR {{ item.price.value.toLocaleString("en") }}
            <span v-if="serviceName === 'SNAPSHOT'">/ GB</span>
            <span v-else>/ {{ item.price.per }}</span>
          </template>

          <template v-slot:item.billed="{ item }">
            IDR {{ item.billed.toLocaleString("en") }}
          </template>

          <template v-slot:item.type="{ item }">
            {{ item.status.toUpperCase() }}
          </template>

          <template v-slot:footer="{ props }" v-if="item.length">
            <custom-footer-datatable :props="props" />
          </template>
        </v-data-table>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>
import { defineComponent, computed } from "@vue/composition-api";

export default defineComponent({
  props: ["value", "item", "serviceName"],
  setup(props, { emit }) {
    const dialog = computed({
      get: () => props.value,
      set: (val) => {
        emit("input", val);
      },
    });

    const tblHeaders = computed(() => {
      return [
        { text: "Service Name", value: "service_name", sortable: false },
        { text: "Specification", value: "specification", sortable: false },
        { text: "Price", value: "price", sortable: false },
        { text: "Billing Type", value: "billing_type", sortable: false },
        { text: "Status", value: "type", sortable: false },
        { text: "Billed Estimate", value: "billed" },
      ];
    });

    return {
      dialog,
      tblHeaders,
    };
  },
});
</script>
