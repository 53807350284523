import { INSTANCE } from '../namespace'
import {
    useNamespacedActions,
    useNamespacedState,
  } from "vuex-composition-helpers";
import { ref, computed} from  '@vue/composition-api'
export const useVpc = ()=>{
    const vpcidselected = ref("");
    const { fetchvpcs } = useNamespacedActions(INSTANCE,['fetchvpcs'])
    const { vpcs } = useNamespacedState(INSTANCE, ['vpcs'])
    const selectvpcs = computed(() => {
      return vpcs.value.map((e) => ({
          text: e.name,
          value: e.vpcid,
        }));
    });
    return {
        vpcidselected,
        fetchvpcs,
        vpcs,
        selectvpcs
    }
}