<template>
  <v-card-text>
    <v-container>
      <v-col class="justify-center">
        <h1
          style="
            text-align: center;
            font-weight: 800;
            font-size: 28px;
            line-height: 36px;
            letter-spacing: 0.04em;
            font-family: Mont;
            font-style: normal;
          "
        >
          {{isNeedCreateProject ? '1' : '2' }}. CREATE NEW PROJECT
        </h1>
        <p
          style="font-size: 20px; color: #a5b3bf; line-height: 27.24px"
          class="text-center mt-5"
        >
          You have to create project <br />for using the app
        </p>
        <v-form ref="form" style="margin-top: 40px" lazy-validation>
          <v-alert v-if="errorMessage" color="red" type="error"
            >{{ errorMessage }}
          </v-alert>

          <v-text-field
            id="projectName"
            flat
            outlined
            maxlength="30"
            v-model="projectName"
            placeholder="Project Name"
            required
            style="margin-bottom: 22px"
            :error-messages="
              projectNameValidation.$errors.length
                ? projectNameValidation.$errors[0].$message
                : ''
            "
            :disabled="loading"
            :filled="loading"
          ></v-text-field>

          <v-textarea
            rows="5"
            id="description"
            flat
            outlined
            maxlength="255"
            v-model="description"
            placeholder="Description Project"
            required
            style="margin-bottom: 22px"
            :error-messages="
              descriptionValidation.$errors.length
                ? descriptionValidation.$errors[0].$message
                : ''
            "
            :disabled="loading"
            :filled="loading"
          ></v-textarea>

          <v-radio-group
            v-model="payment_method"
            row
            :disabled="loading"
            :filled="loading"
            :error-messages="
              descriptionValidation.$errors.length
                ? descriptionValidation.$errors[0].$message
                : ''
            "
          >
            <v-row>
              <v-col v-if="!hidePrepaid">
                <v-radio label="Prepaid" value="prepaid">
                  <template v-slot:label>
                    <span class="fs-14">Prepaid</span>
                  </template>
                </v-radio>
              </v-col>

              <v-col v-if="!hidePostpaid">
                <v-radio label="Postpaid" value="postpaid">
                  <template v-slot:label>
                    <span class="fs-14">Postpaid</span>
                  </template>
                </v-radio>
              </v-col>
            </v-row>
          </v-radio-group>

          <p v-if="payment_method === 'postpaid'" :style="{ color: 'red', backgroundColor: 'pink', padding: '10px' }">
            Please contact our Team to activate your Postpaid account.
          </p>

          <v-btn
            id="submit"
            :disabled="isLoading"
            block
            height="50"
            depressed
            color="secondary"
            class="mr-4 mt-7 text-capitalize font-weight-bold"
            @click="save"
          >
            <beat-loader
              v-if="isLoading"
              :loading="isLoading"
              :color="'white'"
              :size="'10px'"
              class="ml-2"
            ></beat-loader>
            <span v-else>Create</span>
          </v-btn>
          <br />
        </v-form>
      </v-col>
    </v-container>
  </v-card-text>
</template>
<script>
import { useAction } from "./useAction";
import { mapGetters } from "vuex";
import localstorage from "@/lib/localstorage";
import api from "@/lib/api";

// const hidePrepaid = process.env.VUE_APP_DEV_MODE !== 'fase-1.3'

export default {
  computed: {
    ...mapGetters({
      loggedUser: "CREATEORGANIZATION/getProfile",
      isLoading: "CREATENEWPROJECT/isLoading",
    }),
    isNeedCreateProject() {
      return localstorage.getItem("need_create_project")
    },
    hidePrepaid() {
      return !this.isPersonal
    },
    hidePostpaid() {
      return this.isPersonal
    },
    isPersonal() {
      return localstorage.getItem('type') === 'P' && this.personalStatus
    }
  },
  data() {
    return {
      errorMessage: undefined,
      success: false,
      personalStatus: false,
      loading: false,
    };
  },
  async mounted() {
    // Gak tau kenapa, after create organization,
    // terus gak bisa nge call API create project (error 400)
    // harus relogin dlu (refresh token) baru bisa :D
    this.$store.dispatch("HOMEPAGE/relogin");
    const token = localstorage.getItem("token")
    if (!token) {
      this.$router.push("/")
    }

    await this.getPersonalStatus();
    
    this.$nextTick(() => {
      this.payment_method = this.hidePostpaid ? 'prepaid' : 'postpaid'
    })
  },
  methods: {
    async getPersonalStatus() {
      this.loading = true;

      try {
        const res = await api.GET("/user/personal-status");
        this.personalStatus = res.data.personal_active;
      } catch (err) {
        let errmsg = "Sorry, an error occurred while displaying data";
        const thereIsErrorMessage = err && err.response && err.response.data;

        if (thereIsErrorMessage && err.response.data.message) {
          errmsg = err.response.data.message;
        }

        if (
          thereIsErrorMessage &&
          err.response.data.data !== "record not found"
        ) {
          this.$store.dispatch("HOMEPAGE/showErrorToast", errmsg);
        }
      }

      this.loading = false;
    },
  },
  setup(props, context) {
    const Composable = useAction();
    const { save: Save } = Composable;
    const $auth = context.root.$auth;
    return {
      ...Composable,
      async save() {
        try {
          let result = await Save();
          if (result.code === 200) {
            localstorage.removeItem("need_create_project");
            location.href = "/";
          } else {
            let error = result.response.data.data
            if (error.includes('already exist')) this.errorMessage = 'Project name already exists'
            else this.errorMessage = 'Internal server error. Please contact administrator.'
          }
        } catch (err) {
          let response_data = err.response.data.response_data;
          if (!response_data)
            response_data =
              "Internal server error. Please contact administrator for more help.";
          this.errorMessage = response_data;
        }
      },
    };
  },
};
</script>
<style lang="scss" scoped>
.v-card__text {
  ::v-deep .v-btn__content {
    font-size: 16px;
    font-style: normal;
    font-weight: 700;
    line-height: 20px;
    letter-spacing: 0.02em;
  }
}

.v-input {
  ::v-deep .v-input__slot {
    margin-bottom: 0;
  }
}
</style>