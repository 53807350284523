var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-card',{staticClass:"pa-7 rounded-0 rounded-b-lg",attrs:{"flat":""}},[_c('v-row',[_c('v-col',{staticClass:"d-flex align-center justify-end pt-0 pb-4"},[(_vm.vpcs.length)?_c('v-btn',{staticClass:"secondary fz-14",attrs:{"width":"150","depressed":"","to":"/network/vpc/create"}},[_vm._v(" Create VPC ")]):_vm._e()],1)],1),(_vm.vpcs.length)?_c('v-data-table',{staticClass:"elevation-0",attrs:{"headers":_vm.headers,"items":_vm.vpcs,"items-per-page":10,"hide-default-footer":""},scopedSlots:_vm._u([{key:"item.name",fn:function(ref){
var item = ref.item;
return [_c('router-link',{attrs:{"to":{ path: ("/network/vpc/" + (item.id)) }}},[_vm._v(" "+_vm._s(item.name)+" ")])]}},{key:"item.region",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.region)+" ")]}},{key:"item.ip",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.ip_prefix)+" / "+_vm._s(item.network_size)+" ")]}},{key:"item.resource",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.instances.length ? ((item.instances.length) + " Resources") : "-")+" ")]}},{key:"item.action",fn:function(ref){
var item = ref.item;
return [_c('router-link',{staticClass:"primary--text edit",attrs:{"to":{ path: '/network/vpc/edit', query: { id: item.id } }}},[_vm._v("Edit")]),_c('span',{staticClass:"error--text pr-3 delete ml-4",on:{"click":function () {
              _vm.validateprivilages(['Network', 'editor', true]).then(function () {
                _vm.vpcselected = item;
                _vm.opendialogdeletevpc = true;
              });
            }}},[_vm._v("Delete")])]}},{key:"footer",fn:function(ref){
            var props = ref.props;
return [(_vm.vpcs.length > 5)?_c('custom-footer-datatable',{attrs:{"props":props}}):_vm._e()]}}],null,false,2406753866)}):_c('v-card',{staticClass:"rounded-lg",attrs:{"flat":"","outlined":""}},[_c('v-row',[_c('v-col',{attrs:{"md":"12"}},[_c('v-card',{staticClass:"d-flex rounded-lg flex-grow-1 flex-column text-center ma-auto",attrs:{"flat":""}},[_c('v-card-text',{staticStyle:{"padding-top":"72px","padding-bottom":"78px"}},[_c('p',{staticClass:"font-weight-bold",staticStyle:{"font-size":"16px","margin-bottom":"10px","text-transform":"capitalize"}},[_vm._v(" Looks like you don’t have any VPC Networks ")]),_c('v-row',[_c('v-col',[_c('p',{staticStyle:{"font-size":"12px","color":"#a5b3bf","margin-bottom":"20px"}},[_vm._v(" Resources assigned to the same VPC network can communicate securely with each other via private IP. Communication with resources outside the VPC must use a public network IP. ")])])],1),_c('v-row',[_c('v-col',[_c('v-btn',{staticClass:"secondary",attrs:{"width":"100%","append":"","max-width":"300","height":"50","depressed":"","exact":"","to":"/network/vpc/create"}},[_vm._v(" Create VPC Network ")])],1)],1)],1)],1)],1)],1)],1)],1),_c('dialogDeleteVpc',{on:{"confirm":function () {
        _vm.deleteSelectedVPC();
      }},model:{value:(_vm.opendialogdeletevpc),callback:function ($$v) {_vm.opendialogdeletevpc=$$v},expression:"opendialogdeletevpc"}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }