import network from './network'
import domain from './domain'
import dns from './dns/page'
import main from './main'
import floatingip from './floatingip/page'
import loadbalance from './loadbalance/page'
import vpc from './vpc/page'
import page from './page'
import createloadbalance from './loadbalance/create'
import createvpc from './vpc/create'
import editloadbalance from './loadbalance/edit'
import { NETWORKVPC } from './vpc/namespace'
import vpcstore from './vpc/store'
import { NETWORKFLOATINGIP } from './floatingip/namespace'
import folatingipstore from './floatingip/store'
import { NETWORKLOADBALANCER } from './loadbalance/namespace'
import loadbalancerstore from './loadbalance/store'
import vpcdetail from './vpc/vpc_detail/vpcdetail.vue'
import loadbalancerdetail from './loadbalance/loadbalance_detail/loadbalance_detail.vue'
import beforeenterpermission from '../../lib/beforeenterpermission'
import beforeselectfirst from '../../lib/beforeselectfirst'
import handlepermissionprivilages from '../../lib/handlepermissionprivilages'

export default {
    install(Vue, { VueEvent }) {
        Vue.addModules({ [NETWORKFLOATINGIP]: folatingipstore })
        Vue.addModules({ [NETWORKVPC]: vpcstore });
        Vue.addModules({ [NETWORKLOADBALANCER]: loadbalancerstore });
        Vue.addRoutetoDashboard({
            path: '/network',
            components: {
                default: page,
            },
            beforeEnter: (to, from, next) => {
                beforeenterpermission(Vue, next, 'Network', 'disable', false)().then(()=>{
                    return beforeselectfirst(Vue)()
                }).then(()=>{
                    next()
                }).catch(()=>{
                    if(from.path == '/'){
                      next('/')
                    }
                  })
            },
            meta: {
                permission: ['network-view'],
                breadcrumb: {
                    text: 'Network',
                    to: '/network'
                }
            },
            children: [
                {
                    path: '',
                    component: network,
                    meta: {
                        permission: ['network-view']
                    },
                    children: [
                        { path: '', redirect: 'vpc' },
                        {
                            path: 'dns',
                            component: dns,
                            name : 'dns',
                            meta : {
                                breadcrumb : {
                                    text : 'DNS',
                                    to : '/network/dns'
                                }
                            },

                        }, 

                        {
                            path: 'floatingip',
                            component: floatingip,
                            name: 'floatingip',
                            meta: {
                                breadcrumb: {
                                    text: 'Floating IP',
                                    to: '/network/floatingip'
                                }
                            }
                        },

                        {
                            path: 'loadbalancer',
                            name: 'loadbalancer',
                            component: loadbalance,
                            meta: {
                                breadcrumb: {
                                    text: 'Load Balancer',
                                    to: '/network/loadbalancer'
                                }
                            }
                        },

                        {
                            path: 'vpc',
                            name: 'vpc',
                            component: vpc,
                            meta: {
                                breadcrumb: {
                                    text: 'VPC',
                                    to: '/network/vpc'
                                }
                            }
                        }



                    ]
                },


                {
                    beforeEnter: (to, from, next) => {
                        beforeenterpermission(Vue, next, 'Network', 'editor', true)().then(()=>{
                            next()
                        }).catch(()=>{
                            if(from.path == '/'){
                              next('/')
                            }
                          })
                    },
                    path: 'dns/new-record',
                    component: domain,
                    name: 'newrecord',
                    meta: {
                        breadcrumb: {
                            text: 'New Record',
                            to: '/network/dns/new-record'
                        }
                    }
                },

                {
                    beforeEnter: (to, from, next) => {
                        handlepermissionprivilages(Vue, next, 'Network', 'editor', true)().then(()=>{
                            next()
                        }).catch(()=>{
                            if(from.path == '/'){
                              next('/')
                            }
                          })
                    },
                    path: 'loadbalancer/create',
                    component: main,
                    meta: {
                        breadcrumb: {
                            text: 'Load Balancer',
                            to: '/network/loadbalancer'
                        }
                    },
                    children: [
                        {
                            path: '',
                            component: createloadbalance,
                            meta: {
                                breadcrumb: {
                                    text: 'Create Load Balance',
                                    to: '/network/loadbalancer/create'
                                }
                            },
                        }
                    ]
                },
                {
                    path: 'loadbalancer/:loadbalancer_id',
                    component: main,
                    meta: {
                        breadcrumb: {
                            text: 'Load Balancer',
                            to: '/network/loadbalancer'
                        }
                    },
                    children: [
                        {
                            path: '',
                            redirect: '/network/loadbalancer/:loadbalancer_id/instances'
                        },
                        {
                            path: 'instances',
                            component: loadbalancerdetail,
                            meta: {
                                breadcrumb: {
                                    text: 'Instances',
                                    to: '/network/loadbalancer/:loadbalancer_id/instances'
                                }
                            },
                        },
                        {
                            path: 'settings',
                            component: loadbalancerdetail,
                            meta: {
                                breadcrumb: {
                                    text: 'Settings',
                                    to: '/network/loadbalancer/:loadbalancer_id/settings'
                                }
                            },
                        },
                    ]
                },

                {
                    beforeEnter: (to, from, next) => {
                        beforeenterpermission(Vue, next, 'Network', 'editor', true)().then(()=>{
                            next()
                        }).catch(()=>{
                            if(from.path == '/'){
                              next('/')
                            }
                          })
                    },
                    path: 'loadbalancer/edit',
                    component: editloadbalance,
                    meta: {
                        breadcrumb: {
                            text: 'Load Balancer',
                            to: 'loadbalancer/edit'
                        }
                    }
                },

                {
                    path: 'vpc/create',
                    beforeEnter: (to, from, next) => {
                        handlepermissionprivilages(Vue, next, 'Network', 'editor', true)().then(()=>{
                            next()
                        }).catch(()=>{
                            if(from.path == '/'){
                              next('/')
                            }
                          })
                    },
                    component: main,
                    meta: {
                        breadcrumb: { 
                            text: 'VPC',
                            to: '/network/vpc'
                        }
                    },
                    children: [
                        {
                            path: '', component: createvpc,
                            meta: {
                                breadcrumb: {
                                    text: 'Create VPC',
                                    to: '/network/vpc/create'
                                }
                            }
                        }
                    ]
                },

                {
                    beforeEnter: (to, from, next) => {
                        handlepermissionprivilages(Vue, next, 'Network', 'editor', true)().then(()=>{
                            next()
                        }).catch(()=>{
                            if(from.path == '/'){
                              next('/')
                            }
                          })
                    },
                    path: 'vpc/edit',
                    component: createvpc,
                    meta: {
                        breadcrumb: {
                            text: 'Edit VPC',
                            to: ''
                        }
                    }
                },
                {
                    path: 'vpc/:vpcid',
                    component: main,
                    meta: {
                        breadcrumb: {
                            text: 'VPC',
                            to: '/network/vpc'
                        }
                    },
                    children: [{
                        path: '',
                        component: vpcdetail,
                        meta: {
                            breadcrumb: {
                                text: 'Detail VPC',
                                to: ''
                            }
                        }
                    }]
                }
            ]

        }
        )

        VueEvent.listen('after-store-created', (store) => {
            store.commit('ROLEPERMISSION/addmenumodulepermission',
                {
                    header: 'MANAGE', headerno: 1,
                    name: 'Network', link: 'network', icon: 'networkIcon', sort: 4, permission: ['network-sidemenu']
                }
            )
            store.commit('ROLEPERMISSION/addpermissions', 'network-view')
        })
    }
}