



import localstorage from "@/lib/localstorage";
import { defineComponent } from "@vue/composition-api";
import {
  useNamespacedMutations,
  useNamespacedState,
} from "vuex-composition-helpers";
import { SUPERADMIN } from "../superadmin/namespace";
import { fetchorgbyid } from "./fetchorgbyid";
export default defineComponent({
  setup() {
    const { setCurrentOrg, setCurrentProj } = useNamespacedMutations(
      SUPERADMIN,
      ["setCurrentOrg", "setCurrentProj"]
    );
    const { currentOrg } = useNamespacedState(SUPERADMIN, ["currentOrg"]);
    return {
      setCurrentOrg,
      setCurrentProj,
      currentOrg,
    };
  },

  beforeRouteUpdate: function (to: any, from: any, next: any) {
    if (to.name.match(/^organization-detail-orgnizationid-projectname/))
      return next();
    fetchorgbyid(to.params.organizationid)
      .then((response) => {
        const org = response?.data.data;
        const currentProj = localstorage.getItem("currentProj");
        this.setCurrentOrg(org);
        if (currentProj) this.setCurrentProj(JSON.parse(currentProj));
        next();
      })
      .catch(() => {
        next("/notfound");
      });
  },
  beforeRouteEnter: function (to: any, from: any, next: any) {
    fetchorgbyid(to.params.organizationid)
      .then((response) => {
        const org = response?.data.data;
        next((vm: any) => {
          const currentProj = localstorage.getItem("currentProj");
          vm.setCurrentOrg(org);
          if (currentProj) vm.setCurrentProj(JSON.parse(currentProj));
        });
      })
      .catch(() => {
        next("/notfound");
      });
  },
});
