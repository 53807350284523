



















































































































































































































































import { defineComponent, ref, Ref, watch } from "@vue/composition-api";
import { useMember } from "./useMember";
import dialogEditUser from "./dialogEditUser.vue";
import dialogAddUser from "./dialogAddUser.vue";
import dialogDeleteUser from "./dialogDeleteUser.vue";

import dialogRecendInviteUser from "./dialogResendInviteUser.vue";

import dialogProjectList from "./dialogProjectList.vue";
import dialogDetailUser from "./dialogDetailUser.vue";

const { Parser, transforms: { unwind, flatten } } = require("json2csv");
import {
  useNamespacedActions,
  useNamespacedState,
} from "vuex-composition-helpers";
import { GODMODEMANAGEUSER } from "./namespace";
import dialogActivateUser from './dialogActivateUser.vue'
export default defineComponent({
  components: {
    dialogEditUser,
    dialogAddUser,
    dialogDeleteUser,
    dialogProjectList,
    dialogDetailUser,
    dialogActivateUser,
    dialogRecendInviteUser
  },
  setup() {
    const memberselectedvalue: Ref<any> = ref("");
    const Composable = { ...useMember() };
    const { membersselected, members } = Composable;
    const headers = ref([
      {
        text: "Fullname",
        value: "fullname",
      },
      {
        text: "Email",
        value: "email",
      },
      {
        text: "Phone",
        value: "phone_number",
      },
     
      {
        text: "Date",
        value: "joindate",
      },
      {
        text: "User Status",
        value: "statususer",
      },
      {
        text: "Status",
        value: "status",
      },
      {
        text: "Action",
        value: "action",
      },
    ]);
    // const Download = () => {
    //   // const fields = ['id',	'organization_id',	'fullname',	'firstname',	'lastname',	'email',	'phone_number',	'npwp_privacy',	'role_id',	'photo',	'status',	'created_at',	'updated_at',	'deleted_at',	'organization',	'userproject',	'role']
    //   const json2csvParser = new Parser( { quote: '', transforms:[unwind({ paths:['userproject'], blankOut: true }),flatten('__')]});
    //   const csv = json2csvParser.parse(membersselected.value);
    //   FileDownload(csv, 'users.csv');
    // };
  
    const { changestatus } = useNamespacedActions(GODMODEMANAGEUSER, [
      "changestatus",
    ]);
    
    const opendialogactivateuser = ref(false)
    return {
      changestatus,
      opendialogactivateuser,
      headers,
      // Download,
      opendialogdetailuser: ref(false),
      opendialogprojectlist: ref(false),
      opendialogdeleteuser: ref(false),
      opendialogresendinviteuser: ref(false),
      opendialogadduser: ref(false),
      memberselectedvalue,
      opendialogedituser: ref(false),
      log(e: any) {
      },
      ...Composable,
      members,
      show: ($event: any, item: any) => {
        memberselectedvalue.value = item;
        console.log(memberselectedvalue);
      },
    };
  },
  created() {
    this.fetchmembers();

  },
});
