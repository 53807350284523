
































































































import { defineComponent, ref, reactive, computed } from "@vue/composition-api";
import { object, string } from "yup";
import * as yup from "yup";
import { EMATERAI } from "./namespace";
import moment from "moment";
import {
  useNamespacedActions,
  useNamespacedState,
} from "vuex-composition-helpers";

export default defineComponent({
  props: {
    item: {
      type: Object,
      required: true,
      default: () => {},
    },
  },
  setup(props, { emit }) {
    const { editStamp, fetchStamps } = useNamespacedActions(EMATERAI, [
      "editStamp",
      "fetchStamps",
    ]);
    const { isLoading } = useNamespacedState(EMATERAI, ["isLoading"]);
    const formSchema = object().shape({
      no_materai: string().required("Value is required"),
      formated_expire_date: string()
        .required("Value is required")
        .nullable(),
    });
    const form: any = reactive({
      no_materai: props.item.stamp_code,
      expire_date: moment(props.item.expire_date).format("YYYY-MM-DD"),
      formated_expire_date: computed(() => {
        if (form.expire_date) {
          const [year, month, day] = form.expire_date.split("-");
          return `${day}/${month}/${year}`;
        } else {
          return null;
        }
      }),
    });
    let errors: any = reactive({
      no_materai: "",
      formated_expire_date: "",
    });
    const modal = ref(true);
    const toggleDatePicker = ref(false);
    const isValid = ref(false);
    const validate = (field: any) => {
      formSchema
        .validateAt(field, form)
        .then(() => {
          errors[field] = "";
        })
        .catch((err) => {
          errors[field] = err.message;
        });
    };
    const {
      showErrorToast,
      showSuccessToast,
    } = useNamespacedActions("HOMEPAGE", [
      "showErrorToast",
      "showSuccessToast",
    ]);
    const submit = () => {
      const data = {
        id: props.item.id,
        stamp_code: form.no_materai,
        expired_at: moment(form.expire_date).format(),
      };
      formSchema
        .validate(form, { abortEarly: false })
        .then(() => {
          errors = {};
          editStamp(data)
            .then(() => {
              fetchStamps();
              emit("close");
            })
            .catch((err: any) => {
              console.log("error-code", err.response.data.code);
              if (err.response.data.code == 400) {
                showErrorToast(err.response.data.data);
              }
            });
        })
        .catch((err: any) => {
          err.inner.forEach((error: any) => {
            errors[error.path] = error.message;
          });
        });
    };
    return {
      form,
      toggleDatePicker,
      modal,
      isValid,
      submit,
      errors,
      validate,
      isLoading,
    };
  },
});
