var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-dialog',{attrs:{"width":"800"},model:{value:(_vm.SelectModal),callback:function ($$v) {_vm.SelectModal=$$v},expression:"SelectModal"}},[(_vm.projects.length == 0 && !_vm.loading)?_c('v-card',{staticClass:"rounded-lg",attrs:{"flat":""}},[_c('v-container',{staticClass:"pa-8"},[_c('h3',[_vm._v("Select Project")]),_c('br'),_c('v-card',{staticClass:"rounded-lg",attrs:{"outlined":"","flat":""}},[_c('v-container',{staticClass:"pa-8"},[_c('div',{staticClass:"text-center",staticStyle:{"padding-top":"70px","padding-bottom":"70px"}},[_c('div',{staticClass:"empty-title"},[_vm._v(" Looks like you don’t have any project ")]),_c('div',{staticClass:"empty-content"},[_vm._v(" Project organize your resources into gropus that fit the way you work ")]),_c('v-btn',{staticClass:"secondary",staticStyle:{"width":"300px","height":"50px"},attrs:{"depressed":""},on:{"click":function () {
                  _vm.validateprivilages(['Project', 'editor'])
                    .then(function () {
                      return _vm.validatelimitcreateproject();
                    })
                    .then(function () {
                      _vm.newProject();
                    });
                }}},[_vm._v(" Create New Project ")])],1)])],1),_c('div',{staticClass:"text-center pt-8"},[_c('v-btn',{staticClass:"accent",staticStyle:{"width":"150px","height":"50px"},attrs:{"depressed":"","dark":""},on:{"click":function (){_vm.SelectModal = false}}},[_vm._v(" CLOSE ")])],1)],1)],1):_c('v-card',{staticClass:"rounded-lg",attrs:{"flat":""}},[_c('v-card-title',[_c('v-row',[_c('v-col',{staticClass:"d-flex flex-row align-center",attrs:{"cols":"8"}},[_c('h3',{class:{ animate: _vm.loading }},[_c('span',{class:{ hidden: _vm.loading }},[_vm._v("Project")])])]),_c('v-col',{class:{ animate: _vm.loading },attrs:{"cols":"4 text-right"}})],1)],1),_c('div',{class:{ animate: _vm.loading },staticStyle:{"max-width":"775px","padding-left":"25px","padding-bottom":"15px"}},[_c('v-text-field',{class:{ hidden: _vm.loading },attrs:{"maxlength":"30","label":"Enter Name","prepend-inner-icon":"mdi-magnify","single-line":"","outlined":""},model:{value:(_vm.project_name),callback:function ($$v) {_vm.project_name=$$v},expression:"project_name"}})],1),_c('v-card-text',{class:{ animate: _vm.loading },staticStyle:{"height":"300px","overflow":"auto"}},[_c('v-data-table',{class:{ hidden: _vm.loading },staticStyle:{"position":"sticky","top":"0","z-index":"10"},attrs:{"id":"headeronly","headers":_vm.headers,"hide-default-footer":"","hide-default-header":"","items-per-page":-1,"items":_vm.projects,"search":_vm.project_name,"sort-by":_vm.sortby,"sort-desc":_vm.sortdesc},on:{"update:sortBy":function($event){_vm.sortby=$event},"update:sort-by":function($event){_vm.sortby=$event},"update:sortDesc":function($event){_vm.sortdesc=$event},"update:sort-desc":function($event){_vm.sortdesc=$event}},scopedSlots:_vm._u([{key:"header",fn:function(ref){
                var props = ref.props;
                var on = ref.on;
return [_c('v-data-table-header',_vm._g(_vm._b({attrs:{"sort-icon":"mdi-menu-down"}},'v-data-table-header',props,false),on))]}},{key:"item.name",fn:function(ref){
                var item = ref.item;
return [_c('div',{staticClass:"d-flex flex-row"},[_c('v-icon',[_vm._v("$vuetify.icons.targetIcon")]),_c('router-link',{staticStyle:{"white-space":"nowrap"},attrs:{"to":("/project/" + (item.name))}},[_c('span',{staticClass:"font-weight-bold ml-2"},[_vm._v(" "+_vm._s(_vm.$lodash.truncate(item.name)))])])],1)]}},{key:"item.datecreated",fn:function(ref){
                var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.$moment(new Date(item.datecreated)).format("DD/MM/YYYY"))+" ")]}}])}),_c('v-data-table',{class:{ hidden: _vm.loading },attrs:{"id":"hideheader","headers":_vm.headers,"hide-default-footer":"","hide-default-header":"","items":_vm.projects,"items-per-page":-1,"search":_vm.project_name,"sort-by":_vm.sortby,"sort-desc":_vm.sortdesc},on:{"update:sortBy":function($event){_vm.sortby=$event},"update:sort-by":function($event){_vm.sortby=$event},"update:sortDesc":function($event){_vm.sortdesc=$event},"update:sort-desc":function($event){_vm.sortdesc=$event}},scopedSlots:_vm._u([{key:"header",fn:function(ref){
                var props = ref.props;
                var on = ref.on;
return [_c('v-data-table-header',_vm._g(_vm._b({attrs:{"sort-icon":"mdi-menu-down"}},'v-data-table-header',props,false),on))]}},{key:"item.name",fn:function(ref){
                var item = ref.item;
return [_c('div',{staticClass:"d-flex flex-row"},[_c('v-icon',[_vm._v("$vuetify.icons.targetIcon")]),_c('router-link',{staticStyle:{"white-space":"nowrap"},attrs:{"to":("/project/" + (item.name) + "/")},nativeOn:{"click":function($event){return (function () {
                  _vm.SelectModal = false;
                }).apply(null, arguments)}}},[_c('span',{staticClass:"font-weight-bold ml-2",on:{"click":function($event){return _vm.openProject(item.id)}}},[_vm._v(" "+_vm._s(_vm.$lodash.truncate(item.name)))])])],1)]}},{key:"item.datecreated",fn:function(ref){
                var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.$moment(new Date(item.created_at)).format("DD/MM/YYYY"))+" ")]}}])})],1),_c('v-divider')],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }