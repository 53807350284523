<template>
	<v-container class="px-7">
		<div class="table-title mb-4">Instance </div>

		<instance />
		
		<!-- <div class="font-weight-bold fz-21 font--text mb-4">Object Storage</div>

		<objectstorage /> -->
		
		<div class="font-weight-bold fz-21 font--text mb-4">Storage</div>
		<storage />

		<div class="font-weight-bold fz-21 font--text mb-4">Custom Image</div>
		<images />
		
		</v-container>
</template>

<script>
import storage from "./resource/storage";
// import objectstorage from "./resource/objectstorage";
import instance from "./resource/instance";
import images from './resource/image'
import { ref, reactive } from "@vue/composition-api";

export default {
	components: {
		storage,
		// objectstorage,
		instance,
		images
	},
	setup() {
		const loading = ref(true);

		return {
			loading,			
		};
	},
};
</script>

<style scoped>
.table-title {
	font-family: Fira Sans;
	font-style: normal;
	font-weight: bold;
	font-size: 21px;
	line-height: 140%;
	color: #556272;
	padding-top: 30px;
}

.table-status {
	font-style: normal;
	font-weight: 600;
	font-size: 16px;
	line-height: 22px;
	text-transform: capitalize;
	color: #a5b3bf;
	padding-top: 5px;
	padding-bottom: 20px;
}
</style>