<template>
  <v-navigation-drawer
    id="NavigationDrawer"
    v-model="drawer"
    app
    :clipped="$vuetify.breakpoint.lgAndUp"
    dark
    class="darkblue"
    width="248"
  >
    <v-expansion-panels v-model="panels" value="panels" multiple app flat>
      <component
        v-for="menu in customMenu"
        :is="menu.component"
        :key="menu.component"
      />
      <v-expansion-panel
        v-for="item in menu"
        :key="item.header"
        :style="{ 'background-color': $vuetify.theme.themes.light.primary }"
      >
        <v-expansion-panel-header>
          {{ item.header }}
        </v-expansion-panel-header>
        <v-expansion-panel-content>
          <v-list-item
            v-for="submenu in item.list"
            :key="submenu.link"
            router
            @click="menuclick(submenu.link)"
          >
            <v-list-item-icon>
              <v-icon>${{ submenu.icon }}</v-icon>
            </v-list-item-icon>
            <v-list-item-content>
              <v-list-item-title v-text="submenu.name"></v-list-item-title>
            </v-list-item-content> </v-list-item
        ></v-expansion-panel-content>
      </v-expansion-panel>
    </v-expansion-panels>
    <dialogSelectFirst />
    <dialogDontHavePermission />
    <dialogReachLimit />
    <dialogBalance />
    <dialoghavebeensubmitted />
    <dialogCommonWarning />
    <dialogCommonSucess />
    <Createproject />

  </v-navigation-drawer>
</template>

<script>
import { computed, ref } from "@vue/composition-api";
import {
  useGetters,
  useNamespacedState,
  useNamespacedMutations,
  useNamespacedActions,
} from "vuex-composition-helpers";
import { PROJECT } from "../project/namespace";
import dialogSelectFirst from "@/modules/project/dialogSelectFirst";
import dialogDontHavePermission from "./dialogDontHavePermission.vue";
import dialogReachLimit from "./dialogReachLimit";
import dialoghavebeensubmitted from "./dialogHaveBeenSubmitted";
import dialogCommonWarning from "./dialogCommonWarning";
import dialogCommonSucess from "./dialogCommonSuccess";
import Createproject from "../project/createproject.vue"
import dialogBalance from './dialogBalance.vue';

export default {
  components: {
    dialogSelectFirst,
    dialogDontHavePermission,
    dialogReachLimit,
    dialoghavebeensubmitted,
    dialogCommonWarning,
    dialogCommonSucess,
    Createproject,
    dialogBalance,
  },
  setup(props, context) {
    props;
    const { drawer: getdrawer } = useNamespacedState("DASHBOARD", ["drawer"]);
    const { setdrawer } = useNamespacedMutations("DASHBOARD", ["setdrawer"]);
    let drawer = computed({
      get: () => getdrawer.value,
      set: (val) => setdrawer(val),
    });
    if (context.root.$vuetify.breakpoint.mdAndDown) {
      drawer.value = false;
    }
    const { menu, customMenu } = useGetters({
      menu: "ROLEPERMISSION/getmenu",
      customMenu: "ROLEPERMISSION/getcustommenu",
    });
    const { projectname } = useNamespacedState(PROJECT, ["projectname"]);
    const { opendialogselectproject } = useNamespacedActions(PROJECT, [
      "opendialogselectproject",
    ]);
    const opendialogselectfirst = ref(false);
    const menuclick = (link) => {
      if (context.root.$router.history.current.fullPath != `/${link}`)
        context.root.$router.push(`/${link}`);
    };
    return {
      opendialogselectproject,
      opendialogselectfirst,
      menuclick,
      drawer,
      menu,
      customMenu,
      panels: ref([0, 1, 2]),
    };
  },
};
</script>

<style lang="scss">
#NavigationDrawer {
  .v-expansion-panel::before {
    box-shadow: none;
  }
  .v-expansion-panel-header {
    min-height: 44px;
    padding-top: 0px;
    padding-bottom: 0px;
    font-weight: 700;
    font-size: 14px;
  }
  .v-expansion-panel-content__wrap {
    padding: 0px;
    .v-list-item {
      padding-left: 24px;
    }
  }
  .v-list-item--active {
    background-color: #2c94d2;
    .v-list-item__title {
      color: white;
    }
  }
  .v-list-item__icon {
    margin-right: 15px !important;
    margin-top: auto;
    margin-bottom: auto;
  }
  .v-list-item__title {
    font-weight: 600 !important;
    font-size: 14px;
  }

  .v-subheader {
  }
  .v-icon {
    height: 20px;
    width: 20px;
  }
  .v-list-item {
    min-height: 44px;
  }

  .v-application--is-ltr .v-list-item__action:last-of-type:not(:only-child),
  .v-application--is-ltr .v-list-item__avatar:last-of-type:not(:only-child),
  .v-application--is-ltr .v-list-item__icon:last-of-type:not(:only-child) {
    margin-left: 0px;
  }
}
</style>