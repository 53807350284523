<template>
  <div>
    <!-- top white box (Balance) -->
    <BalanceAndHistoryBtn :balance="balance" />

    <!-- middle white box (Detail Payment) -->
    <UserInfo
      v-if="currentOrg"
      :current-org="currentOrg"
      :totalproject="totalproject"
      :projectname="projectname"
      :error="error"
      :userprofile="userprofile"
    />

    <Outstanding />

    <!-- bottom white box (Choose Payment) -->
    <v-card flat class="rounded-lg pa-4 mt-3">
      <v-card-text>
        <p class="fz-21 font-weight-bold">
          Choose Payment
        </p>
      </v-card-text>

      <v-card-text class="pt-0">
        <v-row>
          <!-- proj name -->
          <v-col cols="6">
            <p><b>Project Name</b></p>
            <v-select
              id="project"
              v-model="project"
              :items="projects"
              label="Project"
              outlined
              item-text="name"
              item-value="id"
              :error-messages="projectError"
            />
          </v-col>

          <!-- nominal balance -->
          <v-col v-if="isPrepaid" cols="6">
            <p><b>Nominal Balance</b></p>
            <v-text-field
              id="idr"
              flat
              outlined
              v-model.number="idr"
              @keypress="isNumber($event)"
              placeholder="IDR"
              required
              style="margin-bottom: 22px"
              :error-messages="idrError"
              @blur="onChangeHandler"
            />
          </v-col>

          <v-col v-if="isPostpaid" cols="6">
            <p><b>Outstanding Amount</b></p>
            <v-text-field
              id="idr"
              flat
              outlined
              v-model.number="idr"
              @keypress="isNumber($event)"
              placeholder="IDR"
              required
              disabled
              style="margin-bottom: 22px"
              :error-messages="idrError"
              @blur="onChangeHandler"
            />
          </v-col>
        </v-row>

        <v-row>
          <!-- choose payment -->
          <v-col>
            <p><b>Choose Payment</b></p>
            <p v-if="paymentError" class="error--text">{{ paymentError }}</p>
            <v-radio-group v-model="payment" row>
              <v-row>
                <v-col
                  v-for="(method, index) in paymentMethods"
                  :key="'payment-method-' + method.value + index"
                  style="font-size: 10px"
                >
                  <v-radio :label="method.label" :value="method.value">
                    <template v-slot:label>
                      <img
                        v-if="method.icon"
                        :src="require('@/assets/payment-method/' + method.icon)"
                        class="mr-2"
                      />

                      <span class="fs-14">{{ method.label }}</span>
                    </template>
                  </v-radio>
                </v-col>
              </v-row>
            </v-radio-group>
          </v-col>
        </v-row>
      </v-card-text>

      <!-- continue payment button -->
      <v-row>
        <v-col cols="12" sm="12" md="6" />

        <v-col cols="12" sm="12" md="6">
          <v-btn
            id="submit"
            block
            height="50"
            depressed
            color="secondary"
            class="mr-4 text-capitalize font-weight-bold"
            @click="submit"
            :disabled="loading || totalUnpaidBilledError || !payment"
          >
            <!-- loading indicator -->
            <beat-loader
              v-if="loading"
              :loading="loading"
              :color="'white'"
              :size="'10px'"
              class="ml-2"
            />

            {{ loading ? "Continue Payment..." : "Continue Payment" }}
          </v-btn>
        </v-col>
      </v-row>
    </v-card>

    <form
      ref="enterPaymentPageForm"
      class="d-none"
      :action="enterPaymentPageRes.payment_url"
      method="POST"
    >
      <input
        type="hidden"
        name="CheckoutID"
        :value="enterPaymentPageRes.checkout_id"
      />
      <input
        type="hidden"
        name="Signature"
        :value="enterPaymentPageRes.signature"
      />
    </form>
  </div>
</template>

<script>
import { useNamespacedState } from "vuex-composition-helpers";
import localstorage from "@/lib/localstorage";
import api from "@/lib/api";
import BalanceAndHistoryBtn from "./payment/BalanceAndHistoryBtn.vue";
import UserInfo from "./payment/UserInfo.vue";
import Outstanding from "./payment/Outstanding.vue";

export default {
  components: {
    BalanceAndHistoryBtn,
    UserInfo,
    Outstanding,
  },
  data() {
    return {
      totalUnpaidBilledError: false,
      totalUnpaidBilled: 0,
      balance: 0,
      idr: 0,
      idrError: "",
      payment: null,
      paymentError: "",
      loading: false,
      totalproject: "",
      projectname: "",
      error: false,
      paymentMethods: [],
      enterPaymentPageRes: {
        payment_url: "",
        checkout_id: "",
        signature: "",
      },

      // outstanding update
      projects: [],
      project: "",
      projectError: "",
    };
  },
  computed: {
    currentOrg() {
      const org = localstorage.getItem("currentOrg");

      if (!org) return null;

      return JSON.parse(org);
    },
    isSuperAdmin() {
      const role = localstorage.getItem("role");
      const isSuperAdmin = role && role === "Superadmin";

      return isSuperAdmin;
    },
    isPrepaid() {
      const projid = this.project;
      const proj = this.projects.find((proj) => proj.id === projid);
      return proj && proj.payment_method === "prepaid";
    },
    isPostpaid() {
      const projid = this.project;
      const proj = this.projects.find((proj) => proj.id === projid);
      return proj && proj.payment_method === "postpaid";
    },
  },
  setup() {
    const { userprofile } = useNamespacedState("ROLEPERMISSION", [
      "userprofile",
    ]);

    return {
      userprofile,
    };
  },
  methods: {
    isNumber(e) {
      const charCode = e.which ? e.which : e.keyCode;

      if (charCode > 31 && (charCode < 48 || charCode > 57)) {
        e.preventDefault();
      } else {
        return true;
      }
    },
    onChangeHandler(e) {
      if (!this[e.target.id] && !this[e.target.id + "Error"]) {
        this[e.target.id + "Error"] = "Value is required";
      }

      if (this[e.target.id] && this[e.target.id + "Error"]) {
        this[e.target.id + "Error"] = "";
      }
    },
    async submit() {
      // if nominal balance field is empty
      const requiredFields = ["idr", "payment", "project"];
      const noEmptyField = requiredFields.every((field) => this[field]);

      // if (!this.idr) {
      //   this.idrError = "Value is required";
      // } else if (!this.payment) {
      //   this.paymentError = "Value is required";
      // } else if (!this.project) {
      //   this.projectError = "Value is required";
      // } else {

      if (noEmptyField) {
        this.loading = true;

        const endpoint = this.isPrepaid
          ? "/payment/deposit"
          : "/payment/postpaid";

        const payload = {
          payment_id: this.payment.toString(),
          amount: this.idr.toString(),
          project_id: this.project,
        };

        try {
          const res = await api.POST(endpoint, payload);

          this.enterPaymentPageRes = res.data;
        } catch (err) {
          let errmsg = "Sorry, there was an error while submitting data";

          if (err.response && err.response.data && err.response.data.data) {
            errmsg = err.response.data.data;
          }

          this.$store.dispatch("HOMEPAGE/showErrorToast", errmsg);
        }

        this.loading = false;
      } else {
        requiredFields.forEach((field) => {
          if (!this[field]) {
            this[field + "Error"] = "Value is required";
          }
        });
      }
    },
    async fetchBalance() {
      if (this.currentOrg) {
        this.loading = true;

        try {
          const endpoint = this.isSuperAdmin
            ? "/superadmin/balance/remaining/" + this.currentOrg.id
            : "/balance/remaining";
          const balance = await api.GET(endpoint);
          this.balance = balance.data.balance;
        } catch {
          this.$store.dispatch(
            "HOMEPAGE/showErrorToast",
            "Sorry, an error occurred while displaying The Remaining Balance"
          );
        }

        this.loading = false;
      }
    },
    async getPaymentMethods() {
      try {
        const res = await api.GET("/payment/id");
        this.paymentMethods = res.data.map((method) => {
          const { id, name, icon, is_active } = method;

          return {
            label: name,
            value: id,
            icon,
            is_active,
          };
        });
      } catch {
        this.$store.dispatch(
          "HOMEPAGE/showErrorToast",
          "Sorry, an error occurred while getting payment methods"
        );
      }
    },
    async getProjects() {
      if (this.isSuperAdmin) {
        if (
          !this.currentOrg ||
          !this.currentOrg.projects ||
          !this.currentOrg.projects.length
        ) {
          this.totalproject = 0;
          this.projectname = "No project found";
        } else {
          const projects = this.currentOrg.projects;
          this.projects = projects;
          this.totalproject = projects.length;
          this.projectname = projects.map((proj) => proj.name).join(", ");
        }
      } else {
        this.loading = true;

        try {
          this.error = false;
          const res = await api.GET("/user/organization/projects");
          const data = res.data;
          const projectnames = data.map((project) => project.name);

          this.projects = data;
          this.projectname = projectnames.join(", ");
          this.totalproject = data.length;
        } catch {
          this.$store.dispatch(
            "HOMEPAGE/showErrorToast",
            "Sorry, an error occurred while displaying project list"
          );
        }

        this.loading = false;
      }
    },
    async getOutstandingAmount(
      search,
      page,
      limit,
      sortBy,
      sortDesc,
      project_id
    ) {
      this.loading = true;

      try {
        const orgid = this.currentOrg.id;
        const userEndpoint = "/balance/outstanding";
        const superadminEndpoint = "/superadmin/balance/outstanding/" + orgid;
        const res = await api.GET(
          this.isSuperAdmin ? superadminEndpoint : userEndpoint,
          {
            search,
            page,
            limit,
            sortBy,
            sortDesc,
            project_id,
          }
        );

        const { data } = res.data;
        this.idr = data && data.length ? data[0].amount : 0;

        // this.total = total;
        // this.tblItems = details;

        // if (pagination) {
        //   this.pagination = {
        //     ...pagination,
        //     itemsPerPage: limit || this.pagination.itemsPerPage,
        //     sortBy: sortBy || this.pagination.sortBy,
        //     sortDesc: sortDesc || this.pagination.sortDesc,
        //   };
        // }
      } catch (err) {
        let errmsg = "Sorry, an error occurred while displaying data";
        const thereIsErrorMessage = err && err.response && err.response.data;

        if (thereIsErrorMessage && err.response.data.data) {
          errmsg = err.response.data.data;
        }

        if (
          thereIsErrorMessage &&
          err.response.data.data !== "record not found"
        ) {
          this.$store.dispatch("HOMEPAGE/showErrorToast", errmsg);
        }
      }

      this.loading = false;
    },
  },
  mounted() {
    this.getProjects();
    this.getPaymentMethods();
    this.fetchBalance();
  },
  watch: {
    idr() {
      this.onChangeHandler({ target: { id: "idr" } });
    },
    payment() {
      this.onChangeHandler({ target: { id: "payment" } });
    },
    project(newVal) {
      this.onChangeHandler({ target: { id: "project" } });
      this.getOutstandingAmount("", 1, 10, [], [], newVal);
    },
    "enterPaymentPageRes.signature"() {
      setTimeout(() => {
        this.$refs.enterPaymentPageForm.submit();
      }, 1000);
    },
  },
};
</script>

<style scoped>
.fs-14 {
  font-size: 14px !important;
}
</style>
