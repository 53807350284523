





















































import { defineComponent } from "@vue/composition-api";
import { ref } from "@vue/composition-api";
import {
  useNamespacedState,
  useNamespacedActions,
} from "vuex-composition-helpers";

const module = defineComponent({
  props: {
    value: {
      type: Boolean,
      default: false,
    },
    sshId: {
      type: String,
      required: true,
    },
    userId: {
      type: String,
      required: true,
    },
  },
  setup(props, { emit }) {
    const { deletesshkey, fetchsshkeys } = useNamespacedActions("PROFILE", [
      "deletesshkey",
      "fetchsshkeys",
    ]);
    const { isLoading } = useNamespacedState("PROFILE", ["isLoading"]);
    const { showSuccessToast } = useNamespacedActions("HOMEPAGE", [
      "showSuccessToast",
    ]);
    const deletessh = (sshId: String, userId: String) => {
      deletesshkey(sshId).then(() => {
        fetchsshkeys(userId);
        showSuccessToast("Keypair success deleted");
        emit("close");
      });
    };
    return {
      isLoading,
      deletessh,
    };
  },
});
export default module;
