var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[(_vm.projectID === 'all-project')?_c('Summary'):_c('v-row',[(
        _vm.projectBilling && _vm.projectBilling.length && _vm.projectBilling[0].project
      )?_c('v-col',{attrs:{"cols":"12"}},[_c('v-card',{staticClass:"rounded-lg",attrs:{"flat":""}},[_c('v-container',{staticClass:"pa-7",attrs:{"fluid":""}},[_c('v-row',[_c('v-col',{staticClass:"py-0",attrs:{"cols":"12"}},[_c('p',{staticClass:"project-name"},[_vm._v(" "+_vm._s(_vm.projectBilling[0].project.name || "")+" ")])]),_c('v-col',{staticClass:"d-flex mb-2 pt-0"},[_c('div',[_c('p',{staticClass:"font-weight-bold fz-21 mb-4 mr-4"},[_vm._v(" Cost for "+_vm._s(_vm.moment(_vm.selectedDate).format("DD MMMM YYYY"))+" ")]),_c('p',[_vm._v(" This is the current cost for your usage this billing period. A breakdown of your cost is available below. ")])]),_c('v-spacer'),_c('div',{staticClass:"summary-billed"},[_c('b',{staticClass:"font--text"},[_vm._v("Billed")]),_c('p',{staticClass:"total-billed"},[_vm._v(" "+_vm._s(_vm.IDRformat.format(_vm.projectBilling[0].billed))+" ")])])],1)],1),_c('v-row',[_c('v-col',[_c('div',{staticClass:"total primary--text"})])],1),_c('br'),(_vm.services.instances.length)?_c('div',[_c('p',{staticClass:"font-weight-bold fz-21"},[_vm._v(" Instance Detail ")]),_c('v-data-table',{staticClass:"datatable-services",attrs:{"headers":_vm.instanceHeaders,"items":_vm.services.instances,"hide-default-footer":""},scopedSlots:_vm._u([{key:"item.name",fn:function(ref){
      var item = ref.item;
return [_c('span',{staticClass:"linkpointer"},[_vm._v(_vm._s(item.instance.name || "N/A"))])]}},{key:"item.spec",fn:function(ref){
      var item = ref.item;
return [_vm._v(" "+_vm._s(item.package_instance.vcpu)+" vCPU / "+_vm._s(item.package_instance.ram)+" GB Memory ")]}},{key:"item.price",fn:function(ref){
      var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.IDRformat.format(item.package_instance.price_per_hours))+" / hour ")]}},{key:"item.usage",fn:function(ref){
      var item = ref.item;
return [(item.usage_hours)?_c('span',[_vm._v(_vm._s(item.usage_hours > 1 ? ((item.usage_hours) + " hours") : ((item.usage_hours) + " hour")))]):_c('span',[_vm._v("-")])]}},{key:"item.billed",fn:function(ref){
      var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.IDRformat.format(item.billed))+" ")]}},(!_vm.postpaidFixed)?{key:"body.append",fn:function(){return [(_vm.services.instances.length)?_c('tr',{staticStyle:{"border":"none"}},[_c('td',{staticClass:"border-bottom-none"}),_c('td',{staticClass:"border-bottom-none"}),_c('td',{staticClass:"border-bottom-none"}),_c('td',{staticClass:"border-bottom-none"}),_c('td',{staticClass:"border-bottom-none"},[_c('div',[_c('p',{staticClass:"font-weight-bold"},[_vm._v("Total")])]),_c('div',{staticClass:"d-flex flex-row justify-start"},[_vm._v(" "+_vm._s(_vm.totalBilledInstances ? _vm.IDRformat.format(_vm.totalBilledInstances) : "-")+" ")])])]):_vm._e()]},proxy:true}:null],null,true)})],1):_vm._e(),(_vm.services.root_disks.length)?_c('div',[_c('p',{staticClass:"font-weight-bold fz-21 pt-4"},[_vm._v(" Root Disk Detail ")]),_c('v-data-table',{staticClass:"datatable-services",attrs:{"headers":_vm.storageHeaders,"items":_vm.services.root_disks,"hide-default-footer":""},scopedSlots:_vm._u([{key:"item.name",fn:function(ref){
      var item = ref.item;
return [_c('span',{staticClass:"linkpointer"},[_vm._v(_vm._s(item.instance.name || "N/A"))])]}},{key:"item.spec",fn:function(ref){
      var item = ref.item;
return [_vm._v(" "+_vm._s(item.package_storage ? ((item.package_storage.volume_size) + " GB") : "-")+" ")]}},{key:"item.price",fn:function(ref){
      var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.IDRformat.format(item.package_storage.price_per_month))+" / month ")]}},{key:"item.billed",fn:function(ref){
      var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.IDRformat.format(item.billed))+" ")]}},(!_vm.postpaidFixed)?{key:"body.append",fn:function(){return [(_vm.services.root_disks.length)?_c('tr',{staticStyle:{"border":"none"}},[_c('td',{staticClass:"border-bottom-none"}),_c('td',{staticClass:"border-bottom-none"}),_c('td',{staticClass:"border-bottom-none"}),_c('td',{staticClass:"border-bottom-none"},[_c('div',[_c('p',{staticClass:"font-weight-bold"},[_vm._v("Total")])]),_c('div',{staticClass:"d-flex flex-row justify-start"},[_vm._v(" "+_vm._s(_vm.IDRformat.format(_vm.totalBilledRootDisks))+" ")])])]):_vm._e()]},proxy:true}:null],null,true)})],1):_vm._e(),(_vm.services.storages.length)?_c('div',[_c('p',{staticClass:"font-weight-bold fz-21 pt-4"},[_vm._v(" Storage Detail ")]),_c('v-data-table',{staticClass:"datatable-services",attrs:{"headers":_vm.storageHeaders,"items":_vm.services.storages,"hide-default-footer":""},scopedSlots:_vm._u([{key:"item.name",fn:function(ref){
      var item = ref.item;
return [_c('span',{staticClass:"linkpointer"},[_vm._v(_vm._s(item.storage.name || "N/A"))])]}},{key:"item.spec",fn:function(ref){
      var item = ref.item;
return [_vm._v(" "+_vm._s(item.package_storage ? ((item.package_storage.volume_size) + " GB") : "-")+" ")]}},{key:"item.price",fn:function(ref){
      var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.IDRformat.format(item.package_storage.price_per_month))+" / month ")]}},{key:"item.billed",fn:function(ref){
      var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.IDRformat.format(item.billed))+" ")]}},(!_vm.postpaidFixed)?{key:"body.append",fn:function(){return [(_vm.services.storages.length)?_c('tr',{staticStyle:{"border":"none"}},[_c('td',{staticClass:"border-bottom-none"}),_c('td',{staticClass:"border-bottom-none"}),_c('td',{staticClass:"border-bottom-none"}),_c('td',{staticClass:"border-bottom-none"},[_c('div',[_c('p',{staticClass:"font-weight-bold"},[_vm._v("Total")])]),_c('div',{staticClass:"d-flex flex-row justify-start"},[_vm._v(" "+_vm._s(_vm.IDRformat.format(_vm.totalBilledStorages))+" ")])])]):_vm._e()]},proxy:true}:null],null,true)})],1):_vm._e(),(_vm.services.object_storages.length)?_c('div',[_c('p',{staticClass:"font-weight-bold fz-21 pt-4"},[_vm._v(" Object Storage Detail ")]),_c('v-data-table',{staticClass:"datatable-services",attrs:{"headers":_vm.storageHeaders,"items":_vm.services.object_storages,"hide-default-footer":""},scopedSlots:_vm._u([{key:"item.name",fn:function(ref){
      var item = ref.item;
return [_c('span',{staticClass:"linkpointer"},[_vm._v(_vm._s(item.object_storage.name || "N/A"))])]}},{key:"item.spec",fn:function(ref){
      var item = ref.item;
return [_vm._v(" "+_vm._s(item.package_object_storage ? ((item.package_object_storage.object_storage_size) + " GB") : "-")+" ")]}},{key:"item.price",fn:function(ref){
      var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.IDRformat.format( item.package_object_storage.price_per_month ))+" / month ")]}},{key:"item.billed",fn:function(ref){
      var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.IDRformat.format(item.billed))+" ")]}},(!_vm.postpaidFixed)?{key:"body.append",fn:function(){return [(_vm.services.object_storages.length)?_c('tr',{staticStyle:{"border":"none"}},[_c('td',{staticClass:"border-bottom-none"}),_c('td',{staticClass:"border-bottom-none"}),_c('td',{staticClass:"border-bottom-none"}),_c('td',{staticClass:"border-bottom-none"},[_c('div',[_c('p',{staticClass:"font-weight-bold"},[_vm._v("Total")])]),_c('div',{staticClass:"d-flex flex-row justify-start"},[_vm._v(" "+_vm._s(_vm.IDRformat.format(_vm.totalBilledObjectStorages))+" ")])])]):_vm._e()]},proxy:true}:null],null,true)})],1):_vm._e(),(_vm.isDevModePrepaid && _vm.services.snapshots.length)?[_c('p',{staticClass:"font-weight-bold fz-21 mt-4"},[_vm._v(" Snapshot ")]),_c('v-data-table',{staticClass:"datatable-services",attrs:{"headers":_vm.snapshotHeaders,"items":_vm.services.snapshots,"items-per-page":10,"hide-default-footer":""},scopedSlots:_vm._u([{key:"item.size",fn:function(ref){
      var item = ref.item;
return [_vm._v(" "+_vm._s(item.size)+" GB ")]}},{key:"item.billed",fn:function(ref){
      var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.IDRformat.format(item.billed))+" ")]}},(!_vm.postpaidFixed)?{key:"body.append",fn:function(){return [(_vm.services.snapshots.length)?_c('tr',{staticStyle:{"border":"none"}},[_c('td',{staticClass:"border-bottom-none"}),_c('td',{staticClass:"border-bottom-none"}),_c('td',{staticClass:"border-bottom-none"},[_c('div',[_c('p',{staticClass:"font-weight-bold"},[_vm._v("Total")])]),_c('div',{staticClass:"d-flex flex-row justify-start"},[_vm._v(" "+_vm._s(_vm.services.snapshots.length ? _vm.IDRformat.format( _vm.services.snapshots.reduce( function (total, item) { return total + item.billed; }, 0 ) ) : "0")+" ")])])]):_vm._e()]},proxy:true}:null,(_vm.services.snapshots.length)?{key:"footer",fn:function(ref){
      var props = ref.props;
return [_c('custom-footer-datatable',{attrs:{"props":props}})]}}:null],null,true)})]:_vm._e(),(_vm.isDevModePrepaid && _vm.services.floating_ips.length)?[_c('p',{staticClass:"font-weight-bold fz-21 mt-4"},[_vm._v(" Floating IP ")]),_c('v-data-table',{staticClass:"datatable-services",attrs:{"headers":_vm.floating_ipHeaders,"items":_vm.services.floating_ips,"items-per-page":10,"hide-default-footer":""},scopedSlots:_vm._u([{key:"item.ip_address",fn:function(ref){
      var item = ref.item;
return [_vm._v(" "+_vm._s(item.ip_address)+" ")]}},{key:"item.total",fn:function(ref){
      var item = ref.item;
return [_vm._v(" "+_vm._s(item.total)+" x ")]}},{key:"item.billed",fn:function(ref){
      var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.IDRformat.format(item.billed))+" ")]}},(!_vm.postpaidFixed)?{key:"body.append",fn:function(){return [(_vm.services.floating_ips.length)?_c('tr',{staticStyle:{"border":"none"}},[_c('td',{staticClass:"border-bottom-none"}),_c('td',{staticClass:"border-bottom-none"}),_c('td',{staticClass:"border-bottom-none"},[_c('div',[_c('p',{staticClass:"font-weight-bold"},[_vm._v("Total")])]),_c('div',{staticClass:"d-flex flex-row justify-start"},[_vm._v(" "+_vm._s(_vm.services.floating_ips.length ? _vm.IDRformat.format( _vm.services.floating_ips.reduce( function (total, item) { return total + item.billed; }, 0 ) ) : "0")+" ")])])]):_vm._e()]},proxy:true}:null,(_vm.services.floating_ips.length)?{key:"footer",fn:function(ref){
      var props = ref.props;
return [_c('custom-footer-datatable',{attrs:{"props":props}})]}}:null],null,true)})]:_vm._e()],2)],1)],1):_vm._e()],1),_c('billingdetail')],1)}
var staticRenderFns = []

export { render, staticRenderFns }