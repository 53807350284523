import {ActionContext, Module} from 'vuex'
import findProjectByName from '../../project/findprojectbyname'
import libProject from '@/lib/project'

interface State{
    floatingips: Array<any>,
    isLoading: Boolean,
    availableInstances: Array<any>,
}

import axios from '@/lib/axios'
const store: Module<State,any>= {
    namespaced: true,
    state: {
        floatingips: [],
        isLoading: false,
        availableInstances: [],
    },
    getters: {
        activeProject(state, getters, rootState, rootGetters) {
            return rootGetters['PROJECT/getprojectname']
        },
        getIsFloatingIPLoading: state => state.isLoading,
    },
    mutations: {
        setfloatingips : ( state: State, payload: any ) => { state.floatingips = payload },
        setIsLoading : ( state: State, payload: any ) => { state.isLoading = payload },
        setAvailableInstances : ( state: State, payload: any ) => { state.availableInstances = payload },
    },
    actions: {
        addfloatingip: async({ commit, dispatch }, payload: any) => {
            const activeProjectID = await libProject.getActiveProjectID()
            if (!activeProjectID) dispatch('HOMEPAGE/showErrorToast', 'Could not find project data.', { root: true })
            try {
                commit('setIsLoading', true)
                const { id, voucher_id, billing_type, ip_type } = payload
                const response = await axios.instance.post('/network/floating-ip', {
                    project_id: activeProjectID,
                    instance_id: id,
                    voucher_id,
                    billing_type,
                    ip_type
                })
                
                if(response.status === 200){
                    dispatch('fetchfloatingips')
                    dispatch('fetchAvailableInstances')
                    dispatch('HOMEPAGE/showSuccessToast', 'Floating IP has been successfully assigned!', { root: true })
                    commit('setIsLoading', false)
                    return response
                }
                commit('setIsLoading', false)
            } catch (err) {
                const error = err.response.data.data
                let errorMessage = 'A problem encountered while tring to assign floating IP'

                if(error.includes('instance has already attached with floating ip')) errorMessage = 'Selected Instance already has Floating IP attached.'
                else if (error.includes('floating ip voucher has reached limit')) errorMessage = 'Floating IP quota of the trial voucher has reached the limit.'
                else if (error.includes('Floating IP has exceeded the limit')) errorMessage = 'Floating IP has exceeded the limit. Please contact the administrator.'
                else if (error.includes('voucher has already expired')) errorMessage = 'Voucher has already expired.'
                else if (error.includes('Service activation')) errorMessage = error
                
                dispatch('HOMEPAGE/showErrorToast', errorMessage, { root: true })
                commit('setIsLoading', false)
            }
        },
        fetchfloatingips: async(context: ActionContext<State, any>, payload: any)=>{
            const activeProjectID = await libProject.getActiveProjectID()
            if (!activeProjectID) context.dispatch('HOMEPAGE/showErrorToast', 'Could not find project data.', { root: true })
            context.commit('setIsLoading', true)
            const response = await axios.instance.get(`/network/floating-ip/project/${activeProjectID}`)
            if (response.status === 200) {
                context.commit('setfloatingips', response.data.data.sort((x:any) => x.instance_id ? -1 : 1))
                context.commit('setIsLoading', false)
                return response.data.data
            } else {
                context.dispatch('HOMEPAGE/showErrorToast', 'Could not get Floating IP data.', { root: true })
                context.commit('setIsLoading', false)
                return []
            }
        },
        fetchAvailableInstances: async(context: ActionContext<State, any>, payload: any)=>{
            const projectID = await libProject.getActiveProjectID()
            if (!projectID) context.dispatch('HOMEPAGE/showErrorToast', 'Could not find project data.', { root: true })
            const response = await axios.instance.get(`/network/floating-ip/available-instances/${projectID}`)
            if (response.status === 200) {
                context.commit('setAvailableInstances', response.data.data)
                return response.data.data
            } else {
                context.dispatch('HOMEPAGE/showErrorToast', 'Could not get available instances data.', { root: true })
                return []
            }
        },
        DELETE_FLOATING_IP: async(context: ActionContext<State, any>, payload: any)=>{
            try {
                const { id }:any = payload
                const response:any = await axios.instance.delete(`/network/floating-ip/delete/${id}`)
                if(response.status === 200) {
                    context.dispatch('fetchfloatingips')
                    context.dispatch('fetchAvailableInstances')
                    context.dispatch('HOMEPAGE/showSuccessToast', 'Floating IP has been successfully deleted', {root: true})
                }
            } catch (e) {
                const errorMessage = e.response.data.data
                if(errorMessage === 'make sure to detach floating ip from instance before deleting floating ip') {
                    context.dispatch('HOMEPAGE/showErrorToast', 'Please detach selected Floating IP from the Instance and try again.', {root: true})
                } else {
                    context.dispatch('HOMEPAGE/showErrorToast', 'Failed to delete Floating IP', {root: true})
                }
            }
        },
        REASSIGN_FLOATING_IP: async(context: ActionContext<State, any>, payload: any) => {
            try {
                context.commit('setIsLoading', true)
                const response = await axios.instance.post('/network/floating-ip/reassign', payload)
                if(response.status === 200) {
                    context.dispatch('fetchfloatingips')
                    context.dispatch('fetchAvailableInstances')
                    context.dispatch('HOMEPAGE/showSuccessToast', 'Floating IP has been successfully reassigned!', {root: true})
                    context.commit('setIsLoading', false)
                }
            } catch (err) {
                const errorMessage = err.response.data.data
                if(errorMessage.includes('already has a floating IP on external')) context.dispatch('HOMEPAGE/showErrorToast', 'Selected instance already has Floating IP attached.', { root: true })
                else context.dispatch('HOMEPAGE/showErrorToast', 'Failed to assign Floating IP.', { root: true })
                context.commit('setIsLoading', false)
            }
        },
        UNASSIGN_FLOATING_IP: async({ commit, dispatch }, payload: any) => {
            try {
                commit('setIsLoading', true)
                const response = await axios.instance.post('/network/floating-ip/unassign', { floating_ip_id: payload })
                if(response.status === 200) {
                    dispatch('fetchfloatingips')
                    dispatch('fetchAvailableInstances')
                    dispatch('HOMEPAGE/showSuccessToast', 'Floating IP has been successfully unassigned!', { root: true })
                    return response
                } else dispatch('HOMEPAGE/showErrorToast', 'Failed to unassign Floating IP!', { root: true })
                commit('setIsLoading', false)
            } catch (err) {
                commit('setIsLoading', false)
                dispatch('HOMEPAGE/showErrorToast', 'Failed to unassign Floating IP.', { root: true })
            }
        },
    }
}
export default store