var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('div',{staticClass:"row"},[_c('v-col',{attrs:{"cols":"12"}},[_c('v-card',{staticClass:"rounded-lg",attrs:{"flat":""}},[_c('v-container',{staticClass:"pa-8",attrs:{"fluid":""}},[_c('v-row',{staticClass:"pb-5"},[_c('v-col',{staticClass:"d-flex align-center"},[_c('p',{staticClass:"mb-0 fz-21 font-weight-bold"},[_vm._v("Manage Ticket ")]),_c('v-spacer'),_c('v-btn',{staticClass:"secondary ml-7",attrs:{"depressed":"","to":"/manage-ticket/create-ticket"}},[_c('span',{staticClass:"fz-14"},[_vm._v("Create Ticket")])])],1)],1),_c('v-row',[_c('v-col',{attrs:{"cols":"3"}},[_c('v-select',{attrs:{"placeholder":"Organization","outlined":"","items":_vm.projects,"item-text":"name","item-value":"name"}})],1),_c('v-col',{attrs:{"cols":"3"}},[_c('v-select',{attrs:{"placeholder":"Project","outlined":"","items":_vm.projects,"item-text":"name","item-value":"name"}})],1),_c('v-col',{attrs:{"cols":"3"}},[_c('v-select',{attrs:{"placeholder":"Type","outlined":"","items":_vm.severities}})],1),_c('v-col',{attrs:{"cols":"3"}},[_c('v-select',{attrs:{"placeholder":"Status","outlined":"","items":_vm.status}})],1)],1),(_vm.tickets.length)?_c('v-data-table',{staticClass:"elevation-0",attrs:{"headers":_vm.table.headers,"items":_vm.tickets,"items-per-page":5,"server-items-length":_vm.pagination.count,"options":_vm.options,"hide-default-footer":"","item-key":"createddate"},on:{"update:options":function($event){_vm.options=$event}},scopedSlots:_vm._u([{key:"item.status",fn:function(ref){
var item = ref.item;
return [_c('v-select',{attrs:{"height":"50","items":_vm.selectStatus,"item-text":"text","item-value":"value","placeholder":"Select Status","hide-details":"","outlined":"","menu-props":{ contentClass: item.status }},model:{value:(item.status),callback:function ($$v) {_vm.$set(item, "status", $$v)},expression:"item.status"}})]}},{key:"item.project",fn:function(ref){
var item = ref.item;
return [_c('p',[_vm._v(" "+_vm._s(item.project.name)+" ")])]}},{key:"item.created_at",fn:function(ref){
var item = ref.item;
return [_c('p',[_vm._v(" "+_vm._s(_vm.$moment(new Date(item.created_at)).format("DD/MM/YYYY h:mm A"))+" ")])]}},{key:"item.action",fn:function(ref){
var item = ref.item;
return [_c('popupquote',{attrs:{"offsetTop":20,"documentid":'popup' + item.id,"offset-y":"","allow-overflow":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('label',_vm._b({staticClass:"primary--text linkpointer",staticStyle:{"position":"relative","white-space":"nowrap"},on:{"click":function ($event) {
                        on.click($event);
                        _vm.show($event, item);
                      }}},'label',attrs,false),[_vm._v("More "),_c('v-icon',{class:{
                        rotate: item.open,
                      },attrs:{"id":'popup' + item.id,"color":"primary"}},[_vm._v("mdi-chevron-down")])],1)]}}],null,true),model:{value:(item.open),callback:function ($$v) {_vm.$set(item, "open", $$v)},expression:"item.open"}},[_c('v-list',[_c('v-list-item',{on:{"click":function () {
                        _vm.memberselectedvalue = item;
                        _vm.opendialogedituser = true;
                      }}},[_c('v-list-item-title',[_vm._v("Edit")])],1),_c('v-list-item',{staticStyle:{"min-height":"1px","margin-left":"-16px","margin-right":"-16px"}},[_c('v-divider',{staticStyle:{"min-width":"160px"}})],1),_c('v-list-item',{on:{"click":function () {
                        _vm.memberselected = item;
                        _vm.opendialogdeleteuser = true;
                      }}},[_c('v-list-item-title',{staticClass:"error--text"},[_vm._v("Delete")])],1)],1)],1)]}},{key:"footer",fn:function(ref){
                      var props = ref.props;
return [_c('custom-footer-datatable',{attrs:{"props":props},on:{"input":function (val) {
                    _vm.options = val;
                  }}})]}}],null,false,875014466)}):_vm._e(),(!_vm.tickets.length)?_c('v-simple-table',{staticStyle:{"border":"1px solid #cad6e1"}},[_c('thead',[_c('tr',[_c('th',[_vm._v("Subjet")]),_c('th',[_vm._v("Project")]),_c('th',[_vm._v("Ticket ID")]),_c('th',[_vm._v("PIC")]),_c('th',[_vm._v("Created Date")]),_c('th',[_vm._v("Status")])])]),_c('tbody',[_c('tr',[_c('td',{staticClass:"py-8",attrs:{"colspan":"6"}},[_c('v-row',{attrs:{"align":"center","justify":"center"}},[_c('div',{staticClass:"text-center w-50"},[_c('h3',[_vm._v("No Ticket")])])]),_c('v-row',[_c('v-col',{staticClass:"d-flex flex-row justify-center"},[_c('router-link',{attrs:{"to":"/ticket/create-ticket"}},[_c('v-btn',{staticClass:"secondary",attrs:{"depressed":"","height":"55","width":"465"}},[_c('span',{staticClass:"fz-14"},[_vm._v("Create Ticket")])])],1)],1)],1)],1)])])]):_vm._e()],1)],1)],1)],1)])}
var staticRenderFns = []

export { render, staticRenderFns }