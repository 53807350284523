var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',{attrs:{"fluid":""}},[_c('v-row',[_c('v-col',{staticClass:"pa-0"},[_c('v-card',{staticClass:"rounded-lg pa-3",attrs:{"flat":""}},[_c('v-card-title',[_c('div',{staticClass:"headline font-weight-bold font--text"},[_vm._v("Turn off Instance")])]),_c('v-card-text',{staticClass:"pb-0"},[_c('p',[_vm._v(" When you turn off a VM from the control panel, we first try a graceful shutdown. If that fails, we do a forced shutdown, which may corrupt data. To ensure data integrity, we recommend shutting down from the command line with poweroff. ")]),_c('p',[_c('b',[_vm._v(" When a VM is off:")])]),_c('p',[_vm._v(" Its data and IP address are retained and its disk, CPU and RAM are reserved. You continue to accrue its data transfer allowance. ")]),_c('p',[_c('span',{staticClass:"error--text"},[_c('b',[_vm._v("Warning:")])]),_vm._v(" You will still be billed for a turned off VM. To end billing, destroy the Instance instead. ")])]),_c('v-card-text',[_c('v-btn',{staticClass:"secondary",attrs:{"disabled":!['ACTIVE'].includes(_vm.instanceDetail.state) || ![null, ''].includes(_vm.instanceDetail.revert_expired) || _vm.isLoadingTurn,"depressed":"","height":"45","width":"150"},on:{"click":function () {
                    _vm.validateprivilages([
                      'Storage',
                      'editor' ]).then(function () {
                      _vm.opendialogturnoff = true 
                    });
                  }}},[_c('span',{staticStyle:{"font-size":"12px"}},[(_vm.isLoadingTurn)?_c('beat-loader',{staticClass:"mr-2",attrs:{"loading":_vm.isLoadingTurn,"color":'white',"size":'10px'}}):_c('span',[_vm._v("Turn Off")])],1)])],1)],1)],1),_c('v-col',{staticClass:"px-0 py-5",attrs:{"cols":"12"}},[_c('v-card',{staticClass:"rounded-lg pa-3",attrs:{"flat":""}},[_c('v-card-title',[_c('div',{staticClass:"headline font-weight-bold font--text"},[_vm._v("Power cycle")])]),_c('v-card-text',{staticClass:"pb-0"},[_c('p',[_vm._v(" A power cycle will immediately hard reset the server. You should only choose this option when you are unable to reboot the Instance from the command line. ")]),_c('p',[_c('b',[_vm._v("Do you wish to proceed?")]),_c('br')])]),_c('v-card-text',[_c('v-btn',{staticClass:"secondary",staticStyle:{"letter-spacing":"0.07em"},attrs:{"disabled":_vm.isLoadingTurn 
              || !['ACTIVE'].includes(_vm.instanceDetail.state) || ![null, ''].includes(_vm.instanceDetail.revert_expired),"depressed":"","height":"50","width":"145"},on:{"click":function () { 
                _vm.validateprivilages([
                  'Instance',
                  'editor' ]).then(function () {
                  _vm.opendialogcycle = true
                });  
              }}},[(_vm.isLoadingTurn)?_c('beat-loader',{staticClass:"mr-2",attrs:{"loading":_vm.isLoadingTurn,"color":'white',"size":'10px'}}):_c('span',{staticStyle:{"font-size":"12px"}},[_vm._v("Power Cycle")])],1)],1)],1)],1)],1),_c('dialogTurnOff',{on:{"close":_vm.turnOff},model:{value:(_vm.opendialogturnoff),callback:function ($$v) {_vm.opendialogturnoff=$$v},expression:"opendialogturnoff"}}),_c('dialogRecycle',{on:{"close":_vm.powerCycle},model:{value:(_vm.opendialogcycle),callback:function ($$v) {_vm.opendialogcycle=$$v},expression:"opendialogcycle"}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }