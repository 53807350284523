var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-dialog',{attrs:{"max-width":"500"},model:{value:(_vm.dialog),callback:function ($$v) {_vm.dialog=$$v},expression:"dialog"}},[_c('v-card',[_c('v-card-title',{staticClass:"pb-7"},[_c('div',{staticClass:"headline font-weight-bold font--text"},[_vm._v("Attach to Instance")])]),_c('v-card-text',[_c('p',{staticClass:"font-weight-bold"},[_vm._v("Choose a Instance")]),_c('v-select',{attrs:{"id":"SelectInstance","outlined":"","items":_vm.instanceSelect,"placeholder":"Select an Instance"},scopedSlots:_vm._u([{key:"selection",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.text)+" ")]}}]),model:{value:(_vm.instanceselected),callback:function ($$v) {_vm.instanceselected=$$v},expression:"instanceselected"}})],1),_c('v-card-actions',[_c('v-row',{staticClass:"ma-2"},[_c('v-col',{attrs:{"cols":"6"}},[_c('v-btn',{attrs:{"height":"50","color":"accent","block":"","depressed":""},on:{"click":function () {
                  _vm.$emit('close');
                }}},[_vm._v(" Cancel ")])],1),_c('v-col',{attrs:{"cols":"6"}},[_c('v-btn',{attrs:{"depressed":"","height":"50","color":"secondary","block":"","disabled":!_vm.instanceselected},on:{"click":function () {
                  if(_vm.instanceselected){
                  _vm.$emit('confirm',_vm.instanceselected)
                  _vm.$emit('close');  
                  }
                  
                }}},[_vm._v(" Confirm ")])],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }