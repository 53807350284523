import {INSTANCE} from '../namespace'
import {
  useNamespacedActions,
  useNamespacedState,
} from "vuex-composition-helpers";
import {ref} from '@vue/composition-api'

export const useInstance = (props) => {
  props;
  const instanceselected = ref({});
  const { 
    fetchinstancesize,
    fetchMasterInstances,
  } = useNamespacedActions(INSTANCE, [
    'fetchinstancesize',
    'fetchMasterInstances',
  ])
  let { instancesize, masterInstances } = useNamespacedState(INSTANCE, ['instancesize', 'masterInstances'])
  
  function isSelected(id) {
    return id == instanceselected.value;
  }

  const rootDiskSelected = ref(null)

  return {
    instanceselected,
    fetchMasterInstances,
    rootDiskSelected,
    fetchinstancesize,
    instancesize,
    masterInstances,
    isSelected
  }
}