var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('div',{staticClass:"row"},[_c('v-col',{attrs:{"cols":"12"}},[_c('v-card',{staticClass:"rounded-lg",attrs:{"flat":""}},[_c('v-container',{staticClass:"pa-8"},[_c('v-row',[_c('v-col',{attrs:{"cols":"8"}},[_c('div',{staticClass:"fz-21 font-weight-bold font--text"},[_vm._v(" Create Ticket ")])])],1),_c('v-row',[_c('v-col',{attrs:{"cols":"6"}},[_c('v-text-field',{attrs:{"maxlength":"50","outlined":"","placeholder":"Enter Subject Ticket","error-messages":_vm.$v.subject.$errors.length
                    ? _vm.$v.subject.$errors[0].$message
                    : ''},model:{value:(_vm.subject),callback:function ($$v) {_vm.subject=$$v},expression:"subject"}})],1)],1),_c('v-row',[_c('v-col',{staticClass:"d-flex flex-row"},[_c('v-card',{staticClass:"rounded-lg pl-2",style:({
                  border: _vm.type == 'Billing' ? '1px solid #2C94D2' : '',
                }),attrs:{"height":"80","min-width":"372","outlined":"","flat":""},on:{"click":function () {
                    _vm.type = 'Billing';
                  }}},[_c('div',{staticClass:"d-flex pa-1 align-center"},[_c('div',[_c('v-radio-group',{staticClass:"mr-3 ml-1",model:{value:(_vm.type),callback:function ($$v) {_vm.type=$$v},expression:"type"}},[_c('v-radio',{attrs:{"value":"Billing"}})],1)],1),_c('div',[_c('p',{staticClass:"fz-16 font-weight-bold mb-2"},[_vm._v(" Account and Billing Support ")]),_c('p',{staticClass:"fz-12 accent--text mb-0"},[_vm._v(" Assistance with account and billing related inquires ")])])])]),_c('div',{staticStyle:{"height":"auto","width":"40px"}}),_c('v-card',{staticClass:"rounded-lg pl-2",style:({
                  border: _vm.type == 'Technical' ? '1px solid #2C94D2' : '',
                }),attrs:{"outlined":"","height":"80","min-width":"372","flat":""},on:{"click":function () {
                    _vm.type = 'Technical';
                  }}},[_c('div',{staticClass:"d-flex pa-1 align-center"},[_c('div',[_c('v-radio-group',{staticClass:"mr-3 ml-1",model:{value:(_vm.type),callback:function ($$v) {_vm.type=$$v},expression:"type"}},[_c('v-radio',{attrs:{"value":"Technical"}})],1)],1),_c('div',[_c('p',{staticClass:"fz-16 font-weight-bold mb-2"},[_vm._v(" Technical Support ")]),_c('p',{staticClass:"fz-12 accent--text mb-0"},[_vm._v(" Service related technical issues ")])])])])],1)],1),_c('br'),_c('v-divider'),_c('br'),_c('v-row',[_c('v-col',{attrs:{"cols":"6"}},[_c('div',{staticClass:"fz-20 font-weight-bold font--text"},[_vm._v(" Ticket Details ")]),_c('br'),_c('h4',{staticClass:"pt-3"},[_vm._v("Project Name*")]),_c('v-select',{attrs:{"placeholder":"Enter Project Name","items":_vm.projects,"item-text":"name","item-value":"id","outlined":"","single-line":"","append-icon":"mdi-chevron-down","error-messages":_vm.$v.type.$errors.length ? _vm.$v.type.$errors[0].$message : ''},model:{value:(_vm.project),callback:function ($$v) {_vm.project=$$v},expression:"project"}}),_c('h4',{staticClass:"pt-3"},[_vm._v("Service List")]),_c('v-select',{attrs:{"placeholder":"Normal","items":_vm.severities,"outlined":"","item-text":'text',"item-value":'value',"single-line":"","append-icon":"mdi-chevron-down"},model:{value:(_vm.severity),callback:function ($$v) {_vm.severity=$$v},expression:"severity"}}),_c('h4',{staticClass:"pt-3"},[_vm._v("Priority")]),_c('v-select',{attrs:{"placeholder":"Normal","items":_vm.severities,"outlined":"","item-text":'text',"item-value":'value',"single-line":"","append-icon":"mdi-chevron-down"},model:{value:(_vm.severity),callback:function ($$v) {_vm.severity=$$v},expression:"severity"}}),_c('h4',{staticClass:"pt-3"},[_vm._v("Description")]),_c('v-textarea',{attrs:{"outlined":"","name":"input-7-4","placeholder":"Enter Description","error-messages":_vm.$v.limittype.$errors.length
                    ? _vm.$v.limittype.$errors[0].$message
                    : ''},model:{value:(_vm.limittype),callback:function ($$v) {_vm.limittype=$$v},expression:"limittype"}}),_c('h4',{staticClass:"pt-3"},[_vm._v("Type Ticket")]),_c('v-select',{attrs:{"placeholder":"Normal","items":_vm.ticket_type,"outlined":"","item-text":'text',"item-value":'value',"single-line":"","append-icon":"mdi-chevron-down"},model:{value:(_vm.ticket_type),callback:function ($$v) {_vm.ticket_type=$$v},expression:"ticket_type"}})],1)],1),_c('v-row',[_c('v-col',{attrs:{"cols":"6"}},[_c('v-btn',{staticClass:"accent",attrs:{"to":"/ticket","dark":"","depressed":"","block":""}},[_c('span',{staticClass:"fz-14"},[_vm._v("Cancel")])])],1),_c('v-col',{attrs:{"cols":"6"}},[_c('v-btn',{staticClass:"secondary",attrs:{"depressed":"","block":""},on:{"click":function($event){return _vm.submitTicket()}}},[_c('span',{staticClass:"fz-14"},[_vm._v("Submit")])])],1)],1)],1)],1)],1)],1)])}
var staticRenderFns = []

export { render, staticRenderFns }