



























































































































































import {
  computed,
  defineComponent,
  onMounted,
  ref,
  watch,
} from "@vue/composition-api";
import {
  useNamespacedActions,
  useNamespacedState,
} from "vuex-composition-helpers";
import dialogAddNewVersion from "./dialogAddNewVersion.vue";
import { SUPERADMINMANAGEOS } from "./namespace";
import dialogDeleteVersion from "./dialogDeleteVersion.vue";
export default defineComponent({
  components: { dialogAddNewVersion, dialogDeleteVersion },
  setup() {
    const headers = ref([
      { text: "Operating System", value: "os", sortable: true },
      { text: "Bit Version", value: "bitversion", sortable: true },
      { text: "Version Number", value: "versionnumber", sortable: true },
      { text: "Status", value: "status", sortable: true },
      { text: "Action", sortable:false, value: "action", align: "center" },
    ]);
    const { fetchosversions } = useNamespacedActions(SUPERADMINMANAGEOS, [
      "fetchosversions",
    ]);
    const {
      osversions,
      pagination,
      selectos,
    } = useNamespacedState(SUPERADMINMANAGEOS, [
      "osversions",
      "pagination",
      "selectos",
    ]);
    onMounted(() => {
      fetchosversions();
    });

    const resetSelect = () => {
      osselected.value = ''
      setTimeout(fetchosversions, 9000);
      // clearInterval(setload);
    }

    const chooseos = computed(() => {
      const all = [{ text: "All", id: "" }];
      const others = selectos as any;
      return all.concat(
        others.value.map((x: any) => ({ text: x.name, id: x.id }))
      );
    });

    const osselected = ref("");
    const versionselected = ref();
    const openDialogDelete = ref(false);

    const Delete = () => {
      versionselected.value.ref.delete().then(() => {
        openDialogDelete.value = false;
        fetchosversions();
      });
    };
    const options = ref({});
    watch(osselected, (val) => {
      options.value = { ...options.value, os_id: val, page: 1  };
    });
    watch(options, (val) => {
      console.log(val);
      fetchosversions(val);
    });
    const search = ref("");
    let timesearch: any = null;
    watch(search, (val) => {
      if (timesearch) {
        clearTimeout(timesearch);
        timesearch = setTimeout(() => {
          options.value = { ...options.value, search: val, page: 1  };
        }, 500);
      } else {
        timesearch = setTimeout(() => {
          options.value = { ...options.value, search: val, page: 1  };
        }, 500);
      }
    });
    return {
      chooseos,
      options,
      pagination,
      openDialogAdd: ref(false),
      openDialogEdit: ref(false),
      headers,
      osversions,
      openDialogDelete,
      search,
      selectos,
      osselected,
      Delete,
      versionselected,
      fetchosversions,
      resetSelect,
    };
  },
});
