var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-card',{staticClass:"rounded-lg ",attrs:{"flat":""}},[_c('v-card-text',{staticClass:"px-0 pb-0 pt-2 rounded-lg"},[_c('v-tabs',{attrs:{"slider-size":"4"},model:{value:(_vm.tab),callback:function ($$v) {_vm.tab=$$v},expression:"tab"}},[_c('v-tab',[_c('span',{staticClass:"ml-4",staticStyle:{"font-size":"16px"}},[_vm._v("Instance ")])]),_c('v-tab',[_c('span',{staticStyle:{"font-size":"16px"}},[_vm._v(" "+_vm._s(_vm.isDevModeStorageTiering ? "Storage" : "Volume")+" ")])]),_c('v-tab',[_c('span',{staticStyle:{"font-size":"16px"}},[_vm._v("Object Storage ")])]),_c('v-tab',[_c('span',{staticStyle:{"font-size":"16px"}},[_vm._v("Restore")])]),(_vm.isDevModeChargingForSnapshot)?_c('v-tab',[_c('span',{staticStyle:{"font-size":"16px"}},[_vm._v("Snapshot")])]):_vm._e(),(_vm.isDevModeFloatingIP)?_c('v-tab',[_c('span',{staticStyle:{"font-size":"16px"}},[_vm._v("Floating IP")])]):_vm._e()],1)],1),_c('v-tabs-items',{staticStyle:{"border-top":"0.5px solid #e0e0e0"},model:{value:(_vm.tab),callback:function ($$v) {_vm.tab=$$v},expression:"tab"}},[_c('v-tab-item',[_c('v-card-text',{staticClass:"d-flex pa-8 flex-row align-center"},[_c('div',{staticClass:"headline font-weight-bold font--text"},[_vm._v(" Default Instance Price ")])]),_c('v-card-text',{staticClass:"px-8"},[_c('v-data-table',{staticClass:"elevation-0",attrs:{"hide-default-footer":true,"headers":_vm.defaultPriceHeaders,"items-per-page":10,"items":[_vm.defaultcpu, _vm.defaultram, _vm.defaultgpu]},scopedSlots:_vm._u([{key:"item.name",fn:function(ref){
var item = ref.item;
return [(item.name && item.name == 'memory')?_c('span',[_vm._v(" "+_vm._s(item.name == "ram" || item.name == "memory" ? " RAM" : "")+" ")]):_c('span',[_vm._v(" "+_vm._s(item.name && (item.name == "cpu" || item.name == "gpu") ? item.name.toUpperCase() : _vm.$lodash.capitalize(item.name))+" ")])]}},{key:"item.size",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.total)+" "+_vm._s(item.name == "cpu" ? " vCPU" : "")+" "+_vm._s(item.name == "ram" || item.name == "memory" ? " RAM" : "")+" "),_vm._v(" "+_vm._s(item.name == "gpu" ? " GHz" : "")+" ")]}},{key:"item.price",fn:function(ref){
var item = ref.item;
return [_c('p',[_vm._v("Rp "+_vm._s(_vm.Rpformat.format(item.price_per_month))+"/Month")]),_c('p',[_vm._v("Rp "+_vm._s(_vm.Rpformat.format(item.price_per_hours))+"/Hour")])]}},{key:"item.action",fn:function(ref){
var item = ref.item;
return [_c('div',[_c('label',{staticStyle:{"cursor":"pointer"},on:{"click":function () {
                    _vm.itemdefaultedited = item;
                    _vm.unit = item.name == 'cpu' ? 'vCPU' : 'GB';
                    _vm.opendialogeditdefault = true;
                  }}},[_c('span',{staticClass:"primary--text"},[_vm._v("Edit")])])])]}}])}),_c('v-divider')],1),_c('v-card-text',{staticClass:"pa-8 pb-0 d-flex flex-row align-center"},[_c('div',{staticClass:"headline font-weight-bold font--text"},[_vm._v(" Manage Instance Flavors ")]),_c('v-spacer'),_c('v-btn',{staticClass:"secondary--text mr-4",staticStyle:{"border":"2px solid #2c94d2","background-color":"white"},attrs:{"to":"/manage-pricing/manage-region","depressed":"","height":"50","width":"150"}},[_c('span',{staticClass:"fz-14"},[_vm._v("Manage Region")])]),_c('v-btn',{staticClass:"secondary--text mr-4",staticStyle:{"border":"2px solid #2c94d2","background-color":"white"},attrs:{"to":"/manage-pricing/manage-type","depressed":"","height":"50","width":"150"}},[_c('span',{staticClass:"fz-14"},[_vm._v("Manage Type")])]),_c('v-btn',{staticClass:"secondary",attrs:{"depressed":"","height":"50","width":"200","to":"/manage-pricing/create","append":""}},[_c('span',{staticStyle:{"font-size":"14px"}},[_vm._v("Create Instance Flavors")])])],1),_c('v-card-text',{staticClass:"px-8"},[_c('v-row',[_c('v-col',[_c('v-select',{attrs:{"placeholder":"Type","item-text":"name","item-value":"id","items":_vm.selectplan,"outlined":""},model:{value:(_vm.plan),callback:function ($$v) {_vm.plan=$$v},expression:"plan"}})],1),_c('v-col',[_c('v-text-field',{staticClass:"search",attrs:{"maxlength":"50","placeholder":"Search","outlined":""},model:{value:(_vm.searchinstancesize),callback:function ($$v) {_vm.searchinstancesize=$$v},expression:"searchinstancesize"}})],1)],1)],1),_c('v-card-text',{staticClass:"px-8"},[_c('v-data-table',{staticClass:"elevation-0",attrs:{"hide-default-footer":true,"headers":_vm.headers,"items":_vm.flavors,"items-per-page":10,"options":_vm.options,"server-items-length":_vm.pagination.count},on:{"update:options":function($event){_vm.options=$event}},scopedSlots:_vm._u([{key:"item.name",fn:function(ref){
                  var item = ref.item;
return [_c('span',{staticClass:"linkpointer",on:{"click":function () {
                  _vm.selectedinstance = item;
                  _vm.opendialogfalvordetails = true;
                }}},[_vm._v(" "+_vm._s(item.flavor_name))])]}},{key:"item.type",fn:function(ref){
                var item = ref.item;
return [_vm._v(" "+_vm._s(item.type ? item.type.name : "-")+" ")]}},{key:"item.cpu",fn:function(ref){
                var item = ref.item;
return [_vm._v(" "+_vm._s(item.vcpu)+" vCPU ")]}},{key:"item.usage_limit",fn:function(ref){
                var item = ref.item;
return [_vm._v(" "+_vm._s(item.usage_limit == -1 ? "Unlimited" : item.usage_limit ? ((item.usage) + " / " + (item.usage_limit)) : "-")+" ")]}},{key:"item.region",fn:function(ref){
                var item = ref.item;
return [_vm._v(" "+_vm._s(item.region ? item.region : "-")+" ")]}},{key:"item.ram",fn:function(ref){
                var item = ref.item;
return [_vm._v(" "+_vm._s(item.ram)+" GB ")]}},{key:"item.gpu",fn:function(ref){
                var item = ref.item;
return [_vm._v(" "+_vm._s(!item.gpu ? "-" : item.gpu > 1 ? ((item.gpu) + " Units") : ((item.gpu) + " Unit"))+" ")]}},{key:"item.status",fn:function(ref){
                var item = ref.item;
return [_c('v-switch',{staticClass:"mt-0 mb-2",attrs:{"readonly":"","hide-details":"","flat":"","false-value":'deactive',"true-value":'active'},on:{"click":function () {
                  _vm.flavorSelected = item;
                  _vm.opendialogFlavorSwitch = true;
                }},model:{value:(item.status),callback:function ($$v) {_vm.$set(item, "status", $$v)},expression:"item.status"}})]}},{key:"item.pricepermonth",fn:function(ref){
                var item = ref.item;
return [_c('p',[_vm._v("Rp "+_vm._s(_vm.Rpformat.format(item.price_per_month))+"/month")]),_c('p',[_vm._v("Rp "+_vm._s(_vm.Rpformat.format(item.price_per_hours))+"/jam")])]}},{key:"item.action",fn:function(ref){
                var item = ref.item;
return [_c('div',[_c('label',{staticStyle:{"cursor":"pointer"},on:{"click":function () {
                    _vm.deleteinstance(item);
                  }}},[_c('span',{staticClass:"error--text"},[_vm._v("Delete")])])])]}},{key:"footer",fn:function(ref){
                  var props = ref.props;
return [(_vm.pagination.count > 5)?_c('custom-footer-datatable',{attrs:{"hidden":true,"props":props},on:{"input":function (val) {
                  _vm.options = Object.assign({}, _vm.options, val);
                }}}):_vm._e()]}}])})],1)],1),_c('v-tab-item',[_c('v-card-text',{staticClass:"d-flex pa-8 flex-row align-center"},[_c('div',{staticClass:"headline font-weight-bold font--text"},[_vm._v(" Default Standard Storage ")])]),_c('v-card-text',{staticClass:"px-8"},[_c('v-data-table',{staticClass:"elevation-0",attrs:{"hide-default-footer":true,"headers":_vm.defaultVolumeHeaders,"items-per-page":10,"items":_vm.defaultstoragesize},scopedSlots:_vm._u([{key:"item.size",fn:function(ref){
                var item = ref.item;
return [_vm._v(" "+_vm._s(item.volume_size)+" GB ")]}},{key:"item.price",fn:function(ref){
                var item = ref.item;
return [_c('p',[_vm._v("Rp "+_vm._s(_vm.Rpformat.format(item.price_per_month))+"/Month")]),_c('p',[_vm._v("Rp "+_vm._s(_vm.Rpformat.format(item.price_per_hours))+"/Hour")])]}},{key:"item.action",fn:function(ref){
                var item = ref.item;
return [_c('div',[_c('label',{staticStyle:{"cursor":"pointer"},on:{"click":function () {
                    _vm.selectedvolume = item;
                    _vm.opendialogeditvol = true;
                  }}},[_c('span',{staticClass:"primary--text"},[_vm._v("Edit")])])])]}}])}),_c('v-divider')],1),_c('v-card-text',{staticClass:"d-flex pa-8 align-center"},[_c('div',{staticClass:"headline font-weight-bold font--text"},[_vm._v(" "+_vm._s(_vm.isDevModeStorageTiering ? "Manage Storage Flavor" : "Manage Volume")+" ")]),_c('v-spacer'),_c('v-btn',{staticClass:"secondary ml-6",attrs:{"height":"50","depressed":""},on:{"click":function () {
              _vm.opendialogcreatevol = true;
            }}},[_c('span',{staticStyle:{"font-size":"14px"}},[_vm._v(" Create Storage Flavor")])])],1),(_vm.isDevModeStorageTiering)?_c('v-card-text',{staticClass:"py-0 px-8"},[_c('v-row',[_c('v-col',[_c('v-select',{attrs:{"placeholder":"Type","item-text":"name","item-value":"id","items":_vm.volumeTypes,"outlined":""},model:{value:(_vm.searchVolumeType),callback:function ($$v) {_vm.searchVolumeType=$$v},expression:"searchVolumeType"}})],1),_c('v-col',[_c('v-text-field',{staticClass:"search",attrs:{"maxlength":"50","placeholder":_vm.isDevModeStorageTiering
                  ? 'Search Storage (GB)'
                  : 'Search Volume (GB)',"outlined":""},model:{value:(_vm.searchstoragesize),callback:function ($$v) {_vm.searchstoragesize=$$v},expression:"searchstoragesize"}})],1)],1)],1):_vm._e(),_c('v-card-text',{staticClass:"px-8"},[_c('v-data-table',{staticClass:"elevation-0",attrs:{"hide-default-footer":true,"headers":_vm.VolumeHeaders,"items-per-page":10,"server-items-length":_vm.paginationstorage.count,"items":_vm.storagesizes,"search":_vm.searchstoragesize,"options":_vm.optionsstorage},on:{"update:options":function($event){_vm.optionsstorage=$event}},scopedSlots:_vm._u([{key:"item.size",fn:function(ref){
                  var item = ref.item;
return [_vm._v(" "+_vm._s(item.volume_size)+" GB ")]}},{key:"item.type",fn:function(ref){
                  var item = ref.item;
return [(item.type && item.type.name)?_c('span',[_vm._v(" "+_vm._s(item.type.name)+" ")]):_vm._e()]}},{key:"item.price",fn:function(ref){
                  var item = ref.item;
return [_c('span',[_vm._v("Rp "+_vm._s(_vm.Rpformat.format(item.price_per_month))+"/month")])]}},{key:"item.status",fn:function(ref){
                  var item = ref.item;
return [_c('v-switch',{staticClass:"mt-0 mb-2",attrs:{"hide-details":"","flat":"","readonly":"","true-value":"active","false-value":"deactive"},on:{"click":function () {
                  _vm.opendialogStorageSwitch = true;
                  _vm.storageSelected = item;
                }},model:{value:(item.status),callback:function ($$v) {_vm.$set(item, "status", $$v)},expression:"item.status"}})]}},{key:"item.action",fn:function(ref){
                var item = ref.item;
return [_c('div',[_c('label',{staticStyle:{"cursor":"pointer"},on:{"click":function () {
                    _vm.selectedvolume = item;
                    _vm.opendialogdeletevol = true;
                  }}},[_c('span',{staticClass:"error--text"},[_vm._v("Delete")])])])]}},{key:"footer",fn:function(ref){
                  var props = ref.props;
return [(_vm.paginationstorage.count > 5)?_c('custom-footer-datatable',{attrs:{"props":props},on:{"input":function (val) {
                  _vm.optionsstorage = val;
                }}}):_vm._e()]}}])})],1)],1),_c('v-tab-item',[_c('v-card-text',{staticClass:"d-flex pa-8 flex-row align-center"},[_c('div',{staticClass:"headline font-weight-bold font--text"},[_vm._v(" Default Object Storage Flavor ")])]),_c('v-card-text',{staticClass:"px-8"},[_c('v-data-table',{staticClass:"elevation-0",attrs:{"hide-default-footer":true,"headers":_vm.defaultObjectStorageHeaders,"items-per-page":10,"items":_vm.s3Default},scopedSlots:_vm._u([{key:"item.size",fn:function(ref){
                var item = ref.item;
return [_vm._v(" "+_vm._s(item.object_storage_size)+" GB ")]}},{key:"item.price",fn:function(ref){
                var item = ref.item;
return [_c('p',[_vm._v("Rp "+_vm._s(_vm.Rpformat.format(item.price_per_month))+"/Month")]),_c('p',[_vm._v("Rp "+_vm._s(_vm.Rpformat.format(item.price_per_hours))+"/Hour")])]}},{key:"item.action",fn:function(ref){
                var item = ref.item;
return [_c('div',[_c('label',{staticStyle:{"cursor":"pointer"},on:{"click":function () {
                    _vm.selectedObjectStorage = item;
                    _vm.openDialogEditObjectStorage = true;
                  }}},[_c('span',{staticClass:"primary--text"},[_vm._v("Edit")])])])]}}])}),_c('v-divider')],1),_c('v-card-text',{staticClass:"d-flex pa-8 align-center"},[_c('div',{staticClass:"headline font-weight-bold font--text"},[_vm._v(" Manage Object Storage Flavors ")]),_c('v-spacer'),_c('v-text-field',{staticStyle:{"max-width":"233px"},attrs:{"maxlength":"50","outlined":"","placeholder":"Search","hide-details":""},model:{value:(_vm.objectStorageSearch),callback:function ($$v) {_vm.objectStorageSearch=$$v},expression:"objectStorageSearch"}}),_c('v-btn',{staticClass:"secondary ml-8",attrs:{"height":"50","width":"200","depressed":""},on:{"click":function () {
              _vm.openDialogCreateObjectStorage = true;
            }}},[_c('span',{staticStyle:{"font-size":"14px"}},[_vm._v(" Create Object Storage Flavor")])])],1),_c('v-card-text',{staticClass:"px-8"},[_c('v-data-table',{staticClass:"elevation-0",attrs:{"hide-default-footer":true,"headers":_vm.ObjectStorageHeaders,"items-per-page":10,"server-items-length":_vm.s3Pagination.count,"items":_vm.s3Flavors,"search":_vm.objectStorageSearch,"options":_vm.s3Options},on:{"update:options":function($event){_vm.s3Options=$event}},scopedSlots:_vm._u([{key:"item.size",fn:function(ref){
            var item = ref.item;
return [(item.object_storage_size)?_c('span',[_vm._v(_vm._s(_vm.toThousands(item.object_storage_size))+" GB")]):_c('span',[_vm._v("-")])]}},{key:"item.price",fn:function(ref){
            var item = ref.item;
return [(item.price_per_month)?_c('p',[_vm._v(" Rp "+_vm._s(_vm.Rpformat.format(item.price_per_month))+"/month ")]):_c('span',[_vm._v("-")])]}},{key:"item.status",fn:function(ref){
            var item = ref.item;
return [_c('v-switch',{staticClass:"mt-0 mb-2",attrs:{"hide-details":"","flat":"","readonly":"","true-value":"active","false-value":"deactive"},on:{"click":function () {
                  _vm.s3Switch = true;
                  _vm.selectedObjectStorage = item;
                }},model:{value:(item.status),callback:function ($$v) {_vm.$set(item, "status", $$v)},expression:"item.status"}})]}},{key:"item.action",fn:function(ref){
                var item = ref.item;
return [_c('div',[_c('label',{staticStyle:{"cursor":"pointer"},on:{"click":function () {
                    _vm.selectedObjectStorage = item;
                    _vm.openDialogDeleteObjectStorage = true;
                  }}},[_c('span',{staticClass:"error--text"},[_vm._v("Delete")])])])]}},{key:"footer",fn:function(ref){
                  var props = ref.props;
return [(_vm.s3Pagination.count > 5)?_c('custom-footer-datatable',{attrs:{"props":props},on:{"input":function (val) {
                  _vm.s3Options = val;
                }}}):_vm._e()]}}])})],1)],1),_c('restore'),(_vm.isDevModeChargingForSnapshot)?_c('snapshot'):_vm._e(),(_vm.isDevModeFloatingIP)?_c('floatingip'):_vm._e()],1),(_vm.openDialogCreateObjectStorage)?_c('dialogCreateObjectStorage',{model:{value:(_vm.openDialogCreateObjectStorage),callback:function ($$v) {_vm.openDialogCreateObjectStorage=$$v},expression:"openDialogCreateObjectStorage"}}):_vm._e(),(_vm.selectedObjectStorage && _vm.openDialogEditObjectStorage)?_c('dialogEditObjectStorage',{attrs:{"objectStorage":_vm.selectedObjectStorage},model:{value:(_vm.openDialogEditObjectStorage),callback:function ($$v) {_vm.openDialogEditObjectStorage=$$v},expression:"openDialogEditObjectStorage"}}):_vm._e(),(_vm.selectedObjectStorage && _vm.openDialogDeleteObjectStorage)?_c('dialogDeleteObjectStorage',{attrs:{"objectStorage":_vm.selectedObjectStorage},model:{value:(_vm.openDialogDeleteObjectStorage),callback:function ($$v) {_vm.openDialogDeleteObjectStorage=$$v},expression:"openDialogDeleteObjectStorage"}}):_vm._e(),(_vm.opendialogcreatevol)?_c('dialogCreateVol',{model:{value:(_vm.opendialogcreatevol),callback:function ($$v) {_vm.opendialogcreatevol=$$v},expression:"opendialogcreatevol"}}):_vm._e(),(_vm.selectedvolume && _vm.opendialogeditvol)?_c('dialogEditVol',{attrs:{"volume":_vm.selectedvolume},on:{"edited":function () {
        _vm.fetchstoragesizes();
      }},model:{value:(_vm.opendialogeditvol),callback:function ($$v) {_vm.opendialogeditvol=$$v},expression:"opendialogeditvol"}}):_vm._e(),(_vm.selectedvolume && _vm.opendialogdeletevol)?_c('dialogDelVol',{attrs:{"volume":_vm.selectedvolume},on:{"deleted":function () {
        _vm.fetchstoragesizes();
      }},model:{value:(_vm.opendialogdeletevol),callback:function ($$v) {_vm.opendialogdeletevol=$$v},expression:"opendialogdeletevol"}}):_vm._e(),(_vm.opendialogdeleteins && _vm.selectedinstance)?_c('dialogDelIns',{attrs:{"instance":_vm.selectedinstance},on:{"deleted":function () {
        _vm.fetchflavors();
      }},model:{value:(_vm.opendialogdeleteins),callback:function ($$v) {_vm.opendialogdeleteins=$$v},expression:"opendialogdeleteins"}}):_vm._e(),(_vm.opendialogeditdefault && _vm.itemdefaultedited)?_c('dialogEditDefault',{attrs:{"item":_vm.itemdefaultedited,"unit":_vm.unit},model:{value:(_vm.opendialogeditdefault),callback:function ($$v) {_vm.opendialogeditdefault=$$v},expression:"opendialogeditdefault"}}):_vm._e(),(_vm.selectedObjectStorage && _vm.s3Switch)?_c('dialogActivateFlavor',{attrs:{"onoff":_vm.selectedObjectStorage.status == 'active' ? 'off' : 'on'},on:{"confirm":function($event){return _vm.s3FlavorSwitchStatus(_vm.selectedObjectStorage)}},model:{value:(_vm.s3Switch),callback:function ($$v) {_vm.s3Switch=$$v},expression:"s3Switch"}}):_vm._e(),(_vm.flavorSelected && _vm.opendialogFlavorSwitch)?_c('dialogActivateFlavor',{attrs:{"instancetype":"Flavor","onoff":_vm.flavorSelected.status == 'active' ? 'off' : 'on'},on:{"confirm":function () {
        _vm.activate(_vm.flavorSelected).then(function () {
          _vm.opendialogFlavorSwitch = false;
        });
      }},model:{value:(_vm.opendialogFlavorSwitch),callback:function ($$v) {_vm.opendialogFlavorSwitch=$$v},expression:"opendialogFlavorSwitch"}}):_vm._e(),(_vm.storageSelected && _vm.opendialogStorageSwitch)?_c('dialogActivateFlavor',{attrs:{"instancetype":"Volume","onoff":_vm.storageSelected.status == 'active' ? 'off' : 'on'},on:{"confirm":function () {
        _vm.switchstorage(_vm.storageSelected).then(function () {
          _vm.storageSelected.status =
            _vm.storageSelected.status == 'active' ? 'deactive' : 'active';
          _vm.opendialogStorageSwitch = false;
        });
      }},model:{value:(_vm.opendialogStorageSwitch),callback:function ($$v) {_vm.opendialogStorageSwitch=$$v},expression:"opendialogStorageSwitch"}}):_vm._e(),(_vm.selectedinstance && _vm.opendialogfalvordetails)?_c('dialogOpenFlavorDetails',{attrs:{"item":_vm.selectedinstance},model:{value:(_vm.opendialogfalvordetails),callback:function ($$v) {_vm.opendialogfalvordetails=$$v},expression:"opendialogfalvordetails"}}):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }