






































import Vue from "vue";
import { useNamespacedActions, useNamespacedMutations, useNamespacedState } from "vuex-composition-helpers";
import { PROJECT } from "./namespace";
import { computed } from "@vue/composition-api";
const module = Vue.extend({
  props: ["value"],
  setup(props: any, context) {
    
    const {opendialogselectfirst} = useNamespacedState(PROJECT,['opendialogselectfirst'])
    const {setopendialogselectfirst} = useNamespacedMutations(PROJECT,['setopendialogselectfirst'])
		const {opendialogselectproject} = useNamespacedActions(PROJECT,['opendialogselectproject'])
    const dialog = computed({
      get:()=>opendialogselectfirst.value,
      set:(val:any)=>setopendialogselectfirst(val)
    })
    return {
      dialog,
      opendialogselectproject
    };
  },
});
export default module;
