<template>
  <v-card color="transparent" elevation="0">
    <v-tabs v-model="tab" background-color="white" color="primary" grow>
      <v-tab v-for="item in items" :key="item">
        {{ item }}
      </v-tab>
    </v-tabs>

    <v-tabs-items v-if="org" class="mt-8 transparent" v-model="tab">
      <v-tab-item v-for="item in items" :key="item">
        <DailyCost v-if="tab === 0" />
        <MonthlyCost v-else-if="tab === 1" />
        <HistoryBillingInvoice v-else-if="tab === 2" />
        <SummaryMonthly v-else />
      </v-tab-item>
    </v-tabs-items>
  </v-card>
</template>

<script>
import DailyCost from "./new-summary/DailyCost.vue";
import MonthlyCost from "./new-summary/MonthlyCost.vue";
import HistoryBillingInvoice from "./new-summary/HistoryBillingInvoice.vue";
import SummaryMonthly from "./new-summary/SummaryMonthly.vue";
import localstorage from "@/lib/localstorage";

export default {
  components: {
    DailyCost,
    MonthlyCost,
    HistoryBillingInvoice,
    SummaryMonthly,
  },
  data() {
    return {
      tab: null,
      items: ["Daily Cost Estimated", "Monthly Cost", "Invoice", "Summary Monthly"],
    };
  },
  computed: {
    org() {
      return JSON.parse(localstorage.getItem("currentOrg"));
    },
  },
};
</script>
