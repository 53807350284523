












































import { defineComponent } from '@vue/composition-api'
import {useNamespacedActions} from 'vuex-composition-helpers'
import {useDialog} from '@/composable/usedialog'
import { MANAGEFLAVOR } from './namespace';
const module =  defineComponent({
  props: ["value",'instance'],
  setup(props,{emit}){
    console.log(props.instance)
    const {dialog} = useDialog(props,{emit})
    const {deleteFlavor} = useNamespacedActions(MANAGEFLAVOR,['deleteFlavor'])
    return{
        dialog,
        Delete: async()=>{
          try{
            await deleteFlavor(props.instance).then(()=>{
              emit('deleted')
            })
          }finally{
            dialog.value = false
          }
        }
    }
  },
});
export default module
