var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[(_vm.loading)?_c('v-card',{attrs:{"color":"white","flat":""}},[_c('v-card-text',{staticClass:"pa-8"},[_c('p',{staticClass:"text-center"},[_c('beat-loader',{staticClass:"mr-2",attrs:{"color":"gray","size":"10px"}})],1)])],1):[(_vm.detail)?_c('v-card',{attrs:{"color":"white","flat":""}},[_c('v-card-text',{staticClass:"pa-8"},[_c('h6',{staticClass:"text-h6 font-weight-bold"},[_vm._v("Summary Monthly")]),_c('v-row',{staticClass:"mt-4"},[_c('v-col',[_c('b',[_vm._v("Report ID")]),_c('p',{staticClass:"mt-4"},[_vm._v(" "+_vm._s(_vm.detail.report_id)+" ")])]),_c('v-col',[_c('b',[_vm._v("Billing Account")]),_c('p',{staticClass:"mt-4"},[_vm._v(" "+_vm._s(_vm.detail.billing_account)+" ")])]),_c('v-col',[_c('b',[_vm._v("Status")]),_c('p',{staticClass:"mt-4",class:{
                'green--text': _vm.detail.status === 'PAID',
              }},[_vm._v(" "+_vm._s(_vm.detail.status)+" ")])]),_c('v-col',[_c('b',[_vm._v("Project Name")]),_c('p',{staticClass:"mt-4"},[_vm._v(" "+_vm._s(_vm.detail.project.name)+" ")])])],1)],1)],1):_vm._e(),(_vm.detail)?_c('v-card',{staticClass:"mt-8",attrs:{"color":"white","flat":""}},[_c('v-card-text',{staticClass:"pa-8"},[_c('v-data-table',{staticClass:"mt-8",attrs:{"hide-default-footer":true,"headers":_vm.tblHeaders,"items-per-page":99,"items":_vm.list},scopedSlots:_vm._u([{key:"item.qty",fn:function(ref){
              var item = ref.item;
return [_vm._v(" "+_vm._s(item.qty)+" h ")]}},{key:"item.amount",fn:function(ref){
              var item = ref.item;
return [_c('b',[_vm._v(" IDR "+_vm._s(item.unit.toLocaleString("en"))+"/h ")])]}},{key:"item.total",fn:function(ref){
              var item = ref.item;
return [_c('b',[_vm._v(" IDR "+_vm._s(item.billed.toLocaleString("en"))+" ")])]}}],null,false,1942180450)}),_c('v-row',[_c('v-col'),_c('v-col',[_c('v-row',{staticClass:"grey lighten-4"},[_c('v-col',[_c('b',[_vm._v("Subtotal")])]),_c('v-col',[_c('b',[_vm._v(" IDR "+_vm._s(_vm.detail.subtotal.toLocaleString("en"))+" ")])])],1),_c('v-row',{staticClass:"grey lighten-4 mt-4"},[_c('v-col',[_c('b',[_vm._v(" Credit ")])]),_c('v-col',[_c('b',{staticClass:"red--text"},[_vm._v(" IDR "+_vm._s(_vm.detail.credit.toLocaleString("en"))+" ")])])],1),_c('v-row',{staticClass:"mt-4 primary"},[_c('v-col',[_c('b',{staticClass:"white--text"},[_vm._v("Total")])]),_c('v-col',[_c('b',{staticClass:"white--text"},[_vm._v(" IDR "+_vm._s(_vm.detail.total.toLocaleString("en"))+" ")])])],1)],1)],1)],1)],1):_vm._e()]],2)}
var staticRenderFns = []

export { render, staticRenderFns }