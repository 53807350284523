var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-card-text',{staticClass:"px-8"},[_c('v-data-table',{staticClass:"elevation-0",attrs:{"hide-default-footer":true,"headers":_vm.tableHeaders,"items-per-page":5,"items":_vm.tableItems},scopedSlots:_vm._u([{key:"item.price",fn:function(ref){
var item = ref.item;
return [_vm._v(" Rp "+_vm._s(item.price.toLocaleString("en-US"))+" ")]}},{key:"item.action",fn:function(ref){
var item = ref.item;
return [_c('div',[_c('label',{staticStyle:{"cursor":"pointer"},on:{"click":function($event){return _vm.editDefaultRestore(item)}}},[_c('span',{staticClass:"primary--text"},[_vm._v("Edit")])])])]}}])}),_c('v-divider'),(_vm.selectedRestore && _vm.openDialogEditRestore)?_c('dialogEditRestore',{attrs:{"item":_vm.selectedRestore},on:{"close":function () {
        _vm.openDialogEditRestore = false;
        _vm.selectedRestore = null;
      },"updateDefaultRestore":function($event){return _vm.updateDefaultRestore($event)}},model:{value:(_vm.openDialogEditRestore),callback:function ($$v) {_vm.openDialogEditRestore=$$v},expression:"openDialogEditRestore"}}):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }