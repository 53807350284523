<template>
  <v-card flat class="rounded-lg pa-4 mt-3">
    <v-card-text class="px-0 pb-0 pt-2 rounded-lg">
      <v-tabs v-model="tab" slider-size="4">
        <!-- <v-tab>
          <span class="ml-4" style="font-size: 16px">Voucher Discount</span>
        </v-tab> -->
        <v-tab>
          <span style="font-size: 16px">Voucher Trial</span>
        </v-tab>
      </v-tabs>
    </v-card-text>
    <v-tabs-items style="border-top: 0.5px solid #e0e0e0" v-model="tab">
      <!-- <v-tab-item>
      </v-tab-item> -->
      <v-tab-item>
        <v-card-text class="d-flex flex-row align-center">
          <div class="headline font-weight-bold font--text">Manage Voucher Trial</div>
          <v-spacer />
          <v-text-field
            maxlength="50"
            v-model="search"
            class="search pt-3"
            style="max-width: 188px"
            placeholder="Search"
            outlined
          ></v-text-field>
          <div style="height: auto; width: 20px" />
          <v-btn
            depressed
            height="50"
            width="200"
            class="secondary"
            to="/manage-voucher/create"
            ><span class="fz-14">Create New Voucher</span></v-btn
          >
        </v-card-text>
        <v-card-text>
          <v-data-table
            hide-default-footer
            :headers="headers"
            hide-default-header
            :items-per-page="10"
            :server-items-length="pagination.count"
            :search="search"
            :items="vouchers"
            :options.sync="options"
          >
            <template v-slot:header="{ props, on }">
              <v-data-table-header
                v-on="on"
                v-bind="props"
                sort-icon="mdi-menu-down"
              >
              </v-data-table-header>
            </template>
            <template v-slot:item.name="{ item }">
              <router-link append :to="{path:`/manage-voucher/${item.id}`}">{{ item.name }}</router-link>
            </template>
            <template v-slot:item.service="{ item }">
              <div>
                {{item.quota}}
              </div>
            </template>
            <template v-slot:item.date="{ item }">
              <div>
                {{ $moment(item.claim_period_start).format("DD/MM/YYYY") }}
                to {{ $moment(item.claim_period_end).format("DD/MM/YYYY") }}
              </div>
            </template>
            <template v-slot:item.action="{ item }">
              <div
                @click="
                  () => {
                    selectedVoucher = item;
                    opendialogdelete = true;
                  }
                "
                style="cursor: pointer"
                class="error--text"
              >
                Delete
              </div>
            </template>
            <template v-slot:footer="{ props }">
              <custom-footer-datatable
                @input="
                  (val) => {
                    options = { ...options, ...val };
                  }
                "
                :hidden="true"
                :props="props"
              />
            </template>
          </v-data-table>
        </v-card-text>
      </v-tab-item>
    </v-tabs-items>
    <dialogDeleteVoucher
      v-if="selectedVoucher && opendialogdelete"
      v-model="opendialogdelete"
      @confirm="doDelete"
      :selectedVoucher="selectedVoucher"
    />
  </v-card>
</template>

<script>
import { ref, onMounted, watch } from "@vue/composition-api";
import {
  useNamespacedActions,
  useNamespacedState,
} from "vuex-composition-helpers";
import { MANAGEVOUCHER } from "./namespace";
import dialogDeleteVoucher from "./dialogDeleteVoucher";
export default {
  components: { dialogDeleteVoucher },
  setup() {
    const headers = ref([
      { text: "Voucher Name", value: "name" },
      { text: "Quota", value: "quota" },
      { text: "Claim Period", value: "date" },
      { text: "Action", value: "action", sortable: false },
    ]);

    const { fetchvouchers, deleteVoucher } = useNamespacedActions(MANAGEVOUCHER, [
      "fetchvouchers",
      "deleteVoucher",
    ]);
    onMounted(() => {
      fetchvouchers();
    });
    const {
      vouchers,
      pagination,
    } = useNamespacedState(MANAGEVOUCHER, [
      "vouchers",
      "pagination",
    ]);
    const options = ref({ page: 1, itemsPerPage: 10, search: "", sortBy: [], sortDesc: [] });
    const loading = ref(false);
    watch(
      options,
      async (val) => {
        loading.value = true;
        try {
          await fetchvouchers(val);
        } finally {
          loading.value = false;
        }
      },
      { deep: true }
    );
    const search = ref("");
    let searchtimeout = setTimeout(() => {}, 500);
    watch(search, (val) => {
      clearTimeout(searchtimeout);
      searchtimeout = setTimeout(() => {
        options.value.page = 1;
        options.value.search = val;
      }, 500);
    });

    const selectedVoucher = ref(null)
    const doDelete = async () => {
      const { id } = selectedVoucher.value
      await deleteVoucher(id)
    }

    return {
      doDelete,
      opendialogdelete: ref(false),
      selectedVoucher, 
      vouchers,
      headers,
      tab: ref(""),
      search,
      fetchvouchers,
      pagination,
      options
    };
  },
};
</script>