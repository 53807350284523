<template>
  <v-app>
    <LandingPageAppBar
      v-show="isEmpty(user) || $route.path.includes('/otp')"
      :pagesMenu="pagesMenu"
      :components="appBarButton"
    />

    <router-view></router-view>
    <v-snackbar
      v-model="snackbar"
      :timeout="5000"
      :color="toastMode"
      class="text-center"
    >
      <span class="fz-14">{{ toastmessage }}</span>
      <template v-slot:action="{ attrs }">
        <v-btn color="white" text v-bind="attrs" @click="snackbar = false">
          Close
        </v-btn>
      </template>
    </v-snackbar>
    <v-snackbar
      v-model="snackbarWS"
      :color="
        toastWSMode == 'success'
          ? 'green'
          : toastWSMode == 'error'
          ? 'red'
          : 'dark'
      "
      class="text-center"
      bottom
      right
      timeout="-1"
    >
      <div style="display: flex; align-items: center">
        <v-icon class="mr-2 mt-1" v-if="toastWSMode === 'success'"
          >mdi-check-circle-outline</v-icon
        >
        <v-icon class="mr-2 mt-1" v-if="toastWSMode === 'error'"
          >mdi-close-circle-outline</v-icon
        >
        <beat-loader
          v-if="toastWSMode === 'process'"
          :loading="toastWS"
          :color="'white'"
          :size="'10px'"
          style="transform: translateY(3px)"
          class="mr-2"
        ></beat-loader>
        <span class="fz-14">{{ toastWSMessage }}</span>
      </div>
      <template v-slot:action="{ attrs }">
        <v-btn color="white" text v-bind="attrs" @click="snackbarWS = false">
          X
        </v-btn>
      </template>
    </v-snackbar>
  </v-app>
</template>

<script>
import '@/scss/custom.css'
import { SUPERADMIN } from "./modules/superadmin/namespace";
import LandingPageAppBar from "./components/LandingPageAppBar";
import { mapGetters } from "vuex";
import { computed, watch } from "@vue/composition-api";
import {
  useGetters,
  useNamespacedGetters,
  useNamespacedMutations,
  useNamespacedState,
  useNamespacedActions,
} from "vuex-composition-helpers";
import localstorage from "@/lib/localstorage";

export default {
  name: "App",
  setup(props, context) {
    props;
    const { user } = useGetters({
      user: "ROLEPERMISSION/getuser",
    });
    const {
      toast,
      toastMode,
      toastmessage,
      toastWS,
      toastWSMode,
      toastWSMessage,
    } = useNamespacedState("HOMEPAGE", [
      "toast",
      "toastMode",
      "toastmessage",
      "toastWS",
      "toastWSMode",
      "toastWSMessage",
    ]);
    const { isEmpty } = context.root.$lodash;
    const { setToast, setToastWS } = useNamespacedMutations("HOMEPAGE", [
      "setToast",
      "setToastWS",
    ]);

    const snackbar = computed({
      get: () => toast.value,
      set: (val) => setToast(val),
    });

    const snackbarWS = computed({
      get: () => toastWS.value,
      set: (val) => setToastWS(val),
    });
    // connect to ws superadmin
    const { currentProj } = useNamespacedState(SUPERADMIN, ["currentProj"]);
    const { projectid } = useNamespacedState('PROJECT', ['projectid']);
    let url;

    context.root.$options.sockets.onopen = (e) => {
      console.log("Connected.",);
    };

    context.root.$options.sockets.onclose = (e) => {
      console.log(`Disconnected.`,);
    };
    
    context.root.$options.sockets.onerror = (e) => {
      console.log("Error.", e);
    };

    // ==========================
    // WEBSOCKET MESSAGE HANDLER
    // ==========================

    const handleSocketMessage = (data) => {
      const isLoggedIn = localStorage.getItem('token') ? true : false
      if(!isLoggedIn) return

      // CREATE LOAD BALANCER
      if (data.action === "create_load_balancer") {
        if (data.status === "success") {
          context.root.$store.dispatch(
            "HOMEPAGE/showSuccessToastWS",
            `Load balancer ${data.load_balancer_name} has been successfully created`,
            { root: true }
          );
          
          context.root.$store.dispatch('NETWORKLOADBALANCER/fetchLoadBalancers');
        } else {
          context.root.$store.dispatch(
            'HOMEPAGE/showErrorToastWS',
            `Failed to build load balancer ${data.load_balancer_name}`,
            { root: true }
          );
          context.root.$store.dispatch('NETWORKLOADBALANCER/fetchLoadBalancers');
        }
      }

      // UPDATE LOAD BALANCER
      if (data.action === "update_load_balancer") {
        if (data.status === "success") {
          context.root.$store.dispatch(
            "HOMEPAGE/showSuccessToastWS",
            `Load balancer ${data.load_balancer_name} has been successfully updated`,
            { root: true }
          );
          context.root.$store.dispatch('NETWORKLOADBALANCER/fetchLoadBalancers');
        } else {
          context.root.$store.dispatch(
            'HOMEPAGE/showErrorToastWS',
            `Failed to update load balancer ${data.load_balancer_name}`,
            { root: true }
          );
          context.root.$store.dispatch('NETWORKLOADBALANCER/fetchLoadBalancers');
        }
      }

      // DELETE INSTANCE
      if (data.action === "delete_instance") {
        if (data.status === "success") {
          context.root.$store.dispatch(
            "HOMEPAGE/showSuccessToastWS",
            `Instance ${data.instance_name} has been successfully destroyed`,
            { root: true }
          );
          
          context.root.$store.dispatch('INSTANCE/fetchPaginatedInstances');
          context.root.$store.commit('INSTANCE/setIsLoadingTurn', false);
        } else {
          context.root.$store.dispatch(
            'HOMEPAGE/showErrorToastWS',
            `Failed to destroy instance ${data.instance_name}`,
            { root: true }
          );
          context.root.$store.dispatch('INSTANCE/fetchPaginatedInstances');
          context.root.$store.commit('INSTANCE/setIsLoadingTurn', false);
        }
      }

      // DELETE INSTANCE
      if (data.action === "delete_instance") {
        if (data.status === "success") {
          context.root.$store.dispatch(
            "HOMEPAGE/showSuccessToastWS",
            `Instance ${data.instance_name} has been successfully destroyed`,
            { root: true }
          );
          
          context.root.$store.dispatch('INSTANCE/fetchPaginatedInstances', data.instance_id);
          context.root.$store.commit('INSTANCE/setIsLoadingTurn', false);
        } else {
          context.root.$store.dispatch(
            'HOMEPAGE/showErrorToastWS',
            `Failed to destroy instance ${data.instance_name}`,
            { root: true }
          );
          context.root.$store.dispatch('INSTANCE/fetchPaginatedInstances', data.instance_id);
          context.root.$store.commit('INSTANCE/setIsLoadingTurn', false);
        }
      }

      // POWER ON INSTANCE ..
      if (data.action === "vm_start") {
        if (data.status === "success") {
          context.root.$store.dispatch(
            "HOMEPAGE/showSuccessToastWS",
            `Instance ${data.instance_name} has been successfully turned on`,
            { root: true }
          );
          
          context.root.$store.dispatch('INSTANCE/fetchInstanceDetail', data.instance_id);
          context.root.$store.commit('INSTANCE/setIsLoadingTurn', false);
        } else {
          context.root.$store.dispatch(
            'HOMEPAGE/showErrorToastWS',
            `Failed to power on instance ${data.instance_name}`,
            { root: true }
          );
          context.root.$store.dispatch('INSTANCE/fetchInstanceDetail', data.instance_id);
          context.root.$store.commit('INSTANCE/setIsLoadingTurn', false);
        }
      }

      // POWER OFF INSTANCE ..
      if (data.action === "vm_stop") {
        if (data.status === "success") {
          context.root.$store.dispatch(
            "HOMEPAGE/showSuccessToastWS",
            `Instance ${data.instance_name} has been successfully turned off`,
            { root: true }
          );
          context.root.$store.dispatch('INSTANCE/fetchInstanceDetail', data.instance_id);
          context.root.$store.commit('INSTANCE/setIsLoadingTurn', false);
        } else {
          context.root.$store.dispatch(
            "HOMEPAGE/showErrorToastWS",
            `Failed to shutdown instance ${data.instance_name}`,
            { root: true }
          );
          context.root.$store.dispatch('INSTANCE/fetchInstanceDetail', data.instance_id);
          context.root.$store.commit('INSTANCE/setIsLoadingTurn', false);
        }
      }

      // RESTARTING INSTANCE ..
      if (data.action === "vm_restart") {
        if (data.status === "success") {
          context.root.$store.dispatch(
            "HOMEPAGE/showSuccessToastWS",
            `Instance ${data.instance_name} has been successfully restarted`,
            { root: true }
          );
          context.root.$store.dispatch('INSTANCE/fetchInstanceDetail', data.instance_id);
          context.root.$store.commit('INSTANCE/setIsLoadingTurn', false);
        } else {
          context.root.$store.dispatch(
            "HOMEPAGE/showErrorToastWS",
            `Failed to restart instance ${data.instance_name}`,
            { root: true }
          );
          context.root.$store.dispatch('INSTANCE/fetchInstanceDetail', data.instance_id);
          context.root.$store.commit('INSTANCE/setIsLoadingTurn', false);
        }
      }

      // CREATING INSTANCE ..
      if (data.action === "create_instance") {
        if (data.status === "success") {
          context.root.$store.dispatch(
            "HOMEPAGE/showSuccessToastWS",
            `Instance ${data.instance_name} has been successfully created`,
            { root: true }
          );
          context.root.$store.dispatch("INSTANCE/fetchPaginatedInstances");
        } else {
          context.root.$store.dispatch(
            "HOMEPAGE/showErrorToastWS",
            `Failed to build instance ${data.instance_name}`,
            { root: true }
          );
          context.root.$store.dispatch("INSTANCE/fetchPaginatedInstances");
        }
      }

      // CREATING INSTANCE ..
      if (data.action === "resize_root_disk") {
        if (data.status === "success") {
          context.root.$store.dispatch(
            "HOMEPAGE/showSuccessToastWS",
            `Instance ${data.instance_name} root disk has been successfully increased`,
            { root: true }
          );
          context.root.$store.dispatch("INSTANCE/fetchPaginatedInstances");
        } else {
          context.root.$store.dispatch(
            "HOMEPAGE/showErrorToastWS",
            `Failed to increase instance ${data.instance_name} root disk`,
            { root: true }
          );
          context.root.$store.dispatch("INSTANCE/fetchPaginatedInstances");
        }
      }

      // DELETE OBJECT STORAGE ..
      if (data.action === "delete_object_storage") {
        if (data.status === "success") {
          context.root.$store.dispatch(
            "HOMEPAGE/showSuccessToastWS",
            `Object storage ${data.object_storage_name} has been successfully destroyed`,
            { root: true }
          );
          context.root.$store.dispatch("OBJECTSTORAGE/fetchObjectStorages");
        } else {
          context.root.$store.dispatch(
            "HOMEPAGE/showErrorToastWS",
            `Failed to destroy ${data.object_storage_name}`,
            { root: true }
          );
          context.root.$store.dispatch("INSTANCE/fetchObjectStorages");
        }
      }

      // IMPORT CUSTOM IMAGE BY URL
      if (data.action === "image_upload") {
        if (data.status === "success") {
          context.root.$store.dispatch(
            "HOMEPAGE/showSuccessToastWS",
            `${data.image_name} has been successfully imported`,
            { root: true }
          );
          context.root.$store.dispatch("IMAGES/fetchCustomImages");
          context.root.$store.commit("IMAGES/setIsLoading", false);
        } else {
          context.root.$store.dispatch(
            "HOMEPAGE/showErrorToastWS",
            `Failed to import custom image`,
            { root: true }
          );
          context.root.$store.dispatch("IMAGES/fetchCustomImages");
          context.root.$store.commit("INSTANCE/setIsLoadingTurn", false);
        }
      }

      // SNAPSHOTS
      if (data.action === "create_snapshot") {
        const { instanceid } = context.root._route.params
        const isInInstanceDetailsPage = instanceid ? true : false
        if (data.status === "success") {
          context.root.$store.dispatch(
            "HOMEPAGE/showSuccessToastWS",
            `Snapshot ${data.image_name} has been successfully created`,
            { root: true }
          );
          
          if (isInInstanceDetailsPage) context.root.$store.dispatch('IMAGES/fetchInstanceSnapshotsByInstanceID', { instance_id: instanceid })
          else context.root.$store.dispatch('IMAGES/fetchInstanceSnapshots')

          context.root.$store.dispatch('IMAGES/fetchStorageSnapshots');
          context.root.$store.commit('IMAGES/setIsLoading', false);
        } else {
          context.root.$store.dispatch(
            "HOMEPAGE/showErrorToastWS",
            `Failed to create snapshot`,
            { root: true }
          );
          context.root.$store.dispatch('IMAGES/fetchStorageSnapshots');
          if (isInInstanceDetailsPage) context.root.$store.dispatch('IMAGES/fetchInstanceSnapshotsByInstanceID', { instance_id: instanceid })
          else context.root.$store.dispatch('IMAGES/fetchInstanceSnapshots')
          context.root.$store.commit('INSTANCE/setIsLoadingTurn', false);
        }
      }
    }
  
    const user_role = localstorage.getItem("role");
    
    // ==============================
    // WEBSOCKET SUPER ADMIN CHANNEL
    // ==============================
    
    let connection = null
    if (user_role && user_role == "Superadmin") {
      connection = new WebSocket(`${process.env.VUE_APP_WS_BASE_URL}/admin`)
      connection.onmessage = (message) => {
        const data = JSON.parse(message.data);
        handleSocketMessage(data)
      }
      connection.onopen = (event) => {
        context.root.$store.commit('HOMEPAGE/setSuperAdminWebsocket', connection, { root: true })
        console.log('SA Connected.')
      }
      connection.onclose = (event) => {
        console.log('SA Disconnected.')
      }

    }
    
    // ==========================
    // WEBSOCKET PROJECT CHANNEL
    // ==========================
    if (currentProj.value && currentProj.value.id) {
      url = `${process.env.VUE_APP_WS_BASE_URL}?project_id=${currentProj.value.id}`;
      context.root.$connect(url);
    }

    context.root.$options.sockets.onmessage = (message) => {
      const data = JSON.parse(message.data);
      handleSocketMessage(data)
    };

    watch(
      currentProj,
      () => {
          const currentProjectID = currentProj.value && currentProj.value.id ? currentProj.value.id : null;
          if (!currentProjectID) return;
          url = `${process.env.VUE_APP_WS_BASE_URL}?project_id=${currentProj.value.id}`;
          context.root.$connect(url);
      },
      { deep: true }
    );

    // check idle time
    setIdleTimeout(1000 * 60 * 15, function() {
      context.root.$store.dispatch('HOMEPAGE/logout', 'Your session is expired due to inactivity.')
      const token = localstorage.getItem('token')
      if (token) {
        localStorage.removeItem('token')
      }
      // context.root.$store.dispatch('HOMEPAGE/showSuccessToast', 'Your session is expired due to inactivity.')
    }, function() {
      console.log('Activity detected');
    });
    function setIdleTimeout(millis, onIdle, onUnidle) {
        let timeout = 0;
        startTimer();
        function startTimer() {
            timeout = setTimeout(onExpires, millis);
            document.addEventListener("mousemove", onActivity);
            document.addEventListener("click", onActivity);
            document.addEventListener("scroll", onActivity);
            document.addEventListener("keypress", onActivity);
        }
        function onExpires() {
            timeout = 0;
            onIdle();
        }
        function onActivity() {
            if (timeout) clearTimeout(timeout);
            else onUnidle();
            //since the mouse is moving, we turn off our event hooks for 1 second
            document.removeEventListener("mousemove", onActivity);
            document.removeEventListener("click", onActivity);
            document.removeEventListener("scroll", onActivity);
            document.removeEventListener("keypress", onActivity);
            setTimeout(startTimer, 1000);
        }
    }


    return {
      connection,
      snackbar,
      toast,
      toastmessage,
      toastMode,
      snackbarWS,
      toastWS,
      toastWSMessage,
      toastWSMode,
      user,
      isEmpty,
      user_role
    };
  },
  watch:{
    $route(){
      if(this.$gtag) this.$gtag.pageview({ page_path: this.$route.path })
      
      // need OTP
      const need_validate = localstorage.getItem('need_validate')
      if (need_validate && need_validate === 'true') {
        this.$store.dispatch('HOMEPAGE/logout')
      }
    }
  },
  mounted(){
    if (this.$gtag) this.$gtag.pageview({ page_path: this.$route.path })
  },
  sockets: {
      connect: function () {
          console.log('socket connected')
      },
  },
  created() {
    this.$worker.onmessage = (c) => {
      switch (c.data.type) {
        case "newToken":
          localStorage.setItem("token", c.data.data);
          break;
        case "invalidToken":
          this.$store.dispatch('HOMEPAGE/logout')
      }
    };
  },
  components: {
    LandingPageAppBar,
  },

  computed: {
    ...mapGetters({
      pagesMenu: "PAGES/pagesMenu",
      appBarButton: "HOMEPAGE/appBarButton",
    }),
  },
};
</script>
<style lang="scss">
$font-color: #556272;
.v-application {
  p,
  label,
  td,
  .v-menu__content {
    color: $font-color;

    .v-list-item__title {
      color: $font-color;
    }
  }

  .v-icon::after {
    background-color: transparent;
  }
}
</style>
