var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-dialog',{attrs:{"max-width":"500"},model:{value:(_vm.value),callback:function ($$v) {_vm.value=$$v},expression:"value"}},[_c('v-card',[_c('v-card-title',[_c('div',{staticClass:"headline font-weight-bold font--text pb-4"},[_vm._v(" Delete Instance "+_vm._s(_vm.selectedinstance.instanceName)+" ")])]),_c('v-card-text',{staticClass:"pb-0"},[_c('p',[_vm._v(" Resources associated with this Instance will not be automatically destroyed, and you may continue to be billed for them. ")]),_c('p',[_c('b',[_vm._v("Do you wish to proceed?")])])]),_c('v-card-actions',[_c('v-row',{staticClass:"ma-2"},[_c('v-col',{staticClass:"pl-0",attrs:{"cols":"6"}},[_c('v-btn',{attrs:{"color":"accent","block":"","depressed":"","height":"50"},on:{"click":function () {
                _vm.$emit('input', false);
              }}},[_vm._v(" Cancel ")])],1),_c('v-col',{staticClass:"px-0",attrs:{"cols":"6"}},[_c('v-btn',{attrs:{"disabled":_vm.isLoading,"color":"error","block":"","height":"50","depressed":""},on:{"click":function () {
                _vm.deleteInstance({
                  project_id: _vm.selectedinstance.project_id,
                  instance_id: _vm.selectedinstance.id,
                }).then(function (){
                  _vm.dialog = false
                })
              }}},[_vm._v(" "+_vm._s(_vm.isLoading ? "Loading..." : "Delete Instance")+" ")])],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }