var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-main',{staticStyle:{"margin-top":"65px"}},[_c('v-container',{style:({
        'background-image': 'url(' + require('@/assets/g8429.png') + ')',
        'background-position': 'bottom right',
        'padding-bottom': '50px',
        'background-size':'contain'
      }),attrs:{"fluid":""}},[_c('v-row',{staticStyle:{"height":"100%"}},[_c('v-col',{staticClass:"d-flex flex-column justify-center",class:{ 'order-2': _vm.$vuetify.breakpoint.xsOnly }},[_c('div',{staticStyle:{"max-width":"580px","line-height":"1.7em","margin-top":"5vw","margin-right":"0","margin-bottom":"5vw"},style:({
            'margin-left':_vm.$vuetify.breakpoint.xsOnly?'20px':'min(20%,300px)'
          })},[_c('h1',{staticClass:"display-2",staticStyle:{"font-family":"Fira sans !important","font-weight":"700","color":"#556272"}},[_vm._v(" Welcome to Lintasarta ")]),_c('h1',{staticClass:"display-2",staticStyle:{"font-family":"Fira sans !important","font-weight":"700","color":"#2388C9"}},[_vm._v(" Cloudeka ")]),_c('br'),_c('p',{staticStyle:{"font-family":"Fira sans","font-style":"normal","font-weight":"500","font-size":"24px","line-height":"31px"}},[_vm._v(" The more a company’s business develops, the more complex its IT infrastructure required eventually becomes. ")])])]),_c('v-col',{directives:[{name:"show",rawName:"v-show",value:(_vm.visibilityloginorsignup),expression:"visibilityloginorsignup"}],class:{
            'order-1': _vm.$vuetify.breakpoint.xsOnly,
            },staticStyle:{"flex-grow":"2","margin-top":"5vw"},attrs:{"cols":"12","md":"6"}},[_c('v-card',{staticClass:"rounded-lg",staticStyle:{"box-shadow":"0px 0px 20px rgba(0, 0, 0, 0.05) !important","border-radius":"20px","max-width":"460px","margin":"auto"},attrs:{"elevation":"0"}},[_c('loginform',{directives:[{name:"show",rawName:"v-show",value:(_vm.loginorsignup == 'login'),expression:"loginorsignup == 'login'"}]})],1)],1)],1)],1),_c('Footer'),_c('Dialog',{attrs:{"title":_vm.title,"message":_vm.message},on:{"close":function () {
            _vm.dialog = false;
          }},model:{value:(_vm.dialog),callback:function ($$v) {_vm.dialog=$$v},expression:"dialog"}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }