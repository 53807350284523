<template>
  <div>
    <div class="row">
      <v-col cols="12">
        <v-card class="rounded-lg" flat>
          <v-container class="pa-8">
            <v-row>
              <v-col cols="8">
                <div class="fz-21 font-weight-bold font--text">
                  Create Ticket
                </div>
              </v-col>
            </v-row>

            <v-row>
              <v-col cols="6">
                <v-text-field
                  maxlength="50"
                  v-model="subject"
                  outlined
                  placeholder="Enter Subject Ticket"
                  :error-messages="
                    $v.subject.$errors.length
                      ? $v.subject.$errors[0].$message
                      : ''
                  "
                ></v-text-field>
              </v-col>
            </v-row>

            <v-row>
              <v-col class="d-flex flex-row">
                <v-card
                  height="80"
                  min-width="372"
                  outlined
                  class="rounded-lg pl-2"
                  flat
                  :style="{
                    border: type == 'Billing' ? '1px solid #2C94D2' : '',
                  }"
                  @click="
                    () => {
                      type = 'Billing';
                    }
                  "
                >
                  <div class="d-flex pa-1 align-center">
                    <div>
                      <v-radio-group v-model="type" class="mr-3 ml-1">
                        <v-radio value="Billing"></v-radio>
                      </v-radio-group>
                    </div>
                    <div>
                      <p class="fz-16 font-weight-bold mb-2">
                        Account and Billing Support
                      </p>
                      <p class="fz-12 accent--text mb-0">
                        Assistance with account and billing related inquires
                      </p>
                    </div>
                  </div>
                </v-card>
                <div style="height: auto; width: 40px" />
                <v-card
                  outlined
                  height="80"
                  min-width="372"
                  flat
                  class="rounded-lg pl-2"
                  :style="{
                    border: type == 'Technical' ? '1px solid #2C94D2' : '',
                  }"
                  @click="
                    () => {
                      type = 'Technical';
                    }
                  "
                >
                  <div class="d-flex pa-1 align-center">
                    <div>
                      <v-radio-group v-model="type" class="mr-3 ml-1">
                        <v-radio value="Technical"></v-radio>
                      </v-radio-group>
                    </div>
                    <div>
                      <p class="fz-16 font-weight-bold mb-2">
                        Technical Support
                      </p>
                      <p class="fz-12 accent--text mb-0">
                        Service related technical issues
                      </p>
                    </div>
                  </div>
                </v-card>
              </v-col>
            </v-row>
            <br />
            <v-divider />
            <br />
            <v-row>
              <v-col cols="6">
                <div class="fz-20 font-weight-bold font--text">
                  Ticket Details
                </div>

                <br />
                <h4 class="pt-3">Project Name*</h4>
                <v-select
                  placeholder="Enter Project Name"
                  :items="projects"
                  v-model="project"
                  item-text="name"
                  item-value="id"
                  outlined
                  single-line
                  append-icon="mdi-chevron-down"
                  :error-messages="
                    $v.type.$errors.length ? $v.type.$errors[0].$message : ''
                  "
                ></v-select>

                <h4 class="pt-3">Service List</h4>
                <v-select
                  placeholder="Normal"
                  :items="severities"
                  outlined
                  v-model="severity"
                  :item-text="'text'"
                  :item-value="'value'"
                  single-line
                  append-icon="mdi-chevron-down"
                ></v-select>

                 <h4 class="pt-3">Priority</h4>
                <v-select
                  placeholder="Normal"
                  :items="severities"
                  outlined
                  v-model="severity"
                  :item-text="'text'"
                  :item-value="'value'"
                  single-line
                  append-icon="mdi-chevron-down"
                ></v-select>

                <h4 class="pt-3">Description</h4>
                <v-textarea
                  v-model="limittype"
                  outlined
                  name="input-7-4"
                  placeholder="Enter Description"
                  :error-messages="
                    $v.limittype.$errors.length
                      ? $v.limittype.$errors[0].$message
                      : ''
                  "
                ></v-textarea>

                <h4 class="pt-3">Type Ticket</h4>
                <v-select
                  placeholder="Normal"
                  :items="ticket_type"
                  outlined
                  v-model="ticket_type"
                  :item-text="'text'"
                  :item-value="'value'"
                  single-line
                  append-icon="mdi-chevron-down"
                ></v-select>

              </v-col>
            </v-row>

            <v-row>
              <v-col cols="6">
                <v-btn to="/ticket" class="accent" dark depressed block
                  ><span class="fz-14">Cancel</span></v-btn
                >
              </v-col>
              <v-col cols="6">
                <v-btn depressed class="secondary" block @click="submitTicket()"
                  ><span class="fz-14">Submit</span></v-btn
                >
              </v-col>
            </v-row>
          </v-container>
        </v-card>
      </v-col>
    </div>
  </div>
</template>

<style scoped>
.v-btn {
  height: 55px !important;
}
</style>

<script>
import ticket from "./ticket";
import { onMounted, ref } from "@vue/composition-api";
import useVuelidate from "@vuelidate/core";
import { required } from "@vuelidate/validators";
import { TICKET } from "./namespace";
import {
  useNamespacedActions,
  useNamespacedState,
} from "vuex-composition-helpers";
import { PROJECT } from "../project/namespace";

export default {
  setup(props, context) {
    const test = ref("");
    const test2 = ref("");
    const types = ref(["Billing", "Technical"]);
    const severities = ref([
      {
        text: "Low",
        value: "low",
      },
      {
        text: "Normal",
        value: "normal",
      },
      {
        text: "Medium",
        value: "medium",
      },
      {
        text: "High",
        value: "high",
      },
    ]);

    // const ticket_type = ref([
    //    {
    //         id: 1,
    //         text: 'billing'
    //     },
    //     {
    //         id: 2,
    //         text: 'technical'
    //     }
    // ])

    function check1() {
      test.value = "checked";
      test2.value = "";
    }

    function check2() {
      test.value = "";
      test2.value = "checked";
    }

    const type = ref("");
    const limittype = ref("");
    const subject = ref("");
    const project = ref("");
    const $v = useVuelidate(
      {
        subject: { required },
        type: { required: required },
        limittype: { required: required },
        project: { required },
      },
      { subject, type, limittype, project }
    );
    const severity = ref("");
    const { addticket } = useNamespacedActions(TICKET, ["addticket"]);
    function submitTicket() {
      console.log("submitticket");
      $v.value.$touch();
      if ($v.value.$errors.length) return;
      const Value = {
        subject: subject.value,
        ticket_type: type.value,
        priority: severity.value,
        description: limittype.value,
        project_id: project.value,
        status: "open",
      }; //
      addticket(Value).then(()=>{
        context.root.$router.replace('/ticket')
      })
    }
    const { fetchprojects } = useNamespacedActions(PROJECT, ["fetchprojects"]);
    const { projects } = useNamespacedState(PROJECT, ["projects"]);
    const { fetchticketstype } = useNamespacedActions(PROJECT, ["fetchticketstype"]);
    const { ticket_type, } = useNamespacedState(TICKET, ["typetickets"]);
    onMounted(() => {
      fetchprojects();
      fetchticketstype();
    });
    return {
      projects,
      ticket_type,
      project,
      $v,
      subject,
      types,
      type,
      severities,
      severity,
      limittype,
      check1,
      check2,
      test,
      test2,
      submitTicket,
    };
  },
};
</script>

<style lang="scss" scoped>
.v-card {
  ::v-deep .v-card--link::before {
    background: none;
  }
}

.title {
  font-style: normal;
  font-weight: bold;
  font-size: 21px;
  line-height: 140%;
  color: #556272;
}

.title-info {
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 140%;
  color: #a5b3bf;
}
h4 {
  padding-bottom: 10px;
}

input[type="radio"] {
  width: 30px;
  height: 30px;
}

.v-input {
  ::v-deep .v-text-field__details .v-messages__wrapper :not(.error--text) {
    z-index: -1;
    width: 100px;
  }
}
</style>