var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-card-text',[_c('v-container',[_c('v-col',{staticClass:"justify-center"},[_c('h1',{staticStyle:{"text-align":"center","font-weight":"800","font-size":"28px","line-height":"36px","letter-spacing":"0.04em","font-family":"Fira sans","font-style":"normal"}},[_vm._v(" Sign In Now! ")]),_c('v-form',{ref:"form",staticStyle:{"margin-top":"40px"},attrs:{"lazy-validation":""}},[_c('v-text-field',{staticStyle:{"margin-bottom":"22px"},attrs:{"maxlength":"50","id":"username","flat":"","outlined":"","placeholder":"Email Address","required":"","error-messages":_vm.$v.email.$errors.length ? _vm.$v.email.$errors[0].$message : ''},model:{value:(_vm.email),callback:function ($$v) {_vm.email=(typeof $$v === 'string'? $$v.trim(): $$v)},expression:"email"}}),_c('v-text-field',{attrs:{"id":"password","flat":"","outlined":"","append-icon":_vm.show1 ? 'mdi-eye' : 'mdi-eye-off',"type":_vm.show1 ? 'text' : 'password',"name":"input-10-1","placeholder":"Password","error-messages":_vm.$v.password.$errors.length ? _vm.$v.password.$errors[0].$message : ''},on:{"click:append":function($event){_vm.show1 = !_vm.show1},"keypress":function (ev) {
              _vm.enter(ev);
            }},model:{value:(_vm.password),callback:function ($$v) {_vm.password=$$v},expression:"password"}}),(_vm.isDevModeIPProtection && _vm.recaptchaSitekey)?_c('vue-recaptcha',{ref:"recaptcha",staticClass:"g-recaptcha",attrs:{"sitekey":_vm.recaptchaSitekey},on:{"verify":_vm.onCaptchaVerified}}):_vm._e(),_c('p',{staticClass:"error--text",style:({
            opacity:
              _vm.$v.captcha && _vm.$v.captcha.$errors && _vm.$v.captcha.$errors.length
                ? 1
                : 0,
            marginTop: '16px',
          })},[_vm._v(" "+_vm._s(_vm.$v.captcha && _vm.$v.captcha.$errors && _vm.$v.captcha.$errors.length ? _vm.$v.captcha.$errors[0].$message : "")+" ")]),_c('div',{staticClass:"mt-2"},[_c('p',{staticClass:"linkpointer text-right",staticStyle:{"font-weight":"600","font-size":"12px"},on:{"click":function () {
                _vm.opendialogforgot = true;
              }}},[_vm._v(" Forgot Password? ")])]),_c('v-btn',{staticClass:"mr-4 mt-7 text-capitalize font-weight-bold",attrs:{"id":"submit","block":"","height":"50","disabled":_vm.loading,"depressed":"","color":"secondary"},on:{"click":_vm.login}},[_vm._v(" "+_vm._s(_vm.loading ? "Sign In..." : "Sign In")+" ")]),_c('br'),_c('v-btn',{staticClass:"mr-4 mt-2 text-capitalize font-weight-bold",staticStyle:{"border":"1px solid #2c94d2"},attrs:{"to":"/signup","exact":"","block":"","height":"50","disabled":_vm.loading,"depressed":"","outlined":"","color":"white"}},[_c('span',{staticClass:"secondary--text"},[_vm._v("Sign Up")])])],1)],1),_c('dialogfailloginVue',{attrs:{"message":_vm.dialogfailloginMessage},on:{"reset":_vm.doReset,"close":function () {
          _vm.dialogfaillogin = false;
        }},model:{value:(_vm.dialogfaillogin),callback:function ($$v) {_vm.dialogfaillogin=$$v},expression:"dialogfaillogin"}})],1),_c('dialogForgot',{on:{"success":function () {
        _vm.opendialogreset = true;
        _vm.dialogfaillogin = false;
      },"error":function (message) {
        _vm.opendialogfailedforgot = true;
        _vm.dialogfailForgotMessage = message;
      }},model:{value:(_vm.opendialogforgot),callback:function ($$v) {_vm.opendialogforgot=$$v},expression:"opendialogforgot"}}),(_vm.ipError)?_c('dialogIP',{attrs:{"errmsg":_vm.ipErrorMsg},model:{value:(_vm.ipError),callback:function ($$v) {_vm.ipError=$$v},expression:"ipError"}}):_vm._e(),_c('dialogReset',{model:{value:(_vm.opendialogreset),callback:function ($$v) {_vm.opendialogreset=$$v},expression:"opendialogreset"}}),_c('dialogFailedReset',{attrs:{"message":_vm.dialogfailForgotMessage},model:{value:(_vm.opendialogfailedforgot),callback:function ($$v) {_vm.opendialogfailedforgot=$$v},expression:"opendialogfailedforgot"}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }