<template>
  <div>
    <v-row>
      <v-col cols="12">
        <v-card class="rounded-lg" flat>
          <v-container>
            <v-row>
              <v-col cols="1">
                <div
                  class="mx-2"
                  style="
                    padding-left: 30;
                    width: 50px;
                    height: 50px;
                    background: #f1f2f2;
                    border-radius: 50%;
                  "
                ></div>
              </v-col>
              <v-col cols="8" class="px-0">
                <p class="mb-0 fz-21 font-weight-bold">
                  {{ $route.params.project }}
                </p>
                <p class="mb-0">
                  Update your project information under Settings
                </p>
              </v-col>
              <v-col cols="3" class="text-center">
                <v-btn
                  to="setting"
                  style="height: 45px; width: 150px"
                  depressed
                  class="secondary"
                  append
                  >Settings Project</v-btn
                >
              </v-col>
            </v-row>
          </v-container>
        </v-card>
      </v-col>
    </v-row>

    <v-row>
      <v-col cols="12">
        <v-card class="rounded-lg" flat>
          <resource :key="$route.params.project"/>
        </v-card>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import resource from "./resource";
import Capacity from "./capacity.vue";
import { useNamespacedMutations } from "vuex-composition-helpers";
import { PROJECT } from "./namespace";
import findprojectbyname from "./findprojectbyname";
import { SUPERADMIN } from '../superadmin/namespace';


export default {
  components: { resource },
  setup(props, context) {

    const { setprojectname } = useNamespacedMutations(PROJECT, [
      "setprojectname",
    ]);
    const {setCurrentProj} = useNamespacedMutations(SUPERADMIN, ['setCurrentProj'])
    return {
      setprojectname,
      setCurrentProj
    };
  },

  // beforeRouteUpdate(to, from, next) {
  //   const projectname = to.params.project;
  //   findprojectbyname(projectname)
  //     .then((project) => {
  //       if (project) {
  //         this.setprojectname(projectname);
  //         next();
  //       } else {
  //         next("/notfound");
  //       }
  //     })
  //     .catch((e) => {
  //       next("/notfound");
  //     });
  // },

  beforeRouteUpdate: async (to, from, next) => {
    console.log('update');
    const projectname = to.params.project;
    const project = await findprojectbyname(projectname);
    console.log(project);
    if (project) {
      next((vm) => {
        vm.setprojectname(projectname);
        vm.setCurrentProj(project)

      });
    } else {
      next("/project");
    }
  },

  beforeRouteDestroy:async (to, from, next) => {
    console.log('destroy');
    const projectname = to.params.project;
    const project = await findprojectbyname(projectname);
    console.log(project);
    if (project) {
      next((vm) => {
        vm.setprojectname(projectname);
        vm.setCurrentProj(project)

      });
    } else {
      next("/project");
    }
  },

  beforeRouteEnter: async (to, from, next) => {
    console.log('enter');
    const projectname = to.params.project;
    const project = await findprojectbyname(projectname);
    if (project) {
      next((vm) => {
        vm.setprojectname(projectname);
        vm.setCurrentProj(project)

      });
    } else {
      next("/project");
    }
  },
};
</script>
<style lang="scss" scoped>
.v-tabs {
  ::v-deep .v-window {
    border-radius: 8px !important;
  }
}
</style>