





















































import { defineComponent } from "@vue/composition-api";
import { ref } from '@vue/composition-api'
import { TICKET } from "./namespace";
import { useNamespacedState, useNamespacedActions } from "vuex-composition-helpers";

const module = defineComponent({
  props: {
     value: {
          type: Boolean,
          default: false,
      },
      attachId: {
          type: String,
          required: true,
      },
      ticketId: {
          type: String,
          required: true,
      },
  },
  setup(props, { emit }) {
    const { deleteattachment, fetchattachment } = useNamespacedActions(TICKET, ["deleteattachment", "fetchattachment"]);
    const { isLoading } = useNamespacedState('PROFILE', [ 'isLoading' ]);

    function deleteattach(attachId: any, ticketId: any) {
        const Value = {
          attachId: attachId,
          ticketId: ticketId,
        }

        deleteattachment(Value).then(()=>{
          fetchattachment(ticketId)
          emit('close')
          location.reload();
        })
    }

    return {
      isLoading,
      deleteattach,
      deleteattachment,
      fetchattachment,
    };
  },
});
export default module;
