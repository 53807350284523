var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-dialog',{attrs:{"max-width":"1000"},model:{value:(_vm.dialog),callback:function ($$v) {_vm.dialog=$$v},expression:"dialog"}},[_c('v-card',{staticClass:"pt-7"},[_c('v-card-text',[_c('div',{staticClass:"headline font-weight-bold font--text"},[_vm._v("List of Voucher Code")])]),_c('v-card-text',[_c('v-data-table',{staticClass:"elevation-0",attrs:{"hide-default-footer":true,"headers":_vm.headers,"items":_vm.voucherDetails.voucher_detail,"items-per-page":5},scopedSlots:_vm._u([{key:"item.code",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.code)+" ")]}},{key:"item.name",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.voucher.name || '-')+" ")]}},{key:"item.claim_period",fn:function(ref){
var item = ref.item;
return [(item.voucher.id)?_c('span',[_vm._v(_vm._s(_vm.moment(item.voucher.claim_period_start).format('D MMMM Y'))+" - "+_vm._s(_vm.moment(item.voucher.claim_period_end).format('D MMMM Y')))]):_c('span',[_vm._v("-")])]}},{key:"item.used_by",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.organization_id ? item.organization.name : '-')+" ")]}}])})],1),_c('v-card-text',[_c('v-col',[_c('v-btn',{staticClass:"accent",attrs:{"block":"","height":"50","depressed":""},on:{"click":function($event){_vm.dialog = false}}},[_c('span',{staticStyle:{"font-size":"14px"}},[_vm._v("Close")])])],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }