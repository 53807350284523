<template>
  <v-dialog max-width="500" v-model="dialog">
    <v-card class="pt-7">
      <v-card-text>
        <div class="headline font-weight-bold font--text">Add Floating IP</div>
        <!-- {{ item.id }} -->
      </v-card-text>

      <v-card-text>
        <p class="titletext">Billing Type</p>
        <v-select id="billingType" placeholder="Select Billing Type" :items="billingTypes" v-model="billingType" outlined single-line append-icon="mdi-chevron-down" :error-messages="billingTypeError" />
      </v-card-text>

      <v-card-text v-if="billingType === 'trial'">
        <p class="titletext">Trial Voucher</p>
        <v-select id="trialVoucher" placeholder="Choose trial voucher to use" :items="trialVouchers" v-model="trialVoucher" outlined single-line append-icon="mdi-chevron-down" :error-messages="trialVoucherError" />
      </v-card-text>

      <v-card-text>
        <p class="titletext">Floating IP Type</p>

        <v-select id="floatingIpType" placeholder="Select Floating IP Type" :items="floatingIpTypes" v-model="floatingIpType" outlined single-line append-icon="mdi-chevron-down" :error-messages="floatingIpTypeError" />

        <p v-if="floatingIpPrice" class="primary--text">*{{ floatingIpName.charAt(0).toUpperCase() + floatingIpName.slice(1) }} Price RP {{ floatingIpPrice.toLocaleString("en-US") }}/month</p>
        <p v-else class="primary--text">*Information about floating IP type price</p>

        <!-- <p class="primary--text">
          *Reserved Price RP 10,003/month
        </p> -->
      </v-card-text>

      <v-card-text>
        <v-row>
          <v-col>
            <v-btn
              depressed
              height="45"
              block
              class="accent"
              @click="
                () => {
                  $emit('input', false);
                }
              "
              :disabled="loading"
            >
              <!-- loading indicator -->
              <beat-loader v-if="loading" :loading="loading" :color="'white'" :size="'10px'" class="ml-2" />

              Cancel
            </v-btn>
          </v-col>

          <v-col>
            <v-btn id="Add" depressed height="45" block class="secondary" @click="submit" :disabled="loading">
              <!-- loading indicator -->
              <beat-loader v-if="loading" :loading="loading" :color="'white'" :size="'10px'" class="ml-2" />

              Create
            </v-btn>
          </v-col>
        </v-row>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>
import { computed, ref } from "@vue/composition-api";
import api from "@/lib/api";
import { useNamespacedActions } from "vuex-composition-helpers";
import { INSTANCE } from "./namespace";

export default {
  props: ["value", "item"],
  setup(props, { emit }) {
    const dialog = computed({
      get: () => props.value,
      set: (val) => {
        emit("input", val);
      },
    });

    const loading = ref(false)
    const billingTypes = ref([
      {
        text: "Default",
        value: "default",
      },
      {
        text: "Trial",
        value: "trial",
      },
    ])
    const billingType = ref("")
    const billingTypeError = ref("")
    const floatingIpTypes = ref([])
    const floatingIpType = ref("")
    const floatingIpTypeError = ref("")
    const trialVouchers = ref([])
    const trialVoucher = ref("")
    const trialVoucherError = ref("")

    const { addfloatingip } = useNamespacedActions("NETWORKFLOATINGIP", ["addfloatingip"]);
    const { fetchPaginatedInstances } = useNamespacedActions(INSTANCE, ["fetchPaginatedInstances"]);

    const assignFloatingIP = async (obj) => {
      loading.value = true
      const response = await addfloatingip(obj);
      loading.value = false
      emit("input", false);
      if (response.status === 200) await fetchPaginatedInstances();
    };

    return {
      assignFloatingIP,
      dialog,
      loading,
      billingTypes,
      billingType,
      billingTypeError,
      floatingIpTypes,
      floatingIpType,
      floatingIpTypeError,
      trialVouchers,
      trialVoucher,
      trialVoucherError,
    };
  },
  computed: {
    selectedFloatingIP() {
      return this.floatingIpTypes.find((type) => type.value === this.floatingIpType);
    },
    floatingIpPrice() {
      if (this.selectedFloatingIP) {
        return this.selectedFloatingIP.price;
      }

      return 0;
    },
    floatingIpName() {
      if (this.selectedFloatingIP) {
        return this.selectedFloatingIP.text;
      }

      return "";
    },
  },
  methods: {
    submit() {
      const fields = ["billingType", "floatingIpType"];

      const areFieldsEmpty = fields.some((field) => !this[field]);

      if (areFieldsEmpty) {
        for (let i = 0; i < fields.length; i++) {
          if (!this[fields[i]]) this[fields[i] + "Error"] = "Value is required";
        }
      } else if (this.billingType && this.billingType === "trial" && !this.trialVoucher) {
        this.trialVoucherError = "Value is required";
      } else {
        this.assignFloatingIP({
          id: this.item.id,
          voucher_id: this.trialVoucher ? this.trialVoucher : null,
          ip_type: this.floatingIpType,
        })

        // const response = await addfloatingip({
        //   project_id: activeProjectID,
        //   instance_id: payload.id,
        //   voucher_id: payload.voucher_id,
        //   ip_type: payload.ip_type,
        // });
      }
    },
    async getFloatingIpTypes(type, text) {
      this.loading = true;

      try {
        const res = await api.GET("/network/floating-ip/price/" + type);

        this.floatingIpTypes.push({
          text,
          value: res.data.type,
          price: res.data.price_per_month,
        });
      } catch {
        this.$store.dispatch("HOMEPAGE/showErrorToast", `Sorry, an error occurred while displaying floating IP ${type} type`);
      }

      this.loading = false;
    },
    async getTrialVouchers() {
      this.loading = true;

      try {
        const res = await api.GET("/user/org-voucher");

        this.trialVouchers = res.data.filter((x) => x.voucher.id);
      } catch {
        this.$store.dispatch("HOMEPAGE/showErrorToast", "Sorry, an error occurred while displaying trial vouchers");
      }

      this.loading = false;
    },
    onChangeHandler(e) {
      if (!this[e.target.id] && !this[e.target.id + "Error"]) {
        this[e.target.id + "Error"] = "Value is required";
      }

      if (this[e.target.id] && this[e.target.id + "Error"]) {
        this[e.target.id + "Error"] = "";
      }
    },
  },
  mounted() {
    this.getFloatingIpTypes("reserved", "Reserved");
    this.getFloatingIpTypes("non-reserved", "Non-Reserved");
    this.getTrialVouchers();
  },
  watch: {
    billingType() {
      this.onChangeHandler({ target: { id: "billingType" } });
    },
    floatingIpType() {
      this.onChangeHandler({ target: { id: "floatingIpType" } });
    },
    trialVoucher() {
      this.onChangeHandler({ target: { id: "trialVoucher" } });
    },
  },
};
</script>

<style lang="scss" scoped>
.titletext {
  font-weight: 700;
  font-size: 12px;
}
</style>
