<template>
  <v-dialog v-if="CrateModal" v-model="CrateModal" max-width="543px">
    <v-card flat class="rounded-lg">
      <v-container class="pa-6">
        <div class="title">Add New Project</div>
        <div style="padding-top: 30px; padding-y: 10px">
          <div class="label">Project Name</div>
          <v-text-field
            placeholder="Enter Name"
            single-line
            outlined
            v-model="name"
            maxlength="30"
            :disabled="loading"
            :filled="loading"
            :error-messages="createErrorMessage('name')
            "
          ></v-text-field>
          <br />
          <div class="label">Description Project</div>
          <v-textarea
            outlined
            name="input-7-4"
            placeholder="Enter Description"
            v-model="description"
            maxlength="225"
            :error-messages="createErrorMessage('description')"
            :disabled="loading"
            :filled="loading"
          ></v-textarea>

          <div class="label mt-4" style="margin-bottom: -35px">Payment Method</div>

          <v-radio-group v-model="payment_method" row 
            :disabled="loading"
            :filled="loading">
            <v-row>
              <v-col v-if="!hidePrepaid">
                <v-radio label="Prepaid" value="prepaid">
                  <template v-slot:label>
                    <span class="fs-14">Prepaid</span>
                  </template>
                </v-radio>
              </v-col>

              <v-col v-if="!hidePostpaid">
                <v-radio label="Postpaid" value="postpaid">
                  <template v-slot:label>
                    <span class="fs-14">Postpaid</span>
                  </template>
                </v-radio>
              </v-col>
            </v-row>
          </v-radio-group>

          <p v-if="payment_method === 'postpaid'" :style="{ color: 'red', backgroundColor: 'pink', padding: '10px' }">
            Please contact our Team to activate your Postpaid account.
          </p>

          <v-row>
            <v-col cols="6">
              <v-btn
                block
                @click="
                  () => {
                    CrateModal = false;
                    reset();
                  }
                "
                depressed
                dark
                class="rounded-lg accent"
            :disabled="loading"
                >Cancel</v-btn
              >
            </v-col>
            <v-col cols="6">
              <v-btn
                block
                class="rounded-lg secondary"
                @click="()=>{createproject()}"
                depressed
            :disabled="loading"
                >Create</v-btn
              >
            </v-col>
          </v-row>
        </div>
      </v-container>
    </v-card>
    <dialogfailcreate :message="messagefailcreate" v-model="opendialogfailcreate" />
  </v-dialog>
</template>

<script>
import { useCreateProject } from "./useCreateProject";
import dialogfailcreate from "./dialogFailCreate";
import { ref } from "@vue/composition-api";
import { useNamespacedMutations } from 'vuex-composition-helpers'
import localstorage from '@/lib/localstorage';
import api from '@/lib/api';
const { required } = require("@vuelidate/validators");
const useVuelidate = require("@vuelidate/core").default;

// const hidePrepaid = process.env.VUE_APP_DEV_MODE !== 'fase-1.3'

export default {
  components: { dialogfailcreate },
  setup(props, context) {
    const {
      name,
      description,
      payment_method,
      descValidation,
      CreateProject,
      CrateModal,
    } = useCreateProject();
    
    const opendialogfailcreate = ref(false);
    const messagefailcreate = ref('')
    const {
      setopendialogcommonsuccess,
    } = useNamespacedMutations("DASHBOARD", [
      "setopendialogcommonsuccess",
    ]);

    const reset = async () => {
      name.value='';
      description.value='';
      payment_method.value='prepaid';
    };
    
    const $v = useVuelidate(
      {
        name: {
            required, alpha: {
                $message: 'value must alpha or alphanumeric',
                $validator:(v)=>{
                    let val = v 
                    return /^[a-zA-Z0-9- ]+$/.test(val)
                }
            }
        },
        description: { required },
        payment_method: { required }
      },
      {
        name,
        description,
        payment_method
      }
    );

    
    function createErrorMessage(element) {
      return $v.value[element].$errors.length
        ? $v.value[element].$errors[0].$message
        : "";
    }

    const createproject = async () => {
      $v.value.$touch();
      console.log($v.value.$invalid);
      if ($v.value.$invalid) return;

      await CreateProject()
        .then((e) => {
          console.log(e);
          context.root.$router.push(`/project/${name.value}`);
          setopendialogcommonsuccess(true);
          name.value='';
          description.value='';
          payment_method.value='prepaid';
        })
        .catch((e) => {
          if(!e.response) return
          const { data } = e.response.data;
          messagefailcreate.value = data;
          opendialogfailcreate.value = true;
          name.value='';
          description.value='';
          payment_method.value='prepaid';
        });
    };
    

    return {
      reset,
      createErrorMessage,
      messagefailcreate,
      opendialogfailcreate,
      createproject,
      name,
      description,
      payment_method,
      descValidation,
      CreateProject,
      CrateModal,
    };
  },
  data() {
    return {
      personalStatus: false,
      loading: false,
    }
  },
  computed: {
    hidePrepaid() {
      return !this.isPersonal
    },
    hidePostpaid() {
      return this.isPersonal
    },
    isPersonal() {
      return localstorage.getItem('type') === 'P' && this.personalStatus
    }
  },
  methods: {
    async getPersonalStatus() {
      this.loading = true;

      try {
        const res = await api.GET("/user/personal-status");
        this.personalStatus = res.data.personal_active;
      } catch (err) {
        let errmsg = "Sorry, an error occurred while displaying data";
        const thereIsErrorMessage = err && err.response && err.response.data;

        if (thereIsErrorMessage && err.response.data.message) {
          errmsg = err.response.data.message;
        }

        if (
          thereIsErrorMessage &&
          err.response.data.data !== "record not found"
        ) {
          this.$store.dispatch("HOMEPAGE/showErrorToast", errmsg);
        }
      }

      this.loading = false;
    },
  },
  mounted() {
    this.getPersonalStatus();
  },
};
</script>

<style scoped>
.v-btn {
  height: 50px !important;
  font-style: normal;
  font-weight: bold;
  font-size: 14px;
  line-height: 140%;
}

.title {
  font-style: normal;
  font-weight: bold;
  font-size: 21px;
  line-height: 140%;
  color: #556272;
}

.label {
  font-style: normal;
  font-weight: bold;
  font-size: 12px;
  line-height: 140%;
  color: #556272;
  padding-bottom: 14px;
}
</style>