import main from "./main";

export default {
  install(Vue) {
    Vue.addRoutes({
      path: "/payment-respond",
      component: main,
    });
  },
};
