






































import Vue from "vue";
import { useDialog } from "@/composable/usedialog";
const module = Vue.extend({
  props: ["value"],
  setup(props: any, context) {
    const { dialog } = useDialog(props, context);
    return {
      dialog,
    };
  },
});
export default module;
