






















































































































































import { defineComponent, watch, ref } from "@vue/composition-api";
import { useMember } from "../organization/usemember";
// import { useProjects } from "../project/useprojects";
import {
  useNamespacedActions,
} from "vuex-composition-helpers";
import dialogEditUser from './dialogEditUser.vue'
import dialogAddUser from './dialogAddUser.vue'
export default defineComponent({
  components:{dialogEditUser, dialogAddUser},
  setup(props: any) {
    // const { fetchprojects } = useNamespacedActions("ORGANIZATION", [
    //   "fetchprojects",
    // ]);
    const { fetchmemberById } = useNamespacedActions('MEMBER',['fetchmemberById'])
    //const projects = ref([]);
    const Composable = {
      ...useMember()
    }
    const {fetchmembers} = Composable
    const userdetail = ref('')
    const setuserdetail = (data:any)=>{
        userdetail.value = data
    }
    return {
        setuserdetail, userdetail,
        fetchmemberById,
      //...useProjects(),
      //fetchprojects,
      //projects,
      ...Composable
    };
  },
  data() {
    return {
        opendialogadduser: false,
            memberselectedvalue:'',
            opendialogedituser: false,
      switch1: true,
      headers: [
        {
          text: "Name",
          value: "name",
        },
        {
          text: "Email",
          value: "email",
        },
        {
          text: "Project",
          value: "project",
        },
        {
          text: "Join Date",
          value: "joindate",
        },
        {
          text: "Status",
          value: "status",
        },
        {
          text: "Action",
          value: "action",
          align:'center'
        },
      ],
    };
  },
  created(){
      this.fetchmembers()
  }
});
