import {INSTANCE} from '../namespace'
import {
    useNamespacedActions,
    useNamespacedState,
  } from "vuex-composition-helpers";
import { ref} from  '@vue/composition-api'
export const useStorage = (props)=>{
    props;
    const storageselected = ref(null);
    const { fetchstoragesize } = useNamespacedActions(INSTANCE,['fetchstoragesize'])
    const { fetchstoragesizes, fetchdefaultstoragesize } = useNamespacedActions('MANAGEFLAVOR',['fetchstoragesizes','fetchdefaultstoragesize'])
    const { storagesize } = useNamespacedState(INSTANCE, ['storagesize'])

    const { fetchVolumeSize } = useNamespacedActions('STORAGE',['fetchVolumeSize'])
    const { volumeSize, isLoading } = useNamespacedState('STORAGE', ['volumeSize', 'isLoading'])

    const { storagesizes, defaultstoragesize } = useNamespacedState('MANAGEFLAVOR', ['storagesizes','defaultstoragesize'])
    function isStorageSelected(id) {
        return id == storageselected.value.id;
      }
    return {
        fetchdefaultstoragesize,
        defaultstoragesize,
        fetchstoragesizes,
        storageselected,
        fetchstoragesize,
        storagesize,
        storagesizes,
        isStorageSelected,
        fetchVolumeSize,
        volumeSize,
        isLoading
    }
}