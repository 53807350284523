var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-card',{staticClass:"rounded-lg pa-2 mt-3",attrs:{"flat":""}},[_c('v-card-text',[_c('v-row',[_c('v-col',[_c('p',{staticClass:"fz-21 font-weight-bold"},[_vm._v(" "+_vm._s(_vm.title)+" ")]),_c('p',{staticClass:"mb-30px"},[_vm._v(" *This is the current Cost for your usage this billing period. A breakdown of your cost is available below ")])]),_c('v-col',{attrs:{"cols":"12","md":"4"}},[_c('div',{staticClass:"balance-card"},[_c('p',{staticClass:"card-title"},[_vm._v(" Total ")]),_c('p',{staticClass:"card-idr"},[_vm._v(" IDR "+_vm._s(_vm.total.toLocaleString("en-US"))+" ")])])])],1),_c('h1',{staticClass:"card-title2 mb-30px"},[_vm._v(" Instance Detail ")]),_c('v-data-table',{staticClass:"elevation-0 mb-30px",attrs:{"headers":_vm.instanceDetailHeaders,"items":_vm.instanceDetails,"items-per-page":99,"hide-default-footer":""},scopedSlots:_vm._u([{key:"item.spesification",fn:function(ref){
var item = ref.item;
return [_c('p',{staticClass:"d-flex align-center"},[_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [(item.spesification)?_c('img',_vm._g(_vm._b({staticClass:"mr-2",staticStyle:{"cursor":"pointer"},attrs:{"src":require("@/assets/blue-circle-mark.svg"),"alt":"small-blue-circle-mark"}},'img',attrs,false),on)):_vm._e()]}}],null,true)},[_c('span',[_vm._v("This Service Has Been Changed Before")])]),_vm._v(" "+_vm._s(item.spesification)+" ")],1)]}},{key:"item.billed",fn:function(ref){
var item = ref.item;
return [(
            !item.service_name &&
              !item.spesification &&
              !item.price &&
              !item.usage
          )?_c('p',[_c('b',[_vm._v("Total")])]):_vm._e(),_c('p',[_vm._v(_vm._s(item.billed))])]}},{key:"item.usage",fn:function(ref){
          var item = ref.item;
return [_c('p',{staticClass:"d-flex align-center"},[_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
          var on = ref.on;
          var attrs = ref.attrs;
return [(item.usage)?_c('img',_vm._g(_vm._b({staticClass:"mr-2",staticStyle:{"cursor":"pointer"},attrs:{"src":require("@/assets/blue-circle-mark.svg"),"alt":"small-blue-circle-mark"}},'img',attrs,false),on)):_vm._e()]}}],null,true)},[_c('span',[_vm._v("Service Usage Has Exceeded "),_c('b',[_vm._v("95%")]),_vm._v(" In "),_c('br'),_vm._v(" One Month, Payment Will Be Shifted "),_c('br'),_vm._v(" From Hourly To Monthly")])]),_vm._v(" "+_vm._s(item.usage)+" ")],1)]}}])}),_c('h1',{staticClass:"card-title2 mb-30px"},[_vm._v(" Storage Detail ")]),_c('v-data-table',{staticClass:"elevation-0 mb-30px",attrs:{"headers":_vm.storageDetailHeaders,"items":_vm.storageDetails,"items-per-page":99,"hide-default-footer":""},scopedSlots:_vm._u([{key:"item.billed",fn:function(ref){
          var item = ref.item;
return [(
            !item.service_name &&
              !item.spesification &&
              !item.type &&
              !item.price
          )?_c('p',[_c('b',[_vm._v("Total")])]):_vm._e(),_c('p',[_vm._v(_vm._s(item.billed))])]}}])})],1),_c('dialogspecification',{on:{"close":function($event){_vm.displaySpecPopup = false}},model:{value:(_vm.displaySpecPopup),callback:function ($$v) {_vm.displaySpecPopup=$$v},expression:"displaySpecPopup"}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }