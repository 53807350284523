var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('div',{staticClass:"row"},[_c('v-col',{attrs:{"cols":"12"}},[_c('v-card',{staticClass:"rounded-lg",attrs:{"flat":""}},[_c('v-container',{staticClass:"pa-8"},[_c('v-row',[_c('v-col',{attrs:{"cols":"8"}},[_c('div',{staticClass:"fz-21 font-weight-bold font--text"},[_vm._v(" Create Ticket asd ")])])],1),_c('v-row',[_c('v-col',{attrs:{"cols":"6"}},[_c('v-text-field',{attrs:{"maxlength":"50","outlined":"","placeholder":"Enter Subject Ticket","error-messages":_vm.$v.subject.$errors.length
                    ? _vm.$v.subject.$errors[0].$message
                    : '',"required":""},model:{value:(_vm.subject),callback:function ($$v) {_vm.subject=$$v},expression:"subject"}})],1)],1),_c('v-row',[_c('v-col',{staticClass:"d-flex flex-row"},[_c('v-card',{staticClass:"rounded-lg pl-2",style:({
                  border: _vm.type == '1' ? '1px solid #2C94D2' : '',
                }),attrs:{"height":"80","min-width":"372","outlined":"","flat":""},on:{"click":function () {
                    _vm.type = '1';
                    _vm.getReqType();
                  }}},[_c('div',{staticClass:"d-flex pa-1 align-center"},[_c('div',[_c('v-radio-group',{staticClass:"mr-3 ml-1",model:{value:(_vm.type),callback:function ($$v) {_vm.type=$$v},expression:"type"}},[_c('v-radio',{attrs:{"value":"1"}})],1)],1),_c('div',[_c('p',{staticClass:"fz-16 font-weight-bold mb-2"},[_vm._v(" Account and Billing Support ")]),_c('p',{staticClass:"fz-12 accent--text mb-0"},[_vm._v(" Assistance with account and billing related inquires ")])])])]),_c('div',{staticStyle:{"height":"auto","width":"40px"}}),_c('v-card',{staticClass:"rounded-lg pl-2",style:({
                  border: _vm.type == '2' ? '1px solid #2C94D2' : '',
                }),attrs:{"outlined":"","height":"80","min-width":"372","flat":""},on:{"click":function () {
                    _vm.type = '2';
                    _vm.getReqType();
                  }}},[_c('div',{staticClass:"d-flex pa-1 align-center"},[_c('div',[_c('v-radio-group',{staticClass:"mr-3 ml-1",model:{value:(_vm.type),callback:function ($$v) {_vm.type=$$v},expression:"type"}},[_c('v-radio',{attrs:{"value":"2"}})],1)],1),_c('div',[_c('p',{staticClass:"fz-16 font-weight-bold mb-2"},[_vm._v(" Technical Support ")]),_c('p',{staticClass:"fz-12 accent--text mb-0"},[_vm._v(" Service related technical issues ")])])])])],1)],1),_c('br'),_c('v-divider'),_c('br'),_c('v-row',[_c('v-col',{attrs:{"cols":"6"}},[_c('div',{staticClass:"fz-20 font-weight-bold font--text"},[_vm._v(" Ticket Details ")]),_c('br'),_c('h4',{staticClass:"pt-3"},[_vm._v("Project Name*")]),_c('v-select',{attrs:{"placeholder":"Choose Project Name","items":_vm.projects,"item-text":"name","return-object":"","outlined":"","single-line":"","append-icon":"mdi-chevron-down","error-messages":_vm.$v.project.$errors.length
                    ? _vm.$v.project.$errors[0].$message
                    : ''},model:{value:(_vm.project),callback:function ($$v) {_vm.project=$$v},expression:"project"}}),_c('h4',{staticClass:"pt-3"},[_vm._v("Description")]),_c('v-textarea',{attrs:{"outlined":"","name":"input-7-4","placeholder":"Enter Description","error-messages":_vm.$v.limittype.$errors.length
                    ? _vm.$v.limittype.$errors[0].$message
                    : ''},model:{value:(_vm.limittype),callback:function ($$v) {_vm.limittype=$$v},expression:"limittype"}}),_c('h4',{staticClass:"pt-3"},[_vm._v("Request Type")]),_c('v-select',{attrs:{"placeholder":"Choose Request Type","items":_vm.requestItemFilter,"item-text":"name","item-value":"id","outlined":"","single-line":"","append-icon":"mdi-chevron-down","error-messages":_vm.$v.request.$errors.length
                    ? _vm.$v.request.$errors[0].$message
                    : ''},model:{value:(_vm.request),callback:function ($$v) {_vm.request=$$v},expression:"request"}}),_c('h4',{staticClass:"pt-3"},[_vm._v("Site")]),_c('v-select',{attrs:{"placeholder":"Choose Site","items":_vm.sitesitem,"item-text":"name","item-value":"id","outlined":"","single-line":"","append-icon":"mdi-chevron-down","error-messages":_vm.$v.website.$errors.length
                    ? _vm.$v.website.$errors[0].$message
                    : ''},model:{value:(_vm.website),callback:function ($$v) {_vm.website=$$v},expression:"website"}})],1)],1),_c('v-row',[_c('v-col',{attrs:{"cols":"6"}},[_c('v-btn',{staticClass:"accent",attrs:{"to":"/ticket","dark":"","depressed":"","block":""}},[_c('span',{staticClass:"fz-14"},[_vm._v("Cancel")])])],1),_c('v-col',{attrs:{"cols":"6"}},[_c('v-btn',{staticClass:"secondary",attrs:{"depressed":"","block":""},on:{"click":function($event){return _vm.submitTicket()}}},[_c('span',{staticClass:"fz-14"},[_vm._v("Submit")])])],1)],1)],1)],1)],1)],1)])}
var staticRenderFns = []

export { render, staticRenderFns }