import { VOUCHER } from './namespace'
import store from './store'
import Voucher from './Voucher'

export default {
    install(Vue){
        Vue.addRoutetoDashboard({
            path: '/voucher',
            component: Voucher,
            meta:{
                breadcrumb: {
                    text: 'Voucher',
                    to: '/voucher',
                },
            }
        })
        Vue.addModules({[VOUCHER]: store})
    }
}