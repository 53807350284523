






































import { defineComponent } from "@vue/composition-api";
import { useDialog } from "@/composable/usedialog";
import { useNamespacedActions } from "vuex-composition-helpers";
import { MANAGEFLAVOR } from "./namespace";
const module = defineComponent({
  props: ["value", "volume"],
  setup(props, { emit }) {
    const { dialog } = useDialog(props, { emit });
    const { deleteVolume } = useNamespacedActions(MANAGEFLAVOR, [
      "deleteVolume",
    ]);
    const Delete = async () => {
      await deleteVolume(props.volume).then(() => {
        dialog.value = false;
        emit("deleted");
      });
    };
    return {
      dialog,
      Delete
    };
  },
});
export default module;
