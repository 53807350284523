<template>
  <div>
    <p v-if="loading" class="text-center">
      <beat-loader color="gray" size="10px" class="mr-2" />
    </p>

    <template v-else>
      <v-card v-if="detail" color="white" flat>
        <!-- <v-card color="white" flat> -->
        <v-card-text class="pa-8">
          <h6 class="text-h6 font-weight-bold">Detail Invoice</h6>

          <v-row class="mt-4">
            <v-col>
              <b>Report ID</b>
              <p class="mt-4">
                {{ detail.id }}
                <!-- detail.id -->
              </p>
            </v-col>

            <v-col>
              <b>Status</b>
              <p
                class="mt-4"
                :class="{
                  'green--text': detail.payment_status === 'PAID',
                }"
              >
                {{ detail.payment_status }}
              </p>

              <!-- <p class="mt-4">
                detail.status
              </p> -->
            </v-col>
          </v-row>

          <v-row>
            <v-col>
              <b>Issue Date</b>
              <p class="mt-4">
                {{ $moment(detail.last_calculated_for).format("DD MMMM YYYY") }}
                <!-- $moment(detail.created_at).format("DD MMMM YYYY") -->
              </p>
            </v-col>

            <v-col>
              <b>Due Date</b>
              <p class="mt-4">
                {{ $moment(detail.due_date).format("DD MMMM YYYY") }}
                <!-- $moment(detail.due_date).format("DD MMMM YYYY") -->
              </p>
            </v-col>
          </v-row>

          <v-row>
            <v-col>
              <b>Billing Account</b>
              <p class="mt-4">
                <!-- {{ detail.user.fullname }} -->
                <!-- detail.user.fullname -->
                {{ detail.billing_account }}
              </p>
            </v-col>
          </v-row>
        </v-card-text>
      </v-card>

      <v-card v-if="detail" color="white" flat class="mt-8">
        <!-- <v-card color="white" flat class="mt-8"> -->
        <v-card-text class="pa-8">
          <!-- tbls -->
          <InstanceTbl
            v-if="list.instance && list.instance.length"
            :tbl-items="list.instance"
            :is-postpaid-fixed="isPostpaidFixed"
          />

          <RootDiskTbl
            v-if="list.root_disk && list.root_disk.length"
            :tbl-items="list.root_disk"
            :is-postpaid-fixed="isPostpaidFixed"
          />

          <StorageTbl
            v-if="list.storage && list.storage.length"
            :tbl-items="list.storage"
            :is-postpaid-fixed="isPostpaidFixed"
          />

          <ObjectStorageTbl
            v-if="list.object_storage && list.object_storage.length"
            :tbl-items="list.object_storage"
            :is-postpaid-fixed="isPostpaidFixed"
          />

          <SnapshotTbl
            v-if="list.snapshot && list.snapshot.length"
            :tbl-items="list.snapshot"
            :is-postpaid-fixed="isPostpaidFixed"
          />

          <FloatingIPTbl
            v-if="list.floating_ip && list.floating_ip.length"
            :tbl-items="list.floating_ip"
            :is-postpaid-fixed="isPostpaidFixed"
          />

          <!-- old tbl (single tbl) -->
          <!-- <v-data-table
            :hide-default-footer="true"
            :headers="tblHeaders"
            :items-per-page="99999"
            :items="list"
            class="mt-8"
          >
            <template v-slot:item.price="{ item }">
              <b>
                IDR {{ item.price.value.toLocaleString("en") }}
                /
                {{ item.price.per }}
              </b>
            </template>

            <template v-slot:item.usage="{ item }">
              <b> {{ item.usage || "-" }} </b>
            </template>

            <template v-slot:item.billed="{ item }">
              <b> IDR {{ item.billed.toLocaleString("en") }} </b>
            </template>
          </v-data-table> -->

          <!-- totals -->
          <v-row>
            <v-col>
              <!-- empty (for spacing) -->
            </v-col>

            <v-col>
              <v-row class="grey lighten-4">
                <v-col>
                  <b>Subtotal</b>
                </v-col>
                <v-col>
                  <b>IDR {{ detail.price.toLocaleString("en") }}</b>
                  <!-- <b>IDR dummy</b> -->
                </v-col>
              </v-row>

              <v-row class="grey lighten-4 mt-4">
                <v-col>
                  <b>PPN ({{ detail.ppn }}%)</b>
                  <!-- <b>PPN (123%)</b> -->
                </v-col>
                <v-col>
                  <b>IDR {{ detail.ppn_value.toLocaleString("en") }}</b>
                  <!-- <b>IDR 123</b> -->
                </v-col>
              </v-row>

              <v-row class="mt-4 primary">
                <v-col>
                  <b class="white--text">Total</b>
                </v-col>
                <v-col>
                  <b class="white--text"
                    >IDR {{ detail.billed.toLocaleString("en") }}</b
                  >
                  <!-- <b class="white--text">IDR 123</b> -->
                </v-col>
              </v-row>
            </v-col>
          </v-row>
        </v-card-text>
      </v-card>

      <p v-else class="text-center">
        No data
      </p>
    </template>
  </div>
</template>

<script>
import api from "@/lib/api";
import InstanceTbl from "./billing-corporate-detail-billing-type/InstanceTbl.vue";
import RootDiskTbl from "./billing-corporate-detail-billing-type/RootDiskTbl.vue";
import StorageTbl from "./billing-corporate-detail-billing-type/StorageTbl.vue";
import ObjectStorageTbl from "./billing-corporate-detail-billing-type/ObjectStorageTbl.vue";
import SnapshotTbl from "./billing-corporate-detail-billing-type/SnapshotTbl.vue";
import FloatingIPTbl from "./billing-corporate-detail-billing-type/FloatingIPTbl.vue";

export default {
  components: {
    InstanceTbl,
    RootDiskTbl,
    StorageTbl,
    ObjectStorageTbl,
    SnapshotTbl,
    FloatingIPTbl,
  },
  data() {
    return {
      list: {},
      detail: null,
      loading: false,
    };
  },
  computed: {
    isPostpaidFixed() {
      const postpaidFixed =
        this.detail.project && this.detail.project.postpaid_type === "fixed";

      return postpaidFixed;
    },
  },
  methods: {
    async getInvoiceDetail() {
      this.loading = true;

      try {
        const id = this.$route.params.id;
        const res = await api.GET("/billing/invoice/detail/" + id);
        const { detail, list } = res.data;
        this.detail = detail;
        this.list = list;

        // const { description, amount, billed } = res.data;
        // this.list = [
        //   { service_name: description, qty: "1 pc", amount, billed },
        // ];
      } catch {
        this.$store.dispatch(
          "HOMEPAGE/showErrorToast",
          "Sorry, an error occurred while displaying invoice detail"
        );
      }

      this.loading = false;
    },
  },
  mounted() {
    this.getInvoiceDetail();
  },
};
</script>
