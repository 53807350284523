<template>
  <div>
    <!-- contoh template -->
    <v-row>
      <v-col cols="12">
        <v-card class="rounded-lg pa-7" flat>
          <v-row>
            <v-col cols="12">
              <div class="headline font-weight-bold font--text">
                Create Storage
              </div>
            </v-col>
          </v-row>

          <v-row>
            <v-col>
              <p>
                Block storage volumes are created in the same region and project
                as the Instance they are attached to.
              </p>
            </v-col>
          </v-row>

          <v-row v-if="isDevModeStorageTiering">
            <v-col md="6" sm="12">
              <p class="font-weight-bold">Storage Type</p>
              <v-select
                  id="SelectType"
                  v-model="type_id"
                  :items="storageTypes"
                  outlined
                  placeholder="Select a Storage Type"
                  :error-messages="$v.type_id.$errors.length ? $v.type_id.$errors[0].$message: ''"
              >
                <template v-slot:selection="{ item }">
                  {{ item.text }}
                </template>
              </v-select>
            </v-col>
          </v-row>

          <v-row v-if="type_id">
            <v-col class="pb-0" cols="12">
              <p :class="{ 'error--text': $v.storageselected.$errors.length }">
                <span class="font-weight-bold mb-0">Select Volume Size</span>
                <span
                    class="ml-2"
                    style="font-size: 10px; position: absolute"
                    v-if="$v.storageselected.$errors.length"
                >
                  *{{ $v.storageselected.$errors[0].$message }}</span
                >
              </p>
            </v-col>
            <v-slide-group
              class="py-4 pt-0"
              active-class="success"
              show-arrows
            >
              <v-col class="d-flex pt-0 flex-wrap">
                <v-card
                    width="170"
                    border-color="primary"
                    outlined
                    :class="`rounded-lg select-instance mb-4 ${isCustomSizeHighlighted ? 'hightlighted selected' : ''}`"
                    style="text-align: center; margin-right: 20px"

                >
                  <v-card-text class="pb-0 pt-4 d-flex flex-column m-0">
                    <p class="font-weight-bold mb-0">
                      {{
                        IDRformat.format(
                          (type_id === defaultstoragesize[0].type_id ? defaultstoragesize[0].price_per_month : defaultstoragesize[1].price_per_month) *  (customSize ? Number(customSize.slice(0, customSize.length - 3)): 0)
                          )
                          }}/mo
                    </p>
                    <p>{{
                      IDRformat.format(
                        (type_id === defaultstoragesize[0].type_id ? defaultstoragesize[0].price_per_hours : defaultstoragesize[1].price_per_hours) *  (customSize ? Number(customSize.slice(0, customSize.length - 3)): 0)
                        )
                      }}/hour</p>
                  </v-card-text>
                  <v-divider />

                  <v-card-text class="pa-0">
                    <v-text-field
                        v-model="customSize"
                        class="px-4 pt-2 mt-2"
                        @keydown="(ev) => customSize = addGBText(ev, customSize)"
                        style="width: 170px"
                        max="100"
                        flat
                        label="Enter Size in GB"
                        @click="() => {
                          storageselected = null
                          isCustomSizeHighlighted = true
                        }"
                        @blur="() => isCustomSizeHighlighted = !customSize ? false : true"
                    ></v-text-field>
                  </v-card-text>
                </v-card>
                <!-- <div
                  v-for="storage in filteredVolumeSizes"
                  :key="storage.id"
                > -->
                <div
                  v-for="storage in filteredVolumeSizes.filter((s) => s.type_id === type_id)"
                  :key="storage.id"
                >
                  <v-card
                      v-if="storage.volume_size"
                      width="170"
                      :class="{ highlight: storageselected ? is(storageselected.id, storage.id) : false }"
                      @click="
                      () => {
                        storageselected = storage;
                        customSize = null
                        isCustomSizeHighlighted = false
                      }
                    "
                      border-color="primary"
                      outlined
                      class="rounded-lg select-instance mb-4"
                      style="text-align: center; margin-right: 20px"
                  >
                    <!-- <v-card-text class="pb-0 pt-5"> -->
                    <v-card-text v-if="type_id" class="pb-0 pt-5">
                      <p class="font-weight-bold mb-0" style="margin-bottom: 2.5rem">
                        {{ IDRformat.format(
                          (type_id === defaultstoragesize[0].type_id ? defaultstoragesize[0].price_per_month : defaultstoragesize[1].price_per_month) * storage.volume_size
                        ) }}/mo
                      </p>
                      <p>{{ IDRformat.format(
                        (type_id === defaultstoragesize[0].type_id ? defaultstoragesize[0].price_per_hours : defaultstoragesize[1].price_per_hours) * storage.volume_size
                        ) }}/hour</p>
                    </v-card-text>
                    <v-card-text v-if="!type_id" class="pb-0 pt-5">
                      <p class="font-weight-bold mb-0">
                        {{ IDRformat.format(
                          (storage.type_id === defaultstoragesize[0].type_id ? defaultstoragesize[0].price_per_month : defaultstoragesize[1].price_per_month) * storage.volume_size
                        ) }}/mo
                      </p>
                      <p>{{ IDRformat.format(
                        (storage.type_id === defaultstoragesize[0].type_id ? defaultstoragesize[0].price_per_hours : defaultstoragesize[1].price_per_hours) * storage.volume_size
                        ) }}/hour</p>
                    </v-card-text>
                    <v-divider/>
                    <v-card-text class="flavor-card">
                      <p class="font-weight-bold mb-0">{{ storage.volume_size }} GB</p>
                    </v-card-text>
                  </v-card>
                </div>
              </v-col>
            </v-slide-group>
          </v-row>

          <v-divider class="mb-3"/>

          <v-row>
            <v-col>
              <div class="d-flex flex-row align-center">
                <p class="mb-0">
                  <b>Do you want to attach this storage to an instance or not?</b>
                </p>
                <div style="width: 30px"></div>
                <input
                    type="radio"
                    id="yes"
                    value="true"
                    name="attach"
                    v-model="attachstorage"
                />
                <div style="width: 10px"></div>

                <label
                    :class="{ 'error--text': $v.attachstorage.$errors.length }"
                    for="yes">Yes</label>

                <div style="width: 30px"></div>
                <input
                    type="radio"
                    id="no"
                    value="false"
                    name="attach"
                    v-model="attachstorage"
                />
                <div style="width: 10px"></div>
                <label
                    :class="{ 'error--text': $v.attachstorage.$errors.length }"
                    for="no"
                >No</label
                >
              </div>
              <p
                  class="error--text"
                  style="font-size: 12px; position: relative; left: 430px"
                  v-if="$v.attachstorage.$errors.length"
              >
                {{ $v.attachstorage.$errors[0].$message }}
              </p>
            </v-col>
          </v-row>

          <v-row v-if="attachstorage == 'true'">
            <v-col md="6" sm="12">
              <p class="font-weight-bold">Select Instance</p>
              <v-select
                  id="SelectInstance"
                  v-model="instanceselected"
                  :no-data-text="isInstancesLoading ? 'Loading..' : 'No data available'"
                  :items="instanceSelect"
                  outlined
                  placeholder="Select an Instance"
                  :error-messages="$v.instanceselected.$errors.length ? $v.instanceselected.$errors[0].$message: ''"
              >
                <template v-slot:selection="{ item }">
                  {{ item.text }}
                </template>
              </v-select>
            </v-col>
          </v-row>

          <v-row>
            <v-col md="6" sm="12">
              <p class="font-weight-bold">Name Volume</p>
              <v-text-field
                  maxlength="50"
                  id="NameVolume"
                  v-model="volumename"
                  full-width
                  placeholder="Type volume name"
                  outlined
                  :error-messages="createErrorMessage('volumename')"
              >
              </v-text-field>
            </v-col>
          </v-row>
          <v-divider/>
          <br/>

          <!-- <v-row>
            <v-col>
              <div class="headline font-weight-bold font&#45;&#45;text">
                Choose Configuration Options
              </div>
            </v-col>
          </v-row>
          <v-row>
            <v-col class="d-flex flex-nowrap">
              <v-card class="rounded-lg d-flex flex-grow-1" flat outlined>
                <v-card-title
                    @click="
                    () => {
                      configoptions = 'auto';
                    }
                  "
                    style="cursor: pointer"
                    :class="{ highlight: is(configoptions, 'auto') }"
                    class="d-flex flex-nowrap"
                >
                  <div>
                    <v-radio-group
                        :error-messages="createErrorMessage('configoptions')"
                        class="mt-0"
                        v-model="configoptions"
                    >
                      <v-radio value="auto"></v-radio>
                    </v-radio-group>
                  </div>
                  <div class="ml-2">
                    <p class="my-0 font-weight-bold">
                      Automatically Format & Mount
                    </p>
                    <p
                        class="my-0"
                        style="
                        font-size: 12px;
                        color: #a5b3bf;
                        word-break: break-word;
                      "
                    >
                      We will choose the appropriate default configurations.
                      These settings can be changed later via ssh.
                    </p>
                  </div>
                </v-card-title>
              </v-card>
            </v-col>
            <v-col class="d-flex flex-nowrap">
              <v-card
                  id="ChooseConfigOptions"
                  class="rounded-lg d-flex flex-grow-1"
                  flat
                  outlined
              >
                <v-card-title
                    @click="
                    () => {
                      configoptions = 'manual';
                    }
                  "
                    style="cursor: pointer"
                    :class="{ highlight: is(configoptions, 'manual') }"
                    class="d-flex flex-nowrap"
                >
                  <div>
                    <v-radio-group
                        :error-messages="createErrorMessage('configoptions')"
                        class="mt-0"
                        v-model="configoptions"
                    >
                      <v-radio value="manual"></v-radio>
                    </v-radio-group>
                  </div>
                  <div class="ml-2">
                    <p class="my-0 font-weight-bold">Manually Format & Mount</p>
                    <p
                        class="my-0"
                        style="
                        font-size: 12px;
                        color: #a5b3bf;
                        word-break: break-word;
                      "
                    >
                      We will still attach the volume. You can then manually
                      format and mount the volume.
                    </p>
                  </div>
                </v-card-title>
              </v-card>
            </v-col>
          </v-row>
          <v-row v-if="configoptions == 'auto'">
            <v-col class="d-flex py-0 flex-row align-center">
              <p class="font-weight-bold mb-0">Choose a filesystem</p>
              <div
                  style="height: auto; width: 20px; display: inline-block"
              ></div>
              <v-radio-group
                  id="choosefilesystem"
                  :error-messages="createErrorMessage('filesystem')"
                  v-model="filesystem"
                  row
              >
                <v-radio value="ext4">
                  <template v-slot:label>
                    <span style="font-size: 12px; font-weight: 600">Ext4</span>
                  </template>
                </v-radio>
                <v-radio value="xfs">
                  <template v-slot:label>
                    <span style="font-size: 12px; font-weight: 600">Xfs</span>
                  </template>
                </v-radio>
              </v-radio-group>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="12" sm="12" md="6">
              <p class="font-weight-bold">Voucher</p>
              <v-text-field
                  id="Voucher"
                  v-model="voucher"
                  outlined
                  placeholder="Type voucher here"
              ></v-text-field>
            </v-col>
          </v-row> -->

          <v-row>
            <v-col cols="12" md="6" class="py-0">
              <p><b>Billing Type</b></p>
              <v-select
                id="billingType"
                placeholder="Choose Instance Billing Type"
                v-model="selectedBillingType"
                :items="billingTypes"
                :error-messages="createErrorMessage('selectedBillingType')"
                outlined
              ></v-select>
            </v-col>
          </v-row>

          <v-row>
            <v-col cols="12" md="6" class=""
              v-if="selectedBillingType == 'Trial'"
            >
              <p><b>Trial Voucher</b></p>
              <v-select
                id="trialVoucher"
                placeholder="Choose trial voucher to use"
                v-model="selectedTrialVoucher"
                :error-messages="
                  $v.selectedTrialVoucher.$errors.length
                    ? $v.selectedTrialVoucher.$errors[0].$message
                    : ''
                "
                :items="vouchers"
                item-text="voucher.name"
                item-value="id"
                outlined
              ></v-select>
            </v-col>
          </v-row>

          <v-row>
            <v-col
              cols="12"
              v-if="
                user.role.toLowerCase() !== 'superadmin' && agree2 === false
              "
            >
              <!-- agreement checkbox -->
              <div class="d-flex align-center">
                <v-checkbox v-model="agree">
                  <template v-slot:label>
                    <div>By Creating Storage You Agree To The</div>
                  </template>
                </v-checkbox>

                <button
                  type="button"
                  class="text-decoration-underline primary--text ml-2 fz-16"
                  @click="getEula()"
                >
                  Terms of Service
                </button>
              </div>
            </v-col>
          </v-row>
          <!-- btns -->
          <v-row>
            <v-col cols="6">
              <v-btn
                  :disabled="loading"
                  @click="$router.go(-1)"
                  depressed block height="50" class="accent">
                <span style="font-size: 16px; font-weight: 700">Cancel</span>
              </v-btn>
            </v-col>
            <v-col cols="6">

              <v-btn
                v-if="user.role.toLowerCase() !== 'superadmin'"
                :disabled="agree === false && agree2 === false"
                @click="createstorage"
                depressed
                block
                height="50"
                class="secondary"
              >
                <span style="font-size: 16px; font-weight: 700"
                  >Create Storage</span
                >
              </v-btn>
              <v-btn
                v-else
                @click="createstorage"
                depressed
                block
                height="50"
                class="secondary"
              >
                <span style="font-size: 16px; font-weight: 700"
                  >Create Storage</span
                >
              </v-btn>
            </v-col>
          </v-row>
        </v-card>
      </v-col>
    </v-row>
    <Tncpopup v-model="tnc" :data="dataTnc" />
  </div>
</template>

<style scoped>
/* .v-text-field--outlined >>> fieldset {
  border-color: lightgray;
} */
</style>

<script>
import { IDRformat } from "../../lib/formatter";
import { useStorage } from "../instance/compositionapi/usestorage";
import { useCreatedInstance } from "../instance/compositionapi/usecreatedinstance";
import { computed, ref, watch, onMounted } from "@vue/composition-api";
import libProject from '@/lib/project'

const { required, requiredIf } = require("@vuelidate/validators");
const useVuelidate = require("@vuelidate/core").default;
import { useCreateStorage } from "./composableapi/usecreatestorage";
import { mapGetters } from "vuex";
import { addGBText, removeGBText } from '@/lib/formatter'
import { useVoucher } from '@/modules/voucher/useVoucher'
import api from '@/lib/api';
import {
  useNamespacedState
} from 'vuex-composition-helpers'
import Tncpopup from "./tncpopup.vue";
import localstorage from '@/lib/localstorage';

// const isDevModeStorageTiering = process.env.VUE_APP_DEV_MODE === "fase-1.3"
const isDevModeStorageTiering = true

export default {
  components: {
    Tncpopup
  },
  setup(props, context) {
    props;
    const $router = context.root.$router;
    const UseCreatedInstance = useCreatedInstance();
    const { instanceselected, instanceSelect } = UseCreatedInstance;
    const { fetchVouchers, vouchers } = useVoucher()

    const defaultBillingTypes = [
      {
        text: "Pay Per Use (PPU)",
        value: "PPU",
      },
      {
        text: "Trial",
        value: "Trial",
      },
    ]

    const fixedPostpaid = [
      {
        text: "Fixed",
        value: "Fixed",
      },
      {
        text: "Trial",
        value: "Trial",
      },
    ]

    const projJson = localstorage.getItem('currentProj')
    const projObj = projJson && projJson !== 'undefined' ? JSON.parse(projJson) : {}
    const postpaid_type = projObj.postpaid_type

    const billingTypes = ref(postpaid_type && postpaid_type === 'fixed' ? fixedPostpaid : defaultBillingTypes)

    const selectedBillingType = ref(null)
    const selectedTrialVoucher = ref(null)
    const {
      isLoading: isInstancesLoading
    } = useNamespacedState('INSTANCE', [
      'isLoading'
    ])

    const UseStorage = useStorage();
    const {storageselected, defaultstoragesize: defaultstoragesizeS, volumeSize } = UseStorage;
    const defaultstoragesize = computed(() => {
      return defaultstoragesizeS.value.slice().map(x => {
        x.size = 0
        return x
      })
    })

    const type_id = ref("");
    const volumename = ref("");
    const configoptions = ref("auto");
    const filesystem = ref("");
    const voucher = ref("");
    const loading = ref(false);
    const attachstorage = ref('true');

    const additionalRule = isDevModeStorageTiering ? {type_id: {required},} : {}
    const additionalValidation = isDevModeStorageTiering ? {type_id,} : {}

    const $v = useVuelidate(
        {
          storageselected: {
            required: requiredIf((val) => {
              return customSize.value === null && storageselected.value === null;
            }),
          },
          selectedTrialVoucher: {
            required: requiredIf((val) => {
              return selectedBillingType.value === "Trial";
            }),
          },
          selectedBillingType: {
            required,
          },
          customSize: {
            required: requiredIf((val) => {
              return customSize.value === null && storageselected.value === null;
            }),
          },
          instanceselected: {
            conditional: {
              $message: 'please select one or more instance to attach',
              $validator: (val) => {
                if (attachstorage.value == 'true') {
                  return val
                } else {
                  return true
                }
              }
            }
          },
          // type_id: {required},
          volumename: {required},
          attachstorage: {required},
          ...additionalRule,
        },
        {
          selectedBillingType,
          storageselected,
          instanceselected,
          // type_id,
          volumename,
          configoptions,
          filesystem,
          attachstorage,
          selectedTrialVoucher,
          ...additionalValidation
        }
    );

    const {createstorage: CreateStorage, attachStorage: AttachStorage} = useCreateStorage();

    function createErrorMessage(element) {
      return $v.value[element].$errors.length
          ? $v.value[element].$errors[0].$message
          : "";
    }

    function enterSizeInGB(e) {
      if (e.code.match(/^Digit/)) {
        defaultstoragesize.value[0].size = Number('' + defaultstoragesize.value[0].size + e.key)
      } else if (e.code == 'Backspace') {
        defaultstoragesize.value[0].size = Math.floor(defaultstoragesize.value[0].size / 10)
      }
    }

    const customSize = ref(null)
    const isCustomSizeHighlighted = ref(false)
    // const filteredVolumeSizes2 = ref([])
    const filteredVolumeSizes = computed(() => {
      let showCustomFlavor = process.env.VUE_APP_SHOW_CUSTOM_DISK_FLAVORS == 'true' ? true : false
      const filtered = volumeSize.value.filter(x => !x.is_default && x.status == 'active').filter(x => {
        if (showCustomFlavor) return true
        else {
          if (x.organization_id) return false // custom flavor has org id
          return true
        }
      }).sort((a, b) => a.volume_size - b.volume_size)

      if (type_id) {
        filtered.filter((s) => s.type_id === type_id)
      }

      return filtered
    })

    const filteredVolumeSizes2 = ref([])

    if (isDevModeStorageTiering) {
      watch(type_id, (val) => {
        storageselected.value = null
        filteredVolumeSizes2.value = filteredVolumeSizes.value.filter((s) => s.type_id === type_id.value)
      })
    }

    async function createstorage() {
      $v.value.$touch()
      if ($v.value.$errors.length) return

      const activeProjectID = await libProject.getActiveProjectID()
      let payload = isDevModeStorageTiering ? {
        project_id: activeProjectID,
        name: volumename.value,
        billing_type: selectedBillingType.value,
        type_id: type_id.value,
      } : {
        project_id: activeProjectID,
        name: volumename.value,
        billing_type: selectedBillingType.value,
        // type_id: type_id.value,
      }

      if(selectedBillingType.value == 'Trial') payload.voucher_id = selectedTrialVoucher.value

      if(storageselected.value) payload.package_storage_id = storageselected.value.id
      if(customSize.value) {
        // const flavorSameSize = volumeSize.value.filter(x => parseInt(x.volume_size) === parseInt(removeGBText(customSize.value)))
        const flavorSameSize = filteredVolumeSizes2.value.filter(x => parseInt(x.volume_size) === parseInt(removeGBText(customSize.value)))
        if(flavorSameSize.length > 0) payload.package_storage_id = flavorSameSize[0].id
        else payload.custom_size = parseInt(removeGBText(customSize.value))
      }

      if (attachstorage.value == 'true') payload.instance_id = instanceselected.value.id
      const response = await CreateStorage(payload)
      if(response.status === 200) {
        if (
          this.user.role.toLowerCase() !== "superadmin" &&
          this.agree2 === false
        ) {
          this.validateEula();
        }
        if (
          this.user.role.toLowerCase() !== "superadmin" &&
          this.agree2 === true
        ) {
          this.$router.push("/storage");
        }
        if (this.user.role.toLowerCase() === "superadmin") {
          this.$router.push("/storage");
        }
      }
    }

    onMounted(async () => {
      if(context.root.previousInstance) instanceselected.value = instanceSelect.value.find(x => x.value.id === context.root.previousInstance.id).value
      await fetchVouchers()
      // filteredVolumeSizes2.value = filteredVolumeSizes
    })

    watch(attachstorage, (val) => {
      if (val == 'false') instanceselected.value = null
    })

    // watch(type_id, (val) => {
    //   storageselected.value = null
    // })

    return {
      loading,
      isInstancesLoading,
      vouchers,
      selectedTrialVoucher,
      selectedBillingType,
      billingTypes,
      filteredVolumeSizes,
      addGBText,
      removeGBText,
      isCustomSizeHighlighted,
      customSize,
      attachstorage,
      $v,
      createErrorMessage,
      ...UseStorage,
      ...UseCreatedInstance,
      configoptions,
      IDRformat,
      filesystem,
      // type_id,
      volumename,
      voucher,
      createstorage,
      inputdefaultstorage: ref(false),
      defaultstoragesize,
      ...additionalValidation,
    };
  },
  beforeRouteEnter(to, from, next){
    next(vm => {
      const isFromDetailInstance = from.params.instanceid ? true : false
      if(isFromDetailInstance) vm.$root.previousInstance = vm.$store.getters['INSTANCE/getInstanceDetail']
      else vm.$root.previousInstance = null
    })
  },
  async created() {
    await this.checkEula();
    const currentOrg = JSON.parse(localStorage.getItem('currentOrg'))
    const currentOrgID = currentOrg ? currentOrg.id : null

    await this.fetchdefaultstoragesize();
    await this.fetchVolumeSize({ limit: 999 });
    await this.fetchinstances();
    this.getTypes();
  },
  data() {
    return {
      agree: false,
      agree2: false,
      tnc: false,
      dataTnc: {},
      isDevModeStorageTiering,
      radios: "",
      selectedssh: "",
      storageTypes: [
        // {text: 'Standart', value: 'standart'},
        // {text: 'Premium', value: 'premium'}
      ],
    };
  },
  computed: {
    ...mapGetters({
      projectId: "PROJECT/getprojectid",
      user: "ROLEPERMISSION/getuser"
    })
  },
  methods: {
    async validateEula() {
      var res = await api.POST("/useradmin/validate/eula", {
        form_name: "Form Create Storage",
        project_id: this.projectId
      });
      if (res.code === 200) {
        this.$router.push("/storage");
      }
    },
    async checkEula() {
      var res = await api.GET(
        "/useradmin/check/eula?project_id=" +
          this.projectId +
          "&form_name=Form Create Storage"
      );
      if (res.code === 200) {
        this.agree2 = res.data.is_check;
        console.log("agree : ", this.agree2);
      }
    },
    async getEula() {
      var res = await api.GET("/useradmin/eula?form_name=Form Create Storage");
      console.log("res : ", res);
      if (res.code === 200) {
        this.dataTnc = res.data;
        this.tnc = true;
      }
    },
    is(a, b) {
      return a == b;
    },
    select(storage) {
      this.storageselected = storage;
    },
    async getTypes() {
      try {
        const res = await api.GET('/flavor/storage/type')

        if (res.data.length) {
          const newArr = res.data.map((type) => {
            const {name, id} = type
            const formattedName = {
              Premium: 'Premium Volume SSD with 2250 - 4500 IOPS',
              Standard: 'Standard Volume SSD with 750 - 2500 IOPS',
            }

            return {
              text: formattedName[name],
              value: id,
            }
          })

          this.storageTypes = newArr
        }
      } catch {
        this.$store.dispatch(
          "HOMEPAGE/showErrorToast",
          "Sorry, an error occurred while displaying storage types"
        );
      }
    }
  },
};
</script>

<style lang="css">
#customSize .v-label--active {
  top: 10px !important;
}
</style>

<style scoped lang="scss">
.select-instance,
.select-storage {
  cursor: pointer;

  &.selected {
    border-color: #2c94d2;

    .v-divider {
      border-color: #2c94d2;
    }
  }
}

.v-card.select-storage {
  p {
    margin-bottom: 0px;
    text-align: left;
  }
}

.v-card__title {
  line-height: 1.5rem;
}

.highlight {
  border: 1px solid #2c94d2;
}

.v-input--is-disabled {
  ::v-deep .v-input__slot {
    background: #F1F2F2;
  }
}

</style>