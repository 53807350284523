
























































import { defineComponent } from "@vue/composition-api";
import { ref } from "@vue/composition-api";
import {
  useNamespacedState,
  useNamespacedActions,
} from "vuex-composition-helpers";
import { SUPERADMINMANAGEMAILBOX } from "./namespace";

const module = defineComponent({
  props: {
    value: {
      type: Boolean,
      default: false,
    },
    item: {
      type: Object,
      required: true,
      default: () => {},
    },
  },
  setup(props) {
    const { isLoading } = useNamespacedState(SUPERADMINMANAGEMAILBOX, [
      "isLoading",
    ]);
    return {
      isLoading,
    };
  },
});
export default module;
