var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('p',{staticClass:"font-weight-bold fz-21"},[_vm._v(" Instance Detail ")]),_c('v-data-table',{staticClass:"datatable-services",attrs:{"headers":_vm.tblHeaders,"items":_vm.tblItems,"hide-default-footer":""},scopedSlots:_vm._u([{key:"item.name",fn:function(ref){
var item = ref.item;
return [_c('span',{staticClass:"linkpointer"},[_vm._v(_vm._s(item.name || "N/A"))])]}},{key:"item.price",fn:function(ref){
var item = ref.item;
return [_vm._v(" IDR "+_vm._s(item.price.value.toLocaleString("en"))+" / "+_vm._s(item.price.per)+" ")]}},{key:"item.billed",fn:function(ref){
var item = ref.item;
return [_vm._v(" IDR "+_vm._s(item.billed.toLocaleString("en"))+" ")]}},(!_vm.isPostpaidFixed)?{key:"body.append",fn:function(){return [_c('tr',{staticStyle:{"border":"none"}},[_c('td',{staticClass:"border-bottom-none"}),_c('td',{staticClass:"border-bottom-none"}),_c('td',{staticClass:"border-bottom-none"}),_c('td',{staticClass:"border-bottom-none"}),_c('td',{staticClass:"border-bottom-none"},[_c('div',[_c('p',{staticClass:"font-weight-bold"},[_vm._v("Total")])]),_c('div',{staticClass:"d-flex flex-row justify-start"},[_vm._v(" "+_vm._s(_vm.tblItems.length ? "IDR " + _vm.tblItems .reduce(function (total, item) { return total + item.billed; }, 0) .toLocaleString("en") : "0")+" ")])])])]},proxy:true}:null,(_vm.tblItems.length > 10)?{key:"footer",fn:function(ref){
var props = ref.props;
return [_c('custom-footer-datatable',{attrs:{"props":props}})]}}:null],null,true)})],1)}
var staticRenderFns = []

export { render, staticRenderFns }