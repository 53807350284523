import { ActionContext, Module } from 'vuex'
interface State {
    tickets: Array<any>,
    pagination: any,
    typetickets: Array<any>,
}
import axios from '@/lib/axios'
const store: Module<State, any> = {
    namespaced: true,
    state: {
        tickets: [],
        pagination: {},
        typetickets: [],
    },
    mutations: {
        settickets: (state: State, payload: any) => state.tickets = payload,
        setpagination: (state: State, payload: any) => state.pagination = payload,
        settickettype: (state: State, payload: any) => state.typetickets = payload,
    },
    actions: {
        addticket: async (context: ActionContext<State, any>, payload: any) => {
            return await axios.instance.post('/ticket/create', payload).then(response=>{
                console.log(response)
            })
        },
        fetchticketstype: async (context: ActionContext<State, any>, payload: any) => {
            return await axios.instance.get('/ticket/request-type', ).then(response=>{
                console.log('test' + response.data.data)
                const typeticket = response.data.data
                context.commit('settickettype', typeticket )
             })
        },
        fetchtickets: async (context: ActionContext<State, any>, payload: any = { page: 1, itemsPerPage: 10, search: '' }) => {
            return axios.instance.get('/ticket/all', {
                params: {
                    page: payload.page,
                    limit: payload.itemsPerPage,
                    search: payload.search,
                    type_id: payload.type_id
                }
            }).then(response => {
                const { ticket, pagination } = response.data.data
                console.log(ticket)
                console.log(pagination)
                context.commit('settickets', ticket)
                context.commit('setpagination', pagination)
            })
        }
    }
}

export default store