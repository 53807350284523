






































































































































































import { defineComponent, ref, Ref, watch } from "@vue/composition-api";
import { useMailbox } from "./useMailbox";
import dialogResendEmail from "./dialogResendEmail.vue";

export default defineComponent({
  components: { dialogResendEmail },
  setup(props, { emit }) {
    const listmailboxselectedvalue: Ref<any> = ref("");
    const Composable = { ...useMailbox() };
    const { listmailbox, listmailboxselected } = Composable;
    const headers = ref([
      {
        text: "Subject",
        value: "subject",
        sortable: false,
      },
      {
        text: "Date",
        value: "created_at",
      },
      {
        text: "Status",
        value: "status",
      },
      {
        text: "Trigger",
        value: "trigger",
        sortable: false,
      },
      {
        text: "Recipient",
        value: "email",
        sortable: false,
      },
      {
        text: "Organization",
        value: "organization_name",
        sortable: false,
      },
      {
        text: "Action",
        value: "action",
        sortable: false,
      },
    ]);

    return {
      vcalendar: false,
      headers,
      ...Composable,
      listmailbox,
      listmailboxselected,
      listmailboxselectedvalue,
      show: (items: any) => {
        console.log(items);
      },
    };
  },
  created() {
    this.fetchlistmailbox();
  },
});
