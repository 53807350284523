<template>
  <v-container
    v-if="item"
    class="container"
    style="font-family: 'Fira Sans'; padding: 2rem;"
  >
    <v-row>
      <v-col
        cols="12"
        style="display:flex; align-items: center; justify-content: space-between;"
      >
        <img
          style="width: 200px; margin-top: 1.5rem;"
          id="logo"
          v-bind:src="require('@/assets/image_40.png')"
        />

        <p
          class="primary--text"
          style="font-weight: bold; margin-bottom: 0; font-size: 42px; letter-spacing: 2px;"
        >
          INVOICE
        </p>
      </v-col>
    </v-row>

    <p class="primary--text text-spacing">
      <b>PT. Aplikasinusa Lintasarta</b>
    </p>

    <p class="text-spacing"><b>NPWP / NPPKP</b> : {{ org.npwp_corporate }}</p>

    <p class="text-spacing">{{ org.address }}</p>

    <p class="text-spacing"><b>Report ID</b> : {{ item.report_id }}</p>

    <p class="text-spacing"><b>Status</b> : {{ item.status }}</p>

    <p class="text-spacing">
      <b>Billing Account</b> : {{ item.billing_account }}
    </p>

    <div
      :style="{
        marginTop: '8px',
        padding: '10px',
        backgroundColor: '#1f60a8',
        color: 'white',
      }"
    >
      <b>Services</b>
    </div>

    <v-row :style="{ borderBottom: '1px solid black' }">
      <v-col>
        <b>Service Name</b>
      </v-col>
      <v-col>
        <b>Service Type</b>
      </v-col>
      <v-col>
        <b>Usage</b>
      </v-col>
      <v-col>
        <b>Price</b>
      </v-col>
      <v-col>
        <b>Total</b>
      </v-col>
    </v-row>

    <v-row
      v-for="service in item.services"
      :key="'service-' + service.id"
      class="row-bb"
    >
      <v-col>
        <b>{{ service.service_name }}</b>
      </v-col>
      <v-col>
        <b>{{ service.service_type }}</b>
      </v-col>
      <v-col>
        <b>{{ service.qty }}</b>
      </v-col>
      <v-col>
        <b> IDR {{ service.unit.toLocaleString("en") }}/h </b>
      </v-col>
      <v-col>
        <b> IDR {{ service.billed.toLocaleString("en") }} </b>
      </v-col>
    </v-row>

    <!-- totals -->
    <v-row>
      <v-col>
        <!-- empty (for spacing) -->
      </v-col>

      <v-col>
        <v-row :style="{ backgroundColor: '#ECEFF1' }">
          <v-col>
            <b>Subtotal</b>
          </v-col>
          <v-col>
            <b> IDR {{ item.subtotal.toLocaleString("en") }} </b>
          </v-col>
        </v-row>

        <v-row
          class="mt-4"
          :style="{ backgroundColor: '#ECEFF1', marginTop: '8px' }"
        >
          <v-col>
            <b>
              Credit
            </b>
          </v-col>
          <v-col>
            <b :style="{ color: 'red' }">
              IDR {{ item.credit.toLocaleString("en") }}
            </b>
          </v-col>
        </v-row>

        <v-row
          class="mt-4"
          :style="{ backgroundColor: '#1f60a8', marginTop: '8px' }"
        >
          <v-col>
            <b :style="{ color: 'white' }">Total</b>
          </v-col>
          <v-col>
            <b :style="{ color: 'white' }">
              IDR {{ item.total.toLocaleString("en") }}
            </b>
          </v-col>
        </v-row>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import localstorage from "@/lib/localstorage";

export default {
  props: ["item"],
  computed: {
    org() {
      return JSON.parse(localstorage.getItem("currentOrg"));
    },
  },
};
</script>

<style scoped>
.text-spacing {
  padding: 2px 0;
}

.text-spacing {
  display: flex;
}

.text-spacing b {
  width: 200px;
}

.row-bb {
  border-bottom: 1px solid #e0e0e0;
  color: #333333;
}

.mt-4 {
  margin-top: 8px;
}

* {
  font-size: 12px;
}
</style>
