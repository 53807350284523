var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticStyle:{"margin-bottom":"3rem"}},[_c('v-data-table',{staticClass:"elevation-0",attrs:{"id":"DataTableListStorage","hide-default-footer":"","headers":_vm.headers,"items":_vm.storages,"items-per-page":10},on:{"pagination":_vm.pagination},scopedSlots:_vm._u([{key:"no-data",fn:function(){return [(_vm.loading)?_c('v-progress-circular',{attrs:{"indeterminate":"","color":"primary"}}):_c('div',{staticClass:"my-7 text-center"},[_c('p',{staticClass:"font-weight-bold",staticStyle:{"font-size":"16px","margin-bottom":"10px","text-transform":"capitalize","text-align":"center"}},[_vm._v(" Looks like you don’t have any Storages ")]),_c('v-row',{staticClass:"d-flex justify-center"},[_c('v-col',{staticClass:"text-center",staticStyle:{"color":"#a5b3bf"},attrs:{"cols":"12","lg":"6","md":"12"}},[_c('p',{staticStyle:{"font-size":"12px","color":"#a5b3bf","margin-bottom":"20px","font-weight":"normal"}},[_vm._v(" Store and quickly access any amount of data reliably in the cloud and offers several kinds of storage depending on your needs. ")])])],1),_c('v-btn',{staticClass:"secondary",attrs:{"to":"/storage/create","width":"100%","max-width":"300","height":"50","depressed":"","exact":""}},[_vm._v(" Create Storage ")])],1)]},proxy:true},{key:"item.name",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"d-flex"},[_c('div',{staticClass:"dotblue"}),_c('span',[_vm._v(_vm._s(item.name))])])]}},{key:"item.billing_type",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.getBillingTypeLabel(item.billing_type))+" ")]}},{key:"item.instance",fn:function(ref){
var item = ref.item;
return [_c('div',[(item.instance)?_c('div',{staticClass:"d-flex haveinstance"},[_c('div',{staticClass:"dotblue mt-2"}),_c('div',[_c('span',{staticClass:"primary--text"},[_vm._v(_vm._s(item.instance.instanceName))])])]):_c('div',{staticClass:"linkpointer nothaveinstance",on:{"click":function () {
              _vm.selectedstorage = item;
              _vm.dialogAttachtoInstance = true;
            }}},[_vm._v(" Attach Instance ")])])]}},{key:"item.ipaddress",fn:function(ref){
            var item = ref.item;
return [_c('p',{staticClass:"ip"},[_c('span',{staticClass:"ipaddress"},[_vm._v("Int "),_c('span',{staticClass:"tik"},[_vm._v(":")])]),_c('span',[_vm._v(_vm._s(item.instance.ipv4))])]),_c('p',{staticClass:"ip"},[_c('span',{staticClass:"ipaddress"},[_vm._v("Ext "),_c('span',{staticClass:"tik"},[_vm._v(":")])]),_vm._v(_vm._s(item.instance.ext)+" ")])]}},{key:"item.region",fn:function(ref){
            var item = ref.item;
return [_vm._v(" "+_vm._s(item.region.regionid.toUpperCase())+" ")]}},{key:"item.createddate",fn:function(ref){
            var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.$moment(new Date(item.createdate)).format("DD/MM/YYYY"))+" ")]}},{key:"item.action",fn:function(ref){
            var item = ref.item;
return [_c('popupquote',{attrs:{"offsetTop":20,"offset-y":"","allow-overflow":"","documentid":'popup' + item.id},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
            var on = ref.on;
            var attrs = ref.attrs;
return [_c('label',_vm._b({staticClass:"primary--text linkpointer",staticStyle:{"position":"relative"},on:{"click":function ($event) {
                on.click($event);
                _vm.show($event, item);
              }}},'label',attrs,false),[_vm._v("More "),_c('v-icon',{class:{
                rotate: item.id == _vm.selectedstorage.id && _vm.showMenu,
              },attrs:{"id":'popup' + item.id,"color":"primary"}},[_vm._v("mdi-chevron-down ")])],1)]}}],null,true),model:{value:(_vm.showMenu),callback:function ($$v) {_vm.showMenu=$$v},expression:"showMenu"}},[_c('v-list',[(!_vm.selectedstorage.instance)?_c('v-list-item',{on:{"click":function () {
                _vm.dialogAttachtoInstance = true;
              }}},[_c('v-list-item-title',[_vm._v("Attach to Instance")])],1):_vm._e(),(_vm.selectedstorage.instance)?_c('v-list-item',{on:{"click":function () {
                _vm.dialogDetachStorage = true;
              }}},[_c('v-list-item-title',[_c('label',[_vm._v("Detach from Instance")])])],1):_vm._e(),_c('v-list-item',{on:{"click":function () {
                _vm.dialogIncreaseVol = true;
              }}},[_c('v-list-item-title',[_c('label',[_vm._v("Increase Size")])])],1),_c('v-list-item',{staticStyle:{"border-bottom":"1px lightgrey solid"},attrs:{"to":"/image/snapshot#snapshotStorage"}},[_c('v-list-item-title',[_c('label',[_vm._v("Take Snapshot")])])],1),_c('v-list-item',{on:{"click":function () {
                _vm.dialogDeleteVol = true;
              }}},[_c('v-list-item-title',[_c('label',{staticClass:"error--text"},[_vm._v("Delete")])])],1)],1)],1)]}},{key:"footer",fn:function(ref){
              var props = ref.props;
return [_c('custom-footer-datatable',{style:({ visibility: _vm.storages.length > 5 ? 'visible' : 'hidden' }),attrs:{"props":props}})]}}])}),_c('v-dialog',{attrs:{"max-width":"500"},model:{value:(_vm.dialogDetachStorage),callback:function ($$v) {_vm.dialogDetachStorage=$$v},expression:"dialogDetachStorage"}},[_c('v-card',[_c('v-card-title',[_c('div',{staticClass:"headline font-weight-bold font--text"},[_vm._v(" Detach "+_vm._s(_vm.selectedstorage.name)+" from Instance? ")])]),_c('v-card-text',[_c('b',[_vm._v("Warning:")]),_vm._v(" Detaching a volume from a running Instance may cause data loss. Power off the Instance first, or make sure it isn’t currently writing to the volume before proceeding. You’ll be able to access your detached volume and data in the “Volumes\" tab, and you will still be charged for it. ")]),_c('v-card-text',[_c('p',{staticClass:"font-weight-bold"},[_vm._v("Are you sure you want to proceed?")])]),_c('v-card-actions',[_c('v-row',{staticClass:"ma-2"},[_c('v-col',{attrs:{"cols":"6"}},[_c('v-btn',{attrs:{"depressed":"","height":"50","color":"accent","block":""},on:{"click":function () {
                  _vm.dialogDetachStorage = false;
                }}},[_c('span',{staticStyle:{"font-size":"14px","font-weight":"700"}},[_vm._v("Cancel")])])],1),_c('v-col',{attrs:{"cols":"6"}},[_c('v-btn',{attrs:{"depressed":"","height":"50","color":"error","block":""},on:{"click":function () {
                  _vm.detachfrominstance();
                  _vm.dialogDetachStorage = false;
                }}},[_c('span',{staticStyle:{"font-size":"14px","font-weight":"700"}},[_vm._v("Confirm")])])],1)],1)],1)],1)],1),(_vm.dialogAttachtoInstance)?_c('dialogAttachInstance',{on:{"close":function () {
        _vm.dialogAttachtoInstance = false;
      },"confirm":_vm.AttachStorageToInstance},model:{value:(_vm.dialogAttachtoInstance),callback:function ($$v) {_vm.dialogAttachtoInstance=$$v},expression:"dialogAttachtoInstance"}}):_vm._e(),_c('dialogCreateSnapshot',{on:{"close":function () {
        _vm.dialogSnapshot = false;
      }},model:{value:(_vm.dialogSnapshot),callback:function ($$v) {_vm.dialogSnapshot=$$v},expression:"dialogSnapshot"}}),_c('dialogIncreaseVolume',{attrs:{"storage":_vm.selectedstorage},on:{"close":function () {
        _vm.dialogIncreaseVol = false;
      }},model:{value:(_vm.dialogIncreaseVol),callback:function ($$v) {_vm.dialogIncreaseVol=$$v},expression:"dialogIncreaseVol"}}),_c('dialogDeleteStorage',{attrs:{"storage":_vm.selectedstorage},on:{"close":function () {
        _vm.dialogDeleteVol = false;
      },"confirm":function () {
        _vm.deleteStorage(_vm.selectedstorage.id);
      }},model:{value:(_vm.dialogDeleteVol),callback:function ($$v) {_vm.dialogDeleteVol=$$v},expression:"dialogDeleteVol"}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }