<script>
import { Line } from "vue-chartjs";
import { useNamespacedState, useNamespacedGetters } from "vuex-composition-helpers";
import { computed, watch, ref } from "@vue/composition-api";
import moment from "moment";
import INSTANCE from "../namespace";

export default {
  extends: Line,
  props: [
    'startDate',
    'endDate',
    'type',
  ],
  data() {
    return {
      gradient: null,
    }
  },
  setup: (props, context) => {
    const {
      instancemetrics,
      cpumetrics,
      rammetrics,
      inputoutput,
      inputin,
      diskread,
      diskwrite,
    } = useNamespacedState("INSTANCEDETAIL", [
      props.type,
    ]);
    const { getInstanceDetail } = useNamespacedGetters(INSTANCE, [
      "getInstanceDetail",
    ]);
    const { root_disk_package } = getInstanceDetail.value;
    const { name } = root_disk_package.type;
    const dataset = {
      label: "",
      pointBackgroundColor: "#fffff",
      pointStrokeColor : "#fff",
      pointBorderWidth: 10,
      pointHoverRadius: 5,
      pointHoverBorderWidth: 10,
      pointDotStrokeWidth : 10,
      pointRadius: 0,
      borderWidth: 4,
      data: [],
      backgroundColor: 'transparent',
    }
    const formatRange = () => {
      switch (props.type) {
        case 'inputin':
        case 'inputoutput':
          return 'Mbps'
        case 'diskread':
        case 'diskwrite':
          return 'IOPS'
        default:
          return '%'
      }
    }
    const metricColor = () => {
      switch (props.type) {
        default:
        case 'cpumetrics':
          return '#F2994A'
        case 'rammetrics':
          return '#2C94D2'
        case 'inputin':
        case 'inputoutput':
          return '#7459D9'
        case 'diskread':
        case 'diskwrite':
          return '#27AE60'
      }
    }
    const typeDisk = ref([])

    const chartdata = {
      labels: [],
      datasets: [
        {
          ...dataset,
          borderColor: metricColor(),
          fill: true,
        },
        {
          borderColor: '#FFFF00', // YELLOW
          fill: false,
          ...dataset,
        },
        {
          borderColor: '#0000FF', // BLUE
          fill: false,
          ...dataset,
        },
        {
          borderColor: '#008000', // GREEN
          fill: false,
          ...dataset,
        },
        {
          borderColor: '#FF0000', // RED
          fill: false,
          ...dataset,
        },
        {
          borderColor: '#800080', // PURPLE
          fill: false,
          ...dataset,
        },
        {
          borderColor: '#FFFFE0', // LIGHT YELLOW
          fill: false,
          ...dataset,
        },
        {
          borderColor: '#ADD8E6', // LIGHT BLUE
          fill: false,
          ...dataset,
        },
        {
          borderColor: '#8B0000', // DARK RED
          fill: false,
          ...dataset,
        },
        {
          borderColor: '#9370DB', // MEDIUM PURPLE
          fill: false,
          ...dataset,
        },
        {
          borderColor: '#FFA500', // ORANGE
          fill: false,
          ...dataset,
        },
        {
          borderColor: '#DDA0DD', // PLUM
          fill: false,
          ...dataset,
        },
        {
          borderColor: '#FA8072', // SALMON
          fill: false,
          ...dataset,
        },
      ],
    };


    return {
      cpumetrics,
      instancemetrics,
      rammetrics,
      inputoutput,
      inputin,
      diskread,
      diskwrite,
      dataset,
      formatRange,
      metricColor,
      typeDisk,
      chartdata: chartdata,
      options: {
        showLines: true,
        legend: {
          display: false,
        },
        maintainAspectRatio: true,
        aspectRatio: 2.5,
        responsive: true,
        layout: {
          padding: {
            top: 50
          }
        },
      
        scales: {
          xAxes: [
            {
              gridLines: {
                display: false,
                zeroLineWidth: 0,
              },
              display: true,
              scaleLabel: {
                display: true,
              },
              ticks: {
                autoSkip: true,
                maxTicksLimit: 8,
                callback: (val) => {
                  return moment(new Date(val)).format("DD HH:mm");
                },
              },
            },
          ],
          yAxes: [
            {
              gridLines: {
                display: true,
                zeroLineWidth: 1,
              },
              ticks: {
                min: 0,
                max: 100,
                stepSize: 20,
                callback:(val) => {
                 return `${parseFloat(val).toFixed(0)} ${formatRange()}`
                },
              },
              display: true,
              scaleLabel: {
                display: true,
              },
            },
          ],
        },
        hover: {
          mode: "index",
          intersect: false,
        },
        tooltips: {
          displayColors: false,
          mode: "index",
          intersect: false,
          yAlign: "bottom",
          xAlign: "center",
          titleAlign: "left",
          caretSize: 0,
          custom: function (tooltipModel) {
            tooltipModel.titleMarginBottom = 0;
          },
        },

      },
    };
  },
  mounted() {
    this.gradient = this.$refs.canvas.getContext('2d').createLinearGradient(0, 0, 0, 500)
    this.gradient.addColorStop(1, 'rgba(255, 255, 255, 0)');
    this.gradient.addColorStop(0, this.metricColor() + '80');
    // MAX RANGE
    this.options.scales.yAxes[0].ticks.max = this.type == 'diskread' || this.type == 'diskwrite'
        ? 4500
        : 100
    // STEP SIZE
    this.options.scales.yAxes[0].ticks.stepSize = this.type == 'diskread' || this.type == 'diskwrite'
        ? 4500 / 4
        : 100 / 4
    // GRADIENT
    this.chartdata.datasets[0].backgroundColor = this.gradient
    // TOOLTIP
    this.options.tooltips.callbacks = {
        title: (val) => {
          return (
            val.map((d, i) => {
              return `${this.type == 'diskread' || this.type == 'diskwrite' ? this.typeDisk[i].disk + ' ' : ''}${parseFloat(d.yLabel).toFixed(2)} ${this.formatRange()}`
            })
          )
        },
        label: (val) => {
          return val.datasetIndex == 0 && moment(new Date(val.xLabel)).format("DD/MM/YY HH:mm");
        },
      }

    this.renderChart(this.chartdata, this.options);
  },
  watch: {
    cpumetrics: function (metrics) {
      const dates = metrics[0].values.map(data => data.date_time)
      const datas = metrics[0].values.map(data => data.value)
      const start = dates.indexOf(moment(this.startDate).format())
      const end = dates.indexOf(moment(this.endDate).format())
      const label = dates.slice(start, dates.length - 1)
      const value = datas.slice(start, datas.length - 1)
      if (moment(metrics[metrics.length - 1].date_time).format('DD-MM HH') == moment(this.startDate).format('DD-MM HH')) {
        this.chartdata.labels = dates
        this.chartdata.datasets[0].data = datas
        this.$data._chart.update()
      } else if(label.length !== 0){
        this.chartdata.labels = label
        this.chartdata.datasets[0].data = value
        this.$data._chart.update()
      }
    },
    rammetrics: function (metrics) {
      const dates = metrics[0].values.map(data => data.date_time)
      const datas = metrics[0].values.map(data => data.value)
      const start = dates.indexOf(moment(this.startDate).format())
      const end = dates.indexOf(moment(this.endDate).format())
      const label = dates.slice(start, dates.length - 1)
      const value = datas.slice(start, datas.length - 1)
      if (moment(metrics[metrics.length - 1].date_time).format('DD-MM HH') == moment(this.startDate).format('DD-MM HH')) {
        this.chartdata.labels = dates
        this.chartdata.datasets[0].data = datas
        this.$data._chart.update()
      } else if(label.length !== 0){
        this.chartdata.labels = label
        this.chartdata.datasets[0].data = value
        this.$data._chart.update()
      }
    },
    inputin: function (metrics) {
      const dates = metrics[0].values.map(data => data.date_time)
      const datas = metrics[0].values.map(data => data.value / 1000000)
      const start = dates.indexOf(moment(this.startDate).format())
      const end = dates.indexOf(moment(this.endDate).format())
      const label = dates.slice(start, dates.length - 1)
      const value = datas.slice(start, datas.length - 1)
      if (moment(metrics[metrics.length - 1].date_time).format('DD-MM HH') == moment(this.startDate).format('DD-MM HH')) {
        this.chartdata.labels = dates
        this.chartdata.datasets[0].data = datas
        this.$data._chart.update()
      } else if(label.length !== 0){
        this.chartdata.labels = label
        this.chartdata.datasets[0].data = value
        this.$data._chart.update()
      }
    },
    inputoutput: function (metrics) {
      const dates = metrics[0].values.map(data => data.date_time)
      const datas = metrics[0].values.map(data => data.value / 1000000)
      const start = dates.indexOf(moment(this.startDate).format())
      const end = dates.indexOf(moment(this.endDate).format())
      const label = dates.slice(start, dates.length - 1)
      const value = datas.slice(start, datas.length - 1)
      if (moment(metrics[metrics.length - 1].date_time).format('DD-MM HH') == moment(this.startDate).format('DD-MM HH')) {
        this.chartdata.labels = dates
        this.chartdata.datasets[0].data = datas
        this.$data._chart.update()
      } else if(label.length !== 0){
        this.chartdata.labels = label
        this.chartdata.datasets[0].data = value
        this.$data._chart.update()
      }
    },
    diskread: function (metrics) {
      const metricValues = metrics.map(data => data.values)
      const metricLabels = metrics.map(data => data.labels)
      this.typeDisk = metricLabels
      const dates = metrics[0].values.map(data => data.date_time)
      const datas = metricValues.map(data => {
        return data.map(d => {
          return d.value
        })
      })
      const start = dates.indexOf(moment(this.startDate).format())
      const end = dates.indexOf(moment(this.endDate).format())
      // FILTER BY DATE
      const label = dates.slice(start, dates.length - 1)
      const value = datas.map(d => {
        return d.slice(start, d.length - 1)
      })
      if (moment(metrics[metrics.length - 1].date_time).format('DD-MM HH') == moment(this.startDate).format('DD-MM HH')) {
        this.chartdata.labels = dates
        const dataset = this.chartdata.datasets.slice(0, datas.length)
        dataset.map((data, i) => {
          return data.data = datas[i]
        })
        this.$data._chart.update()
      } else if(label.length !== 0){
        this.chartdata.labels = label
        const dataset = this.chartdata.datasets.slice(0, value.length)
        dataset.map((data, i) => {
          return data.data = value[i]
        })
        this.$data._chart.update()
      }
    },
    diskwrite: function (metrics) {
      const metricValues = metrics.map(data => data.values)
      const metricLabels = metrics.map(data => data.labels)
      this.typeDisk = metricLabels
      const dates = metrics[0].values.map(data => data.date_time)
      const datas = metricValues.map(data => {
        return data.map(d => {
          return d.value
        })
      })
      const start = dates.indexOf(moment(this.startDate).format())
      const end = dates.indexOf(moment(this.endDate).format())
      // FILTER BY DATE
      const label = dates.slice(start, dates.length - 1)
      const value = datas.map(d => {
        return d.slice(start, d.length - 1)
      })
      if (moment(metrics[metrics.length - 1].date_time).format('DD-MM HH') == moment(this.startDate).format('DD-MM HH')) {
        this.chartdata.labels = dates
        const dataset = this.chartdata.datasets.slice(0, datas.length)
        dataset.map((data, i) => {
          return data.data = datas[i]
        })
        this.$data._chart.update()
      } else if(label.length !== 0){
        this.chartdata.labels = label
        const dataset = this.chartdata.datasets.slice(0, value.length)
        dataset.map((data, i) => {
          return data.data = value[i]
        })
        this.$data._chart.update()
      }
    },
  },
};
</script>