<template>
	<div>
		<v-row>
			<v-col cols="12">
				<v-card class="rounded-lg" flat>
					<v-container fluid class="ps-7">
						<v-row>
							<v-col cols="12">
								<div class="font-weight-bold fz-21" style="margin-bottom:6px">{{ security.name }}</div>
								<div class="status">{{ security && security.security_rules ? security.security_rules.length : 0 }} Rules / {{ security && security.instance_ids ? security.instance_ids.length : 0 }} Instance</div>
							</v-col>
						</v-row>
					</v-container>
				</v-card>
			</v-col>
		</v-row>

		<v-row>
			<v-col cols="12">
				<v-card class="rounded-lg" flat>
					<v-tabs v-model="vtabs" height="64" slider-size="4">
						<v-tab class="px-8">Rules</v-tab>
						<v-tab class="px-8">Instance</v-tab>
						<v-tab class="px-8">Destroy</v-tab>
						</v-tabs>
						<v-divider/>

						<v-tabs-items v-model="vtabs">
						<v-tab-item>
							<rules :security="security" />
						</v-tab-item>
						<v-tab-item>
							<v-container class="pa-7">
								<v-data-table
									:headers="instance.headers"
									:items="instances"
									:items-per-page="10"
									class="elevation-0"
									hide-default-footer
									:no-data-text="`There is no instance attached to this firewall`"
								>
									<template v-slot:item.name="{ item }">
										<div class="d-flex instance-name">
											<div class="dotblue mt-2"></div>
											<div>
												<router-link :to="{ path: `/instance/${item.id}/` }"
													><span class="primary--text">{{
														item.name
													}}</span></router-link
												>
												<p>
													{{ item.package_instance.vcpu }}vCPU / {{ item.package_instance.ram }} RAM / {{ item.package_instance.root_disk }} Root Disk
												</p>
											</div>
										</div>
									</template>
									<template v-slot:item.ip="{ item }">
										<table class="table-ip">
                      <tr v-if="item.ip_address_internal">
                        <td style="padding-top: 0 !important;padding-bottom: 0 !important">Internal</td>
                        <td style="padding-top: 0 !important;padding-bottom: 0 !important"><span class="mx-2">:</span> {{ item.ip_address_internal }}</td>
                      </tr>
                      <tr v-if="item.vpc">
                        <td style="padding-top: 0 !important;padding-bottom: 0 !important">Gateway</td>
                        <td style="padding-top: 0 !important;padding-bottom: 0 !important"><span class="mx-2">:</span> {{ item.vpc.ip_gateway }}</td>
                      </tr>
                      <tr v-if="item.floating_ip">
                        <td style="padding-top: 0 !important;padding-bottom: 0 !important">External</td>
                        <td style="padding-top: 0 !important;padding-bottom: 0 !important"><span class="mx-2">:</span> {{ item.floating_ip.ip_address }}</td>
                      </tr>
                    </table>
									</template>
									<template v-slot:item.createddate="{ item }">
										{{ $moment(new Date(item.created_at)).format('DD/MM/YYYY') }}
									</template>
									<template v-slot:item.region="{ item }">
										{{ item.region }}
									</template>
								</v-data-table>
							</v-container>
						</v-tab-item>
						<v-tab-item>
							<v-container class="pa-7">
								<v-row>
									<v-col cols="8">
										<div class="title">Destroy</div>
										<div class="content pt-4">
											This is irreversible. Your Firewall will be destroyed, and
											any Instances will be dissociated from them.
										</div>
									</v-col>
									<v-col cols="4">
										<div class="pt-4 text-right">
											<v-btn
												@click="
												() => {
												validateprivilages([
													'Security',
													'editor',
												]).then(() => {
													deleteModal
												});
												}
												"
												outlined
												color="error"
												width="150"
												height="45"
											>
												Destroy
											</v-btn>
										</div>
									</v-col>
								</v-row>
							</v-container>
						</v-tab-item>
						</v-tabs-items>
				</v-card>
			</v-col>
		</v-row>

		<v-dialog width="713" v-model="isDelete">
			<v-card class="rounded-lg">
				<v-container class="pa-4">
					<div class="modal-title">Destroy Firewall</div>
					<div class="modal-content py-5">
						<p>
							This is irreversible. Your Firewall will be destroyed, and any
							Instance will be dissociated from them.
						</p>
						<p>
							Once this happens, the Instance will allow any type of inbound and
							outbound traffic, unless you've configured a software firewall in
							them.
						</p>
						<p>Do you want to permanently destroy this?</p>
					</div>
					<v-row>
						<v-col cols="6"
							><v-btn
								:disabled="isLoading"
								class="accent"
								depressed
								@click="() => { isDelete = false }"
								block
								>Cancel</v-btn
							></v-col
						>
						<v-col cols="6">
							<v-btn color="error" :disabled="isLoading" depressed 
							@click="
							() => {
							validateprivilages([
								'Security',
								'editor',
							]).then(() => {
								confirmDelete()
							});
							}
							"
							block>
								<beat-loader v-if="isLoading" :disabled="isLoading" :loading="isLoading" :color="'white'" :size="'10px'" class="mr-2"></beat-loader>
								<span v-else>Confirm</span>
							</v-btn>
						</v-col>
					</v-row>
				</v-container>
			</v-card>
		</v-dialog>
	</div>
</template>

<script>
import rules from "./rules";
import { ref, reactive, onMounted, watch } from "@vue/composition-api";
import {
	useNamespacedState,
	useNamespacedActions,
} from "vuex-composition-helpers";

export default {
	components: { rules },
	setup(props, context) {
		const { id } = context.root._route.params

		const { 
			security,
			isLoading, 
		} = useNamespacedState('SECURITY', [
			'security',
			'isLoading',
		])

		const instances = ref([])
		watch(security, item => {
			instances.value = item.instances || []
		})

		const { FETCH_SECURITY_DETAIL, DELETE_SECURITY } = useNamespacedActions('SECURITY', [
			'FETCH_SECURITY_DETAIL',
			'DELETE_SECURITY',
		])

		onMounted(async () => {
			await FETCH_SECURITY_DETAIL({ id })
		})

		const instance = reactive({
			headers: [
				{ text: "Name", value: "name" },
				{ text: "IP Address", value: "ip" },
				{ text: "Region", value: "region" },
				{ text: "Created Date", value: "createddate" },
			],
		});
		const isDelete = ref(false);

		function deleteModal() {
			isDelete.value = true;
		}

		async function confirmDelete() {
			const response = await DELETE_SECURITY({ id })
			if(response.status === 200) context.root.$router.replace('/security')
		}


		const {validateprivilages, validateprivilagesync,validateprivilagesyncnew} = useNamespacedActions("HOMEPAGE", [
		"validateprivilages", "validateprivilagesync", "validateprivilagesyncnew"
		]);
		
		const vtabs = ref()
		return {
			validateprivilages, validateprivilagesync,validateprivilagesyncnew,
			instances,
			isLoading,
			security,
			vtabs,
			deleteModal,
			isDelete,
			confirmDelete,
			instance,
		};
	},
};
</script>

<style scoped>
.title {
	font-style: normal;
	font-weight: bold;
	font-size: 21px;
	line-height: 140%;
	color: #556272;
}
.status {
	font-style: normal;
	font-weight: normal;
	font-size: 12px;
	line-height: 140%;
	color: #a5b3bf;
}

.content {
	font-style: normal;
	font-weight: normal;
	font-size: 14px;
	line-height: 140%;
}

.modal-title {
	font-style: normal;
	font-weight: bold;
	font-size: 21px;
	line-height: 140%;
	color: #556272;
}

.modal-content {
	font-style: normal;
	font-weight: normal;
	font-size: 14px;
	line-height: 140%;

	color: #556272;
}
</style>