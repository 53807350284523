



































































import Vue from "vue";
import { useDialog } from "@/composable/usedialog";
import {
  useNamespacedActions,
  useNamespacedState,
} from "vuex-composition-helpers";
import INSTANCE from "@/modules/instance/namespace";
import { onMounted, ref, watch } from "@vue/composition-api";
const module = Vue.extend({
  props: ['value', 'loadBalancer', 'instances'],
  setup(props: any, context) {
    const { dialog } = useDialog(props, context);

    const instanceselected = ref(null);
    const selectInstances = ref([])
    watch([() => props.loadBalancer, () => props.instances], val => {
      selectInstances.value = props.instances.filter((x:any) => {
        return !props.loadBalancer.instances.includes(x.id)
      })
    })

    return {
      selectInstances,
      dialog,
      instanceselected,
    };
  },
});
export default module;
