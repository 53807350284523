import {INSTANCE} from '../namespace'
import {
    useNamespacedActions,
    useNamespacedState,
} from "vuex-composition-helpers";
import {ref, computed, Ref, ComputedRef} from '@vue/composition-api'

type Select = {
    value: any,
    text: string
}
type instanceSelectT = ComputedRef<Array<Select>>
export const useCreatedInstance = () => {
    const instanceselected: Ref<string> | Ref<any> = ref('');
    const instancesselected = ref([])
    const {fetchinstances} = useNamespacedActions(INSTANCE, ['fetchinstances'])
    const { instances } : { instances: Ref<Array<any>> } = useNamespacedState(INSTANCE, ['instances'])

    function isSelected(id: string) {
        return id == instanceselected.value.id;
    }

    let instanceSelect: instanceSelectT = computed(() => {
        return instances.value.map((x) => {
            return {value: x, text: x.instanceName};
        });
    });
    const instanceSearch = ref('')
    const instanceAutoComplete: instanceSelectT = computed(() => {
        if (instanceSearch.value == '') return []
        else return instanceSelect.value.slice().filter((s) => s.text.includes(instanceSearch.value))

    })
    return {
        instanceselected, instancesselected,
        fetchinstances,
        instances,
        isSelected,
        instanceSelect,
        instanceSearch,
        instanceAutoComplete
    }
}