<template>
  <v-dialog v-model="value" persistent max-width="800">
    <v-card>
      <v-card-title>
        <div class="headline font-weight-bold font--text">Increase Root Disk Size</div>
        </v-card-title>
      <v-card-text>
        <p class="font-weight-bold">
          Select size for your root disk. Must be greater than {{ currentRootDisk }} GB. This change is
          not reversible.
        </p>
        <p>Note: Selected instance will be automatically turned off before the root disk size increased.</p>
      </v-card-text>
      <v-card-text>
      <v-row>
          <v-col class="pb-0" cols="12">
            <p>
              <span class="font-weight-bold mb-0">Select Volume Size</span>
              <span
                  class="ml-2 error--text"
                  style="font-size: 10px;"
                  v-if="$v.volumeSelected.$errors.length || $v.customSize.$errors.length"
              >
                *{{ $v.volumeSelected.$errors[0] ? $v.volumeSelected.$errors[0].$message : $v.customSize.$errors[0].$message }}</span
              >
            </p>
          </v-col>
          <v-col class="d-flex pt-0 flex-wrap">
            <v-card
                width="170"
                border-color="primary"
                outlined
                class="custom-flavor-card rounded-lg select-instance mb-4"
                :class="{
                  'highlight-error': $v.customSize.$errors.length,
                  'hightlighted selected': $v.customSize.$errors.length < 1 && isCustomSizeHighlighted,
                }"
                style="text-align: center; margin-right: 10px"
                
            >
              <v-card-text class="pa-0">
                <v-text-field
                    v-model="customSize"
                    class="px-4 pt-2"
                    @keydown="(ev) => customSize = addGBText(ev, customSize)"
                    style="width: 170px"
                    max="100"
                    flat
                    label="Enter Size in GB"
                    @click="() => {
                      volumeSelected = null
                      isCustomSizeHighlighted = true
                    }"
                    @blur="() => isCustomSizeHighlighted = !customSize ? false : true"
                ></v-text-field>
              </v-card-text>
            </v-card>
            <div
              v-for="storage in filteredVolumeSize"
              :key="storage.id"
            >
              <v-card
                  v-if="storage.volume_size"
                  width="170"
                  :class="{ 
                    highlight: volumeSelected ? is(volumeSelected.id, storage.id) : false,
                    'highlight-error': $v.volumeSelected.$errors.length
                  }"
                  @click="
                  () => {
                    volumeSelected = storage;
                    customSize = null
                    isCustomSizeHighlighted = false
                  }
                "
                  border-color="primary"
                  outlined
                  class="rounded-lg select-instance mb-4"
                  style="text-align: center; margin-right: 10px"
              >
                <!-- <v-card-text class="pb-0 pt-5"> -->
                  <!-- <p class="font-weight-bold" style="margin-bottom: 2.5rem">
                    {{ IDRformat.format(storage.price_per_month) }}/mo
                  </p> -->
                  <!-- <p>{{ IDRformat.format(storage.price_per_hours) }}/hour</p> -->
                <!-- </v-card-text> -->
                <!-- <v-divider/> -->
                <v-card-text class="flavor-card">
                  <p class="font-weight-bold mb-0">{{ storage.volume_size }} GB</p>
                </v-card-text>
              </v-card>
            </div>
          </v-col>
        </v-row>
      </v-card-text>
      <v-card-actions>
        <v-row class="ma-2">
          <v-col cols="6">
            <v-btn
              depressed
              height="50"
              color="accent"
              block
              @click="
                () => {
                  volumeSelected = null
                  customSize = null
                  $v.$reset()
                  $emit('close');
                }
              "
            >
              Cancel
            </v-btn>
          </v-col>
          <v-col cols="6">
            <v-btn
              depressed
              height="50"
              color="secondary"
              block
              :disabled="isLoading"
              @click="doResizeRootDisk"
            >
              <beat-loader v-if="isLoading" :loading="isLoading" color="white" size="10px"></beat-loader>
              <span v-else>Resize Root Disk</span> 
            </v-btn>
          </v-col>
        </v-row>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>
<script>
  
import { onMounted, ref, watch } from "@vue/composition-api";
import { useNamespacedActions, useNamespacedState } from "vuex-composition-helpers";
import { IDRformat } from "../../lib/formatter";
import { addGBText, removeGBText } from '@/lib/formatter'
const { required, requiredIf, helpers } = require("@vuelidate/validators");
const useVuelidate = require("@vuelidate/core").default;

export default {
  props: {
    value: Boolean,
    selectedInstance: Object,
  },
  setup(props, context) {
    const { isLoading } = useNamespacedState('STORAGE', ['isLoading'])
    let { volumeSize } = useNamespacedState('STORAGE', ['volumeSize'])
    const filterBiggerVolumeSize = (currentStorage, flavorSize) => {
      if(flavorSize > currentStorage) return true
      return false
    }

    
    const isCustomSizeHighlighted = ref(false)
    const customSize = ref(null)
    const currentRootDisk = ref(0)
    currentRootDisk.value = props.selectedInstance.package?.root_disk

    let filteredVolumeSize = ref([])
    watch(() => props.selectedInstance, size => {
      if (!props.selectedInstance.package) return 
      currentRootDisk.value = props.selectedInstance.package.root_disk
      filteredVolumeSize.value = volumeSize.value.filter(x => {
        if (showCustomDiskFlavors) {
          if (x.organization_id) return true
          else return false
        } else {
          if (x.organization_id) return false
          else return true
        }
      }).filter(x => filterBiggerVolumeSize(currentRootDisk.value, x.volume_size)) 
    })

    watch(volumeSize, size => {
      if (!props.selectedInstance.package) return
        filteredVolumeSize.value = volumeSize.value.filter(x => {
        if (showCustomDiskFlavors) {
          if (x.organization_id) return true
          else return false
        } else {
          if (x.organization_id) return false
          else return true
        }
      }).filter(x => filterBiggerVolumeSize(currentRootDisk.value, x.volume_size )) 
    })

    const { fetchVolumeSize } = useNamespacedActions('STORAGE', ['fetchVolumeSize', 'RESIZE_VOLUME'])
    const { resizeRootDisk } = useNamespacedActions('INSTANCE', ['resizeRootDisk'])
    
    onMounted(async () => {
      await fetchVolumeSize({ limit: 999})
    })
    
    const volumeSelected = ref(null)
    const showCustomDiskFlavors = process.env.VUE_APP_SHOW_CUSTOM_DISK_FLAVORS == 'true'

    const $v = useVuelidate(
        {
          volumeSelected: {
            required: requiredIf((val) => {
              return customSize.value === null && volumeSelected.value === null;
            }),
            autoDirty: true,
          },
          customSize: {
            autoDirty: true,
            required: requiredIf((val) => {
              return customSize.value === null && volumeSelected.value === null;
            }),
            bigger: helpers.withMessage(`Value must be greater than current root disk size`, (val) => {
              if (customSize.value === null) return true
              let size = val ? removeGBText(val) : null
              return size > currentRootDisk.value
            }),
            moreThan20: helpers.withMessage('Custom size must be atleast 20 GB', (val) => {
              if (customSize.value === null) return true
              let size = val ? removeGBText(val) : null
              return size >= 20
            }),
          },
        },
        {
          volumeSelected,
          customSize,
        }
    );

    return {
      showCustomDiskFlavors,
      $v,
      addGBText,
      removeGBText,
      isCustomSizeHighlighted,
      customSize,
      currentRootDisk,
      filteredVolumeSize,
      isLoading,
      IDRformat,
      volumeSize,
      volumeSelected,
      is(a, b) {
        return a == b;
      },
      isSelected: (id) => {
        return id == volumeSelected.id;
      },
      doResizeRootDisk: async () => {
        $v.value.$touch()
        if ($v.value.$invalid) return

        let payload = {
          instance_id: props.selectedInstance.id || null,
        }

        if(volumeSelected.value) payload.vm_root_disk_id = volumeSelected.value.id
        if(customSize.value) {
          const flavorSameSize = volumeSize.value.filter(x => parseInt(x.volume_size) === parseInt(removeGBText(customSize.value)))
          if(flavorSameSize.length > 0) payload.vm_root_disk_id = flavorSameSize[0].id
          else payload.custom_size = parseInt(removeGBText(customSize.value))
        }

        const response = await resizeRootDisk(payload)
        customSize.value = null
        $v.value.$reset()
        if(response) context.emit('close')
      },
      logging: () => console.log('DIALOG', props),
      filterBiggerVolumeSize
    }
  }
}
</script>
<style lang="css">
#customSize .v-label--active {
  top: 10px !important;
}
</style>
<style lang="scss" scoped>
.highlight {
  border: 1px solid #2c94d2;
}
.select-instance,
.select-storage {
  cursor: pointer;
  &.selected {
    border-color: #2c94d2;
    .v-divider {
      border-color: #2c94d2;
    }
  }
}
.v-card.select-storage {
  p {
    margin-bottom: 0px;
    text-align: left;
  }
}
.v-card__title {
  line-height: 1.5rem;
}
.v-btn{
  ::v-deep .v-btn__content{
    font-size: 14px;
    font-weight: 700;
  }
}


.highlight-error {
  border: 1px solid red;
}

</style>