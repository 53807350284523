var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-card',{staticClass:"rounded-lg elevation-0"},[_c('v-container',{staticClass:"px-7 py-4",attrs:{"fluid":""}},[_c('v-row',[_c('v-col',{staticClass:"d-flex flex-row align-center"},[_c('p',{staticClass:"mb-0 font-weight-bold fz-21"},[_vm._v("Edit Broadcast")])])],1),_c('p',{staticClass:"-mb-18px"},[_c('b',[_vm._v("Send Date")])]),_c('v-row',[_c('v-col',[_c('v-datetime-picker',{attrs:{"disabled":_vm.loading,"time-picker-props":{ useSeconds: true },"text-field-props":{
            outlined: true,
            errorMessages: _vm.send_date ? '' : _vm.send_date_error,
          }},model:{value:(_vm.send_date),callback:function ($$v) {_vm.send_date=$$v},expression:"send_date"}})],1),_c('v-col')],1),_c('p',{staticClass:"-mb-18px"},[_c('b',[_vm._v("Title")])]),_c('v-row',[_c('v-col',[_c('v-text-field',{attrs:{"flat":"","outlined":"","placeholder":"Enter Title","required":"","error-messages":_vm.broadcast_name ? '' : _vm.broadcast_name_error,"disabled":_vm.loading},model:{value:(_vm.broadcast_name),callback:function ($$v) {_vm.broadcast_name=$$v},expression:"broadcast_name"}})],1),_c('v-col')],1),_c('p',{staticClass:"-mb-18px"},[_c('b',[_vm._v("Description")])]),_c('v-row',[_c('v-col',[_c('v-textarea',{attrs:{"flat":"","outlined":"","placeholder":"Enter Description","required":"","error-messages":_vm.description && _vm.description.split(' ').length > 50
              ? _vm.description_error
              : _vm.description
              ? ''
              : _vm.description_error,"disabled":_vm.loading},on:{"input":function($event){return _vm.max50Words($event)}},model:{value:(_vm.description),callback:function ($$v) {_vm.description=$$v},expression:"description"}}),(_vm.description_error && _vm.description_error === 'Max 50 words')?_c('p',{staticClass:"error--text"},[_vm._v(" Max 50 words ")]):_vm._e()],1),_c('v-col')],1),_c('p',{staticClass:"-mb-18px"},[_c('b',[_vm._v("Broadcast To")])]),_c('v-row',[_c('v-col',[_c('v-radio-group',{attrs:{"row":""},model:{value:(_vm.send_to_all),callback:function ($$v) {_vm.send_to_all=$$v},expression:"send_to_all"}},_vm._l((_vm.broadcastTos),function(item){return _c('v-radio',{key:'send-to-all-' + item.value,attrs:{"label":item.label,"value":item.value,"disabled":_vm.loading}})}),1),(!_vm.send_to_all)?_c('v-select2',{attrs:{"options":_vm.organizations,"placeholder":"Select Organization","multiple":"","disabled":_vm.loading},model:{value:(_vm.broadcast_receiver),callback:function ($$v) {_vm.broadcast_receiver=$$v},expression:"broadcast_receiver"}}):_vm._e(),(
            !_vm.send_to_all &&
              !_vm.broadcast_receiver.length &&
              _vm.broadcast_receiver_error
          )?_c('p',{staticClass:"error--text"},[_vm._v(" "+_vm._s(_vm.broadcast_receiver_error)+" ")]):_vm._e()],1),_c('v-col')],1),_c('p',{staticClass:"-mb-18px"},[_c('b',[_vm._v("Content")]),_c('br'),_c('span',[_vm._v(" This content uses markdown code. See the "),_c('a',{attrs:{"href":"https://www.markdownguide.org/basic-syntax/","target":"_blank","rel":"noopener noreferrer"}},[_vm._v(" markdown guide ")]),_vm._v(" for more info. ")])]),(!_vm.content && _vm.content_error)?_c('span',{staticClass:"error--text d-inline-block mt-4"},[_vm._v(" "+_vm._s(_vm.content_error)+" ")]):_vm._e(),_c('v-row',[_c('v-col',[_c('markdown-editor',{staticClass:"markdown-editor ",class:{ 'markdown-error': !_vm.content && _vm.content_error },model:{value:(_vm.content),callback:function ($$v) {_vm.content=$$v},expression:"content"}}),_c('p',{staticClass:"mt-4"},[_c('b',[_vm._v("Output")])]),_c('VueMarkdown',{attrs:{"source":_vm.content,"show":"","html":""}})],1)],1),_c('v-row',[_c('v-col',[_c('v-btn',{staticClass:"primary",staticStyle:{"background-color":"#a5b3bf !important"},attrs:{"depressed":"","block":"","height":"58","to":"/broadcast","exact":"","disabled":_vm.loading}},[_c('span',{staticClass:"fz-16px"},[_vm._v("Cancel")]),(_vm.loading)?_c('beat-loader',{staticClass:"ml-2",attrs:{"loading":_vm.loading,"color":'white',"size":'10px'}}):_vm._e()],1)],1),_c('v-col',[_c('v-btn',{staticClass:"secondary",attrs:{"depressed":"","block":"","height":"58","disabled":_vm.loading},on:{"click":_vm.submit}},[_c('span',{staticClass:"fz-16px"},[_vm._v("Edit")]),(_vm.loading)?_c('beat-loader',{staticClass:"ml-2",attrs:{"loading":_vm.loading,"color":'white',"size":'10px'}}):_vm._e()],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }