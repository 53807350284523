


















































































import { useDialog } from '@/composable/usedialog'
import {ref} from '@vue/composition-api'
import Vue from 'vue'
import useVuelidate from "@vuelidate/core";
import { required } from "@vuelidate/validators";
import { useNamespacedActions } from 'vuex-composition-helpers'
import { PROFILE } from './namespace'
export default Vue.extend({
    props:['value', 'userselected'],
    
    setup(props, context){
        const {dialog} = useDialog(props, context)
        const member = ref(props.userselected) as any
        // const fullname = member.value.fullname
        // const address =  member.value.address
        // const email =  member.value.email
        // const phone = member.value.phone

        // member.value.phone = member.value.phone.replace("+62", "")

        //  const $v = useVuelidate(
        //   {
        //     member: {
        //       fullname: { required },
        //       address: { required },
        //       phone: { required },
        //     }
        //   },
        //   {
        //      member: {
        //       fullname,
        //       address,
        //       phone,
        //     }
        //   }
        // );

        const errors = ref("");

        const {updateprofile} = useNamespacedActions(PROFILE,['updateprofile'])
        function Update() {
          // $v.value.$touch();
          // if ($v.value.$errors.length) return;
            const membervalue = member.value as any
            if(!membervalue.address || !membervalue.phone || !membervalue.fullname) {
              errors.value = 'Required'
            } else {
              const Value = {
                id: membervalue.id,
                name: membervalue.fullname,
                phone_number: membervalue.phone,
                address: membervalue.address
              }

            console.log(Value)
            return updateprofile(Value).then(() => {
            dialog.value = false;
            context.emit("updated");
            });
          }

        }
        return {
            errors,
            dialog,
            member,
            Update
        }
    }
})
