var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"d-flex flex-row justify-end mt-3 pt-3 customfooter",style:({
    'border-top': _vm.bordertop == '' ? '1px solid lightgrey' : '',
    visibility:
      _vm.hidden == '' &&
      _vm.props.pagination.pageCount <= 1 &&
      _vm.props.pagination.itemsPerPage == 10
        ? 'hidden'
        : '',
    display:
      _vm.hidden != '' &&
      _vm.props.pagination.pageCount <= 1 &&
      _vm.props.pagination.itemsPerPage == 10
        ? 'none !important'
        : '',
  })},[_c('v-spacer'),_c('v-select',{staticClass:"rounded-lg flex-grow-0 selectFooter",attrs:{"dense":"","hide-details":"","items":_vm.items,"item-text":"text","item-value":"value","outlined":""},on:{"change":function (val) {
          _vm.change(val);
        }},scopedSlots:_vm._u([{key:"selection",fn:function(ref){
        var item = ref.item;
return [_vm._v(" "+_vm._s(((_vm.props.pagination.itemsPerPage * (_vm.props.pagination.page - 1) + 1) + " - " + (_vm.props.pagination.itemsPerPage == -1 ? _vm.props.pagination.itemsLength : item.value * _vm.props.pagination.page > _vm.props.pagination.itemsLength ? _vm.props.pagination.itemsLength : _vm.props.pagination.itemsPerPage * (_vm.props.pagination.page - 1) + item.value) + " of " + (_vm.props.pagination.itemsLength)))+" ")]}}]),model:{value:(_vm.itemselected),callback:function ($$v) {_vm.itemselected=$$v},expression:"itemselected"}}),_c('div',{staticClass:"px-3 rounded-lg d-flex flex-row align-center ml-3",staticStyle:{"border":"1px solid #cad6e1"}},[_c('p',{staticClass:"mb-0",class:{ linkpointer: _vm.props.pagination.page > 1 },attrs:{"id":"Prev"},on:{"click":function($event){$event.preventDefault();return (function () {
          if (_vm.props.pagination.page <= 1) { return; }
          _vm.props.pagination.page > 1 ? _vm.props.options.page-- : '';
          _vm.$nextTick(function () {
            _vm.pagination();
          });
        }).apply(null, arguments)}}},[_vm._v(" Prev ")]),_vm._l((_vm.props.pagination.pageCount),function(n){return _c('div',{key:n},[_c('v-btn',{directives:[{name:"show",rawName:"v-show",value:(_vm.showbutton(n)),expression:"showbutton(n)"}],staticClass:"mx-1",class:{
          primary: _vm.props.pagination.page == n,
          'accent--text': _vm.props.pagination.page != n,
        },staticStyle:{"background-color":"transparent"},attrs:{"height":"25","width":"25","ripple":false,"depressed":"","fab":"","small":""},on:{"click":function () {
            _vm.props.options.page = n;
            _vm.$emit('input', _vm.props.options);
          }}},[_vm._v(_vm._s(n))])],1)}),_c('p',{staticClass:"mb-0",class:{
        linkpointer: _vm.props.pagination.page < _vm.props.pagination.pageCount,
      },attrs:{"id":"Next"},on:{"click":function($event){$event.preventDefault();return (function () {
          if (_vm.props.pagination.page >= _vm.props.pagination.pageCount) { return; }
          _vm.props.options.page++;
          _vm.$nextTick(function () {
            _vm.pagination();
          });
        }).apply(null, arguments)}}},[_vm._v(" Next ")])],2)],1)}
var staticRenderFns = []

export { render, staticRenderFns }