


















































import Vue from 'vue'
import { useNamespacedActions, useNamespacedState } from 'vuex-composition-helpers'
import { IMAGES } from './namespace'
const module =  Vue.extend({
  props: {
    value: Boolean,
    snapshot: Object,
    filterByInstanceID: Boolean,
  },
  setup(props, context){
    const { isLoading } = useNamespacedState(IMAGES, [ 'isLoading' ])
    const { deleteSnapshot } = useNamespacedActions(IMAGES, [ 'deleteSnapshot' ])
    
    return {
      isLoading,
      async doDelete(){
        const data = Object.assign({}, props.snapshot)
        const payload = {...data, filterByInstanceID: props.filterByInstanceID}
        const response = await deleteSnapshot(payload)
        if(response.status === 200) context.emit('close')
      }
    }
  },
  computed: {
    dialog: {
      get: function (): any {
        return this.value;
      },
      set: function (val:boolean): any {
        this.$emit("close", val);
      },
    },
  },
});
export default module
