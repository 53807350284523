<template>
  <v-dialog v-model="dialog" max-width="500">
    <v-card>
      <v-card-title>
        <div class="headline font-weight-bold font--text pb-4">
          Restore Instance
        </div>
      </v-card-title>
      <v-card-text class="pb-0">
        <p>
          Are you sure you want to restore
          <b>{{ item && item.snapshot.name }}</b> from
          <b>{{ item && item.instance.value.instanceName }}</b
          >?
        </p>
        <br />
        <p>
          Note: Restoring will replace the current Instance with an older image.
          Snapshots will be charged based on space used and are charged

          <span class="primary--text">
            {{ price }}
          </span>.
        </p>
      </v-card-text>
      <v-card-actions>
        <v-row class="ma-2">
          <v-col cols="6">
            <v-btn
              color="accent"
              block
              depressed
              height="50"
              @click="
                () => {
                  $emit('close');
                }
              "
            >
              Cancel
            </v-btn>
          </v-col>
          <v-col cols="6">
            <v-btn color="error" block height="50" depressed @click="doRestore">
              Restore Instance
            </v-btn>
          </v-col>
        </v-row>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import api from '@/lib/api';
import localstorage from '@/lib/localstorage';
import { useCreateSnapshot } from "./composableapi/usecreatesnapshot";

export default {
  props: ["value", "item"],
  computed: {
    dialog: {
      get: function() {
        return this.value;
      },
      set: function(val) {
        this.$emit("close", val);
      }
    }
  },
  setup(props, context) {
    const { restoreSnapshot } = useCreateSnapshot();

    const doRestore = async () => {
      const { instance_id, image_id } = props.item;

      const payload = {
        instance_id,
        image_id
      };

      const response = await restoreSnapshot(payload);

      if (response.status == 200) {
        context.emit("close");
      } else {
        console.log("error");
      }
    };

    return {
      doRestore
    };
  },
  data() {
    return {
      loading: false,
      price: '',
    }
  },
  methods: {
    async getRestorePrice() {
      this.loading = true

      try {
        const currentOrg = localstorage.getItem("currentProj");
        const proj = JSON.parse(currentOrg);
        const projId = proj && proj !== 'undefined' ? proj.id : ''

        const res = await api.GET('/restore/quota/' + projId)
        
        // this.price = res.data.price
        // console.log('getRestorePrice', res.data)
        // IDR {{ price.toLocaleString('en') }}
        //     /GB/month

        const { is_default, remaining, price, additional_price } = res.data

        if (!is_default && remaining > 0) {
          this.price = `IDR ${price.toLocaleString('en')}/month with remaining restore quota for this month of ${remaining > 0 ? remaining : 0}x and additional price of IDR ${additional_price.toLocaleString('en')}/restore`
        } else if (!is_default && remaining < 1) {
          this.price = `IDR ${additional_price.toLocaleString('en')}/month with remaining restore quota for this month of ${remaining > 0 ? remaining : 0}x and additional price of IDR ${additional_price.toLocaleString('en')}/restore`
        } else if (is_default) {
          this.price = `IDR ${price.toLocaleString('en')}/restore`
        } else {
          this.price = 'Sorry, an error occurred'
        }
      } catch (err) {
        let errmsg = "Sorry, an error occurred while displaying data";
        const thereIsErrorMessage = err && err.response && err.response.data;

        if (thereIsErrorMessage && err.response.data.message) {
          errmsg = err.response.data.message;
        }

        if (
          thereIsErrorMessage &&
          err.response.data.data !== "record not found"
        ) {
          this.$store.dispatch("HOMEPAGE/showErrorToast", errmsg);
        }
      }

      this.loading = false
    }
  },
  mounted() {
    this.getRestorePrice()
  }
};
</script>
