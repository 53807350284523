


































































































































































































































































import Vue from "vue";
import pie from "./pie.vue";
export default Vue.extend({
  components: { pie },
});
