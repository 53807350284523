<template>
  <v-app-bar app color="white" :clipped-left="$vuetify.breakpoint.lgAndUp" flat>
    <v-app-bar-nav-icon @click="triggerdrawer">
      <v-icon>mdi-menu</v-icon>
    </v-app-bar-nav-icon>

    <v-toolbar-title style="width: 195px" class="ml-0 pl-0 pr-4">
      <router-link to="/">
        <v-img width="150px" src="@/assets/logo1.svg" />
      </router-link>
    </v-toolbar-title>
    <component
      v-for="item in leftnavmenu"
      :key="item.component"
      :is="item.component"
    ></component>
    <v-spacer />
    <v-toolbar-items>
      <component
        v-for="item in navCustomMenu"
        :key="item.name"
        :is="item.name"
      ></component>
    </v-toolbar-items>
  </v-app-bar>
</template>

<script>
import {
  useGetters,
  useMutations,
  useNamespacedState,
  useNamespacedGetters
} from "vuex-composition-helpers";
import selectproject from "@/modules/project/selectproject";
import { computed } from "@vue/composition-api";

export default {
  setup() {
    const { openModal, activeProject } = selectproject();
    const { getleftnavmenu: leftnavmenu,getnavcustommenu: navCustomMenu } = useNamespacedGetters("ROLEPERMISSION", [
      "getleftnavmenu", "getnavcustommenu"
    ]);
    const { triggerdrawer } = useMutations({
      triggerdrawer: "DASHBOARD/triggerdrawer",
    });
    const navCustomeMenuSorted = computed(() => {
      return navCustomMenu.value.slice().sort((a, b) => {
        return b.sort - a.sort;
      });
    });
    return {
      leftnavmenu,
      openModal,
      activeProject,
      navCustomMenu: navCustomeMenuSorted,
      triggerdrawer,
    };
  },
};
</script>