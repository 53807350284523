var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-dialog',{attrs:{"width":"800","retain-focus":false},model:{value:(_vm.dialog),callback:function ($$v) {_vm.dialog=$$v},expression:"dialog"}},[_c('v-card',{staticClass:"rounded-lg",attrs:{"flat":""}},[_c('v-card-title',[_c('v-row',[_c('v-col',{attrs:{"cols":"8"}},[_c('div',{staticClass:"headline font-weight-bold font--text"},[_vm._v(" Select Organization or Project ")])])],1)],1),_c('div',{staticStyle:{"max-width":"775px","padding-left":"25px","padding-bottom":"15px"},attrs:{"id":"SearchOrg"}},[_c('v-text-field',{attrs:{"maxlength":"50","placeholder":"Search","prepend-inner-icon":"mdi-magnify","single-line":"","outlined":""},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}})],1),_c('v-card-text',[_c('v-data-table',{attrs:{"search":_vm.search,"id":"hideheader","hide-default-footer":true,"headers":_vm.headers,"items":_vm.organizations,"items-per-page":-1,"hide-default-header":true,"height":"290"},scopedSlots:_vm._u([{key:"item",fn:function(ref){
var item = ref.item;
return [_c('tr',{key:item.id},[_c('td',[(_vm.T != item.id)?_c('v-icon',{staticStyle:{"cursor":"pointer"},on:{"click":function () {
                    _vm.T = item.id;
                  }}},[_vm._v("mdi-menu-down")]):_vm._e(),(_vm.T == item.id)?_c('v-icon',{staticStyle:{"cursor":"pointer"},on:{"click":function () {
                    _vm.T = '';
                  }}},[_vm._v("mdi-menu-up")]):_vm._e(),_c('v-icon',{attrs:{"left":""}},[_vm._v("$vuetify.icons.gg_organizationIcon")]),_c('span',{staticStyle:{"cursor":"pointer"},on:{"click":function () {
                    _vm.T = item.id;
                  }}},[_vm._v(_vm._s(item.name))])],1),_c('td',[_vm._v(" "+_vm._s(item.id)+" ")])]),_vm._l((item.projects),function(p){return _c('tr',{key:p.id,style:({
              display:
                item.projects.length && _vm.T == item.id ? '' : 'none',
            })},[_c('td',[_c('div',{staticClass:"d-flex ml-7 pl-7"},[_c('v-icon',{attrs:{"left":""}},[_vm._v("$vuetify.icons.targetIcon")]),_c('div',{staticClass:"linkpointer",on:{"click":function () {
                      _vm.dialog = false;
                      _vm.setCurrentOrg(item)
                      _vm.setCurrentProj(p)
                      _vm.$router
                        .replace(
                          '/project/' + encodeURI(p.name)
                        )
                        .catch(function () {});
                    }}},[_vm._v(" "+_vm._s(p.name)+" ")])],1)]),_c('td',[_vm._v(_vm._s(p.id))])])})]}}])})],1),_c('v-divider')],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }