<template>
  <v-container fluid >
    <v-row>
      <v-col class="pa-0">
        <v-card flat class="rounded-lg pa-3">
          <v-card-text>
            <div class="headline font-weight-bold font--text">Resize</div>
            <p><b>Currently using:</b> 
            {{ instance.package_instance ? instance.package_instance.flavor_name : '-' }} / 
            {{ instance.package_instance ? instance.package_instance.vcpu : '-' }} vCPU / 
            {{ instance.package_instance ? instance.package_instance.ram : '-' }} GB RAM / 
            {{ instance.root_disk_package ? `${instance.root_disk_package.volume_size}GB` : 
                instance.package_instance ? `${instance.package_instance.root_disk}GB` : '-' }} Root Disk</p>
            <!-- <div v-if="instance.revert_expired">
              <v-badge color="orange" content="Warning!"></v-badge>
              <v-row>
                <v-col lg="10" sm="12">
                  Please check and make sure there is no problem with your Instance after changing the flavor. 
                  <br>If there is any problem with your new flavor, you have 15 seconds to revert to your previous flavor 
                  <span v-if="instance.revert_expired">
                    until <b>{{ moment(instance.revert_expired).format('LL h:m:s') }}</b>
                  </span>
                </v-col>
                <v-col lg="2" sm="12">
                  <v-btn @click="doRevert()" depressed :disabled="isLoading" height="50" block color="error" >
                    <beat-loader v-if="isLoading" :loading="isLoading" :color="'white'" :size="'10px'" class="mr-2"></beat-loader>
                    <span v-else>Revert</span>
                  </v-btn>
                </v-col>
              </v-row>
            </div> -->
            </v-card-text>
          <v-card-text>
            <v-tabs v-model="tab" :show-arrows="false" prev-icon="" grow slider-size="4">
              <v-tab>All Types</v-tab>
              <v-tab v-for="x in type" :key="x.id">
                <v-container>
                  <v-row> <div>{{ x.name }}</div></v-row>
                </v-container>
              </v-tab>
            </v-tabs>
          </v-card-text>
          <v-card-text>
            <v-tabs-items v-model="tab">
              <v-tab-item>
                <v-data-table
                  hide-default-footer
                  :headers="headers"
                  :items="filteredFlavors"
                  :items-per-page="10"
                >
                  <template v-slot:no-data>
                    {{ isLoading ? 'Loading..' : 'No data available' }}
                  </template>
                  <template v-slot:item.type="{ item }">
                    <div class="d-flex">
                      <v-radio-group hide-details v-model="selectedtype">
                        <v-radio 
                          :value="item" 
                          :disabled="item.outOfStock"
                          :class="{'disabled-flavor': item.outOfStock}"
                        ></v-radio>
                      </v-radio-group>
                      <div style="padding-top: 1px">
                        <span>{{ item.type.name }}</span>
                      </div>
                    </div>
                  </template>
                  <template v-slot:item.price="{ item }">
                      <p class="mb-0">IDR {{ toThousands(item.price_per_month) }}/month</p>
                      <p>IDR {{ item.price_per_hours }}/hour</p>
                  </template>
                  <template v-slot:item.name="{ item }">
                    <span>{{ item.flavor_name }}</span>
                  </template>
                  <template v-slot:item.vcpu="{ item }">
                      <span>{{ item.vcpu }} vCPU</span>
                  </template>
                  <template v-slot:item.gpu="{ item }">
                    <span>{{ item.gpu ? item.gpu : '-' }}</span>
                  </template>
                  <template v-slot:item.ram="{ item }">
                    <span>{{ item.ram }} GB</span>
                  </template>
                  <template v-slot:item.root_disk="{ item }">
                    <span>{{ item.root_disk }} GB</span>
                  </template>
                  <template
                    v-if="masterInstances.length > 5"
                    v-slot:footer="{ props }"
                  >
                    <custom-footer-datatable :props="props" />
                  </template>
                </v-data-table>
              </v-tab-item>
              <v-tab-item v-for="x in type" :key="x.id">
                <v-data-table
                  hide-default-footer
                  :headers="headers"
                  :items="filteredFlavors.filter(flavor => flavor.type.id === x.id && flavor.id !== instance.package_instance_id)"
                  :items-per-page="10"
                >
                  <template v-slot:no-data>
                    {{ isLoading ? 'Loading..' : 'No data available' }}
                  </template>
                  <template v-slot:item.type="{ item }">
                    <div class="d-flex">
                      <v-radio-group hide-details v-model="selectedtype">
                        <v-radio 
                          :value="item"
                          :disabled="item.outOfStock"
                          :class="{'disabled-flavor': item.outOfStock}"
                        ></v-radio>
                      </v-radio-group>
                      <div style="padding-top: 1px">
                        <span>{{ item.type.name }}</span>
                      </div>
                    </div>
                  </template>
                  <template v-slot:item.name="{ item }">
                    <span>{{ item.flavor_name }}</span>
                  </template>
                  <template v-slot:item.vcpu="{ item }">
                    <span>{{ item.vcpu }} vCPU</span>
                  </template>
                  <template v-slot:item.gpu="{ item }">
                    <span>{{ item.gpu ? item.gpu : '-' }}</span>
                  </template>
                  <template v-slot:item.ram="{ item }">
                    <span>{{ item.ram }} GB</span>
                  </template>
                  <template v-slot:item.root_disk="{ item }">
                    <span>{{ item.root_disk }} GB</span>
                  </template>
                  <template v-slot:item.price="{ item }">
                    <div>
                      <p class="mb-0">IDR {{ toThousands(item.price_per_month) }}/month</p>
                      <p>IDR {{ toThousands(item.price_per_hours) }}/hour</p>
                    </div>
                  </template>
                  <template
                    v-if="masterInstances.length > 5"
                    v-slot:footer="{ props }"
                  >
                    <custom-footer-datatable :props="props" />
                  </template>
                </v-data-table>
              </v-tab-item>
            </v-tabs-items>
          </v-card-text>
          <v-card-text>
            
          <v-btn  @click="()=>{
          validateprivilages([
            'Instance',
            'editor',
          ]).then(() => {
            doResize()
          }); }" depressed :disabled="isLoading || !selectedtype || !validateprivilages(['Instance', 'editor']) || !masterInstances.length" height="50" block color="secondary" >
              <beat-loader v-if="isLoading" :loading="isLoading" :color="'white'" :size="'10px'" class="mr-2"></beat-loader>
              <span v-else style="font-size:14px">Resize</span>
            </v-btn>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>
<script>
import { ref, onMounted, watch } from '@vue/composition-api';
import { useNamespacedActions } from 'vuex-composition-helpers';
import useResize from './useResize'
import { toThousands } from '@/lib/formatter'
import moment from 'moment'

export default {
  props: ['instance'],
  setup(props, context) {
    const { instanceid } = context.root._route.params
    const tab = ref('')
    const selectedtype = ref('')
    let regionName = props.instance.region
    
    watch(props.instance, val => {
      regionName = props.instance.region
    })

    const {
      fetchMasterInstances,
      masterInstances,
      fetchType,
      type,
      resize,
      revert,
      isLoading,
      region,
      regions,
      fetchRegions,
    } = useResize()

    const filteredFlavors = ref([])
    // filteredFlavors.value = masterInstances.value.filter(flavor => 
    //   flavor.id !== props.instance.package_instance_id
    // )

    onMounted(async () => {
      await fetchRegions()
      await fetchType()
      await fetchMasterInstances({
        region: regionName
      })

      const thisRegion = regions.value.filter(x => x.name == regionName)[0]
      filteredFlavors.value = masterInstances.value.filter(x => {
        if (x.status == 'active') {
          if (x.usage_limit == -1 || x.usage < x.usage_limit) return true //check flavor limit (unused) 
          return false
        }
        return false
      })
      .filter(flavor => 
        flavor.id != props.instance.package_instance_id
      )
      .sort((a, b) => a.ram > b.ram ? -1 : 1)
      .sort((a, b) => a.vcpu > b.vcpu ? 1 : -1)
      .map(x => {
        if (region.value) {
          if (x.vcpu <= (thisRegion.cpu_limit - thisRegion.cpu_usage) && 
              x.ram <= (thisRegion.ram_limit - thisRegion.ram_usage)
          ) return { ...x, outOfStock: false }
          else return { ...x, outOfStock: true }
        }
        return x
      })
    })

    const doResize = async () => {
      const payload = {
        instance_id: instanceid,
        package_instance_id: selectedtype.value.id,
        region: regionName,
      }
      await resize(payload)
      selectedtype.value = null
    }

    const doRevert = async () => {
      const payload = {
        instance_id: instanceid
      }
      await revert(payload)
    }

    const {
      validateprivilages,
      validateprivilagesync,
    } = useNamespacedActions('HOMEPAGE', [
      'validateprivilages',
      'validateprivilagesync',
    ]);

    return {
      headers: [
        {
          text: "Type",
          value: "type",
          sortable: false,
        },
        {
          text: "Flavor Name",
          value: "name",
          sortable: false,
        },
        {
          text: "vCPU",
          value: "vcpu",
          sortable: false,
        },
        {
          text: "RAM",
          value: "ram",
          sortable: false,
        },
        {
          text: "GPU",
          value: "gpu",
          sortable: false,
        },
        // {
        //   text: "Root Disk",
        //   value: "root_disk",
        //   sortable: false,
        // },
        // {
        //   text: "Price",
        //   value: "price",
        //   sortable: false,
        // },
      ],
      filteredFlavors,
      toThousands,
      masterInstances,
      tab,
      type,
      selectedtype,
      validateprivilages,
      validateprivilagesync,
      doResize,
      isLoading,
      moment,
      doRevert
    };
  },
};
</script>
<style>
  .disabled-flavor circle {
    stroke: #cdcdcd;
  }
</style>
<style scoped lang="scss">
.v-tabs{
  ::v-deep .v-slide-group__prev{
  display: none !important;
}
}

.headline {
  font-weight: 600;
  font-family: "Poppins", sans-serif;
}
.accent--text {
  font-size: 12px;
}
.v-tab {
  padding-left: 5px;
  padding-right: 5px;
}
.v-input--radio-group {
  margin: 0;
  padding: 0;
}
</style>