<template>
  <v-dialog v-model="value" scrollable persistent max-width="85%">
    <v-card>
      <v-card-title>
        <h1 class="tnc-title">
          Terms of Service
        </h1>
      </v-card-title>

      <v-card-text>
        <VueMarkdown :source="data[0].content_raw" show html />
      </v-card-text>

      <v-card-actions>
        <v-row>
          <v-col>
            <v-btn
              depressed
              height="45"
              block
              class="secondary"
              @click="
                () => {
                  $emit('input', false);
                }
              "
            >
              Okay
            </v-btn>
          </v-col>
        </v-row>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  props: ["value", "data"],

  data() {
    return {};
  },
  mounted() {
    console.log("asdas");
  },
  methods: {}
};
</script>

<style scoped>
.tnc-title {
  font-size: 28px;
  font-style: normal;
  font-weight: 700;
  line-height: 38px;
  letter-spacing: 0em;
  text-align: left;
}

.tnc-subtitle {
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: 22px;
  letter-spacing: 0em;
  text-align: left;
}
</style>
