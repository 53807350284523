export const IDRformat = new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'IDR',
    minimumFractionDigits: 0
  });

export const Rpformat = new Intl.NumberFormat('locale', {});
export const Fformat = new Intl.NumberFormat('en-US', {});

export const addGBText = (ev: any, value: any) => {
  let code = ev.code
  if (!code.includes('Digit') && !code.includes('Backspace')) {
    ev.preventDefault()
    return
  } 
  
  let val = value ? value.replaceAll(',', '').replace(' GB', '') + ev.key : ev.key
  if (code.includes('Digit')) {
    value = val + ' GB'
    ev.preventDefault()
  }
  return value
}

export const removeGBText = (value:any) => parseInt(value.replaceAll(' ', '').replaceAll('G', '').replaceAll('B', ''))

// Thousand Separator
export const toThousands = (x: any) => {
  return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
}

export const toPlain = (str: string) => {
  let res = parseInt(str.split(',').join(''))
  return isNaN(res) ? 0 : res
}