<template>
  <div style="margin-bottom: 3rem;" :class="{ animate : true }">
    <v-data-table
      id="DataTableListInstance"
      :hide-default-footer="true"
      :headers="headers"
      :items="instances"
      :items-per-page="5"
      class="elevation-0"
    >
      <template v-slot:item.name="{ item }">
        <div class="d-flex instance-name" style="align-items:center;">
          <v-tooltip top color="#333" transition="slide-y-reverse-transition">
            <template v-slot:activator="{ on, attrs }">
              <div v-on="on" v-bind="attrs">
                <v-icon v-if="item.error_message" class="mr-3" v-on="on" v-bind="attrs" color="error">mdi-close-circle</v-icon>
                <v-icon v-if="!item.error_message && item.openstack_vm_uuid" class="mr-3" v-on="on" v-bind="attrs" :color="['ACTIVE', 'RESIZE', 'VERIFY_RESIZE'].includes(item.state) ? 'primary' : 'error'">mdi-circle</v-icon>
                <beat-loader v-if="!item.error_message && !item.openstack_vm_uuid" :loading="true" :color="'#1f60a8'" :size="'10px'" class="mr-2"></beat-loader>
              </div>
            </template>
            <span v-if="item.error_message">Failed</span>
            <span v-if="!item.error_message && item.openstack_vm_uuid">{{ item.state === 'ACTIVE' ? 'Active' : 'Shutoff' }}</span>
            <span v-if="!item.error_message && !item.openstack_vm_uuid">Building</span>
          </v-tooltip>
          <div>
            <router-link
              :to="{ path: `/project/${projectname}/${item.id}/` }"
              ><span class="primary--text">{{
                item.instanceName
              }}</span></router-link
            >
            <p v-if="item.image">{{ item.image.name }} {{ item.image.file_system }} {{ item.image.version_number }}</p>
            <p>{{ item.package.vcpu }}vCPU / {{ item.package.ram }}GB RAM / {{ item.package.root_disk }}GB Root Disk</p>
            <p class="red--text"><small>{{ item.error_message }}</small></p>
          </div>
        </div>
      </template>
      <template v-slot:item.ipaddress="{ item }">
        <table class="table-ip" style="border:none;">
          <tr v-if="item.ipv4">
            <td style="padding-top: 0 !important;padding-bottom: 0 !important">Internal</td>
            <td style="padding-top: 0 !important;padding-bottom: 0 !important"><span class="mx-2">:</span> {{ item.ipv4 }}</td>
          </tr>
          <tr v-if="item.ip_gateway">
            <td style="padding-top: 0 !important;padding-bottom: 0 !important">Gateway</td>
            <td style="padding-top: 0 !important;padding-bottom: 0 !important"><span class="mx-2">:</span> {{ item.ip_gateway }}</td>
          </tr>
          <tr v-if="item.ipext">
            <td style="padding-top: 0 !important;padding-bottom: 0 !important">External</td>
            <td style="padding-top: 0 !important;padding-bottom: 0 !important"><span class="mx-2">:</span> {{ item.ipext }}</td>
          </tr>
        </table>
      </template>
      <template v-slot:item.createddate="{ item }">
        {{ $moment(new Date(item.createddate)).format("DD/MM/YYYY") }}
      </template>
      <template v-slot:item.region="{ item }">
        {{ item.region.city.toUpperCase() }}
      </template>

      <template v-slot:item.action="{ item }">
        <popupquote
          v-model="popo"
          :offsetTop="20"
          :coordinate="coordinate"
          :documentid="'popup' + item.id"
          offset-y
          allow-overflow
        >
          <template v-slot:activator="{ on, attrs }">
            <label
              v-bind="attrs"
              class="primary--text linkpointer"
              style="position: relative; white-space: nowrap"
              @click="
                ($event) => {
                  on.click($event);
                  show($event, item);
                }
              "
              >More
              <v-icon
                :class="{
                  rotate: item.id == activemenuinstance.id && popo,
                }"
                :id="'popup' + item.id"
                color="primary"
                >mdi-chevron-down
              </v-icon>
            </label>
          </template>
          <v-list>
            <v-list-item
              v-if="!item.floating_ip"
              :disabled="
                !validateprivilagesync(['Instance', 'editor']) || !item.openstack_vm_uuid
              "
              @click="() => {
                assignFloatingIP({ id: item.id })
              }"
              exact
            >
              <v-list-item-title>Add Floating IP</v-list-item-title>
            </v-list-item>
            <v-list-item
              v-else
              :disabled="
                !validateprivilagesync(['Instance', 'editor']) || !item.openstack_vm_uuid
              "
              @click="() => {
                unassignFloatingIP(item.floating_ip.id)
              }"
              exact
            >
              <v-list-item-title>Unassign Floating IP</v-list-item-title>
            </v-list-item>
            <v-list-item
              :disabled="!item.openstack_vm_uuid"
              @click="
                () => {
                  openConsole(
                    activemenuinstance.project_id,
                    activemenuinstance.openstack_vm_uuid
                  );
                }
              "
            >
              <v-list-item-title>Access Console</v-list-item-title>
            </v-list-item>
            <v-list-item
              :disabled="!validateprivilagesync(['Instance', 'editor']) || !item.openstack_vm_uuid"
              :to="'/instance/' + activemenuinstance.id + '/resize'"
              exact
            >
              <v-list-item-title>Resize Instance</v-list-item-title>
            </v-list-item>
            <v-list-item
              :disabled="!validateprivilagesync(['Instance', 'editor']) || !item.openstack_vm_uuid"
              :to="'/instance/' + activemenuinstance.id + '/usage'"
            >
              <v-list-item-title>View Usage</v-list-item-title>
            </v-list-item>
            <!-- <v-list-item
              :disabled="!validateprivilagesync(['Instance', 'editor'])"
              :to="'/instance/' + activemenuinstance.id + '/backups'"
            > -->
            <v-list-item
              disabled
              :to="'/instance/' + activemenuinstance.id + '/backups'"
            >
              <v-list-item-title>Enable Backup</v-list-item-title>
            </v-list-item>
            <v-list-item
              :disabled="!validateprivilagesync(['Instance', 'editor']) || !item.openstack_vm_uuid"
              :to="'/instance/' + activemenuinstance.id + '/snapshots'"
              style="border-bottom: 1px solid lightgray"
            >
              <v-list-item-title>Create Snapshot</v-list-item-title>
            </v-list-item>
            <v-list-item
              :disabled="!validateprivilagesync(['Instance', 'editor'])"
              @click="
                () => {
                  opendialogdelete = true;
                }
              "
            >
              <v-list-item-title class="error--text"
                >Delete Instance
              </v-list-item-title>
            </v-list-item>
          </v-list>
        </popupquote>
      </template>
      
      <template
          v-if="instances.length > 1"
          v-slot:footer="{ props }"
      >
        <custom-footer-datatable :props="props"/>
      </template>

      <template v-slot:no-data>
        <div class="my-7">
          <p
            style="
              font-size: 16px;
              margin-bottom: 10px;
              text-transform: capitalize;
            "
            class="font-weight-bold"
          >
            Looks like you don’t have any Instance
          </p>
          <v-row class="d-flex justify-center">
            <v-col cols="12" lg="10" md="12">
              <p style="font-size: 12px; color: #a5b3bf; margin-bottom: 20px">
                Build and release faster with scalable instance products in the
                cloud. Provides flexible server configurations sized for any
                application, industry leading price to performance, and
                predictable pricing that is the same across regions and usage
                volumes.
              </p>
            </v-col>
          </v-row>

          <v-btn
            to="/instance/create"
            width="300"
            height="50"
            depressed
            class="secondary"
          >
            Create Instance
          </v-btn>
        </div>
      </template>
    </v-data-table>
    <dialogDeleteInstance
      v-if="activemenuinstance"
      v-model="opendialogdelete"
      :selectedinstance="activemenuinstance"
    />
  </div>
</template>

<script>
import dialogDeleteInstance from "@/modules/instance/dialogDeleteInstance";
import { reactive, ref, watch, onMounted } from "@vue/composition-api";
import { INSTANCE } from "@/modules/instance/namespace";
import { mapGetters } from "vuex";
import {
  useNamespacedActions,
  useNamespacedState,
} from "vuex-composition-helpers";
import popupquote from "@/modules/customsharedcomponents/popupquote.vue";

export default {
  components: { popupquote, dialogDeleteInstance },
  computed: {
    ...mapGetters({
      projectname: "PROJECT/getprojectname",
      isLoading: "INSTANCE/getIsLoading",
      privilagesGroup: "HOMEPAGE/privilagesGroup",
    }),
  },
  setup(props, context) {
    props;
    const {
      fetchinstances,
      openconsole,
      deleteinstance: Deleteinstance,
    } = useNamespacedActions(INSTANCE, [
      "fetchinstances",
      "deleteinstance",
      "openconsole",
    ]);

    const { instances } = useNamespacedState(INSTANCE, ["instances"]);

    const selected = ref("list");

    function isSelected(val) {
      return selected.value == val;
    }

    const showMenu = ref(false);
    const coordinate = reactive({ x: 0, y: 0 });
    const activemenuinstance = ref({});

    function deleteinstance() {
      Deleteinstance(activemenuinstance.value.id);
    }

    function show(e, instance) {
      e.preventDefault();
      activemenuinstance.value = instance;
      showMenu1.value = false;
      coordinate.x = e.clientX - 20;
      coordinate.y = e.clientY + 10;
      context.root.$nextTick(() => {
        showMenu1.value = true;
      });
    }

    const showMenu1 = ref(false);
    const {
      validateprivilages,
      validateprivilagesync,
    } = useNamespacedActions("HOMEPAGE", [
      "validateprivilages",
      "validateprivilagesync",
    ]);

    const { 
      addfloatingip,
      UNASSIGN_FLOATING_IP
    } = useNamespacedActions('NETWORKFLOATINGIP', [
      'addfloatingip',
      'UNASSIGN_FLOATING_IP'
    ])

    const assignFloatingIP = async (id) => {
      let response = await addfloatingip(id)
      if(response.status === 200) await fetchinstances()
    }
    const unassignFloatingIP = async (id) => {
      let response = await UNASSIGN_FLOATING_IP(id)
      if(response.status === 200) await fetchinstances()
    }

    return {
      unassignFloatingIP,
      assignFloatingIP,
      validateprivilages,
      validateprivilagesync,
      opendialogdelete: ref(false),
      coordinate,
      showMenu1,
      fetchinstances,
      instances,
      selected,
      isSelected,
      deleteinstance,
      showMenu,
      show,
      activemenuinstance,
      async openConsole(project_id, openstack_vm_uuid) {
        let params = {
          project_id: project_id,
          openstack_vm_uuid: openstack_vm_uuid,
        };
        await openconsole(params);
      },
    };
  },
  data() {
    return {
      popo: false,
      headers: [
        { text: "Name", value: "name" },
        { text: "IP Address", value: "ipaddress" },
        { text: "Region", value: "region" },
        { text: "Created date", value: "createddate" },
        { text: "Action", sortable: false, align: "center", value: "action" },
      ],
    };
  },
  created() {
    this.fetchinstances();
  },
};
</script>
<style lang="scss" scoped>
[role="menu"] {
  margin-top: 12px;
}

.mdi-chevron-down.rotate::before {
  transform: rotate(-180deg);
  transition: 100ms linear all;
}

.mdi-chevron-down::before {
  transition: 100ms linear all;
}

p {
  margin-bottom: 0px;

  .ipaddress {
    display: inline-block;
    width: 50px;
    position: relative;

    .tik {
      position: absolute;
      right: 10px;
      display: inline-block;
    }
  }
}

.select-view {
  border: 6px solid #f2f2f2;
  border-radius: 4px;
  background-color: #f2f2f2;
  opacity: 0.5;

  &.selected {
    opacity: 1;
  }
}

.instanceinfo {
  .col:first-child {
    p {
      font-weight: 700;
    }
  }

  .col {
    overflow-wrap: initial;
    padding: 0px 0px 0px 12px;
    font-size: 12px;
  }
}

.v-data-table {
  ::v-deep table {
    border-bottom: 1px solid #e0e0e0;
  }
}
</style>
<style lang="scss">
#DataTableListInstance {
  font-weight: 600;

  td {
    padding-top: 20px;
    padding-bottom: 14px;
  }
}
</style>