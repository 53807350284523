<template>
  <v-container class="pa-0" fluid>
    <v-row>
      <v-col v-if="objectStorage">
        <v-card flat class="rounded-lg elevation-0 pa-3">
          <v-card-title class="pb-0">
            <div class="d-flex flex-row">
              <div>
                <v-icon style="font-size: 50px;" color="primary"
                  >mdi-circle</v-icon
                >
              </div>
              <v-col class="py-0">
                <h4>{{ objectStorage.name }}</h4>
                <p class="my-0" style="font-size: 0.8rem">
                  in
                  <span class="primary--text font-weight-bold"
                    >{{ objectStorage.project.name }}
                  </span>
                  |
                  {{
                    objectStorage &&
                    objectStorage.package_object_storage &&
                    objectStorage.package_object_storage.object_storage_size
                      ? `${objectStorage.package_object_storage.object_storage_size} GB`
                      : "N/A"
                  }}
                  | {{ objectStorage.region }} |
                  https://bucket.cloud.lintasarta.co.id:8082/
                </p>
              </v-col>
            </div>
          </v-card-title>
          <v-card-actions>
            <v-tabs v-model="tab">
              <v-tab to="overview" replace>Overview</v-tab>
              <v-tab to="access-keys" replace>Access Keys</v-tab>
              <v-tab to="settings" replace>Settings</v-tab>
            </v-tabs>
          </v-card-actions>
        </v-card>
      </v-col>
    </v-row>
    <v-row>
      <v-col>
        <v-tabs-items v-model="tablastpath">
          <v-tab-item value="overview">
            <v-card flat class="rounded-lg pa-3">
              <v-card-text>
                <div class="d-flex flex-row" style="align-items: center">
                  <div class="headline font-weight-bold font--text">
                    Total Usage
                  </div>
                  <v-spacer />
                </div>
              </v-card-text>
              <v-card-text :class="{ animate: loading }">
                <v-row :class="{ hidden: loading }">
                  <v-col cols="12">
                    <div
                      class="text-right font-weight-bold"
                      style="font-size: 1.2rem"
                    >
                      <br />{{ totalDataBytes }}/{{
                        objectStorage &&
                        objectStorage.package_object_storage &&
                        objectStorage.package_object_storage.object_storage_size
                          ? `${objectStorage.package_object_storage.object_storage_size} GB`
                          : "-"
                      }}
                    </div>
                    <v-progress-linear
                      color="light-blue"
                      height="20"
                      :value="
                        calculatePercentage(
                          totalDataBytes,
                          objectStorage &&
                            objectStorage.package_object_storage &&
                            objectStorage.package_object_storage
                              .object_storage_size
                            ? objectStorage.package_object_storage
                                .object_storage_size
                            : 0
                        )
                      "
                      striped
                    >
                      <template v-slot:default="{ value }">
                        <strong>{{ value }}</strong>
                      </template>
                    </v-progress-linear>
                    <div class="text-right">
                      *value will be updated frequently
                    </div>
                  </v-col>
                  <v-col cols="12">
                    <v-row v-if="objectStorageUsage">
                      <v-col
                        cols="4"
                        v-for="bucket in objectStorageUsage.data.buckets"
                        :key="bucket.name"
                      >
                        <v-card outlined>
                          <v-card-text>
                            <v-row>
                              <v-col
                                cols="12"
                                class="py-1 primary--text font-weight-bold"
                                >{{ bucket.name }}</v-col
                              >
                              <v-col
                                cols="4"
                                class="py-1 font--text font-weight-bold"
                                >Object Count</v-col
                              >
                              <v-col cols="8" class="py-1 font--text ">{{
                                bucket.objectCount
                              }}</v-col>
                              <v-col
                                cols="4"
                                class="py-1 font--text font-weight-bold"
                                >Usage</v-col
                              >
                              <v-col cols="8" class="py-1 font--text ">{{
                                getGBUsage(bucket.dataBytes) + " GB"
                              }}</v-col>
                            </v-row>
                          </v-card-text>
                        </v-card>
                      </v-col>
                    </v-row>
                  </v-col>
                </v-row>
              </v-card-text>
            </v-card>
          </v-tab-item>
          <v-tab-item value="access-keys">
            <v-card flat class="rounded-lg pa-3">
              <v-card-text>
                <div class="d-flex flex-row" style="align-items: center">
                  <div class="headline font-weight-bold font--text">
                    Access Keys
                  </div>
                  <v-spacer />
                  <v-btn
                    depressed
                    width="150"
                    height="50"
                    class="secondary"
                    @click="
                      () => {
                        validateprivilages(['Object Storage', 'editor']).then(
                          () => {
                            openDialogCreateAccessKey = true;
                          }
                        );
                      }
                    "
                  >
                    Create Access Key
                  </v-btn>
                </div>
              </v-card-text>
              <v-card-text :class="{ animate: loading }">
                <v-data-table
                  :class="{ hidden: loading }"
                  :hide-default-footer="true"
                  :headers="headers"
                  :items="accessKeys"
                  :items-per-page="10"
                >
                  <template v-slot:item.user="{ item }">
                    {{ item.user.fullname }}
                    <v-badge
                      v-if="objectStorage.user_id == item.user.id"
                      class="ml-2"
                      content="Creator"
                      color="blue"
                    ></v-badge>
                  </template>
                  <template v-slot:item.keyID="{ item }">
                    {{ item.access_key }}
                  </template>
                  <template v-slot:item.secretKey="{ item }">
                    {{ item.secret_access_key }}
                    <span>********</span>
                    <v-icon
                      small
                      class="mb-2 ml-2 secondary white--text py-1 px-2 rounded"
                      style="cursor: pointer;"
                      @click="
                        () => {
                          validateprivilages(['Object Storage', 'editor']).then(
                            () => {
                              showDialogReveal(item);
                            }
                          );
                        }
                      "
                      >mdi-eye</v-icon
                    >
                  </template>
                  <template v-slot:item.created_at="{ item }">
                    {{ moment(item.created_date).format("DD/MM/YYYY") }}
                  </template>
                  <template v-slot:item.action="{ item }">
                    <a
                      v-if="item.name != 'Creator'"
                      @click="
                        () => {
                          validateprivilages(['Object Storage', 'editor']).then(
                            () => {
                              accessKeyToDelete = item;
                              openDialogDeleteAccessKey = true;
                            }
                          );
                        }
                      "
                      class="error--text"
                      >Delete</a
                    >
                  </template>
                  <template v-slot:no-data> </template>
                  <template
                    v-if="accessKeys.length > 5"
                    v-slot:footer="{ props }"
                  >
                    <custom-footer-datatable :props="props" />
                  </template>
                </v-data-table>
              </v-card-text>
            </v-card>
          </v-tab-item>
          <v-tab-item value="settings">
            <v-card flat class="rounded-lg">
              <v-card-text class="px-7">
                <v-row v-show="!editFileListing" class="my-4">
                  <v-col md="8">
                    <h3>File Listing</h3>
                    <br />
                    <p class="mb-0">
                      Restricted: only users who have the access key to this
                      bucket can access the endpoint
                    </p>
                  </v-col>
                  <!-- <v-col class="text-right align-self-center">
                    <v-btn 
                      depressed 
                      @click="()=>{editFileListing = true}" 
                      width="150" 
                      height="45" 
                      color="secondary"
                      disabled
                    > Edit </v-btn>
                  </v-col> -->
                </v-row>
                <v-row v-show="editFileListing" class="my-4">
                  <v-col md="8">
                    <h3>File Listing</h3>
                  </v-col>
                </v-row>
                <v-row v-show="editFileListing">
                  <v-col cols="12" sm="6" md="6" class="d-flex flex-nowrap">
                    <v-card class="rounded-lg d-flex flex-grow-1" flat outlined>
                      <v-card-title class="d-flex flex-nowrap">
                        <div>
                          <v-radio-group
                            hide-details
                            class="mt-0"
                            v-model="radios"
                          >
                            <v-radio value="ssh"> </v-radio>
                          </v-radio-group>
                        </div>
                        <div class="ml-2">
                          <p class="my-0 font-weight-bold">
                            Restrict File Listing
                          </p>
                          <p class="my-0" style="font-size: 12px">
                            Only users who connect to this bucket using access
                            keys can list the contents.
                          </p>
                        </div>
                      </v-card-title>
                    </v-card>
                  </v-col>
                  <v-col cols="12" sm="6" md="6" class="d-flex flex-nowrap">
                    <v-card class="rounded-lg d-flex flex-grow-1" flat outlined>
                      <v-card-title class="d-flex flex-nowrap">
                        <div>
                          <v-radio-group
                            hide-details
                            class="mt-0"
                            v-model="radios"
                          >
                            <v-radio value="password"> </v-radio>
                          </v-radio-group>
                        </div>
                        <div class="ml-2">
                          <p class="my-0 font-weight-bold">
                            Enable File Listing
                          </p>
                          <p class="my-0" style="font-size: 12px">
                            Anyone can list the contents of this bucket.
                          </p>
                        </div>
                      </v-card-title>
                    </v-card>
                  </v-col>
                </v-row>
                <v-row v-show="editFileListing">
                  <v-col>
                    <p>
                      <span class="primary--text">Important: </span> setting has
                      no effect on whether individual files are visible. It only
                      determines if anonymous users can list the name, size, and
                      other metadata for the files in this bucket.
                    </p>
                  </v-col>
                </v-row>
                <v-row v-show="editFileListing">
                  <v-col>
                    <v-btn depressed width="100" color="secondary">
                      Save
                    </v-btn>
                    <div
                      style="display:inline-block; width:50px; height:auto"
                    />
                    <v-btn
                      depressed
                      @click="
                        () => {
                          editFileListing = false;
                        }
                      "
                      width="100"
                    >
                      Cancel
                    </v-btn>
                  </v-col>
                </v-row>
                <v-divider />
                <v-row v-show="editCDN">
                  <v-col md="6">
                    <p class="font-weight-bold">Use a custom subdomain</p>
                    <v-text-field
                      maxlength="50"
                      outlined
                      hide-details=""
                    ></v-text-field>
                  </v-col>
                  <v-col md="6">
                    <p class="font-weight-bold">Edge Cache TTL</p>
                    <div class="d-flex flex-row align-center">
                      <v-text-field
                        maxlength="50"
                        outlined
                        hide-details
                      ></v-text-field>
                      <v-spacer />
                      <v-btn depressed width="150" height="45" color="accent"
                        >Disable CDN</v-btn
                      >
                    </div>
                  </v-col>
                </v-row>
                <v-row class="mb-4" v-show="editCDN">
                  <v-col>
                    <v-btn depressed width="100" color="secondary">
                      Save
                    </v-btn>
                    <div
                      style="display:inline-block; width:50px; height:auto"
                    />
                    <v-btn
                      depressed
                      @click="
                        () => {
                          editCDN = false;
                        }
                      "
                      width="100"
                    >
                      Cancel
                    </v-btn>
                  </v-col>
                </v-row>
                <v-divider />
                <v-row class="my-4">
                  <v-col md="8">
                    <h3>CORS Configurations</h3>
                    <br />
                    <p class="mb-0">
                      Rules are processed in order from top to bottom until
                      there is match - drag and drop rules to reorder
                    </p>
                  </v-col>
                  <v-col class="text-right align-self-center">
                    <v-btn
                      depressed
                      :disabled="isLoading"
                      @click="
                        () => {
                          validateprivilages(['Object Storage', 'editor']).then(
                            () => {
                              openDialogCors = true;
                            }
                          );
                        }
                      "
                      width="150"
                      height="45"
                      color="secondary"
                      >Add</v-btn
                    >
                  </v-col>
                </v-row>
                <div v-if="CORSRules.length" :class="{ animate: loading }">
                  <v-simple-table :class="{ hidden: loading }">
                    <template>
                      <thead>
                        <tr>
                          <th class="text-left">
                            Allowed Header
                          </th>
                          <th class="text-left">
                            Allowed Method
                          </th>
                          <th class="text-left">
                            Allowed Origin
                          </th>
                          <th class="text-left">
                            Action
                          </th>
                        </tr>
                      </thead>
                      <draggable
                        @change="orderChanges"
                        v-model="CORSRules"
                        tag="tbody"
                      >
                        <tr
                          v-for="(rule, i) in CORSRules"
                          :key="i"
                          :set="(rule.order = i)"
                        >
                          <td>{{ rule.AllowedHeader }}</td>
                          <td>
                            <span
                              v-for="(method, i) in rule.AllowedMethod"
                              :key="method"
                              >{{
                                i == rule.AllowedMethod.length - 1
                                  ? method
                                  : `${method}, `
                              }}</span
                            >
                          </td>
                          <td>{{ rule.AllowedOrigin }}</td>
                          <td>
                            <a
                              class="error--text"
                              @click="deleteCORSRule(rule.order)"
                              >Delete</a
                            >
                          </td>
                        </tr>
                      </draggable>
                    </template>
                  </v-simple-table>
                </div>
                <div v-else>
                  <v-simple-table>
                    <template>
                      <thead>
                        <tr>
                          <th class="text-left">
                            Headers
                          </th>
                          <th class="text-left">
                            Methods
                          </th>
                          <th class="text-left">
                            Origin
                          </th>
                          <th class="text-left">
                            Action
                          </th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr>
                          <td colspan="4" align="center">
                            Your bucket CORS rule is empty
                          </td>
                        </tr>
                      </tbody>
                    </template>
                  </v-simple-table>
                </div>
                <v-col class="text-right align-self-center">
                  <v-btn
                    depressed
                    :disabled="isLoading || disableSaveChanges"
                    width="150"
                    height="45"
                    color="secondary"
                    @click="
                      () => {
                        validateprivilages(['Object Storage', 'editor']).then(
                          () => {
                            saveCORSRules();
                          }
                        );
                      }
                    "
                    >Save Changes</v-btn
                  >
                </v-col>
                <v-divider />
                <v-row class="my-4">
                  <v-col md="8">
                    <h3>Endpoint</h3>
                    <br />
                    <p class="mb-0">
                      The endpoint is used to configure 3rd party clients, which
                      may refer to it by other names such as the S3 Endpoint or
                      Server
                    </p>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col md="6" class="text-right align-self-center">
                    <v-text-field
                      id="endpointURI"
                      maxlength="50"
                      value="https://bucket.cloud.lintasarta.co.id:8082/"
                      filled
                      readonly
                      flat
                      solo
                      hide-details
                      class="v-input-append"
                      ><template v-slot:append>
                        <v-btn
                          depressed
                          @click="copyText"
                          class="secondary"
                          width="100"
                          height="56"
                          style="position: relative; right: -12px;border-radius: 0 4px 4px 0"
                          >Copy</v-btn
                        >
                      </template></v-text-field
                    >
                  </v-col>
                </v-row>
                <v-divider />

                <v-row class="my-4">
                  <v-col md="8">
                    <h3>Destroy</h3>
                    <br />
                    <p class="mb-0">
                      This will schedule your bucket to be d. Your object
                      storage and all associated objects will be permantelly
                      destroyed and irretievable after the deletion period.
                    </p>
                  </v-col>
                  <v-col class="text-right align-self-center">
                    <v-btn
                      depressed
                      outlined
                      @click="
                        () => {
                          validateprivilages(['Object Storage', 'editor']).then(
                            () => {
                              openDialogDestroy = true;
                            }
                          );
                        }
                      "
                      style="border: 2px solid #EB5757"
                      width="150"
                      height="45"
                      color="white"
                      class="error--text"
                    >
                      Destroy
                    </v-btn>
                  </v-col>
                </v-row>
              </v-card-text>
            </v-card>
          </v-tab-item>
        </v-tabs-items>
      </v-col>
    </v-row>
    <dialogAdvanCorsOption
      :objectStorageID="objectStorage ? objectStorage.id : null"
      v-model="openDialogCors"
      @addRule="($event) => addCORSRule($event)"
      @close="
        ($event) => {
          openDialogCors = $event;
        }
      "
    />
    <dialogDestroyObjectStorage
      v-model="openDialogDestroy"
      @close="
        ($event) => {
          openDialogDestroy = $event;
        }
      "
      @confirm="deleteS3"
    />
    <dialogDeleteAccessKey
      v-model="openDialogDeleteAccessKey"
      :accessKey="accessKeyToDelete"
      @close="
        ($event) => {
          openDialogDeleteAccessKey = $event;
        }
      "
      @confirm="deleteKey"
    />
    <dialogCreateAccessKey
      v-model="openDialogCreateAccessKey"
      :objectStorageID="objectStorageID"
      @close="
        ($event) => {
          openDialogCreateAccessKey = false;
        }
      "
      @show="showAccessKey($event)"
    />
    <dialogShowAccessKey
      v-model="openDialogShowAccessKey"
      :accessKey="accessKey"
      @close="
        ($event) => {
          openDialogShowAccessKey = false;
        }
      "
    />
    <dialogRevealAccessKey
      v-model="openDialogRevealAccessKey"
      :accessKey="accessKeyToReveal"
      @close="
        ($event) => {
          openDialogRevealAccessKey = false;
        }
      "
    />
  </v-container>
</template>
<script>
import { useNamespacedActions } from "vuex-composition-helpers";
import dialogShowAccessKey from "./dialogShowAccessKey";
import dialogRevealAccessKey from "./dialogRevealAccessKey";
import dialogCreateAccessKey from "./dialogCreateAccessKey";
import dialogDeleteAccessKey from "./dialogDeleteAccessKey";
import dialogAdvanCorsOption from "./dialogAdvanCorsOption";
import dialogDestroyObjectStorage from "./dialogDestroyObjectStorage";
import { ref, onMounted, computed, watch } from "@vue/composition-api";
import { useObjectStorage } from "./composableapi/useobjectstorage";
import moment from "moment";
import draggable from "vuedraggable";
const byteSize = require("byte-size");
var converter = require("byte-converter").converterBase2;

const XMLConverter = require("xml-js");

export default {
  components: {
    dialogShowAccessKey,
    dialogCreateAccessKey,
    dialogAdvanCorsOption,
    dialogDestroyObjectStorage,
    dialogDeleteAccessKey,
    dialogRevealAccessKey,
    draggable,
  },
  watch: {
    $route() {},
  },
  setup(props, context) {
    const Composable = {
      ...useObjectStorage(),
    };
    const {
      updateCORS,
      deleteObjectStorage,
      deleteAccessKey,
      objectStorageUsage,
      objectStorage,
    } = Composable;

    const objectStorageID = context.root.$route.params.objectsrtorageid;

    const openDialogShowAccessKey = ref(false);
    const openDialogCreateAccessKey = ref(false);
    const openDialogDestroy = ref(false);
    const openDialogCors = ref(false);
    const editCDN = ref(false);
    const editFileListing = ref(false);
    const disableSaveChanges = ref(true);
    const accessKey = ref({});

    const radios = ref("");
    const tab = ref(null);
    const headers = [
      { text: "Access Key Name", value: "name" },
      { text: "User", value: "user" },
      { text: "Key ID", value: "keyID" },
      { text: "Secret Key", value: "secretKey" },
      { text: "Created At", value: "created_at" },
      { text: "Action", value: "action" },
    ];
    const tablastpath = computed(() => {
      return tab.value ? tab.value.split("/").pop() : "";
    });

    const copyText = () => {
      var copyText = document.getElementById("endpointURI");

      /* Select the text field */
      copyText.select();
      copyText.setSelectionRange(0, 99999); /* For mobile devices */

      /* Copy the text inside the text field */
      document.execCommand("copy");
      context.root.$store.dispatch(
        "HOMEPAGE/showSuccessToast",
        "Copied to clipboard",
        { root: true }
      );
    };

    const CORSRules = ref([]);
    const addCORSRule = (rule) => {
      CORSRules.value = [...CORSRules.value, rule];
      openDialogCors.value = false;
      disableSaveChanges.value = false;
    };

    const deleteCORSRule = (order) => {
      CORSRules.value = CORSRules.value.filter((rule, i) => i != order);
      disableSaveChanges.value = false;
    };

    const resetCORSRules = () => {
      CORSRules.value = [];
    };

    const disabledField = ref(false);
    const {
      validateprivilages,
      validateprivilagesync,
      validateprivilagesyncnew,
    } = useNamespacedActions("HOMEPAGE", [
      "validateprivilages",
      "validateprivilagesync",
      "validateprivilagesyncnew",
    ]);

    validateprivilagesyncnew(["Object Storage", "editor"]).then((res) => {
      disabledField.value = res;
    });

    const saveCORSRules = async () => {
      const rules = CORSRules.value.map((rule) => {
        delete rule.order;
        return rule;
      });
      const payload = {
        id: objectStorageID,
        rules,
      };
      const response = await updateCORS(payload);
      if (response.status == 200) disableSaveChanges.value = true;
    };

    const deleteS3 = async () => {
      openDialogDestroy.value = false;
      const response = await deleteObjectStorage({
        id: objectStorageID,
        name: objectStorage.name,
      });
      if (response.status == 200) {
        context.root.$router.replace("/object-storage");
      }
    };

    const accessKeyToDelete = ref(null);
    const openDialogDeleteAccessKey = ref(false);

    const deleteKey = async (id) => {
      const response = await deleteAccessKey({
        id,
        object_storage_id: objectStorageID,
      });
      if (response.status == 200) openDialogDeleteAccessKey.value = false;
    };

    const accessKeyToReveal = ref(null);
    const openDialogRevealAccessKey = ref(false);
    const showDialogReveal = (key) => {
      openDialogRevealAccessKey.value = true;
      accessKeyToReveal.value = key;
    };

    const calculatePercentage = (usageInGB, limitInGB) => {
      if (limitInGB == 0) return "N/A";
      const percentage = (usageInGB / limitInGB) * 100;
      return `${percentage.toFixed(5)}%`;
    };

    const totalDataBytes = ref(0);

    const getGBUsage = (bytes) => {
      return converter(bytes, "B", "GB").toFixed(5);
    };

    const loading = ref(false);
    const orderChanges = (ev) => {
      disableSaveChanges.value = false;
    };

    return {
      validateprivilages,
      disabledField,
      validateprivilagesync,
      validateprivilagesyncnew,
      ...Composable,
      orderChanges,
      totalDataBytes,
      disableSaveChanges,
      accessKeyToDelete,
      accessKeyToReveal,
      openDialogDeleteAccessKey,
      openDialogRevealAccessKey,
      getGBUsage,
      calculatePercentage,
      deleteKey,
      showDialogReveal,
      copyText,
      saveCORSRules,
      deleteS3,
      deleteCORSRule,
      resetCORSRules,
      CORSRules,
      objectStorageID,
      tablastpath,
      openDialogShowAccessKey,
      openDialogCreateAccessKey,
      openDialogDestroy,
      openDialogCors,
      radios,
      editFileListing,
      editCDN,
      tab,
      headers,
      moment,
      accessKey,
      loading,
      addCORSRule,
      log: (msg) => console.log(msg),
      showAccessKey: (val) => {
        accessKey.value = val;
        openDialogCreateAccessKey.value = false;
        openDialogShowAccessKey.value = true;
      },
    };
  },
  async created() {
    this.loading = true;
    await this.fetchObjectStorage({ id: this.objectStorageID });
    await this.fetchAccessKeys({ id: this.objectStorageID });
    await this.fetchUsage({ id: this.objectStorageID });
    this.loading = false;
    this.totalDataBytes = this.objectStorageUsage
      ? this.getGBUsage(this.objectStorageUsage.data.dataBytes)
      : 0;
    const xml =
      this.objectStorage && this.objectStorage.cors_xml
        ? JSON.parse(
            XMLConverter.xml2json(this.objectStorage.cors_xml, {
              compact: true,
              spaces: 0,
            })
          )
        : null;
    if (xml) {
      if (Array.isArray(xml.CORSConfiguration.CORSRule)) {
        this.CORSRules = xml.CORSConfiguration.CORSRule.map((rule, order) => {
          let allowedMethod = [];
          if (rule.AllowedMethod) {
            if (Array.isArray(rule.AllowedMethod))
              allowedMethod = rule.AllowedMethod.map((method) => method._text);
            else allowedMethod = [rule.AllowedMethod._text];
          } else allowedMethod = [];

          return {
            order: ++order,
            AllowedHeader: rule.AllowedHeader ? rule.AllowedHeader._text : null,
            AllowedMethod: allowedMethod,
            AllowedOrigin: rule.AllowedOrigin ? rule.AllowedOrigin._text : null,
          };
        });
      } else {
        let rule = xml.CORSConfiguration.CORSRule;
        let allowedMethod = [];
        if (rule.AllowedMethod) {
          if (Array.isArray(rule.AllowedMethod))
            allowedMethod = rule.AllowedMethod.map((method) => method._text);
          else allowedMethod = [rule.AllowedMethod._text];
        } else allowedMethod = [];

        this.CORSRules = [
          {
            order: 1,
            AllowedHeader: rule.AllowedHeader ? rule.AllowedHeader._text : null,
            AllowedMethod: allowedMethod,
            AllowedOrigin: rule.AllowedOrigin ? rule.AllowedOrigin._text : null,
          },
        ];
      }
    }
  },
};
</script>
<style lang="scss" scoped>
.v-window.v-item-group {
  background-color: transparent;
}
.v-input-append {
  ::v-deep .v-input__slot {
    background: #eee !important;
  }
}
</style>
