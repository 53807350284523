







































































































































import { defineComponent, ref, Ref, watch } from "@vue/composition-api";
// import { useMember } from "./useMember";
import { useType } from "./useType";
import dialogEditType from "./dialogEdit.vue";
import dialogAddType from "./dialogAdd.vue";
import dialogDeleteType from "./dialogDelete.vue";

// import dialogRecendInviteUser from "./dialogResendInviteUser.vue";

// import dialogProjectList from "./dialogProjectList.vue";
// import dialogDetailUser from "./dialogDetailUser.vue";

const {
  Parser,
  transforms: { unwind, flatten },
} = require("json2csv");
// import {
//   useNamespacedActions,
//   useNamespacedState,
// } from "vuex-composition-helpers";
//import { MANAGEFLAVOR } from "./namespace";

// import dialogActivateUser from './dialogActivateUser.vue'
export default defineComponent({
  components: {
    dialogEditType,
    dialogAddType,
    dialogDeleteType,
    // dialogProjectList,
    // dialogDetailUser,
    // dialogActivateUser,
    // dialogRecendInviteUser
  },
  setup() {
    const typeselectedvalue: Ref<any> = ref("");
    const Composable = { ...useType() };
    const { typessselected, ostype } = Composable;
    const headers = ref([
      {
        text: "No",
        value: "no",
      },
      {
        text: "Image",
        value: "os_logo",
      },
      {
        text: "Name",
        value: "name",
      },
      {
        text: "Description",
        value: "description",
      },
      // {
      //   text: "Type",
      //   value: "type_os",
      // },
      // {
      //   text: "Price",
      //   value: "price",
      // },
      {
        text: "Action",
        value: "action",
        width: 200,
      },
    ]);

    watch;
    //const opendialogactivateuser = ref(false)
    return {
      headers,
      opendialogdeletetype: ref(false),
      opendialogaddtype: ref(false),
      typeselectedvalue,
      opendialogedittype: ref(false),
      ...Composable,
      ostype,
      show: ($event: any, item: any) => {
        typeselectedvalue.value = item;
      },

      // Download,
    };
  },
  created() {
    this.fetchostype();
  },
});
