









































































































































































import { defineComponent, watch, ref, onMounted } from "@vue/composition-api";
import { useMember } from "../organization/usemember";
import {
  useNamespacedActions,
  useNamespacedState,
} from "vuex-composition-helpers";
import dialogEditOrganization from "../organization/editorganization.dialog.vue";
import memberlistcard from "../organization/memberlist.card.vue";
import { SUPERADMINMANAGEORG } from "../superadmin-manageorganization/namespace";
import { PROJECT } from "../project/namespace";
export default defineComponent({
  components: { dialogEditOrganization, memberlistcard },
  props: ["currentOrg"],
  setup(props: any) {
    const {
      fetchprojects,
      fetchorganization,
    } = useNamespacedActions(SUPERADMINMANAGEORG, [
      "fetchprojects",
      "fetchorganization",
    ]);
    const options = ref({});
    watch(
      options,
      (val) => {
        const op = { id: props.currentOrg.id, ...val };
        fetchprojects(op);
      },
      { deep: true }
    );
    onMounted(() => {
      fetchprojects({ id: props.currentOrg.id });
    });
    const {
      projects,
      projectspagination,
    } = useNamespacedState(SUPERADMINMANAGEORG, [
      "projects",
      "projectspagination",
    ]);
    const Composable = {
      ...useMember(),
    };
    const { fetchmembers } = Composable;
    const { changeorgstatus } = useNamespacedActions(SUPERADMINMANAGEORG, [
      "changeorgstatus",
    ]);
    const switchstatus = async (item: any) => {
      return await changeorgstatus(item);
    };
    const opendialogaddproj = ref(false);
    const { validatelimitcreateproject } = useNamespacedActions(PROJECT, [
      "validatelimitcreateproject",
    ]);
    return {
      validatelimitcreateproject,
      opendialogaddproj,
      switchstatus,
      options,
      fetchprojects,
      projectspagination,
      projects,
      fetchorganization,
      ...Composable,
    };
  },

  data() {
    return {
      openDoalogEditOrg: false,
      switch1: true,
      headers: [
        { text: "Name", value: "name" },
        { text: "Project", value: "project" },
        { text: "Role ", value: "role" },
        { text: "Status ", value: "status" },
        { text: "Action ", value: "action", align: "center" },
      ],
      headers1: [
        { text: "Project Name ", value: "projectname", sortable: false },
        { text: "Project Number ", value: "projectnumber", sortable: false },
        { text: "Description ", value: "description", sortable: false },
        { text: "Action ", value: "action", sortable: false, align: "center" },
      ],
    };
  },
});
