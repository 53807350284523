var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-dialog',{model:{value:(_vm.dialog),callback:function ($$v) {_vm.dialog=$$v},expression:"dialog"}},[_c('v-card',{staticClass:"pt-7"},[_c('v-card-text',[_c('v-data-table',{staticClass:"mt-8",attrs:{"hide-default-footer":"","headers":_vm.tblHeaders,"items-per-page":10,"items":_vm.item},scopedSlots:_vm._u([{key:"item.service_name",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.name || "N/A")+" ")]}},{key:"item.specification",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.specification)+" ")]}},{key:"item.price",fn:function(ref){
var item = ref.item;
return [_vm._v(" IDR "+_vm._s(item.price.value.toLocaleString("en"))+" "),(_vm.serviceName === 'SNAPSHOT')?_c('span',[_vm._v("/ GB")]):_c('span',[_vm._v("/ "+_vm._s(item.price.per))])]}},{key:"item.billed",fn:function(ref){
var item = ref.item;
return [_vm._v(" IDR "+_vm._s(item.billed.toLocaleString("en"))+" ")]}},{key:"item.type",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.status.toUpperCase())+" ")]}},(_vm.item.length)?{key:"footer",fn:function(ref){
var props = ref.props;
return [_c('custom-footer-datatable',{attrs:{"props":props}})]}}:null],null,true)})],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }