<template>
  <v-card color="white" flat>
    <v-card-text>
      <div class="d-flex justify-space-between align-center">
        <h6 class="text-h6 font-weight-bold">
          Daily Cost All Projects Estimated
        </h6>

        <div>
          <v-select
            v-model="projectName"
            :items="projectNames"
            label="Project"
            outlined
            :style="{ width: '200px' }"
          />
        </div>
      </div>

      <p v-if="loading" class="text-center">
        <beat-loader color="gray" size="10px" class="mr-2" />
      </p>

      <v-data-table
        v-else
        :hide-default-footer="true"
        :headers="tblHeaders"
        :items-per-page="10"
        :items="list"
        class="mt-8"
      >
        <!-- <template v-slot:item.billed="{ item }">
          <b> IDR {{ item.billed.toLocaleString("en-US") }} </b>
          /hours
        </template> -->

        <template v-slot:item.date="{ item }">
          {{ $moment(item.date).format("DD/MM/YYYY") }}
        </template>

        <template v-slot:item.project="{ item }">
          {{ item.project.name }}
        </template>

        <template v-slot:item.service_name="{ item }">
          {{ item.service_name }}
        </template>

        <template v-slot:item.status="{ item }">
          {{ item.status }}
        </template>

        <template v-slot:item.charge="{ item }">
          {{ item.charge ? "Yes" : "No" }}
        </template>

        <template v-slot:item.bill_estimate="{ item }">
          <b>IDR {{ item.bill_estimate.toLocaleString("en") }}</b>
        </template>

        <template v-slot:item.expanded="{ item }">
          <v-btn
            color="white primary--text"
            elevation="0"
            v-if="item.expanded"
            @click="
              () => {
                actionPopupOn = true;
                expanded = item.expanded;
                service_name = item.service_name;
              }
            "
          >
            Detail
          </v-btn>
        </template>

        <template v-slot:body.append>
          <tr style="border:none" v-if="list.length">
            <td class="border-bottom-none"></td>
            <td class="border-bottom-none"></td>
            <td class="border-bottom-none"></td>
            <td class="border-bottom-none"></td>
            <td class="border-bottom-none"></td>
            <td class="border-bottom-none">
              <div>
                <p class="font-weight-bold">Total</p>
              </div>
              <div class="d-flex flex-row justify-start">
                IDR {{ total.toLocaleString("en") }}
              </div>
            </td>
            <td class="border-bottom-none"></td>
          </tr>
        </template>

        <template v-slot:footer="{ props }" v-if="list.length">
          <custom-footer-datatable :props="props" />
        </template>
      </v-data-table>
    </v-card-text>

    <ActionPopup
      v-if="expanded"
      v-model="actionPopupOn"
      :item="expanded"
      :service-name="service_name"
    />
  </v-card>
</template>

<script>
import api from "@/lib/api";
import localstorage from "@/lib/localstorage";
import ActionPopup from "./daily-cost/ActionPopup.vue";

export default {
  components: {
    ActionPopup,
  },
  data() {
    return {
      actionPopupOn: false,
      loading: false,
      tblHeaders: [
        { text: "Date", value: "date" },
        { text: "Project", value: "project", sortable: false },
        { text: "Service", value: "service_name", sortable: false },
        { text: "Status", value: "status", sortable: false },
        { text: "Charge", value: "charge", sortable: false },
        { text: "Bill Estimate", value: "bill_estimate", sortable: false },
        { text: "Action", value: "expanded", sortable: false },
      ],
      list: [],
      total: 0,
      expanded: null,
      projectName: "",
      projectNames: [],
      projects: [],
    };
  },
  computed: {
    currentOrg() {
      const org = localstorage.getItem("currentOrg");

      if (!org) return null;

      return JSON.parse(org);
    },
    isSuperAdmin() {
      const role = localstorage.getItem("role");
      const isSuperAdmin = role && role === "Superadmin";

      return isSuperAdmin;
    },
  },
  methods: {
    async getDailyCost() {
      this.loading = true;

      if (this.currentOrg) {
        try {
          const project = this.projects.find(
            (proj) => proj.name === this.projectName
          );
          const projectId = project ? project.id : "all";
          const normalUserEndpoint = "/billing/daily-cost/" + projectId;
          const superAdminEndpoint = `/superadmin/billing/daily-cost/${this.currentOrg.id}/${projectId}`;
          const res = await api.GET(
            this.isSuperAdmin ? superAdminEndpoint : normalUserEndpoint
          );
          const { service, total } = res.data;
          this.list = service || [];
          this.total = total;
        } catch (err) {
          let errmsg = "Sorry, an error occurred while displaying data";
          const thereIsErrorMessage = err && err.response && err.response.data;

          if (thereIsErrorMessage && err.response.data.message) {
            errmsg = err.response.data.message;
          }

          if (
            thereIsErrorMessage &&
            err.response.data.data !== "record not found"
          ) {
            this.$store.dispatch("HOMEPAGE/showErrorToast", errmsg);
          }
        }
      } else {
        this.$store.dispatch(
          "HOMEPAGE/showErrorToast",
          "Sorry, organization id not found"
        );
      }

      this.loading = false;
    },
    async getProjects() {
      if (this.isSuperAdmin) {
        if (
          !this.currentOrg ||
          !this.currentOrg.projects ||
          !this.currentOrg.projects.length
        ) {
          this.projects = [];
        } else {
          this.projects = this.currentOrg.projects;
          this.projectNames = [
            "all",
            ...this.currentOrg.projects
              .filter((proj) => proj.postpaid_type !== "fixed")
              .map((proj) => proj.name),
          ];
        }
      } else {
        try {
          this.error = false;
          const res = await api.GET("/user/organization/projects");
          this.projects = res.data;
          this.projectNames = [
            "all",
            ...res.data
              .filter((proj) => proj.postpaid_type !== "fixed")
              .map((proj) => proj.name),
          ];
        } catch {
          this.$store.dispatch(
            "HOMEPAGE/showErrorToast",
            "Sorry, an error occurred while displaying projects"
          );
        }
      }
    },
  },
  mounted() {
    this.getDailyCost();
    this.getProjects();
  },
  watch: {
    projectName(newVal) {
      this.getDailyCost();
    },
  },
};
</script>
