































































































import { defineComponent, ref, reactive, computed } from "@vue/composition-api";
import { EMATERAI } from "./namespace";
import useVuelidate from "@vuelidate/core";
import { required } from "@vuelidate/validators";
import {
  useNamespacedActions,
  useNamespacedState,
} from "vuex-composition-helpers";
import moment from "moment";

export default defineComponent({
  setup(props, { emit }) {
    const { createStamp, fetchStamps } = useNamespacedActions(EMATERAI, [
      "createStamp",
      "fetchStamps",
    ]);
    const { isLoading } = useNamespacedState(EMATERAI, ["isLoading"]);
    const no_materai = ref("");
    const expire_date = ref("");
    const formated_expire_date = computed(() => {
      if (expire_date.value) {
        return moment(expire_date.value, "YYYY-MM-DD").format("DD/MM/YYYY");
      } else {
        return "";
      }
    });
    const $v = useVuelidate(
      {
        no_materai: { required },
        formated_expire_date: { required },
      },
      { no_materai, formated_expire_date }
    );
    const modal = ref(true);
    const toggleDatePicker = ref(false);
    const isValid = ref(false);
    const {
      showErrorToast,
      showSuccessToast,
    } = useNamespacedActions("HOMEPAGE", [
      "showErrorToast",
      "showSuccessToast",
    ]);
    const submit = () => {
      $v.value.$touch();
      if ($v.value.$errors.length) {
        return;
      }
      const data = {
        stamp_code: no_materai.value,
        expired_at: moment(expire_date.value).format(),
      };
      createStamp(data)
        .then(() => {
          fetchStamps();
          emit("close");
        })
        .catch((err: any) => {
          console.log("error-code", err.response.data.code);
          if (err.response.data.code == 400) {
            showErrorToast(err.response.data.data);
          }
        });
    };
    return {
      $v,
      no_materai,
      formated_expire_date,
      expire_date,
      toggleDatePicker,
      modal,
      isValid,
      submit,
      isLoading,
      createStamp,
    };
  },
});
