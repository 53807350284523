<template>
    <v-container fluid>
        <v-row>
            <v-col class="pa-0">
                <v-card flat class="rounded-lg pa-3">
                    <v-card-text>
                        <div class="headline font-weight-bold font--text">Public Network</div>
                        </v-card-text>
                    <v-card-text>
                        <p>Anybody can access the VM via these public addresses</p>
                        <v-row>
                            <v-col>
                                <p>
                                    <b>FLOATING IP</b>
                                </p>
                                <span v-if="instanceDetail.floating_ip">{{ instanceDetail.floating_ip ? instanceDetail.floating_ip.ip_address : null  }}</span>
                                <span
                                    v-else
                                    tag="button"
                                    @click="openFloatingIPDialog = true"
                                    class="font-weight-bold"
                                    :disabled="!['ACTIVE', 'SHUTOFF'].includes(instanceDetail.state) || ![null, ''].includes(instanceDetail.revert_expired)"
                                    :class="{ 'linkpointer': ['ACTIVE', 'SHUTOFF'].includes(instanceDetail.state) || ![null, ''].includes(instanceDetail.revert_expired) }"
                                >Enable Now</span>
                            </v-col>
                            <v-col></v-col>
                            <v-col></v-col>
                        </v-row>
                    </v-card-text>
                </v-card>
            </v-col>
            <v-col cols=12 class="px-0 py-5">
                <v-card flat class="rounded-lg pa-3">
                    <v-card-text>
                        <div class="headline font-weight-bold font--text">Private Network</div>
                        </v-card-text>
                    <v-card-text class="pt-0">
                        <v-row>
                            <v-col>
                                <p>
                                    <b>PRIVATE IPV4 ADDRESS</b>
                                </p>
                                <p>
                                    {{ instanceDetail.ip_address_internal || '-' }}
                                    <!-- <span class="linkpointer">Copy</span> -->
                                </p>
                            </v-col>
                            <v-col>
                                <p>
                                    <b>VPC GATEWAY</b>
                                </p>
                                <p>
                                    {{ instanceDetail.vpc ? instanceDetail.vpc.ip_gateway : '-' }}
                                    <!-- <span class="linkpointer">Copy</span> -->
                                </p>
                            </v-col>
                            <v-col>
                                <p>
                                    <b>SUBNET MASK</b>
                                </p>
                                <p>
                                    {{ instanceDetail.vpc ? netmaskPrivate.mask : '-' }} 
                                    <!-- <span class="linkpointer">Copy</span> -->
                                </p>
                                </v-col>
                            <v-col>
                            </v-col>                        
                        </v-row>
                    </v-card-text>
                    
                </v-card>
            </v-col>
        </v-row>
        <dialogFloatingIP v-if="openFloatingIPDialog" v-model="openFloatingIPDialog" :item="instanceDetail" />
    </v-container>
</template>
<script>
import { ref, reactive, onMounted } from "@vue/composition-api";
import {
  useNamespacedState,
  useNamespacedActions,
} from "vuex-composition-helpers";
import Netmask from 'netmask'
import dialogFloatingIP from "@/modules/instance/dialogFloatingIP";

export default {
    components: {
        dialogFloatingIP
    },
    setup(props, context){
        const { instanceDetail } = useNamespacedState('INSTANCE', ['instanceDetail'])
        const netmaskPrivate = new Netmask.Netmask(`${instanceDetail.value.vpc.ip_prefix}/${instanceDetail.value.vpc.network_size}`)
        return {
            instanceDetail,
            netmaskPrivate,
        }
    },
    data() {
        return {
            openFloatingIPDialog: false,
        }
    }
}
</script>
<style scoped>
.headline{
  font-weight:600;
  font-family: 'Poppins', sans-serif;
}
</style>