<template>
  <v-card-text class="px-8">
    <!-- table 1 -->
    <v-data-table
      :hide-default-footer="true"
      :headers="tableHeaders"
      :items-per-page="5"
      :items="tableItems"
      class="elevation-0"
    >
      <template v-slot:item.price="{ item }">
        Rp {{ item.price.toLocaleString("en-US") }}
      </template>

      <!-- edit btn -->
      <template v-slot:item.action="{ item }">
        <div>
          <label
            style="cursor: pointer"
            @click="
              () => {
                selectedSnapshot = item;
                openDialogEditSnapshot = true;
              }
            "
          >
            <span class="primary--text">Edit</span>
          </label>
        </div>
      </template>
    </v-data-table>

    <v-divider />

    <!-- edit popup -->
    <dialogEditSnapshot
      v-if="selectedSnapshot && openDialogEditSnapshot"
      :item="selectedSnapshot"
      v-model="openDialogEditSnapshot"
      @close="openDialogEditSnapshot = false"
      @updateDefaultSnapshot="updateDefaultSnapshot($event)"
    />
  </v-card-text>
</template>

<script>
import api from "@/lib/api";
import dialogEditSnapshot from "./dialogEditSnapshot.vue";

export default {
  components: {
    dialogEditSnapshot,
  },
  data() {
    return {
      // table 1
      tableHeaders: [
        { text: "Default", value: "default" },
        { text: "Price", value: "price", sortable: true },
        { text: "Action", value: "action", align: "center" },
      ],
      tableItems: [],
      selectedSnapshot: null,
      openDialogEditSnapshot: false,
    };
  },
  methods: {
    async getTableItems() {
      try {
        const res = await api.GET("/flavor/snapshot/default");
        this.tableItems = [
          {
            default: res.data.total + " GB",
            price: res.data.price_per_month,
            ...res.data,
          },
        ];
      } catch {
        this.$store.dispatch(
          "HOMEPAGE/showErrorToast",
          "Sorry, an error occurred while displaying Default Snapshot datas"
        );
      }
    },
    updateDefaultSnapshot(pricePerMonth) {
      const item = {
        ...this.tableItems[0],
        price: pricePerMonth,
        price_per_month: pricePerMonth,
      };

      this.tableItems = [item];

      this.selectedSnapshot = item;
    },
  },
  mounted() {
    this.getTableItems();
  },
};
</script>
