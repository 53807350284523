<template>
  <div>
    <div
      :style="{
        marginTop: '8px',
        padding: '10px',
        backgroundColor: '#1f60a8',
        color: 'white',
      }"
    >
      <b>{{ title }}</b>
    </div>

    <v-row :style="{ borderBottom: '1px solid black' }">
      <v-col>
        <b>Service Name</b>
      </v-col>
      <v-col>
        <b>Billing Type</b>
      </v-col>
      <v-col>
        <b>Specification</b>
      </v-col>
      <v-col v-if="!isPostpaidFixed">
        <b>Price</b>
      </v-col>
      <v-col>
        <b>Usage</b>
      </v-col>
      <v-col v-if="!isPostpaidFixed">
        <b>Billed</b>
      </v-col>
    </v-row>

    <v-row
      v-for="(l, index) in list"
      :key="'l-' + l.service_name + index"
      class="row-bb"
    >
      <v-col>
        <b>{{ l.name || "-" }}</b>
      </v-col>
      <v-col>
        <b>{{ l.type || "-" }}</b>
      </v-col>
      <v-col>
        <b>{{ l.specification || "-" }}</b>
      </v-col>
      <v-col v-if="!isPostpaidFixed">
        <b v-if="l.price && l.price.value">
          IDR {{ l.price.value.toLocaleString("en") }}
          /
          {{ l.price.per }}
        </b>

        <b v-else>-</b>
      </v-col>
      <v-col>
        <b>{{ l.usage || "-" }}</b>
      </v-col>
      <v-col v-if="!isPostpaidFixed">
        <b>IDR {{ l.billed ? l.billed.toLocaleString("en") : "-" }}</b>
      </v-col>
    </v-row>
  </div>
</template>

<script>
export default {
  props: ["title", "list", "isPostpaidFixed"],
};
</script>

<style scoped>
.row-bb {
  border-bottom: 1px solid #e0e0e0;
  color: #333333;
}
</style>
