import { ActionContext, Module } from 'vuex'
import { Project } from './type'
import axios from '@/lib/axios'
import localstorage from '@/lib/localstorage'
interface State {
    createModal: boolean,
    selectModal: boolean,
    projects: Array<Project>,
    opendialogproject: boolean,
    projectname: string,
    projectid: string,
    opendialogselectfirst: boolean
}
const store: Module<State, any> = {
    namespaced: true,
    state: {
        createModal: false,
        selectModal: false,
        projects: [],
        opendialogproject: false,
        projectname: '',
        projectid: '',
        opendialogselectfirst: false

    },
    getters: {
        getprojectname: (state: any) => {
            console.log('ya');
            const projectname = localstorage.getItem('projectname')
            console.log(projectname);
            state.projectname = projectname
            return state.projectname
        },
        getprojectid: (state: any) => {
            const projectid = localstorage.getItem('projectid')
            state.projectid = projectid
            return state.projectid
        },
        showCreateModal: (state) => {
            state.createModal
        },
        showSelectModal: (state) => {
            state.selectModal
        },
        getprojects: (state) => state.projects,
    },
    mutations: {
        setprojectname: (state, data) => {
            localstorage.setItem('projectname', data)
            state.projectname = data

        },
        setprojectid: (state, data) => {
            localstorage.setItem('projectid', data)
            state.projectid = data
        },
        setcreatemodal: (state, data) => {
            state.createModal = data
        },

        setselectmodal: (state, data) => {
            state.selectModal = data
        },
        setprojects(state, projects) {
            state.projects = projects
        },
        setopendialogselectfirst(state, payload) { state.opendialogselectfirst = payload }
    },
    actions: {
        changeProjectTo: async ({ commit }, project_id) => {
            commit('setprojectid', project_id)
        },
        opendialogselectproject: async (context: ActionContext<State, any>) => {
            context.commit('setselectmodal', true)
        },
        addproject: async (context: ActionContext<State, any>, project) => {
            const user_role = context.rootState.ROLEPERMISSION.userrole
            if (user_role == 'Superadmin') {
                return await axios.instance.post('/superadmin/projects',project)
            }
            return await axios.instance.post('/user/projects', project)
        },
        openSelectModal({ commit, state }, data) {
            commit('SET_SELECT_MODAL', state.selectModal = data)
        },
        fetchprojects: async (context, payload) => {
            const user_role = context.rootState.ROLEPERMISSION.userrole

            if (user_role == 'Superadmin') {
                const currentOrg = context.rootState.SUPERADMIN.currentOrg
                if(!currentOrg) return
                return await axios.instance.get(`superadmin/manageorg/${currentOrg.id}/projects`).then(response => {
                    const data = response.data.data
                    context.commit('setprojects', data.projects)
                    return data
                }).catch((e: any) => {
                    context.dispatch('HOMEPAGE/showErrorToast', e.message, { root: true })
                })
            }
            return await axios.instance.get('/user/organization/projects').then(response => {
                const data = response.data.data
                context.commit('setprojects', data)
                return data
            }).catch((e: any) => {
                context.dispatch('HOMEPAGE/showErrorToast', e.message, { root: true })
            })
        },
        editproject: async (context, payload) => {
            return await axios.instance.put(`/user/projects/${payload.id}`, payload)
        },
        deleteproject: async (context, payload) => {
            return axios.instance.delete(`/user/projects/${payload.id}`)
        },
        checkproject: async (context, payload) => {
            return axios.instance.delete(`/user/checking/projects/${payload.id}`)
        },
        validatelimitcreateproject: async (context, payload) => {
            const role = localStorage.getItem('role')
            if (role == 'Superadmin') {
                const currentOrg = context.rootState.SUPERADMIN.currentOrg
                return await axios.instance.get(`/superadmin/validate/project/${currentOrg.id}`).then(response => {
                    const data = response.data.data
                    if (data && data.can_create == true) {
                        return Promise.resolve(true)
                    } else {
                        context.commit('DASHBOARD/setopendialogreachlimit', true, { root: true })
                        return Promise.reject('')
                    }
                })
            }
            return await axios.instance.get('/user/validate/project').then(response => {
                const { can_create } = response.data.data
                if (can_create) {
                    return Promise.resolve('')
                } else {
                    context.commit('DASHBOARD/setopendialogreachlimit', true, { root: true })
                    return Promise.reject('')
                }
            })
        }
    }
}
export default store