<template>
  <v-card color="white" flat>
    <v-card-text>
      <v-tabs @change="onTabChange">
        <v-tab>Invoice Prepaid</v-tab>
        <v-tab>Invoice Postpaid</v-tab>
      </v-tabs>
    </v-card-text>

    <InvoicePrepaidTbl v-if="tab < 1" />
    <InvoicePostpaidTbl v-else />
  </v-card>
</template>

<script>
import DownloadInvoice from "./history-billing-invoice/DownloadInvoice.vue";
import InvoicePrepaidTbl from "./history-billing-invoice/InvoicePrepaidTbl.vue";
import InvoicePostpaidTbl from "./history-billing-invoice/InvoicePostpaidTbl.vue";

export default {
  components: {
    InvoicePrepaidTbl,
    InvoicePostpaidTbl,
  },
  data() {
    return {
      tab: 0,
    };
  },
  methods: {
    onTabChange(val) {
      this.tab = val;
    },
  },
};
</script>
