var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-card',{staticClass:"rounded-lg pa-0",attrs:{"flat":""}},[_c('v-card-text',{staticClass:"pa-7"},[_c('v-row',[_c('v-col',{staticClass:"d-flex flex-row align-start"},[_c('div',{staticClass:"d-flex align-center"},[_c('v-icon',{staticClass:"mr-4",staticStyle:{"font-size":"55px"},attrs:{"color":"#F1F2F2"}},[_vm._v("mdi-circle")])],1),_c('div',{staticClass:"mr-8 pr-8"},[_c('div',{staticClass:"headline font-weight-bold"},[_vm._v(_vm._s(_vm.currentOrg.name))]),(_vm.currentOrg.service_type && _vm.currentOrg.service_type !== 'undefined')?_c('p',{style:({marginTop: '8px', display: 'flex', alignItems: 'center', color: _vm.currentOrg.service_type === 'postpaid' ? '#27AE60' : '#A5B3BF'})},[_c('img',{style:({marginRight: '4px'}),attrs:{"src":_vm.currentOrg.service_type === 'postpaid' ? '/postpaid.png' : '/prepaid.png',"alt":"postpaid-logo"}}),_vm._v(" "+_vm._s(_vm.uppercaseFirstLetter(_vm.currentOrg.service_type))+" ")]):_vm._e(),_c('div',{staticClass:"mt-2 d-flex flex-row align-center"},[(_vm.currentOrg.OrgType == 0)?_c('v-icon',{staticStyle:{"width":"18px","height":"18px"}},[_vm._v("$vuetify.icons.diamondIcon")]):_vm._e(),(_vm.currentOrg.OrgType == 1)?_c('v-icon',{staticStyle:{"width":"18px","height":"18px"}},[_vm._v("$vuetify.icons.golddiamondIcon")]):_vm._e(),_c('div',{staticClass:"fz-14 ml-2 accent--text"},[_vm._v(" "+_vm._s(["Basic", "Premium"][_vm.currentOrg.OrgType])+" ")])],1),_c('div',{staticClass:"mt-2"},[_c('p',{staticClass:"fz-14"},[_vm._v(_vm._s(_vm.currentOrg.details))]),_c('p',{staticClass:"fz-14"},[_c('b',[_vm._v("Sales:")]),_vm._v(" "+_vm._s(_vm.currentOrg.sales && _vm.currentOrg.sales.fullname ? _vm.currentOrg.sales.fullname : '-')+" ")])])]),_c('v-spacer'),_c('v-btn',{staticClass:"secondary",attrs:{"height":"50","width":"150","depressed":""},on:{"click":function () {
                _vm.openDoalogEditOrg = true;
              }}},[_c('span',{staticStyle:{"font-size":"12px"}},[_vm._v("Edit Organization")])])],1)],1)],1),_c('v-divider'),_c('v-card-text',{staticClass:"pa-7"},[_c('v-row',[_c('v-col',[_c('p',{staticClass:"mb-0"},[_c('b',[_vm._v("Address:")]),_c('br'),_vm._v(" "+_vm._s(_vm.currentOrg.address)+" ")])]),_c('v-col',[_c('p',{staticClass:"mb-0"},[_c('b',[_vm._v("NPWP Number:")]),_c('br'),_vm._v(" "+_vm._s(_vm.currentOrg.npwp_corporate)+" ")])]),_c('v-col',[_c('p',{staticClass:"mb-0"},[_c('b',[_vm._v("Phone:")])]),_vm._v(" "+_vm._s(_vm.currentOrg.phone_number_org)+" ")])],1)],1)],1),_c('div',{staticStyle:{"height":"30px","width":"auto"}}),(_vm.currentOrg)?_c('memberlistcard',{attrs:{"title":"Member List","organization":_vm.currentOrg,"fontsize":12}}):_vm._e(),_c('div',{staticStyle:{"height":"30px","width":"auto"}}),_c('v-card',{staticClass:"pa-0 rounded-lg",attrs:{"flat":""}},[_c('ApprovalNotification'),_c('v-card-text',{staticClass:"pa-7 d-flex flex-row align-center"},[_c('div',{staticClass:"headline font-weight-bold"},[_vm._v("Project List")]),_c('v-spacer'),(_vm.isDevModePostpaid)?_c('v-btn',{staticClass:"secondary",attrs:{"height":"50","width":"150","depressed":"","to":this.$route.path + '/project/add'}},[_c('span',{staticStyle:{"font-size":"12px"}},[_vm._v("New Project")])]):_c('v-btn',{staticClass:"secondary",attrs:{"height":"50","width":"150","depressed":""},on:{"click":function () {
            _vm.validatelimitcreateproject().then(function () {
              if (_vm.isDevModePostpaid) {
                _vm.toAddProject();
              } else {
                _vm.opendialogaddproj = true;
              }
            });
          }}},[_c('span',{staticStyle:{"font-size":"12px"}},[_vm._v("New Project")])]),(!_vm.isDevModePostpaid)?_c('div',{staticStyle:{"width":"30px","height":"auto"}}):_vm._e(),(!_vm.isDevModePostpaid)?_c('v-btn',{staticClass:"secondary--text",staticStyle:{"border":"2px solid #2c94d2","background-color":"white"},attrs:{"height":"50","width":"150","depressed":""}},[_c('span',{staticStyle:{"font-size":"12px"}},[_vm._v("Download")])]):_vm._e()],1),_c('v-card-text',{staticClass:"pa-7"},[_c('v-data-table',{attrs:{"hide-default-footer":"","headers":_vm.headers1,"server-items-length":_vm.projectspagination.count,"options":_vm.options,"items-per-page":10,"items":_vm.projects,"show-select":""},on:{"update:options":function($event){_vm.options=$event}},scopedSlots:_vm._u([{key:"item.projectname",fn:function(ref){
          var item = ref.item;
return [_c('div',{staticClass:"linkpointer",on:{"click":function (){
            _vm.setCurrentProj(item)
            _vm.$router.push(_vm.$route.path +'/project/' + item.name )}}},[_vm._v(_vm._s(item.name))])]}},{key:"item.projectnumber",fn:function(ref){
            var item = ref.item;
return [_vm._v(" "+_vm._s(item.id)+" ")]}},{key:"item.description",fn:function(ref){
            var item = ref.item;
return [_vm._v(" "+_vm._s(item.description)+" ")]}},{key:"item.created_at",fn:function(ref){
            var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.$FormatDate(item.created_at))+" ")]}},{key:"item.action",fn:function(ref){
            var item = ref.item;
return [(_vm.isDevModePostpaid)?_c('v-menu',{attrs:{"offset-y":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
            var on = ref.on;
            var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"primary--text",attrs:{"color":"white","dark":"","elevation":"0"}},'v-btn',attrs,false),on),[_vm._v(" More "),_c('v-icon',{attrs:{"color":"primary"}},[_vm._v(" mdi-chevron-down ")])],1)]}}],null,true)},[_c('v-list',[_c('v-list-item',[_c('v-list-item-title',[_c('v-btn',{staticClass:"primary--text",attrs:{"color":"white","elevation":"0"},on:{"click":function () {
                        _vm.setCurrentProj(item);
                        _vm.$router.push(_vm.$route.path + '/project/' + item.id + '/detail');
                      }}},[_vm._v(" Detail ")])],1)],1),_c('v-list-item',[_c('v-list-item-title',[_c('v-btn',{staticClass:"primary--text",attrs:{"color":"white","elevation":"0"},on:{"click":function () {
                        _vm.setCurrentProj(item);
                        _vm.$router.push(_vm.$route.path + '/project/' + item.id + '/edit');
                      }}},[_vm._v(" Edit ")])],1)],1),_c('v-list-item',[_c('v-list-item-title',[_c('v-btn',{staticClass:"error--text",attrs:{"color":"white","elevation":"0"},on:{"click":function () {
                        _vm.selectedProj = item;
                        _vm.hapusProject();
                      }}},[_vm._v(" Delete ")])],1)],1)],1)],1):_c('span',{staticClass:"error--text",staticStyle:{"cursor":"pointer"},on:{"click":function () {
                _vm.selectedProj = item;
                _vm.hapusProject();
                //opendeleteproject = true;
              }}},[_vm._v("Delete")])]}},{key:"footer",fn:function(ref){
              var props = ref.props;
return [_c('custom-footer-datatable',{attrs:{"props":props},on:{"input":function (val) {
                _vm.options = val;
              }}})]}}])})],1)],1),(_vm.openDoalogEditOrg)?_c('dialogEditOrganization',{attrs:{"organization":_vm.currentOrg},on:{"updated":function () {
        _vm.fetchorganization(_vm.currentOrg);
      }},model:{value:(_vm.openDoalogEditOrg),callback:function ($$v) {_vm.openDoalogEditOrg=$$v},expression:"openDoalogEditOrg"}}):_vm._e(),_c('dialogAddProj',{on:{"added":function () {
        _vm.fetchprojects(_vm.options);
      }},model:{value:(_vm.opendialogaddproj),callback:function ($$v) {_vm.opendialogaddproj=$$v},expression:"opendialogaddproj"}}),(_vm.opendeleteproject)?_c('dialogDeleteProj',{attrs:{"selectedproject":_vm.selectedProj},on:{"close":function () {
        _vm.opendeleteproject = false;
      },"deleteproject":function (){
      _vm.opendialogareyousure = true
    }},model:{value:(_vm.opendeleteproject),callback:function ($$v) {_vm.opendeleteproject=$$v},expression:"opendeleteproject"}}):_vm._e(),(_vm.opendialogareyousure)?_c('dialogAreYouSure',{attrs:{"project":_vm.selectedProj},on:{"deleted":function (){
    _vm.fetchprojects(_vm.options);
  },"error":function (response) {
        _vm.opendeleteproject = false;
        _vm.dialogerrormsg = response.data.data;
        _vm.opendialogerror = true;
      }},model:{value:(_vm.opendialogareyousure),callback:function ($$v) {_vm.opendialogareyousure=$$v},expression:"opendialogareyousure"}}):_vm._e(),_c('dialogErrorDelete',{attrs:{"message":_vm.dialogerrormsg},model:{value:(_vm.opendialogerror),callback:function ($$v) {_vm.opendialogerror=$$v},expression:"opendialogerror"}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }