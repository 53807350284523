









































































import Vue from "vue";
import { useDialog } from "@/composable/usedialog";
import {
  useNamespacedActions,
  useNamespacedMutations,
  useNamespacedState,
} from "vuex-composition-helpers";
import { SUPERADMINMANAGEOS } from "./namespace";
import { ref } from "@vue/composition-api";
const module = Vue.extend({
  props: ["value", "item"],
  setup(props: any, context) {
    const { dialog } = useDialog(props, context);
    const { deleteVersion } = useNamespacedActions(SUPERADMINMANAGEOS, [
      "deleteVersion",
    ]);
    const { setopendialogcommonsuccess } = useNamespacedMutations("DASHBOARD", [
      "setopendialogcommonsuccess",
    ]);

    const { opendialogdonthavepermission } = useNamespacedMutations(
      "DASHBOARD",["opendialogdonthavepermission"]
    );

    const {
      setopendialogcommonwarning,
      setmessagecommonwarning
    } = useNamespacedMutations("DASHBOARD", [
      "setopendialogcommonwarning",
      "setmessagecommonwarning"
    ]);


    const dialogerror = ref(false);

    const Destroy = async () => {
      deleteVersion(props.item).then(() => {
        context.emit("deleted");
        dialog.value = false;
        setopendialogcommonsuccess(true);
      }).catch(() => {
        dialogerror.value = false;
        setopendialogcommonwarning(true)
        setmessagecommonwarning('Cannot delete this type because still used by some organization')
      });
    };
    return {
      dialog,
      Destroy,
      dialogerror,
    };
  },
});
export default module;
