




















































import { defineComponent } from "@vue/composition-api";
import { EMATERAI } from "./namespace";
import { useNamespacedState } from "vuex-composition-helpers";

const module = defineComponent({
  props: {
    value: {
      type: Boolean,
      default: false,
    },
    item: {
      type: Object,
      required: true,
      default: () => {},
    },
  },
  setup() {
    const { isLoading } = useNamespacedState(EMATERAI, ["isLoading"]);
    return {
      isLoading,
    };
  },
});
export default module;
