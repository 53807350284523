var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-app',[_c('LandingPageAppBar',{directives:[{name:"show",rawName:"v-show",value:(_vm.isEmpty(_vm.user) || _vm.$route.path.includes('/otp')),expression:"isEmpty(user) || $route.path.includes('/otp')"}],attrs:{"pagesMenu":_vm.pagesMenu,"components":_vm.appBarButton}}),_c('router-view'),_c('v-snackbar',{staticClass:"text-center",attrs:{"timeout":5000,"color":_vm.toastMode},scopedSlots:_vm._u([{key:"action",fn:function(ref){
var attrs = ref.attrs;
return [_c('v-btn',_vm._b({attrs:{"color":"white","text":""},on:{"click":function($event){_vm.snackbar = false}}},'v-btn',attrs,false),[_vm._v(" Close ")])]}}]),model:{value:(_vm.snackbar),callback:function ($$v) {_vm.snackbar=$$v},expression:"snackbar"}},[_c('span',{staticClass:"fz-14"},[_vm._v(_vm._s(_vm.toastmessage))])]),_c('v-snackbar',{staticClass:"text-center",attrs:{"color":_vm.toastWSMode == 'success'
        ? 'green'
        : _vm.toastWSMode == 'error'
        ? 'red'
        : 'dark',"bottom":"","right":"","timeout":"-1"},scopedSlots:_vm._u([{key:"action",fn:function(ref){
        var attrs = ref.attrs;
return [_c('v-btn',_vm._b({attrs:{"color":"white","text":""},on:{"click":function($event){_vm.snackbarWS = false}}},'v-btn',attrs,false),[_vm._v(" X ")])]}}]),model:{value:(_vm.snackbarWS),callback:function ($$v) {_vm.snackbarWS=$$v},expression:"snackbarWS"}},[_c('div',{staticStyle:{"display":"flex","align-items":"center"}},[(_vm.toastWSMode === 'success')?_c('v-icon',{staticClass:"mr-2 mt-1"},[_vm._v("mdi-check-circle-outline")]):_vm._e(),(_vm.toastWSMode === 'error')?_c('v-icon',{staticClass:"mr-2 mt-1"},[_vm._v("mdi-close-circle-outline")]):_vm._e(),(_vm.toastWSMode === 'process')?_c('beat-loader',{staticClass:"mr-2",staticStyle:{"transform":"translateY(3px)"},attrs:{"loading":_vm.toastWS,"color":'white',"size":'10px'}}):_vm._e(),_c('span',{staticClass:"fz-14"},[_vm._v(_vm._s(_vm.toastWSMessage))])],1)])],1)}
var staticRenderFns = []

export { render, staticRenderFns }