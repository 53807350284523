












































































































































import { useDialog } from "@/composable/usedialog";
import { onMounted, ref, watch } from "@vue/composition-api";
import useVuelidate from "@vuelidate/core";
import { useNamespacedMutations } from "vuex-composition-helpers";
import { required, url } from "@vuelidate/validators";
import { version } from "moment";
import Vue from "vue";
import {
  useNamespacedActions,
  useNamespacedState,
} from "vuex-composition-helpers";

import { SUPERADMINMANAGEOS } from "./namespace";
import { SelectOs, BitVersion } from "./type";

export default Vue.extend({
  props: ["value", "versionedit"],
  setup(props, context) {
    const { dialog } = useDialog(props, context);
    const loadingselectos = ref(false);
    const loadingselectbit = ref(false);
    const {
      fetchos,
      fetchbitversion,
      editVersion,
    } = useNamespacedActions(SUPERADMINMANAGEOS, [
      "fetchos",
      "fetchbitversion",
      "editVersion",
    ]);

    onMounted(() => {
      fetchos();
      fetchbitversion();
    });

    const Fetchos = async () => {
      try {
        loadingselectos.value = true;
        await fetchos();
      } finally {
        loadingselectos.value = false;
      }
    };

    const Fetchbit = async () => {
      try {
        loadingselectbit.value = true;
        await fetchbitversion();
      } finally {
        loadingselectbit.value = false;
      }
    };

    const { selectos, selectbit } = useNamespacedState(SUPERADMINMANAGEOS, [
      "selectos",
      "selectbit",
    ]);
    const os_id = ref();
    const version_number = ref();
    const filesystem = ref();
    const versions_id = ref();
    const image = ref();

    if (props.versionedit) {
      const versionedit = props.versionedit as any;
      os_id.value = versionedit.os;
      version_number.value = versionedit.version_number;
      versions_id.value = versionedit.versions_id;
      image.value = versionedit.image;
    }
    const imageosalreadyexist = ref("");

    const $v = useVuelidate(
      {
        os_id: {
          required,
          conditional: {
            $message: "image os already exist",
            $validator: (val: any): boolean => {
              return imageosalreadyexist.value == ""
            },
          },
        },
        version_number: {
          required,
          conditional: {
            $message: "image os already exist",
            $validator: (val: any): boolean => {
              return imageosalreadyexist.value == ""
            },
          },
        },
        versions_id: {
          required,
          conditional: {
            $message: "image os already exist",
            $validator: (val: any): boolean => {
              return imageosalreadyexist.value == ""
            },
          },
        },
        image: { required, url },
      },
      {
        os_id,
        version_number,
        filesystem,
        versions_id,
        image,
      }
    );
    watch(os_id, (val: string) => {
      imageosalreadyexist.value = ""
      $v.value.$reset();
    });
    watch(version_number, (val: string) => {
      imageosalreadyexist.value = ""
      $v.value.$reset();
    });
    watch(versions_id, (val: string) => {
      imageosalreadyexist.value = ""
      $v.value.$reset();
    });
    const clickuploadimage = () => {
      document?.getElementById("uploadimage")?.click();
    };

    const { addNewVersion } = useNamespacedActions(SUPERADMINMANAGEOS, [
      "addNewVersion",
    ]);

    const resetform = async () => {
      os_id.value = "";
      version_number.value = "";
      versions_id.value = "";
      image.value = "";
      $v.value.$reset();
      dialog.value = false;
    };

    const { setopendialogcommonsuccess } = useNamespacedMutations("DASHBOARD", [
      "setopendialogcommonsuccess",
    ]);

    const CreateVersion = () => {
      $v.value.$touch();
      console.log($v.value.$errors)
      if ($v.value.$errors.length) return;
      const Value = {
        os_id: os_id.value.id,
        version_number: version_number.value,
        versions_id: versions_id.value.id,
        image: image.value,
        name: os_id.value.name,
        tags: [
          os_id.value.name,
          version_number.value,
          versions_id.value.version_name,
        ],
      };
      addNewVersion(Value)
        .then(() =>
          resetform().then(() => {
            context.emit("added");
            setopendialogcommonsuccess(true);
          })
        )
        .catch((e: any) => {
          if (e.response.data.data == "image os already exist") {
            imageosalreadyexist.value = e.response.data.data;
          }
        });
    };

    const Save = async () => {
      $v.value.$touch();
      if ($v.value.$errors.length) return;
      const versionedit = props.versionedit as any;
      const Value = {
        id: versionedit.id,
        os: os_id.value.id,
        version_number: version_number.value,
        versions_id: versions_id.value.id,
        image: image.value,
        name: os_id.value.name,
        tags: [
          os_id.value.name,
          version_number.value,
          versions_id.value.version_name,
        ],
      };
      await editVersion(Value).then((e: any) => {
        context.emit("updated");
        dialog.value = false;
      });
    };

    return {
      imageosalreadyexist,
      Fetchos,
      Fetchbit,
      loadingselectbit,
      loadingselectos,
      Save,
      clickuploadimage,
      dialog,
      selectos,
      selectbit,
      os_id,
      version_number,
      filesystem,
      versions_id,
      image,
      $v,
      CreateVersion,
    };
  },
});
