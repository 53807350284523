























































































































































import { defineComponent, reactive, ref } from '@vue/composition-api';
import {
  useNamespacedActions,
  useNamespacedState,
} from 'vuex-composition-helpers';
import { SECURITY } from './namespace';
import dialogDeleteSecurity from './dialogDeleteSecurity.vue';
export default defineComponent({
  components: { dialogDeleteSecurity },
  setup() {
    const table = reactive({
      headers: [
        { text: 'Name', value: 'name' },
        { text: 'Applied to', value: 'apply' },
        { text: 'Rules', value: 'rule' },
        { text: 'Action', sortable: false, align: 'center', value: 'action' },
      ],
    });
    const {validateprivilages, validateprivilagesync,validateprivilagesyncnew} = useNamespacedActions("HOMEPAGE", [
      "validateprivilages", "validateprivilagesync", "validateprivilagesyncnew"
    ]);
    const { fetchsecurity } = useNamespacedActions(SECURITY, ['fetchsecurity']);
    const { securities } = useNamespacedState(SECURITY, ['securities']);
    return {
      validateprivilages, validateprivilagesync,validateprivilagesyncnew,
      table,
      fetchsecurity,
      securities,
      opendialogdelete: ref(false),
      selectedsecurity: ref(''),
    };
  },
  created() {
    this.fetchsecurity();
  },
});
