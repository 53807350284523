<template>
  <v-dialog max-width="500" v-model="dialog">
    <v-card class="pt-7">
      <v-card-text>
        <div class="headline font-weight-bold font--text">{{ editMode ? 'Edit' : 'Create New' }} Region</div>
      </v-card-text>
      <v-card-text class="pb-0">
        <p class="titletext">Region Name
          <small class="error--text" v-if="$v.name.$errors.length ">&nbsp;*{{ $v.name.$errors[0].$message }}</small>
        </p>
        <v-text-field
          maxlength="100"
          v-model="name"
          :disabled="editMode"
          placeholder="Region Name"
          flat
          outlined
        ></v-text-field>
      </v-card-text>
      <v-card-text class="pb-0">
        <v-row>
          <v-col cols="12" class="pt-0">
            <p class="titletext">CPU Limit
              <small class="error--text" v-if="$v.cpuLimit.$errors.length ">&nbsp;*{{ $v.cpuLimit.$errors[0].$message }}</small>
            </p>
            <v-text-field
              maxlength="100"
              placeholder="CPU Limit"
              v-model="cpuLimit"
              flat
              outlined
            ></v-text-field>
          </v-col>
          <v-col cols="12" class="pt-0">
            <p class="titletext">Memory Limit
              <small class="error--text" v-if="$v.memoryLimit.$errors.length ">&nbsp;*{{ $v.memoryLimit.$errors[0].$message }}</small>
            </p>
            <v-text-field
              maxlength="100"
              placeholder="CPU Limit"
              v-model="memoryLimit"
              flat
              outlined
            ></v-text-field>
          </v-col>
          <v-col cols="12" class="pt-0">
            <p class="titletext">GPU Limit
              <small class="error--text" v-if="$v.gpuLimit.$errors.length ">&nbsp;*{{ $v.gpuLimit.$errors[0].$message }}</small>
            </p>
            <v-text-field
              maxlength="100"
              placeholder="CPU Limit"
              v-model="gpuLimit"
              flat
              outlined
            ></v-text-field>
          </v-col>
        </v-row>
      </v-card-text>
      <v-card-text class="pb-0">
        <p class="titletext">Description
          <small class="error--text" v-if="$v.description.$errors.length ">&nbsp;*{{ $v.description.$errors[0].$message }}</small>
        </p>
        <v-textarea
            rows="5"
            id="description"
            flat
            outlined
            maxlength="255"
            v-model="description"
            placeholder="Description"
            style="margin-bottom: 22px"
            required
          ></v-textarea>
      </v-card-text>
      <v-card-text>
        <v-row>
          <v-col
            ><v-btn
              depressed
              height="45"
              block
              class="accent"
              @click="
                () => {
                  resetForm()
                  $emit('input', false);
                }
              "
              >Cancel</v-btn
            ></v-col
          >
          <v-col
            ><v-btn
              id="Add"
              depressed
              height="45"
              block
              class="secondary"
              @click="save"
              :disabled="isRegionLoading"
              >
                <beat-loader v-if="isRegionLoading" size="10px" color="white" :loading="isRegionLoading"/>
                <span v-else>
                  {{ editMode ? 'Save Changes' : 'Add' }}
                </span> 
              </v-btn>
            </v-col
          >
        </v-row>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>
<script>
import { computed, onMounted, ref, watch } from "@vue/composition-api";
const { required, maxLength, minValue, maxValue } = require("@vuelidate/validators");
import dialogfailcreate from "./dialogFailCreate";
import { useNamespacedActions, useNamespacedMutations, useNamespacedState } from 'vuex-composition-helpers'
import { MANAGEINSTANCEREGION } from '../namespace'

const useVuelidate = require("@vuelidate/core").default;
export default {
  props: ["value", 'selectedRegion'],
  setup(props, { root, emit }) {
    const {
      setopendialogcommonsuccess,
    } = useNamespacedMutations("DASHBOARD", [
      "setopendialogcommonsuccess",
    ])

    // Reset Form if create success
    root.$on('resetFormRegion', () => {
      resetForm()
    })

    const {
      isRegionLoading,
    } = useNamespacedState(MANAGEINSTANCEREGION, [
      "isRegionLoading",
    ])

    const editMode = ref(false)
    watch(() => props.selectedRegion, (val) => {
      if (val === null) {
        editMode.value = false
        resetForm()
      } else {
        editMode.value = true
        name.value = props.selectedRegion.name
        description.value = props.selectedRegion.description
        cpuLimit.value = props.selectedRegion.cpu_limit
        memoryLimit.value = props.selectedRegion.ram_limit
        gpuLimit.value = props.selectedRegion.gpu_limit
      }
    })

    const dialog = computed({
      get: () => props.value,
      set: (val) => {
        emit("input", val);
      },
    })

    const name = ref(null)
    const cpuLimit = ref(null)
    const memoryLimit = ref(null)
    const gpuLimit = ref(null)
    const description = ref(null)

    const $v = useVuelidate(
      {
        name: { required },
        cpuLimit: { 
          required,
          minValue: minValue(1),
        },
        memoryLimit: { 
          required,
          minValue: minValue(1),
        },
        gpuLimit: { 
          required,
          minValue: minValue(1),
        },
        description: { 
          maxLength: maxLength(255), 
        },
      },
      { 
        name, 
        description,
        memoryLimit,
        gpuLimit,
        cpuLimit,
      }
    )

    const resetForm = () => {
      name.value = null
      description.value = null
      memoryLimit.value = null
      cpuLimit.value = null
      gpuLimit.value = null
      $v.value.$reset()
    }

    const save = async () => {
      $v.value.$touch()
      if ($v.value.$invalid) return false
      const payload = {
        name: name.value,
        description: description.value,
        cpu_limit: parseInt(cpuLimit.value),
        gpu_limit: parseInt(gpuLimit.value),
        ram_limit: parseInt(memoryLimit.value),
      }
      if (props.selectedRegion) payload.sourceName = props.selectedRegion.name 
      if (editMode.value) emit('update', payload)
      else emit('create', payload)
    }
    
    return {
      resetForm,
      editMode,
      dialog,
      save,
      isRegionLoading,
      $v,
      name,
      description,
      memoryLimit,
      cpuLimit,
      gpuLimit,
    }
  },
}
</script>
<style lang="scss" scoped>
.titletext {
  font-weight: 700;
  font-size: 12px;
}
.v-input--is-disabled {
  ::v-deep .v-input__slot {
    background: #f1f2f2;
  }
}

</style>