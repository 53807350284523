import axios from '@/lib/axios'
import { async } from 'rxjs'
import {ActionContext, Module} from 'vuex'
interface State {
    organizations : Array<any>,
    pagination: any,
    members: Array<any>,
    memberpagination: any
    projects: Array<any>,
    projectspagination: any,
    roles: Array<any>
}
const store: Module<State,any>= {
    namespaced: true,
    state:{
        organizations:[],
        pagination: {},
        members: [],
        memberpagination:{},
        projects: [],
        projectspagination:{},
        roles:[]
    },
    mutations:{
        setorganizations:(state:State, payload:any)=>state.organizations = payload,
        setpagination:(state:State, payload:any)=>state.pagination = payload,
        setmembers(state: any, members:any){
            state.members = members
        },
        setprojects:(state:State, payload:any)=>state.projects = payload,
        setprojectspagination:(state:State, payload:any)=>state.projectspagination = payload,
        setmemberpagination: (state:State, payload:any)=>state.memberpagination = payload,
        setroles: (state:State, payload:any)=>state.roles = payload

    },
    actions:{
        fetchorganizations: async(context: ActionContext<State, any>, payload: any={page:1,itemsPerPage: 10})=>{
            return await axios.instance.get('/superadmin/manageorg', {params:{page:payload.page,limit:payload.itemsPerPage, search:payload.search, sortBy:payload.sortBy, sortDesc:payload.sortDesc}}).then(response=>{
               const {organization, pagination} = response.data.data
               context.commit('setorganizations', organization)
               context.commit('setpagination', pagination)
            })
        },
        fetchorganization: async(context: ActionContext<State, any>, payload: any)=>{
            return await axios.instance.get(`/superadmin/manageorg/${payload.id}`, ).then(response=>{
               const currentOrg =  response.data.data
               console.log('test'+ currentOrg)
               context.commit('SUPERADMIN/setCurrentOrg', currentOrg, {root: true})
            })
        },
        addorganization: async(context: ActionContext<State, any>, payload: any)=>{
            return await axios.instance.post('/superadmin/manageorg', payload)
        },
        editorganization: async(context: ActionContext<State, any>, payload: any)=>{
            return await axios.instance.put(`/superadmin/manageorg/${payload.id}`, payload)
        },

        checkdeleteorg: async(context:ActionContext<any, any>, payload:any)=>{
            return await axios.instance.delete(`/superadmin/manageorg/checking/${payload.id}`, {data:payload})
        },

        deleteorg: async(context:ActionContext<any, any>, payload:any)=>{
            console.log(payload.name);
            return await axios.instance.delete(`/superadmin/manageorg/delete/${payload.id}`, {data:payload})
        },

        changeorgstatus: async(context: ActionContext<State, any>, payload: any)=>{
            if(payload.status){
                return await axios.instance.put(`/superadmin/manageorg/${payload.id}/deactive`)
            }else{
                return await axios.instance.put(`/superadmin/manageorg/${payload.id}/active`)

            }
        },
        upgradeorg: async(context: ActionContext<State, any>, payload: any)=>{
            return await axios.instance.put(`/superadmin/manageorg/${payload.id}/npwp`,payload)
        },
        downgradeorg: async(context: ActionContext<State, any>, payload: any)=>{
            return await axios.instance.put(`/superadmin/manageorg/${payload.id}/basic`)
        },
        addmember: async(context: ActionContext<State, any>, payload: any)=>{
            const currentOrg = context.rootState.SUPERADMIN.currentOrg
            return await axios.instance.post(`/superadmin/manageorg/member/${currentOrg.id}`, payload).then(response=>{
            })
        },
        editmember: async(context:ActionContext<any, any>, payload:any)=>{
            const currentOrg = context.rootState.SUPERADMIN.currentOrg
            payload.user_id = payload.id
            return await axios.instance.put(`superadmin/manageorg/${currentOrg.id}/member`, payload)
        },
        deletemember: async(context:ActionContext<any, any>, payload:any)=>{
            const currentOrg = context.rootState.SUPERADMIN.currentOrg
            payload.user_id = payload.id
            return await axios.instance.delete(`/superadmin/manageorg/member/${currentOrg.id}`, {data:payload})
        },

        resendemail:async(context: ActionContext<State, any>, payload: any)=>{
            return await axios.instance.get(`/superadmin/manageuser/resend-verified/${payload.id}`).then(response=>{
                console.log(response)
            })
        },
       
        fetchmembers: async(context: ActionContext<State, any>, payload: any={page:1,itemsPerPage:10})=>{
            const currentOrg = context.rootState.SUPERADMIN.currentOrg
            return await axios.instance.get(`/superadmin/manageorg/${currentOrg.id}/member`,{params:{page:payload.page,limit:payload.itemsPerPage}}).then((response)=>{
               const {user, pagination} = response.data.data
               context.commit('setmembers', user)
               context.commit('setmemberpagination', pagination)
            })
        },
        addproject: async(context: ActionContext<State, any>, payload)=>{
            return await axios.instance.post('/superadmin/projects',payload)
        },
        fetchprojects: async(context: ActionContext<State, any>, payload: any={page:1,itemsPerPage:10})=>{
            const currentOrg = context.rootState.SUPERADMIN.currentOrg
            const id = payload && payload.id ? payload.id : currentOrg?.id
            if(!id){
                context.commit('setprojects', [])
                return
            }
            return await axios.instance.get(`/superadmin/manageorg/${id}/projects`,{params:{page:payload.page,limit:payload.itemsPerPage, search: payload.search}}).then((response)=>{
               const {projects, pagination} = response.data.data
               context.commit('setprojects', projects)
               context.commit('setprojectspagination', pagination)
               return {projects, pagination}
            })
        },
        fetchRoles: async(context:ActionContext<any, any>, payload: any={page:1,itemsPerPage:10})=>{
            var currentOrg = context.rootState.SUPERADMIN.currentOrg
            if(payload.currentOrg){
                currentOrg = payload.currentOrg
            }
            if(!currentOrg) return
            return await axios.instance.get(`/user/organization/role`,
            {
                params:{
                    page:payload.page,limit:payload.itemsPerPage,
                    org_id:currentOrg.id
                }
            }).then((response)=>{
                const {organization_roles} = response.data.data
                context.commit('setroles', organization_roles)
            })
        },
    }
}

export default store