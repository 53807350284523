import axios from '@/lib/axios'
import {ActionContext, Module} from 'vuex'
interface State{
    creators: Array<any>,
    members: Array<any>,
    member: any,
    pagination: any,
    paginationC: any
}
const store: Module<State,any>= {
    namespaced: true,
    state:{
        creators:[],
        members:[],
        member:{},
        pagination:{},
        paginationC:{},
    },
    mutations:{
        setmembers:(state:State, payload:any)=>state.members = payload,
        setmember:(state:State, payload:any)=>state.member = payload,
        setpagination:(state:State, payload:any)=>state.pagination = payload,
        setcreator:(state:State, payload:any)=>state.creators = payload,
        setpaginationC:(state:State, payload:any)=>state.paginationC = payload,
        
    },
    actions:{
        addmember: async(context: ActionContext<State, any>, payload: any)=>{
            return await axios.instance.post('/superadmin/manageuser', payload).then(response=>{
                console.log(response)
            })
        },
        fetchmembers:async(context: ActionContext<State, any>, payload: any={page:1,itemsPerPage:10})=>{
            return await axios.instance.get('/superadmin/manageuser',{params:{page:payload.page,limit:payload.itemsPerPage, search:payload.search, organization_id: payload.organization_id, role_id:payload.role_id, sortBy:payload.sortBy, sortDesc:payload.sortDesc, is_creator: payload.role_id !== 0 ? false : true}}).then(response=>{
                const data = response.data.data
                const {users, pagination} = data
                context.commit('setmembers', users)
                context.commit('setpagination', pagination)
            })
        },

        fetchmember:async(contex: ActionContext<State, any>, payload: any) => {
            return await axios.instance.get(`/superadmin/manageuser/${payload}`).then((response) => {
                const data = response.data.data
                console.log(data)
                contex.commit('setmember', data)
            })
        },

        fetchcreator:async(context: ActionContext<State, any>, payload: any={page:1,itemsPerPage:10})=>{
            return await axios.instance.get('/superadmin/manageuser/creator',{params:{page:payload.page,limit:payload.itemsPerPage, search:payload.search, organization_id: payload.organization_id}}).then(response=>{
                const data = response.data.data
                const {users, pagination} = data
                context.commit('setcreator', users)
                context.commit('setpaginationC', pagination)
            })
        },

        editmember: async(context:ActionContext<any, any>, payload:any)=>{
            return await axios.instance.put(`/superadmin/manageuser/edit/${payload.id}`, payload).then((response)=>{
                context.dispatch('fetchmembers')
            })
        },

        tfcreator: async(context:ActionContext<any, any>, payload:any)=>{
            return await axios.instance.put(`/superadmin/manageuser/set-creator/${payload.id}`, payload).then((response)=>{   
                //context.dispatch('fetchmembers')
            })
        },

        rejectnpwpktp:async(context: ActionContext<State, any>, payload: any)=>{
            return await axios.instance.put(`/superadmin/manageuser/reject/${payload.id}`, {reason: payload.reason}).then(response=>{
                console.log(response)
            })
        },

        verifynpwpktp:async(context: ActionContext<State, any>, payload: any)=>{
            return await axios.instance.put(`/superadmin/manageuser/approve/${payload.id}`, null).then(response=>{
                console.log(response)
            })
        },

        deletemember:async(context: ActionContext<State, any>, payload: any)=>{
            return await axios.instance.delete(`/superadmin/manageuser/delete/${payload.id}`).then(response=>{
                console.log(response)
            })
        },

        resendemail:async(context: ActionContext<State, any>, payload: any)=>{
            return await axios.instance.get(`/superadmin/manageuser/resend-verified/${payload.id}`).then(response=>{
                console.log(response)
            })
        },

        changestatus: async(context:ActionContext<any, any>, payload:any)=>{
            if(payload.status == 'active'){
                return await axios.instance.put(`/superadmin/manageuser/deactive/${payload.id}`, payload)
            }else{
                return await axios.instance.put(`/superadmin/manageuser/active/${payload.id}`, payload)
            }
        }
    }
}

export default store