<template>
  <v-container
    v-if="item"
    class="container"
    style="font-family: 'Fira Sans'; padding: 2rem;"
  >
    <v-row>
      <v-col
        cols="12"
        style="display:flex; align-items: center; justify-content: space-between;"
      >
        <img
          style="width: 200px; margin-top: 1.5rem;"
          id="logo"
          v-bind:src="require('@/assets/image_40.png')"
        />

        <p
          class="primary--text"
          style="font-weight: bold; margin-bottom: 0; font-size: 42px; letter-spacing: 2px;"
        >
          INVOICE
        </p>
      </v-col>
    </v-row>

    <p class="primary--text text-spacing">
      <b>PT. Aplikasinusa Lintasarta</b>
    </p>

    <p class="text-spacing"><b>NPWP / NPPKP</b> : {{ org.npwp_corporate }}</p>

    <p class="text-spacing">{{ org.address }}</p>

    <p class="text-spacing">
      <b>Issue Date</b> :
      {{ $moment(item.last_calcualated_for).format("DD MMMM YYYY") }}
    </p>

    <p class="text-spacing">
      <b>Due Date</b> : {{ $moment(item.due_date).format("DD MMMM YYYY") }}
    </p>

    <p class="text-spacing"><b>Status</b> : {{ item.payment_status }}</p>

    <p class="text-spacing">
      <b>Billing Account</b> : {{ item.billing_account }}
    </p>

    <!-- instance -->
    <Services
      v-if="list.instance && list.instance.length"
      title="Instance"
      :list="list.instance"
      :is-postpaid-fixed="isPostpaidFixed"
    />

    <!-- root_disk -->
    <Services
      v-if="list.root_disk && list.root_disk.length"
      title="Root Disk"
      :list="list.root_disk"
      :is-postpaid-fixed="isPostpaidFixed"
    />

    <!-- storage -->
    <Services
      v-if="list.storage && list.storage.length"
      title="Storage"
      :list="list.storage"
      :is-postpaid-fixed="isPostpaidFixed"
    />

    <!-- object_storage -->
    <Services
      v-if="list.object_storage && list.object_storage.length"
      title="Object Storage"
      :list="list.object_storage"
      :is-postpaid-fixed="isPostpaidFixed"
    />

    <!-- snapshot -->
    <Services
      v-if="list.snapshot && list.snapshot.length"
      title="Snapshot"
      :list="list.snapshot"
      :is-postpaid-fixed="isPostpaidFixed"
    />

    <!-- floating_ip -->
    <Services
      v-if="list.floating_ip && list.floating_ip.length"
      title="Floating IP"
      :list="list.floating_ip"
      :is-postpaid-fixed="isPostpaidFixed"
    />

    <!-- totals -->
    <v-row>
      <v-col>
        <!-- empty (for spacing) -->
      </v-col>

      <v-col>
        <v-row :style="{ backgroundColor: '#ECEFF1' }">
          <v-col>
            <b>Subtotal</b>
          </v-col>
          <v-col>
            <b> IDR {{ item.price ? item.price.toLocaleString("en") : 0 }} </b>
          </v-col>
        </v-row>

        <v-row
          class="mt-4"
          :style="{ backgroundColor: '#ECEFF1', marginTop: '8px' }"
        >
          <v-col>
            <b>VAT ({{ item.ppn }}%)</b>
          </v-col>
          <v-col>
            <b>IDR {{ item.ppn_value.toLocaleString("en") }}</b>
          </v-col>
        </v-row>

        <v-row
          class="mt-4"
          :style="{ backgroundColor: '#1f60a8', marginTop: '8px' }"
        >
          <v-col>
            <b :style="{ color: 'white' }">Total</b>
          </v-col>
          <v-col>
            <b :style="{ color: 'white' }">
              IDR {{ item.billed.toLocaleString("en") }}
            </b>
          </v-col>
        </v-row>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import localstorage from "@/lib/localstorage";
import Services from "./download-invoice2/Services.vue";

export default {
  props: ["item", "list"],
  components: {
    Services,
  },
  computed: {
    org() {
      return JSON.parse(localstorage.getItem("currentOrg"));
    },
    isPostpaidFixed() {
      const postpaidFixed =
        this.item.project && this.item.project.postpaid_type === "fixed";

      return postpaidFixed;
    },
  },
};
</script>

<style scoped>
.text-spacing {
  padding: 2px 0;
}

.text-spacing {
  display: flex;
}

.text-spacing b {
  width: 200px;
}

.mt-4 {
  margin-top: 8px;
}

* {
  font-size: 12px;
}
</style>
