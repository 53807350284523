<template>
  <div>
    <v-card class="pa-7 rounded-0 rounded-b-lg" flat>
      <v-row>
        <v-col class="d-flex align-center justify-end pt-0 pb-4">
          <v-btn
            width="150"
            class="secondary fz-14"
            depressed
            to="/network/vpc/create"
            v-if="vpcs.length"
          >
            Create VPC
          </v-btn>
        </v-col>
      </v-row>
      <v-data-table
        v-if="vpcs.length"
        :headers="headers"
        :items="vpcs"
        :items-per-page="10"
        class="elevation-0"
        hide-default-footer
      >
        <template v-slot:item.name="{ item }">
          <router-link :to="{ path: `/network/vpc/${item.id}` }">
            {{ item.name }}
          </router-link>
        </template>
        <template v-slot:item.region="{ item }">
          {{ item.region }}
        </template>
        <template v-slot:item.ip="{ item }">
          {{ item.ip_prefix }} / {{ item.network_size }}
        </template>
        <template v-slot:item.resource="{ item }">
          {{
            item.instances.length ? `${item.instances.length} Resources` : "-"
          }}
        </template>
        <template v-slot:item.action="{ item }">
          <router-link
            class="primary--text edit"
            :to="{ path: '/network/vpc/edit', query: { id: item.id } }"
            >Edit</router-link
          ><span
            class="error--text pr-3 delete ml-4"
            @click="
              () => {
                validateprivilages(['Network', 'editor', true]).then(() => {
                  vpcselected = item;
                  opendialogdeletevpc = true;
                });
              }
            "
            >Delete</span
          >
        </template>
        <template v-slot:footer="{ props }">
          <custom-footer-datatable v-if="vpcs.length > 5" :props="props" />
        </template>
      </v-data-table>
      <v-card flat outlined class="rounded-lg" v-else>
        <v-row>
          <v-col md="12">
            <v-card
              flat
              class="d-flex rounded-lg flex-grow-1 flex-column text-center ma-auto"
            >
              <v-card-text style="padding-top: 72px; padding-bottom: 78px">
                <p
                  style="
                    font-size: 16px;
                    margin-bottom: 10px;
                    text-transform: capitalize;
                  "
                  class="font-weight-bold"
                >
                  Looks like you don’t have any VPC Networks
                </p>
                <v-row>
                  <v-col>
                    <p
                      style="
                        font-size: 12px;
                        color: #a5b3bf;
                        margin-bottom: 20px;
                      "
                    >
                      Resources assigned to the same VPC network can communicate
                      securely with each other via private IP. Communication with
                      resources outside the VPC must use a public network IP.
                    </p>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col>
                    <v-btn
                      width="100%"
                      append
                      max-width="300"
                      height="50"
                      depressed
                      class="secondary"
                      exact
                      to="/network/vpc/create"
                    >
                      Create VPC Network
                    </v-btn>
                  </v-col>
                </v-row>
              </v-card-text>
            </v-card>
          </v-col>
        </v-row>
      </v-card>
    </v-card>
    <dialogDeleteVpc
      v-model="opendialogdeletevpc"
      @confirm="
        () => {
          deleteSelectedVPC();
        }
      "
    />
  </div>
</template>
<script>
import {
  useNamespacedActions,
  useNamespacedState,
} from "vuex-composition-helpers";
import { ref } from "@vue/composition-api";
import { NETWORKVPC } from "./namespace";
import dialogDeleteVpc from "./dialogDeleteVpc";
import localstorage from "@/lib/localstorage";

export default {
  components: { dialogDeleteVpc },
  setup() {
    const { fetchvpcs, DELETE_VPC } = useNamespacedActions(NETWORKVPC, [
      "fetchvpcs",
      "DELETE_VPC",
    ]);
    const { vpcs } = useNamespacedState(NETWORKVPC, ["vpcs"]);
    const vpcselected = ref();
    const {
      validateprivilages,
      validateprivilagesync,
    } = useNamespacedActions("HOMEPAGE", [
      "validateprivilages",
      "validateprivilagesync",
    ]);
    return {
      headers: [
        { text: "Name", value: "name" },
        { text: "Region", value: "region" },
        { text: "Available IP", value: "ip" },
        { text: "# Resources", value: "resource" },
        { text: "Action", value: "action" },
      ],
      validateprivilages,
      validateprivilagesync,
      vpcs,
      fetchvpcs,
      DELETE_VPC,
      opendialogdeletevpc: ref(false),
      vpcselected,
      async deleteSelectedVPC() {
        this.DELETE_VPC({
          id: vpcselected.value.id,
          resources: vpcselected.value.instances.length,
        });
      },
    };
  },
  computed: {
    userType() {
      return localstorage.getItem("type");
    },
    userRole() {
      return localstorage.getItem("role");
    },
  },
  created() {
    this.fetchvpcs();
  },
};
</script>

<style scoped>
.v-btn {
  height: 55px !important;
}
</style>
