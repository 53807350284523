























































































































import { watch, ref, reactive, onMounted } from "@vue/composition-api";
import {
  useNamespacedActions,
  useNamespacedGetters,
  useNamespacedState,
} from "vuex-composition-helpers";
import { EMATERAI } from "./namespace";
import dialogCreateMaterai from "./dialogCreateMaterai.vue";
import dialogEditMaterai from "./dialogEditMaterai.vue";
import dialogDeleteMaterai from "./dialogDeleteMaterai.vue";
import dialogChangeStatusMaterai from "./dialogChangeStatusMaterai.vue";
import dialogWarningChangeStatusMaterai from "./dialogWarningChangeStatusMaterai.vue";

export default {
  components: {
    "dialog-create-materai": dialogCreateMaterai,
    "dialog-edit-materai": dialogEditMaterai,
    "dialog-delete-materai": dialogDeleteMaterai,
    "dialog-change-status-materai": dialogChangeStatusMaterai,
    "dialog-warning-change-status-materai": dialogWarningChangeStatusMaterai,
  },
  setup() {
    const { fetchStamps, editStatusStamp } = useNamespacedActions(EMATERAI, [
      "fetchStamps",
      "editStatusStamp",
    ]);
    const { pagination } = useNamespacedState(EMATERAI, ["pagination"]);
    const { getStamps } = useNamespacedGetters(EMATERAI, ["getStamps"]);
    const headers = ref([
      {
        text: "No Materai",
        value: "stamp_code",
      },
      {
        text: "Expire Date",
        value: "expired_at",
      },
      {
        text: "Status",
        value: "status",
      },
      {
        text: "Action",
        value: "action",
        sortable: false,
        align: "center",
      },
    ]);
    const options = ref({
      page: 1,
      itemsPerPage: 10,
      rowsPerPage: 5,
      search: "",
      sortBy: [],
      sortDesc: [],
    });
    const modal = reactive({
      add: false,
      edit: false,
      delete: false,
      changeStatus: false,
      warningChangeStatus: false,
    });
    watch(
      options,
      (val) => {
        fetchStamps(val);
      },
      { deep: true }
    );
    onMounted(() => {
      fetchStamps();
    });
    const changeStatusMaterai = (params: any) => {
      const data = {
        id: params.id,
        status: params.status == 0 ? 1 : 0,
      };
      editStatusStamp(data)
        .then(() => {
          fetchStamps();
          modal.changeStatus = false;
        })
        .catch((err: any) => {
          console.log(`error-code: ${err.response.data.code}`);
          if (err.response.data.code == 400) {
            modal.changeStatus = false;
            modal.warningChangeStatus = true;
          }
        });
    };
    const materai = ref();
    return {
      fetchStamps,
      getStamps,
      pagination,
      headers,
      options,
      modal,
      changeStatusMaterai,
      materai,
    };
  },
};
