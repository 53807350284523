var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',{attrs:{"fluid":""}},[_c('v-row',[_c('v-col',{staticClass:"pa-0"},[_c('v-card',{staticClass:"rounded-lg pa-3",attrs:{"flat":""}},[_c('v-card-text',[_c('div',{staticClass:"headline font-weight-bold font--text"},[_vm._v("Resize")]),_c('p',[_c('b',[_vm._v("Currently using:")]),_vm._v(" "+_vm._s(_vm.instance.package_instance ? _vm.instance.package_instance.flavor_name : '-')+" / "+_vm._s(_vm.instance.package_instance ? _vm.instance.package_instance.vcpu : '-')+" vCPU / "+_vm._s(_vm.instance.package_instance ? _vm.instance.package_instance.ram : '-')+" GB RAM / "+_vm._s(_vm.instance.root_disk_package ? ((_vm.instance.root_disk_package.volume_size) + "GB") : _vm.instance.package_instance ? ((_vm.instance.package_instance.root_disk) + "GB") : '-')+" Root Disk")])]),_c('v-card-text',[_c('v-tabs',{attrs:{"show-arrows":false,"prev-icon":"","grow":"","slider-size":"4"},model:{value:(_vm.tab),callback:function ($$v) {_vm.tab=$$v},expression:"tab"}},[_c('v-tab',[_vm._v("All Types")]),_vm._l((_vm.type),function(x){return _c('v-tab',{key:x.id},[_c('v-container',[_c('v-row',[_c('div',[_vm._v(_vm._s(x.name))])])],1)],1)})],2)],1),_c('v-card-text',[_c('v-tabs-items',{model:{value:(_vm.tab),callback:function ($$v) {_vm.tab=$$v},expression:"tab"}},[_c('v-tab-item',[_c('v-data-table',{attrs:{"hide-default-footer":"","headers":_vm.headers,"items":_vm.filteredFlavors,"items-per-page":10},scopedSlots:_vm._u([{key:"no-data",fn:function(){return [_vm._v(" "+_vm._s(_vm.isLoading ? 'Loading..' : 'No data available')+" ")]},proxy:true},{key:"item.type",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"d-flex"},[_c('v-radio-group',{attrs:{"hide-details":""},model:{value:(_vm.selectedtype),callback:function ($$v) {_vm.selectedtype=$$v},expression:"selectedtype"}},[_c('v-radio',{class:{'disabled-flavor': item.outOfStock},attrs:{"value":item,"disabled":item.outOfStock}})],1),_c('div',{staticStyle:{"padding-top":"1px"}},[_c('span',[_vm._v(_vm._s(item.type.name))])])],1)]}},{key:"item.price",fn:function(ref){
var item = ref.item;
return [_c('p',{staticClass:"mb-0"},[_vm._v("IDR "+_vm._s(_vm.toThousands(item.price_per_month))+"/month")]),_c('p',[_vm._v("IDR "+_vm._s(item.price_per_hours)+"/hour")])]}},{key:"item.name",fn:function(ref){
var item = ref.item;
return [_c('span',[_vm._v(_vm._s(item.flavor_name))])]}},{key:"item.vcpu",fn:function(ref){
var item = ref.item;
return [_c('span',[_vm._v(_vm._s(item.vcpu)+" vCPU")])]}},{key:"item.gpu",fn:function(ref){
var item = ref.item;
return [_c('span',[_vm._v(_vm._s(item.gpu ? item.gpu : '-'))])]}},{key:"item.ram",fn:function(ref){
var item = ref.item;
return [_c('span',[_vm._v(_vm._s(item.ram)+" GB")])]}},{key:"item.root_disk",fn:function(ref){
var item = ref.item;
return [_c('span',[_vm._v(_vm._s(item.root_disk)+" GB")])]}},(_vm.masterInstances.length > 5)?{key:"footer",fn:function(ref){
var props = ref.props;
return [_c('custom-footer-datatable',{attrs:{"props":props}})]}}:null],null,true)})],1),_vm._l((_vm.type),function(x){return _c('v-tab-item',{key:x.id},[_c('v-data-table',{attrs:{"hide-default-footer":"","headers":_vm.headers,"items":_vm.filteredFlavors.filter(function (flavor) { return flavor.type.id === x.id && flavor.id !== _vm.instance.package_instance_id; }),"items-per-page":10},scopedSlots:_vm._u([{key:"no-data",fn:function(){return [_vm._v(" "+_vm._s(_vm.isLoading ? 'Loading..' : 'No data available')+" ")]},proxy:true},{key:"item.type",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"d-flex"},[_c('v-radio-group',{attrs:{"hide-details":""},model:{value:(_vm.selectedtype),callback:function ($$v) {_vm.selectedtype=$$v},expression:"selectedtype"}},[_c('v-radio',{class:{'disabled-flavor': item.outOfStock},attrs:{"value":item,"disabled":item.outOfStock}})],1),_c('div',{staticStyle:{"padding-top":"1px"}},[_c('span',[_vm._v(_vm._s(item.type.name))])])],1)]}},{key:"item.name",fn:function(ref){
var item = ref.item;
return [_c('span',[_vm._v(_vm._s(item.flavor_name))])]}},{key:"item.vcpu",fn:function(ref){
var item = ref.item;
return [_c('span',[_vm._v(_vm._s(item.vcpu)+" vCPU")])]}},{key:"item.gpu",fn:function(ref){
var item = ref.item;
return [_c('span',[_vm._v(_vm._s(item.gpu ? item.gpu : '-'))])]}},{key:"item.ram",fn:function(ref){
var item = ref.item;
return [_c('span',[_vm._v(_vm._s(item.ram)+" GB")])]}},{key:"item.root_disk",fn:function(ref){
var item = ref.item;
return [_c('span',[_vm._v(_vm._s(item.root_disk)+" GB")])]}},{key:"item.price",fn:function(ref){
var item = ref.item;
return [_c('div',[_c('p',{staticClass:"mb-0"},[_vm._v("IDR "+_vm._s(_vm.toThousands(item.price_per_month))+"/month")]),_c('p',[_vm._v("IDR "+_vm._s(_vm.toThousands(item.price_per_hours))+"/hour")])])]}},(_vm.masterInstances.length > 5)?{key:"footer",fn:function(ref){
var props = ref.props;
return [_c('custom-footer-datatable',{attrs:{"props":props}})]}}:null],null,true)})],1)})],2)],1),_c('v-card-text',[_c('v-btn',{attrs:{"depressed":"","disabled":_vm.isLoading || !_vm.selectedtype || !_vm.validateprivilages(['Instance', 'editor']) || !_vm.masterInstances.length,"height":"50","block":"","color":"secondary"},on:{"click":function (){
        _vm.validateprivilages([
          'Instance',
          'editor' ]).then(function () {
          _vm.doResize()
        }); }}},[(_vm.isLoading)?_c('beat-loader',{staticClass:"mr-2",attrs:{"loading":_vm.isLoading,"color":'white',"size":'10px'}}):_c('span',{staticStyle:{"font-size":"14px"}},[_vm._v("Resize")])],1)],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }