var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"d-flex justify-center align-center"},[(_vm.userType === 'C')?_c('div',{staticClass:"d-flex justify-center align-center navmenu",staticStyle:{"cursor":"pointer"},on:{"click":function () {
        _vm.$router.push("/ticket");
      }}},[_c('v-icon',{staticClass:"mr-3",staticStyle:{"width":"20px","height":"20px"}},[_vm._v("$vuetify.icons.ticketIcon")]),_c('p',{staticClass:"mb-0"},[_vm._v(" "+_vm._s(_vm.userType === "C" ? "Ticket" : "")+" ")])],1):_vm._e(),_c('v-menu',{ref:"menu",attrs:{"offset-y":"","allow-overflow":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
      var on = ref.on;
      var attrs = ref.attrs;
return [_c('div',_vm._b({staticClass:"d-flex justify-center align-center navmenu",staticStyle:{"cursor":"pointer"},attrs:{"id":"OrganizationNav"},on:{"click":function ($event) {
            on.click($event);
          }}},'div',attrs,false),[_c('v-icon',{staticClass:"mr-3",staticStyle:{"width":"20px","height":"20px"}},[_vm._v("$vuetify.icons.organizationIcon")]),_c('p',{staticClass:"mb-0"},[_vm._v(" "+_vm._s(_vm.userType === "C" ? "Organization" : "Manage Project")+" ")]),_c('v-icon',{class:{ rotate: !_vm.showMenu },attrs:{"id":"IconOrg"}},[_vm._v("mdi-chevron-up")])],1)]}}]),model:{value:(_vm.showMenu),callback:function ($$v) {_vm.showMenu=$$v},expression:"showMenu"}},[(_vm.userType === 'C')?_c('v-list',[_c('v-list-item',{attrs:{"to":"/voucher"}},[_c('v-list-item-title',[_vm._v("Voucher")])],1),_c('v-list-item',{attrs:{"to":"/role-permission"}},[_c('v-list-item-title',[_vm._v("Manage Role")])],1),_c('v-list-item',{attrs:{"to":"/setting-organization"}},[_c('v-list-item-title',[_vm._v("Setting Organization")])],1),_c('v-list-item',{attrs:{"to":"/project"}},[_c('v-list-item-title',[_vm._v("List Project")])],1),_c('v-list-item',{attrs:{"to":"/audit-log"}},[_c('v-list-item-title',[_vm._v("Audit Log")])],1)],1):_c('v-list',[_c('v-list-item',{attrs:{"to":"/project"}},[_c('v-list-item-title',[_vm._v("List Project")])],1),(!_vm.loading && !_vm.unlimited)?_c('v-list-item',{attrs:{"to":"/service-limit"}},[_c('v-list-item-title',[_vm._v("Service Limit")])],1):_vm._e(),_c('v-list-item',{attrs:{"to":"/audit-log"}},[_c('v-list-item-title',[_vm._v("Activity")])],1),(_vm.personalStatus)?_c('v-list-item',{attrs:{"to":"/setting-organization"}},[_c('v-list-item-title',[_vm._v("Setting Organization")])],1):_vm._e()],1)],1),_c('BroadcastBtn')],1)}
var staticRenderFns = []

export { render, staticRenderFns }