


































import { ref } from '@vue/composition-api'
import Vue from 'vue'
export default Vue.extend({
    setup(){
        const headers = ref([
            {text:'Subjet', value:'subject'},
            {text:'Ticket ID', value:'id'},
            {text:'Created Date', value:'createddate'},
            {text:'Status', value:'status'},
        ])
        const tickets = ref([
            {
                subject:'Account and billing support',
                id: '7999968969',
                createddate: 'Nov 1, 2020',
                status: 'Resolved'
                 },
                 {
                subject:'Account and billing support',
                id: '7999968969',
                createddate: 'Nov 2, 2020',
                status: 'Resolved'
                 },
                 {
                subject:'Sevice Limit Increase',
                id: '7999968969',
                createddate: 'Nov 4, 2020',
                status: 'Resolved'
                 },
                  {
                subject:'Sevice Limit Increase',
                id: '7999968969',
                createddate: 'Nov 5, 2020',
                status: 'Resolved'
                 },
                  {
                subject:'Sevice Limit Increase',
                id: '7999968969',
                createddate: 'Nov 4, 2020',
                status: 'Resolved'
                 },
                  {
                subject:'Sevice Limit Increase',
                id: '7999968969',
                createddate: 'Nov 5, 2020',
                status: 'Resolved'
                 },
                  {
                subject:'Sevice Limit Increase',
                id: '7999968969',
                createddate: 'Nov 4, 2020',
                status: 'Resolved'
                 },
                  {
                subject:'Sevice Limit Increase',
                id: '7999968969',
                createddate: 'Nov 5, 2020',
                status: 'Resolved'
                 }
        ])
        return {
            headers,
            tickets
        }
    }
})
