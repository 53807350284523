
























































































































import {
  computed,
  defineComponent,
  ref,
} from "@vue/composition-api";
import {
  useNamespacedActions,
  useNamespacedMutations,
  useNamespacedState,
} from "vuex-composition-helpers";
import { State } from "../organization/organization.store";
import { PROJECT } from "../project/namespace";
import { SUPERADMIN } from "./namespace";
interface Project {
  name: string;
  id: string;
}
interface Organization {
  name: string;
  id: string;
  open: boolean;
  projects: Array<Project>;
}
export default defineComponent({
  props: ["value"],
  methods: {
  },
  setup(props, context) {
    const { fetchorganizations } = useNamespacedActions(SUPERADMIN, [
      "fetchorganizations",
    ]);
    const { organizations } = useNamespacedState<State>(SUPERADMIN, [
      "organizations",
    ]);
    const T = ref();

    const organization = ref([]);

    const { setCurrentOrg, setCurrentProj } = useNamespacedMutations(
      SUPERADMIN,
      ["setCurrentOrg", "setCurrentProj"]
    );
    const {selectModal} = useNamespacedState(PROJECT,["selectModal"])
    const {setselectmodal} = useNamespacedMutations(PROJECT,["setselectmodal"])
    const dialog = computed({
      get:() => selectModal.value,
      set: (val) => setselectmodal(val)
    })
    return {
      search: ref(""),
      dialog,
      setCurrentOrg, setCurrentProj,
      fetchorganizations,
      organizations,
      organization,
      T,
    };
  },
  watch: {
    dialog(newVal) {
      if(newVal == true) this.fetchorganizations({ itemsPerPage: -1 });
    },
  },
  updated() {
    const hideheader = document.getElementById("hideheader");
    const tbody = hideheader? hideheader.querySelector(".v-data-table__wrar"): null;
    if (tbody && tbody.scrollHeight > 290 && this.headers1.length < 3) {
      this.headers1.push({
        text: "",
        value: "none",
        sortable: false,
        class: ["px-1"],
      });
    } else if (
      tbody &&
      tbody.scrollHeight <= 290 &&
      this.headers1.length >= 3
    ) {
      this.headers1 = this.headers1.slice(0, 2);
    }
  },
  data() {
    return {
      headers: [
        { text: "Name", value: "name", sortable: false },
        { text: "ID", value: "id", sortable: false },
      ],
      headers1: [
        { text: "Name", value: "name", sortable: false },
        { text: "ID", value: "id", sortable: false, class: [""] },
      ],
    };
  }
});
