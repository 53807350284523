var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-card',{staticClass:"rounded-lg pa-4 mt-3",attrs:{"flat":""}},[_c('v-card-text',[_c('p',{staticClass:"fz-21 font-weight-bold"},[_vm._v(" Transaction History ")])]),(_vm.loading)?_c('p',{staticClass:"text-center"},[_c('beat-loader',{staticClass:"mr-2",attrs:{"color":"gray","size":"10px"}})],1):_c('v-data-table',{staticClass:"elevation-0",attrs:{"hide-default-footer":"","headers":_vm.headers,"items":_vm.topups,"items-per-page":_vm.pagination.itemsPerPage,"server-items-length":_vm.pagination.count,"options":_vm.pagination},on:{"update:options":function($event){_vm.pagination=$event}},scopedSlots:_vm._u([{key:"item.billed",fn:function(ref){
var item = ref.item;
return [_vm._v(" IDR "+_vm._s(item.billed.toLocaleString("en-US"))+" ")]}},{key:"item.payment_date",fn:function(ref){
var item = ref.item;
return [_c('span',[_vm._v(" "+_vm._s(_vm.$moment(item.payment_date).format("DD/MM/YYYY"))+" ")])]}},{key:"footer",fn:function(ref){
var props = ref.props;
return [(
          (_vm.pagination && _vm.pagination.count > 10) ||
            (_vm.pagination && _vm.pagination.count === 10)
        )?_c('custom-footer-datatable',{attrs:{"props":props},on:{"input":function (val) {
            _vm.pagination = Object.assign({}, _vm.pagination, val);
          }}}):_vm._e()]}}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }