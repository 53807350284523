<template>
<div>
  <v-card flat class="rounded-lg elevation-0 mb-5 pt-6 pb-5 px-7">
    <p v-if="loading" class="text-center">
      <beat-loader color="gray" size="10px" class="mr-2" />
    </p>
      
    <v-card-text v-if="!loading && item" class="pa-0">
      <h1 class="headline font-weight-bold font--text">
        Remaining Quota Restore
      </h1>

      <v-row>
        <v-col cols="12" sm="8">
          <div v-if="item.is_default" class="blue-box-info">
            No data available
          </div>

          <div v-else class="blue-box-info d-flex">
            <div class="w-50">
              <p class="card-title">
                Total Quota
              </p>

              <p class="card-idr">{{ item.quota }}x</p>
            </div>

            <div class="w-50 middle-child-box">
              <p class="card-title">
                Remaining Quota
              </p>

              <p class="card-idr" :style="{ color: '#219653' }">
                {{ item.remaining }}x
              </p>
            </div>

            <div class="w-50 last-child-box">
              <p class="card-title">
                Expired Date
              </p>

              <p class="card-idr">
                {{ $moment(item.expired_date).format('YYYY-MM-DD') }}
              </p>
            </div>
          </div>
        </v-col>
      </v-row>
    </v-card-text>

    <p v-if="!loading && !item" class="text-center">
      Sorry, an error occurred
    </p>
  </v-card>

  <v-card flat class="rounded-lg pa-4 mt-3">
    <v-card-text>
      <p class="fz-21 font-weight-bold">
        Restore History
      </p>
    </v-card-text>


    <v-data-table
      hide-default-footer
      :headers="tblHeaders"
      :items="tblItems"
      :items-per-page="10"
      class="elevation-0"
    >
      <!-- loading / no data -->
      <template v-slot:no-data>
        {{ loading ? "Loading..." : "No data available" }}
      </template>

      <template v-slot:footer="{ props }" v-if="tblItems.length">
        <custom-footer-datatable :props="props" />
      </template>
    </v-data-table>
  </v-card>
  </div>
</template>

<script>
import localstorage from '@/lib/localstorage';
import api from '@/lib/api';
import { mapGetters } from 'vuex';

export default {
  data() {
    return {
      loading: false,
      item: null,
      tblHeaders: [
        { text: "Instance Name", value: "instance_name", sortable: false },
        { text: "Image Name", value: "image_name", sortable: false },
        { text: "Restore Date", value: "restore_date", sortable: true },
      ],
      tblItems: [],
    }
  },
  computed: {
    isCorporate() {
      return localstorage.getItem("type") === "C";
    },
    ...mapGetters({
      projectId: "PROJECT/getprojectid",
    })
  },
  methods: {
    async getRestoreQuota() {
      if (this.isCorporate) {
        this.loading = true

        try {
          const res = await api.GET("/restore/quota/" + this.projectId);

          this.item = res.data
        } catch {
          this.$store.dispatch(
            "HOMEPAGE/showErrorToast",
            "Sorry, an error occurred while displaying restore quota"
          );
        }

        this.loading = false
      }
    },
    async getHistory() {
      this.loading = true

      try {
        const res = await api.GET('/restore/history/' + this.projectId)
        this.tblItems = res.data ? res.data.map((item) => {
          return {
            instance_name: item.instance.name,
            image_name: item.image.name,
            restore_date: this.$moment(item.created_at).format('DD/MM/YYYY')
          }
        }) : []
      } catch (err) {
        let errmsg = "Sorry, an error occurred while displaying data";
        const thereIsErrorMessage = err && err.response && err.response.data;

        if (thereIsErrorMessage && err.response.data.message) {
          errmsg = err.response.data.message;
        }

        if (
          thereIsErrorMessage &&
          err.response.data.data !== "record not found"
        ) {
          this.$store.dispatch("HOMEPAGE/showErrorToast", errmsg);
        }
      }

      this.loading = false
    }
  },
  mounted() {
    this.getRestoreQuota();
    this.getHistory()
  }
};
</script>

<style scoped>
.blue-box-info {
  /* width: 50%; */
  border: 1px solid #2c94d2;
  border-radius: 10px;
  background-color: rgba(31, 96, 168, 0.02);
  margin-bottom: 28px;
}

.card-title {
  margin-bottom: 4px;
  font-size: 12px;
  font-style: normal;
  font-weight: 700;
  line-height: 17px;
  letter-spacing: 0em;
  text-align: left;
}

.card-idr {
  font-size: 21px;
  font-style: normal;
  font-weight: 600;
  line-height: 50px;
  letter-spacing: 0em;
  text-align: left;
  color: #1f60a8;
}

.w-50 {
  width: 50%;
  padding: 25px;
}

.last-child-box {
  border-left: 1px dashed #2c94d2;
}

.middle-child-box {
  border-left: 1px dashed #2c94d2;
}
</style>
