import main from './main.vue'
import create from './create.vue'
import detail from './detail.vue'
import managevoucher from './managevoucher.vue'
import { iVue } from '../type'
import {MANAGEVOUCHER} from './namespace'
import store from './store'
export default{
    install(Vue:iVue){
        Vue.addModules({
            [MANAGEVOUCHER]:store
        })
        Vue.addRoutetoDashboard({
            path: "manage-voucher",
            component: main,
            meta:{
                breadcrumb: {
                    text: 'Manage Voucher',
                    to: '/manage-voucher',
                },
            },
            children:[
                {
                    path:'',
                    component: managevoucher,
                    name: "superadmin-voucher",
                },
                {
                    path:'create',
                    component: create,
                    meta:{
                        breadcrumb: {
                            text: 'Create Voucher',
                            to: '/manage-voucher/create',
                        },
                    },
                },
                {
                    path:':id',
                    component: detail,
                    meta:{
                        breadcrumb: {
                            text: 'Detail Voucher',
                            to: '/manage-voucher/:id',
                        },
                    },
                }
            ]
        })
    }
}