







































































































































































































































































































































import { defineComponent, watch, ref, onMounted } from "@vue/composition-api";
import { useMember } from "../organization/usemember";
import {
  useNamespacedActions,
  useNamespacedMutations,
  useNamespacedState,
} from "vuex-composition-helpers";
import dialogEditOrganization from "../organization/editorganization.dialog.vue";
import memberlistcard from "../organization/memberlist.card.vue";
import { SUPERADMINMANAGEORG } from "./namespace";
import dialogAddProj from "./dialogAddProj.vue";
import { PROJECT } from "../project/namespace";
import dialogAreYouSure from "../project/dialogAreYouSure.vue";
import dialogDeleteProj from "../project/dialogDeleteProj.vue";
import dialogErrorDelete from "../project/dialogErrorDelete.vue";
import { SUPERADMIN } from "../superadmin/namespace";
import ApprovalNotification from "./organization-detail/ApprovalNotification.vue";

const isDevModePostpaid = true

export default defineComponent({
  components: {
    dialogEditOrganization,
    memberlistcard,
    dialogAreYouSure,
    dialogAddProj,
    dialogDeleteProj,
    dialogErrorDelete,
    ApprovalNotification
  },
  props: ["currentOrg"],
  setup(props: any) {
    const {
      fetchprojects,
      fetchorganization,
    } = useNamespacedActions(SUPERADMINMANAGEORG, [
      "fetchprojects",
      "fetchorganization",
    ]);
    const options = ref({});
    watch(
      options,
      (val) => {
        const op = { id: props.currentOrg.id, ...val };
        fetchprojects(op);
      },
      { deep: true }
    );
    onMounted(() => {
      fetchprojects({ id: props.currentOrg.id });
    });
    const {
      projects,
      projectspagination,
    } = useNamespacedState(SUPERADMINMANAGEORG, [
      "projects",
      "projectspagination",
    ]);
    const Composable = {
      ...useMember(),
    };
    const { fetchmembers } = Composable;
    const { changeorgstatus } = useNamespacedActions(SUPERADMINMANAGEORG, [
      "changeorgstatus",
    ]);
    const switchstatus = async (item: any) => {
      return await changeorgstatus(item);
    };
    const opendialogaddproj = ref(false);
    const { validatelimitcreateproject,checkproject } = useNamespacedActions(PROJECT, [
      "validatelimitcreateproject","checkproject",
    ]);
    const selectedProj = ref();
    const opendeleteproject = ref(false);
    const dialogerrormsg = ref("");
    const opendialogareyousure=ref("");
    const opendialogerror = ref(false);
    const { setCurrentProj } = useNamespacedMutations(
      SUPERADMIN,
      [ "setCurrentProj"]
    );

    const hapusProject= () =>{
      const curr = selectedProj.value as any;
      const Value = {
        id: curr.id,
        name: curr.name,
      };
      
      checkproject(Value).then((e:any) => {
        console.log(e);
        opendeleteproject.value=true; 
      }).catch((e:any)=>{
        const {data} = e.response.data
        opendialogerror.value=true;
        dialogerrormsg.value=data;
        console.log(data);
      });
    };

    return {
      hapusProject,
      setCurrentProj,
      dialogerrormsg,
      opendialogerror,
      opendeleteproject,
      selectedProj,
      opendialogareyousure,
      validatelimitcreateproject,
      opendialogaddproj,
      switchstatus,
      options,
      fetchprojects,
      projectspagination,
      projects,
      fetchorganization,
      ...Composable,
    };
  },
  data() {
    return {
      isDevModePostpaid,
      openDoalogEditOrg: false,
      switch1: true,
      headers: [
        { text: "Name", value: "name", sortable: false },
        { text: "Project", value: "project", sortable: false },
        { text: "Role ", value: "role", sortable: false },
        { text: "Status ", value: "status", sortable: false },
        { text: "Action ", value: "action", align: "center", sortable: false },
      ],
      headers1: [
        { text: "Project Name ", value: "projectname", sortable: false },
        { text: "Project Number ", value: "projectnumber", sortable: false },
        { text: "Description ", value: "description", sortable: false },
        { text: "Created Date ", value: "created_at", sortable: false },
        { text: "Action ", value: "action", sortable: false, align: "center" },
      ],
    };
  },
  methods: {
    uppercaseFirstLetter(string: String) {
      return string.charAt(0).toUpperCase() + string.slice(1)
    },
    toAddProject() {
      this.$router.push(this.$route.path + '/project/add');
    },
  }
});
