var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',[_c('v-col',{staticClass:"justify-center"},[_c('h1',{staticStyle:{"text-align":"center","font-weight":"800","font-size":"28px","line-height":"36px","letter-spacing":"0.04em","font-family":"Mont","font-style":"normal"}},[_vm._v(" Sign Up! ")]),_c('v-form',{ref:"form",staticClass:"responsive-form",staticStyle:{"margin-top":"40px"},attrs:{"lazy-validation":""}},[(_vm.errorMessage)?_c('v-alert',{attrs:{"color":"red","type":"error"}},[_vm._v(_vm._s(_vm.errorMessage)+" ")]):_vm._e(),_c('v-text-field',{staticStyle:{"margin-bottom":"22px"},attrs:{"maxlength":"100","id":"fullname","flat":"","outlined":"","placeholder":"Full Name","required":"","error-messages":_vm.fullnameValidation.$errors.length
            ? _vm.fullnameValidation.$errors[0].$message
            : ''},model:{value:(_vm.fullname),callback:function ($$v) {_vm.fullname=$$v},expression:"fullname"}}),_c('v-text-field',{staticStyle:{"margin-bottom":"22px"},attrs:{"maxlength":"50","flat":"","type":"email","outlined":"","placeholder":"Email","required":"","error-messages":_vm.emailValidation.$errors.length
            ? _vm.emailValidation.$errors[0].$message
            : ''},model:{value:(_vm.email),callback:function ($$v) {_vm.email=(typeof $$v === 'string'? $$v.trim(): $$v)},expression:"email"}}),_c('v-text-field',{staticStyle:{"margin-bottom":"22px"},attrs:{"maxlength":"255","id":"password","flat":"","outlined":"","append-icon":_vm.show1 ? 'mdi-eye' : 'mdi-eye-off',"type":_vm.show1 ? 'text' : 'password',"name":"input-10-1","placeholder":"Password","error-messages":_vm.passwordValidation.$errors.length
            ? _vm.passwordValidation.$errors[0].$message
            : ''},on:{"click:append":function($event){_vm.show1 = !_vm.show1}},model:{value:(_vm.password),callback:function ($$v) {_vm.password=$$v},expression:"password"}}),_c('v-text-field',{directives:[{name:"mask",rawName:"v-mask",value:([
          /[1-9]/,
          /[0-9]/,
          /[0-9]/,
          ' ',
          /[0-9]/,
          /[0-9]/,
          /[0-9]/,
          ' ',
          /[0-9]/,
          /[0-9]/,
          /[0-9]/,
          ' ',
          /[0-9]/,
          /[0-9]/,
          /[0-9]/ ]),expression:"[\n          /[1-9]/,\n          /[0-9]/,\n          /[0-9]/,\n          ' ',\n          /[0-9]/,\n          /[0-9]/,\n          /[0-9]/,\n          ' ',\n          /[0-9]/,\n          /[0-9]/,\n          /[0-9]/,\n          ' ',\n          /[0-9]/,\n          /[0-9]/,\n          /[0-9]/,\n        ]"}],staticStyle:{"margin-bottom":"22px"},attrs:{"flat":"","outlined":"","prefix":"+62","placeholder":"Phone Number","required":"","error-messages":_vm.phoneValidation.$errors.length
            ? _vm.phoneValidation.$errors[0].$message
            : ''},model:{value:(_vm.phone),callback:function ($$v) {_vm.phone=$$v},expression:"phone"}}),_c('v-text-field',{directives:[{name:"mask",rawName:"v-mask",value:('##.###.###.#-###.###'),expression:"'##.###.###.#-###.###'"}],staticStyle:{"margin-bottom":"22px"},attrs:{"flat":"","outlined":"","placeholder":"NPWP Number","required":"","error-messages":_vm.npwpValidation.$errors.length
            ? _vm.npwpValidation.$errors[0].$message
            : ''},model:{value:(_vm.npwp),callback:function ($$v) {_vm.npwp=$$v},expression:"npwp"}}),_c('p',{staticClass:"font-weight-bold fz-14 mb-12px"},[_vm._v("Upload NPWP")]),_c('v-btn',{staticClass:"mr-4 text-capitalize font-weight-bold",attrs:{"width":"150","height":"45","depressed":"","color":"secondary"},on:{"click":_vm.triggerUploadNpwp}},[_c('span',{staticClass:"fz-12"},[_vm._v(" Upload File ")])]),(_vm.npwpimg)?_c('img',{staticStyle:{"width":"100%","display":"block","margin-top":"20px"},attrs:{"src":_vm.npwpimg,"alt":"preview-npwp-img"}}):_vm._e(),_c('input',{ref:"npwp_file",staticClass:"d-none",attrs:{"type":"file"},on:{"change":_vm.onChangeUploadNpwp}}),(_vm.npwp_fileError)?_c('p',{staticClass:"red--text mt-11px"},[_vm._v(" "+_vm._s(_vm.npwp_fileError)+" ")]):_vm._e(),_c('p',{staticClass:"font-weight-bold fz-14 mb-12px",staticStyle:{"margin-top":"30px"}},[_vm._v(" Upload KTP ")]),_c('v-btn',{staticClass:"mr-4 text-capitalize font-weight-bold",attrs:{"width":"150","height":"45","depressed":"","color":"secondary"},on:{"click":_vm.triggerUploadKtp}},[_c('span',{staticClass:"fz-12"},[_vm._v(" Upload File ")])]),(_vm.ktpimg)?_c('img',{staticStyle:{"width":"100%","display":"block","margin-top":"20px"},attrs:{"src":_vm.ktpimg,"alt":"preview-ktp-img"}}):_vm._e(),_c('input',{ref:"ktp_file",staticClass:"d-none",attrs:{"type":"file"},on:{"change":_vm.onChangeUploadKtp}}),(_vm.ktp_fileError)?_c('p',{staticClass:"red--text mt-11px"},[_vm._v(" "+_vm._s(_vm.ktp_fileError)+" ")]):_vm._e(),_c('div',{staticClass:"d-flex align-center",staticStyle:{"margin-top":"54.5px","margin-bottom":"24.5px"}},[_c('v-checkbox',{attrs:{"error-messages":_vm.agreeValidation.$errors.length
              ? _vm.agreeValidation.$errors[0].$message
              : ''},on:{"click":_vm.checkboxOnclick},scopedSlots:_vm._u([{key:"label",fn:function(){return [_c('div',[_vm._v("By signing up you agree to the")])]},proxy:true}]),model:{value:(_vm.agree),callback:function ($$v) {_vm.agree=$$v},expression:"agree"}}),_c('button',{staticClass:"text-decoration-underline primary--text ml-2 mt-2 fz-16",attrs:{"type":"button"},on:{"click":function($event){return _vm.getEula()}}},[_vm._v(" Terms of Service ")])],1),_c('v-btn',{staticClass:"mr-4 text-capitalize font-weight-bold",attrs:{"id":"submit","block":"","height":"50","depressed":"","color":"secondary","disabled":_vm.loading || !_vm.agree},on:{"click":_vm.signup}},[(_vm.loading)?_c('beat-loader',{staticClass:"ml-2",attrs:{"loading":_vm.loading,"color":'white',"size":'10px'}}):_vm._e(),_vm._v(" "+_vm._s(_vm.loading ? "Sign Up..." : "Sign Up")+" ")],1),_c('br')],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }