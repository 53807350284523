




















































import { useDialog } from '@/composable/usedialog';
import Vue from 'vue'
import { useNamespacedActions } from "vuex-composition-helpers";
import { PROJECT } from "./namespace";
const module =  Vue.extend({
  props: ["value",'selectedproject'],
  setup(props : any, context : any){
    const { deleteproject } = useNamespacedActions(PROJECT, ["deleteproject"]);
      const {dialog} = useDialog(props, context) 
    return{
      Delete: async ()=>{
        //await deleteproject(props.selectedproject)
        context.emit("deleteproject")
      },
      dialog
    }
  },
});
export default module
