<template>
  <v-dialog v-model="value" max-width="500">
    <v-card>
      <v-card-title class="d-flex align-center pb-4">
        <img src="@/assets/info-red.svg" alt="small-red-info-circle-icon" class="mr-4" />

        <div class="headline font-weight-bold font--text">
          Take a Snapshot
        </div>
      </v-card-title>
      <v-card-text class="pb-0">
        <p>
          <b>Are you sure you want to take a snapshot?</b>
        </p>
        
        <p>
          If you agree, Snapshots will be charged based on space used and are
          charged <b class="primary--text">Rp {{ price.toLocaleString('en-US') }}/GB</b>.
        </p>
        <!-- <p><b>Do you wish to proceed?</b></p> -->
      </v-card-text>
      <v-card-actions>
        <v-row class="ma-2">
          <v-col cols="6" class="pl-0">
            <v-btn
              :disabled="loading || loadingPrice"
              color="accent"
              block
              depressed
              height="50"
              @click="
                () => {
                  $emit('input', false);
                }
              "
            >
              <!-- loading indicator -->
              <beat-loader v-if="loading || loadingPrice" :loading="loading || loadingPrice" :color="'white'" :size="'10px'" class="ml-2" />

              Cancel
            </v-btn>
          </v-col>
          <v-col cols="6" class="px-0">
            <v-btn
              :disabled="loading || loadingPrice"
              color="secondary"
              block
              height="50"
              depressed
              @click="
                () => {
                  $emit('confirm', false);
                }
              "
            >
              <!-- loading indicator -->
              <beat-loader v-if="loading || loadingPrice" :loading="loading || loadingPrice" :color="'white'" :size="'10px'" class="ml-2" />

              Yes, I Agree
            </v-btn>
          </v-col>
        </v-row>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { computed } from "@vue/composition-api";
import api from "@/lib/api";

// const isDevModeSnapshot = process.env.VUE_APP_DEV_MODE === 'fase-1.3'
const isDevModeSnapshot = true;

export default {
  props: ["value", "loading"],
  data() {
    return {
      loadingPrice: false,
      price: 0,
    };
  },
  methods: {
    async getPrice() {
      if (this.price === 0 && isDevModeSnapshot) {
        this.loadingPrice = true;

        try {
          const res = await api.GET("/flavor/snapshot/organization-price");
          // console.log('res.data getPrice', res.data.price_per_month)
          this.price = res.data.price_per_month;
        } catch {
          this.$store.dispatch("HOMEPAGE/showErrorToast", "Sorry, an error occurred while displaying snapshot price");
        }

        this.loadingPrice = false;
      }
    },
  },
  mounted() {
    this.getPrice();
  },
  setup(props, { emit }) {
    const dialog = computed({
      get: () => props.value,
      set: (val) => {
        emit("input", val);
      },
    });

    return {
      dialog,
    };
  },
};
</script>
