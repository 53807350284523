var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-card-text',[_c('v-data-table',{staticClass:"mt-8",attrs:{"hide-default-footer":true,"headers":_vm.tblHeaders,"items-per-page":10,"items":_vm.list},scopedSlots:_vm._u([{key:"item.amount",fn:function(ref){
var item = ref.item;
return [_c('b',[_vm._v(" IDR "+_vm._s(item.amount ? item.amount.toLocaleString("en-US") : 0)+" ")])]}},{key:"item.created_at",fn:function(ref){
var item = ref.item;
return [_c('span',[_vm._v(" "+_vm._s(_vm.$moment(item.created_at).format("DD/MM/YYYY"))+" ")])]}},{key:"item.transaction_id",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"d-flex justify-content-between align-center"},[_c('router-link',{staticClass:"mr-4",attrs:{"to":'/billing/invoice/' + item.id}},[_vm._v(" Detail ")]),_c('v-btn',{attrs:{"color":"primary","elevation":"0"},on:{"click":function () {
              _vm.currentPeriod = _vm.$moment(item.created_at).format('DD-MM-YYYY');
              _vm.downloadInvoice(item.id);
            }}},[_vm._v(" Download ")])],1)]}},(_vm.list.length)?{key:"footer",fn:function(ref){
            var props = ref.props;
return [_c('custom-footer-datatable',{attrs:{"props":props}})]}}:null],null,true)}),_c('vue-html2pdf',{ref:"historyBillingInvoicePdf",attrs:{"show-layout":false,"float-layout":true,"enable-download":true,"filename":("Invoice " + _vm.currentPeriod),"pdf-quality":2,"manual-pagination":true,"pdf-format":"a4","pdf-orientation":"portrait"}},[_c('section',{attrs:{"slot":"pdf-content"},slot:"pdf-content"},[(_vm.detail)?_c('DownloadInvoice',{attrs:{"item":_vm.detail}}):_vm._e()],1)])],1)}
var staticRenderFns = []

export { render, staticRenderFns }