





























import { defineComponent } from '@vue/composition-api'
import { useNamespacedActions } from 'vuex-composition-helpers'
import { ref } from '@vue/composition-api'
import loginform from './login-form.vue'
import {VERIFYEMAIL} from './namespace'
export default defineComponent({
    components:{loginform},
    
})
