import { render, staticRenderFns } from "./objectstorage.vue?vue&type=template&id=58915244&scoped=true&"
import script from "./objectstorage.vue?vue&type=script&lang=js&"
export * from "./objectstorage.vue?vue&type=script&lang=js&"
import style0 from "./objectstorage.vue?vue&type=style&index=0&id=58915244&lang=scss&scoped=true&"
import style1 from "./objectstorage.vue?vue&type=style&index=1&lang=scss&"


/* normalize component */
import normalizer from "!../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "58915244",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VDataTable } from 'vuetify/lib/components/VDataTable';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VList } from 'vuetify/lib/components/VList';
import { VListItem } from 'vuetify/lib/components/VList';
import { VListItemTitle } from 'vuetify/lib/components/VList';
import { VMenu } from 'vuetify/lib/components/VMenu';
installComponents(component, {VDataTable,VIcon,VList,VListItem,VListItemTitle,VMenu})
