import beforeenterpermission from "../../lib/beforeenterpermission";
import billing from "./billing.vue";
import invoice from "./invoice.vue";
import invoice2 from "./invoice2.vue";
import summaryMonthly from "./summaryMonthly.vue";
import billingpersonal from "./billingpersonal.vue";
import payment from "./payment.vue";
import typedetail from "./typedetail.vue";
import history from "./history.vue";
import transactionhistory from "./transactionhistory.vue";
import summary from "./summary.vue";
import summaryCorporate from "./summaryCorporate.vue";
import billingCorporateDetail from "./billingCorporateDetail.vue";
import billingCorporateDetailBillingType from "./billingCorporateDetailBillingType.vue";
import billingCorporateDetailHistory from "./billingCorporateDetailHistory.vue";
import page from "./page.vue";
import { BILLING } from "./namespace";
import store from "./store";
import localstorage from "@/lib/localstorage";
import beforeselectfirst from "../../lib/beforeselectfirst";

const type = localstorage.getItem("type");
const currentOrg = JSON.parse(localStorage.getItem('currentOrg'))
const is_personal = currentOrg && currentOrg.is_personal ? true : false

export default {
  install(Vue, { VueEvent }) {
    Vue;
    Vue.addModules({ [BILLING]: store });
    Vue.addRoutetoDashboard({
      path: "billing",
      component: page,
      beforeEnter: (to, from, next) => {
        beforeenterpermission(Vue, next, "Billing", "disable", false)()
          .then(() => {
            const role = localstorage.getItem("role");
            const currentOrg = JSON.parse(localStorage.getItem("currentOrg"));

            if (role === "Superadmin" && !currentOrg) {
              beforeselectfirst(Vue)();
            }
          })
          .then(() => {
            next();
          })
          .catch(() => {
            if (from.path == "/") {
              next("/");
            }
          });
      },
      meta: {
        breadcrumb: {
          to: "/billing",
          text: "Billing",
        },
      },
      children: [
        {
          path: "/",
          component: is_personal ? summary : summaryCorporate,  
        },
        {
          path: "/billing/personal/:projectname",
          component: billingpersonal,
          meta: {
            breadcumb: {
              to: "/billing/personal/:projectname",
              text: "Billing Personal",
            },
          },
        },
        {
          path: "payment",
          component: page,
          meta: {
            breadcumb: {
              to: "/billing/payment",
              text: "Balance",
            },
          },
          children: [
            {
              path: "/",
              component: payment,
            },
            {
              path: "history",
              component: history,
            },
            {
              path: "transaction-history",
              component: transactionhistory,
            },
          ],
        },
        {
          path: "type",
          component: page,
          meta: {
            breadcumb: {
              to: "/billing/type",
              text: "Billing Type",
            },
          },
          children: [
            {
              path: ":id",
              component: typedetail,
            },
          ],
        },
        {
          path: "invoice",
          component: page,
          meta: {
            breadcumb: {
              to: "/billing/invoice",
              text: "Invoice",
            },
          },
          children: [
            {
              path: ":id",
              component: invoice,
            },
          ],
        },
        {
          path: "invoice2",
          component: page,
          meta: {
            breadcumb: {
              to: "/billing/invoice2",
              text: "Billing Invoice",
            },
          },
          children: [
            {
              path: ":id",
              component: invoice2,
            },
          ],
        },
        {
          path: "summary-monthly",
          component: page,
          meta: {
            breadcumb: {
              to: "/billing/summary-monthly",
              text: "Invoice",
            },
          },
          children: [
            {
              path: ":id",
              component: summaryMonthly,
            },
          ],
        },
        {
          path: ":id",
          component: is_personal ? billing : billingCorporateDetail,
          meta: {
            breadcrumb: {
              to: "/billing/:id",
              text: "Detail",
            },
          },
        },
        {
          path: ":id/history/:date",
          component: is_personal ? billing : billingCorporateDetailHistory,
          meta: {
            breadcrumb: {
              to: "/billing/:id/history/:date",
              text: "Detail",
            },
          },
        },
        {
          path: ":id/:billing_type",
          component: is_personal ? billing : billingCorporateDetailBillingType,
          meta: {
            breadcrumb: {
              to: "/billing/:id/:billing_type",
              text: "Detail",
            },
          },
        },
      ],
    }),
      VueEvent.listen("after-store-created", (store) => {
          store.commit("ROLEPERMISSION/addmenumodulepermission", {
            header: "BILLING",
            headerno: 3,
            name: "Billing",
            link: "billing",
            icon: "billingIcon",
            sort: 3,
            permission: ["billing-sidemenu"],
          });

          store.commit("ROLEPERMISSION/addmenumodulepermission", {
            header: "BILLING",
            headerno: 3,
            name: "Balance",
            link: "billing/payment",
            icon: "billingPaymentIcon",
            sort: 3,
            permission: ["billing-sidemenu"],
          }); 
      })
  },
};
