<template>
  <div>
    <v-card flat class="pa-7 rounded-0 rounded-b-lg">
      <v-card outlined flat class="px-7 rounded-lg">
        <v-row>
          <v-col v-if="isDevModeFloatingIP" cols="4">
            <p><b>Floating IP Type</b></p>
            <v-select
              id="floatingIpType"
              placeholder="Choose Floating IP Type"
              v-model="selectedFloatingIpType"
              :items="floatingIpTypes"
              outlined
              :error-messages="
                $v.selectedFloatingIpType.$errors.length
                  ? $v.selectedFloatingIpType.$errors[0].$message
                  : ''
              "
            />
            <p v-if="floatingIpPrice" class="primary--text">
              *{{ selectedFloatingIpType.charAt(0).toUpperCase() + selectedFloatingIpType.slice(1) }}
              Price RP {{ floatingIpPrice.toLocaleString('en-US') }}/month
            </p>
          </v-col>

          <v-col cols="4">
            <p><b>Billing Type</b></p>
            <v-select
              id="billingType"
              placeholder="Choose Instance Billing Type"
              v-model="selectedBillingType"
              :items="billingTypes"
              outlined
            ></v-select>
          </v-col>
          <v-col cols="4"
            v-if="selectedBillingType == 'Trial'"
          >
            <p><b>Trial Voucher</b></p>
            <v-select
              id="trialVoucher"
              placeholder="Choose trial voucher to use"
              v-model="selectedTrialVoucher"
              :error-messages="
                $v.selectedTrialVoucher.$errors.length
                  ? $v.selectedTrialVoucher.$errors[0].$message
                  : ''
              "
              :items="vouchers"
              item-text="voucher.name"
              item-value="id"
              outlined
            ></v-select>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="4" class="pt-0">
            <p><b>Resource Type</b></p>
            <v-select
              class="mt-2"
              placeholder="Resource Type"
              single-line
              outlined
              v-model="resourceType"
              :items="resourceTypes"
              :error-messages="
                $v.resourceType.$errors.length
                  ? $v.resourceType.$errors[0].$message
                  : ''
              "
            >
            </v-select>
          </v-col>

          <v-col cols="4" class="pt-0">
            <p><b>Resource</b></p>
            <v-autocomplete
              class="mt-2"
              :placeholder="`Search for ${ resourceType == 'Instance' ? 'an Instance' : 'a Load Balancer' }`"
              single-line
              outlined
              :filled="!resourceType"
              :disabled="!resourceType"
              v-model="resourceSelected"
              :items="resources"
              :error-messages="
                $v.resourceSelected.$errors.length
                  ? $v.resourceSelected.$errors[0].$message
                  : ''
              "
            >
              <template v-slot:selection="{ item }">
                <v-icon left color="primary">mdi-circle</v-icon>
                {{ item.name }}
              </template>
              <template v-slot:item="{ item }">
                <v-icon left color="primary">mdi-circle</v-icon>
                {{ item.name }}
              </template>
            </v-autocomplete>
          </v-col>

        </v-row>

        <v-row>
          <v-col
            cols="8"
            v-if="user.role.toLowerCase() !== 'superadmin' && agree2 === false"
          >
            <div class="d-flex align-center">
              <v-checkbox v-model="agree">
                <template v-slot:label>
                  <div>By Creating Floating IP You Agree To The</div>
                </template>
              </v-checkbox>
              <button
                type="button"
                class="text-decoration-underline primary--text ml-2 fz-16"
                @click="getEula()"
              >
                Terms of Service
              </button>
            </div>
          </v-col>

          <v-col cols="4" class="pt-0">
            <p></p>
            <br />
            <v-btn
              v-if="user.role.toLowerCase() !== 'superadmin'"
              block
              :disabled="agree === false && agree2 === false"
              depressed
              class="secondary"
              @click="
                () => {
                  validateprivilages(['Network', 'editor']).then(() => {
                    addFloatingIp();
                  });
                }
              "
            >
              <beat-loader
                v-if="isLoading"
                :loading="isLoading"
                :color="'white'"
                :size="'10px'"
                class="mr-2"
              ></beat-loader>
              <span v-else style="font-size: 14px">Assign Floating IP</span>
            </v-btn>
            <v-btn
              v-else
              block
              depressed
              class="secondary"
              @click="
                () => {
                  validateprivilages(['Network', 'editor']).then(() => {
                    addFloatingIp();
                  });
                }
              "
            >
              <beat-loader
                v-if="isLoading"
                :loading="isLoading"
                :color="'white'"
                :size="'10px'"
                class="mr-2"
              ></beat-loader>
              <span v-else style="font-size: 14px">Assign Floating IP</span>
            </v-btn>
          </v-col>
        </v-row>
      </v-card>
    </v-card>
    <v-card class="rounded-lg mt-7" flat v-if="floatingips.length">
      <v-container class="pa-8">
        <h3>Floating IP</h3>
        <br />
        <v-data-table
          hide-default-footer
          :items="floatingips"
          :headers="headers"
          :items-per-page="10"
        >
          <template v-slot:no-data>
            {{ isLoading ? 'Loading..' : 'No data available' }}
          </template>
          <template v-slot:item.ip="{ item }">
            <div>
                <p class="mb-0">TKP / {{ item.ip_address }}</p>
            </div>
          </template>
          <template v-slot:item.appliedto="{ item }">
            <div
              v-if="floatingipselected != item"
              style="height: 50px"
              class="d-flex flex-column justify-center"
            >
              <div v-if="item.instance">
                <router-link
                  :to="{ path: `/project/${activeProject}/${item.instance_id}/` }"
                  ><span class="primary--text">
                    {{ item.instance.name }}
                  </span></router-link
                >
              </div>
              <div v-else-if="item.load_balancer">
                <router-link
                  :to="{ path: `/network/loadbalancer/${item.load_balancer_id}/instances` }"
                  ><span class="primary--text">
                    {{ item.load_balancer.name }}
                  </span></router-link
                >
              </div>
              <div
                @click="
                  () => {
                    if (isFloatingIPLoading) return false;
                    validateprivilages([
                    'Network',
                    'editor',
                    ]).then(() => {
                      floatingipselected = item;
                    });
                  }
                "
                v-else
                :class="{'linkpointer': !isFloatingIPLoading}"
                style="text-decoration: underline"
              >
                Assign to Instance
              </div>
            </div>
            <div v-if="floatingipselected == item">
              <v-autocomplete
                style="width: 250px"
                @change="
                  (val) => {
                    assignnewvalue(item, val);
                  }
                "
                @blur="
                  () => {
                    floatingipselected = null;
                  }
                "
                :items="availableInstances"
                v-model="assignselected"
                :ref="`searchinstance${item.id}`"
                outlined
                placeholder="Search for a Instance"
                hide-details
              >
                <template v-slot:selection="{ item }">{{
                  item.name
                }}</template>
                <template v-slot:item="{ item }">
                  <div>
                    <div v-if="item.unassigned">
                      {{ item.name }}
                    </div>
                    <div v-else class="d-flex flex-row">
                      <v-icon left color="primary">mdi-circle</v-icon>
                      <div>
                        <p class="mb-0">{{ item.name }}</p>
                        <p class="mb-0">
                          {{ item.instancetype }}
                        </p>
                      </div>
                    </div>
                  </div>
                </template>
              </v-autocomplete>
            </div>
          </template>
          <template v-slot:item.action="{ item }">
            <popupquote
              :ref="`menu${item.id}`"
              :close-on-content-click="false"
              :nudge-left="30"
              :nudge-width="80"
              :offsetTop="21"
              :documentid="'popup' + item.id"
              offset-y
              allow-overflow
            >
              <template v-slot:activator="{ on, attrs }">
                <label
                  v-bind="attrs"
                  class="primary--text linkpointer"
                  style="position: relative; white-space: nowrap"
                  @click="
                    ($event) => {
                      on.click($event);
                    }
                  "
                  >More
                  <v-icon
                    :class="{
                      rotate: item.open,
                    }"
                    :id="'popup' + item.id"
                    color="primary"
                    >mdi-chevron-down</v-icon
                  >
                </label>
              </template>
              <v-list>
                <v-list-item
                  :disabled="disabledField || isFloatingIPLoading"
                  v-if="!item.load_balancer"
                  @click="
                    () => {
                      floatingipselected = item;
                      $refs['menu' + item.id]['dialog'] = false;
                    }
                  "
                >
                  <v-list-item-title>Reassign</v-list-item-title>
                </v-list-item>
                <v-list-item
                  :disabled="disabledField || isFloatingIPLoading"
                  v-if="item.instance || item.load_balancer"
                  @click="() => {
                    unassignFloatingIP(item.id)
                    $refs['menu' + item.id]['dialog'] = false;
                  }"
                >
                  <v-list-item-title>Unassign</v-list-item-title>
                </v-list-item>
                <v-list-item
                  :disabled="disabledField"
                  v-if="item.appliedto"
                  @click="
                    () => {
                      assignselected = {
                        unassigned: true,
                        instanceName: 'Unassign floating IP',
                      };

                      assignnewvalue(item, {
                        id: item.id,
                        unassigned: true,
                        instanceName: 'Unassign floating IP',
                      });

                      $refs['menu' + item.id]['dialog'] = false;
                    }
                  "
                >
                  <v-list-item-title>Unassign</v-list-item-title>
                </v-list-item>

                <v-list-item
                  :disabled="!!item.appliedto || disabledField"
                  @click="
                    () => {
                      opendialogdelete = true;
                      deleteselected = item;
                      $refs['menu' + item.id]['dialog'] = false;
                    }
                  "
                >
                  <v-list-item-title
                    ><span
                      :class="{
                        'accent--text': item.appliedto,
                        'error--text': !item.appliedto,
                      }"
                      >Delete</span
                    ></v-list-item-title
                  >
                </v-list-item>
              </v-list>
            </popupquote>
          </template>
          <template v-slot:footer="{ props }">
            <custom-footer-datatable :props="props" />
          </template>
        </v-data-table>
      </v-container>
    </v-card>
    <!-- <v-card flat class="mt-7 rounded-lg pa-7">
      <v-row>
        <v-col cols="8">
          <div class="headline mb-2 font-weight-bold font--text">
            Floating IP
          </div>
        </v-col>
        <v-col md="12">
          <v-card
            flat
            outlined
            class="d-flex rounded-lg flex-grow-1 flex-column text-center ma-auto"
          >
            <v-card-text style="padding-top: 72px; padding-bottom: 78px">
              <p
                style="
                  font-size: 16px;
                  margin-bottom: 10px;
                  text-transform: capitalize;
                "
                class="font-weight-bold"
              >
                Looks like you don’t have any Floating IP
              </p>
              <v-row>
                <v-col>
                  <p
                    style="
                      font-size: 12px;
                      color: #a5b3bf;
                    "
                  >
                    A floating IP is a static IP address that points to one of your Instance. It allows youto redirect network traffic to any of your Instance in the same datacenter.
                  </p>
                </v-col>
              </v-row>
            </v-card-text>
          </v-card>
        </v-col>
      </v-row>
    </v-card> -->
    <dialogDeleteFloat
      v-model="opendialogdelete"
      @delete="() => {
          deletef().then(()=>{
            opendialogdelete = false
          })
        }"
    />
    <Tncpopupfloating v-model="tnc" :data="dataTnc" />
  </div>
</template>

<script>
import network from "../NetworkComposition";
import { computed, ref, onMounted, watch } from "@vue/composition-api";
import {
  useNamespacedActions,
  useNamespacedGetters,
  useNamespacedState,
} from "vuex-composition-helpers";
import { INSTANCE } from "@/modules/instance/namespace";
import { internet } from "faker";
import { NETWORKFLOATINGIP } from "./namespace";
import dialogDeleteFloat from "./dialogDeleteFloat";
import { useVoucher } from '@/modules/voucher/useVoucher'
import api from '@/lib/api';
import localstorage from '@/lib/localstorage';
const { required, requiredIf } = require("@vuelidate/validators");
const useVuelidate = require("@vuelidate/core").default;
import { mapGetters } from "vuex";
import Tncpopupfloating from "./tncpopupfloating.vue";

const isDevModeFloatingIP = true

export default {
  components: { dialogDeleteFloat, Tncpopupfloating },
  data() {
    return {
      agree: false,
      agree2: false,
      tnc: false,
      dataTnc: {},
      isDevModeFloatingIP
    };
  },
  setup(props, context) {
    const resources = ref([])
    const resourceTypes = ['Instance', 'Load Balancer']
    const resourceType = ref(null)
    const resourceSelected = ref('');
    const floatingipselected = ref();
    const { floatingip, createFloatingIp } = network();
    const { activeProject, getIsFloatingIPLoading: isFloatingIPLoading } = useNamespacedGetters(NETWORKFLOATINGIP, ['activeProject', 'getIsFloatingIPLoading'])
    const {
      addfloatingip,
      DELETE_FLOATING_IP,
      fetchfloatingips,
      REASSIGN_FLOATING_IP,
      UNASSIGN_FLOATING_IP,
      fetchAvailableInstances
    } = useNamespacedActions(NETWORKFLOATINGIP, [
      'addfloatingip',
      'fetchfloatingips',
      'DELETE_FLOATING_IP',
      'REASSIGN_FLOATING_IP',
      'UNASSIGN_FLOATING_IP',
      'fetchAvailableInstances',
    ])

    const { availableInstances } = useNamespacedState('NETWORKFLOATINGIP', [
      'availableInstances',
    ])

    const {
      fetchLoadBalancers,
      assignFloatingIP : assignFloatingIPLB,
    } = useNamespacedActions('NETWORKLOADBALANCER', [
      'fetchLoadBalancers',
      'assignFloatingIP',
    ])

    const { loadBalancers } = useNamespacedState('NETWORKLOADBALANCER', [
      'loadBalancers',
    ])

    const { vouchers, fetchVouchers } = useVoucher()

    const defaultBillingTypes = [
      {
        text: "Pay Per Use (PPU)",
        value: "PPU",
      },
      {
        text: "Trial",
        value: "Trial",
      },
    ]

    const fixedPostpaid = [
      {
        text: "Fixed",
        value: "Fixed",
      },
      {
        text: "Trial",
        value: "Trial",
      },
    ]

    const projJson = localstorage.getItem('currentProj')
    const projObj = projJson && projJson !== 'undefined' ? JSON.parse(projJson) : {}
    const postpaid_type = projObj.postpaid_type

    // const billingTypes = ref(postpaid_type && postpaid_type === 'fixed' ? fixedPostpaid : defaultBillingTypes)
    const billingTypes = ref([])

    const disabledField = ref(false);
    const {validateprivilages, validateprivilagesync,validateprivilagesyncnew} = useNamespacedActions("HOMEPAGE", [
      "validateprivilages", "validateprivilagesync", "validateprivilagesyncnew"
    ]);

    validateprivilagesyncnew([
        'Network',
        'editor',
    ]).then((res) => {
      disabledField.value=res;
    });


    // const selectedBillingType = ref('Default')
    const selectedBillingType = ref('')
    const selectedTrialVoucher = ref(null)
    const floatingIpTypes = ref([])
    const selectedFloatingIpType = ref('')
    const floatingIpPrice = ref(0)

    watch(selectedFloatingIpType, (val) => {
      const type = floatingIpTypes.value.find((type) => type.value === val)
      floatingIpPrice.value = type.price
      resourceType.value = null
      resourceSelected.value = ''
    })

    const additionalRules = isDevModeFloatingIP ? {
      selectedFloatingIpType: { required },
    } : {}

    const $v = useVuelidate({
      selectedTrialVoucher: {
        required: requiredIf((val) => {
          return selectedBillingType.value === "Trial";
        }),
      },
      resourceType: isDevModeFloatingIP ? {
        required: requiredIf((val) => {
          return selectedFloatingIpType.value === "non-reserved";
        }),
      } : { required },
      // resourceType: { required },
      resourceSelected: isDevModeFloatingIP ? {
        required: requiredIf((val) => {
          return resourceType.value;
        }),
      } : { required },
      // resourceSelected: { required },
      ...additionalRules,
    }, isDevModeFloatingIP ? {
      selectedTrialVoucher,
      resourceType,
      resourceSelected,
      selectedFloatingIpType,
    } : {
      selectedTrialVoucher,
      resourceType,
      resourceSelected,
      // selectedFloatingIpType,
    })

    async function addFloatingIp() {
      $v.value.$touch()
      if ($v.value.$invalid) return false;

      if (selectedFloatingIpType && selectedFloatingIpType.value && selectedFloatingIpType.value === 'reserved' && !resourceType.value) {
        const payload = {
          id: '',
          voucher_id: selectedTrialVoucher.value,
          billing_type: selectedBillingType.value,
          ip_type: selectedFloatingIpType.value,
        }
        
        await addfloatingip(payload)
      }

      if (resourceSelected.value) {
        if (resourceType.value == 'Instance' && resourceSelected.value.openstack_vm_uuid) {
          const payload = {
            id: resourceSelected.value.id,
            voucher_id: selectedTrialVoucher.value,
            billing_type: selectedBillingType.value,
            ip_type: selectedFloatingIpType.value,
          }

          if (
            this.user.role.toLowerCase() !== "superadmin" &&
            this.agree2 === false
          ) {
            await this.validateEula();
          }
          await addfloatingip(payload)
        }
        else if (resourceType.value == 'Load Balancer' && resourceSelected.value.openstack_uuid) {
          const payload = {
            id: resourceSelected.value.id,
            voucher_id: selectedTrialVoucher.value,
            billing_type: selectedBillingType.value,
            ip_type: selectedFloatingIpType.value,
          }
          if (
            this.user.role.toLowerCase() !== "superadmin" &&
            this.agree2 === false
          ) {
            await this.validateEula();
          }
          await assignFloatingIPLB(payload)
        }
        else return context.root.$store.dispatch('HOMEPAGE/showErrorToast', `Unable to assign Floating IP. ${resourceType.value} ${resourceSelected.value.name} still in building process.`)
      }
    }

    async function getFloatingIpTypes(type, text) {
      try {
        const res = await api.GET("/network/floating-ip/price/" + type)
        // console.log(res.data, "res.data getFloatingIpTypes")
        floatingIpTypes.value.push({
          text,
          value: res.data.type,
          price: res.data.price_per_month,
        })
      } catch {
        context.root.$store.dispatch(
          "HOMEPAGE/showErrorToast",
          `Sorry, an error occurred while displaying floating IP ${type} type`
        );
      }
    }

    onMounted(async () => {
      if (postpaid_type && postpaid_type === 'fixed') {
        billingTypes.value = fixedPostpaid
        selectedBillingType.value = ''
      } else {
        billingTypes.value = defaultBillingTypes
        selectedBillingType.value = 'Default'
      }

      fetchAvailableInstances()
      fetchLoadBalancers()
      fetchVouchers()
      getFloatingIpTypes('reserved', 'Reserved')
      getFloatingIpTypes('non-reserved', 'Non-Reserved')
    })

    watch(() => resourceType.value, (value) => {
      if (value == 'Instance') resources.value = availableInstances.value
      else if (value == 'Load Balancer') resources.value = loadBalancers.value.filter(x => !x.floating_ip_id)
    })

    const {
      floatingips,
      isLoading,
    } = useNamespacedState(NETWORKFLOATINGIP, [
      'floatingips',
      'isLoading'
    ])

    const { fetchinstances } = useNamespacedActions(INSTANCE, [
      'fetchinstances',
    ])

    const { instances } = useNamespacedState(INSTANCE, ['instances']);
    const instanceunassigned = computed(() => {
      return [
        { unassigned: true, open: false, instanceName: "Unassign Floating IP", instance_id: null },
      ].concat(
        instances.value.map((x) => {
          x.open = false;
          return x;
        })
      );
    });
    const assignselected = ref();
    const opendialogdelete = ref(false);
    const headers = ref([
      { text: "Floating IP", value: "ip" },
      { text: "Assigned to", value: "appliedto", width: 300 },
      { text: "IP Type", value: "type" },
      { text: "", value: "", sortable:false },
      { text: "Action", value: "action" },
    ]);
    const deleteselected = ref()
    const getInstanceNameById = id => {
      const index = instances.value.findIndex(x => x.id === id)
      if(index > -1) return instances.value[index].instanceName
      return null
    }

    return {
      selectedFloatingIpType,
      floatingIpTypes,
      floatingIpPrice,
      isFloatingIPLoading,
      availableInstances,
      headers,
      assignselected,
      unassignFloatingIP: async (floatingIPID) => {
          await UNASSIGN_FLOATING_IP(floatingIPID)
      },
      assignnewvalue: async (ip, val) => {
        floatingipselected.value = null;
        if (val.unassigned) {
          await UNASSIGN_FLOATING_IP(ip.id)
        } else {
          const payload = {
            floating_ip_id: ip.id,
            instance_id: val.id,
          }
          await REASSIGN_FLOATING_IP(payload)
        }
      },
      log(ref) {
        ref.dialog = false;
      },
      deleteselected,
      deletef: async () => {
        await DELETE_FLOATING_IP({ id: deleteselected.value.id })
      },
      resourceTypes,
      validateprivilages, validateprivilagesync,validateprivilagesyncnew,
      disabledField,
      resourceType,
      isLoading,
      activeProject,
      floatingipselected,
      opendialogdelete,
      floatingip,
      addFloatingIp,
      fetchinstances,
      instances,
      instanceunassigned,
      resourceSelected,
      fetchfloatingips,
      getInstanceNameById,
      resources,
      floatingips,
      DELETE_FLOATING_IP,
      REASSIGN_FLOATING_IP,
      loadBalancers,
      vouchers,
      billingTypes,
      selectedTrialVoucher,
      selectedBillingType,
      $v,
    };
  },
  created() {
    this.fetchinstances()
    this.fetchfloatingips()
    this.checkEula()
  },
  computed: {
    ...mapGetters({
      projectId: "PROJECT/getprojectid",
      user: "ROLEPERMISSION/getuser"
    })
  },
  methods: {
    async validateEula() {
      var res = await api.POST("/useradmin/validate/eula", {
        form_name: "Form Create Floating IP",
        project_id: this.projectId
      });
    },
    async checkEula() {
      var res = await api.GET(
        "/useradmin/check/eula?project_id=" +
          this.projectId +
          "&form_name=Form Create Floating IP"
      );
      if (res.code === 200) {
        this.agree2 = res.data.is_check;
        console.log("agree : ", this.agree2);
      }
    },
    async getEula() {
      var res = await api.GET(
        "/useradmin/eula?form_name=Form Create Floating IP"
      );
      console.log("res : ", res);
      if (res.code === 200) {
        this.dataTnc = res.data;
        this.tnc = true;
      }
    }
  }
};
</script>

<style lang="scss" scoped>
.v-btn {
  height: 50px !important;
}
.v-list-item__title {
  user-select: none;
}
</style>