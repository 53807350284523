<template>
  <div>
    <p v-if="loading" class="text-center">
      <beat-loader color="gray" size="10px" class="mr-2" />
    </p>

    <template v-else>
      <v-card v-if="detail" color="white" flat>
        <v-card-text class="pa-8">
          <h6 class="text-h6 font-weight-bold">Detail Invoice</h6>

          <v-row class="mt-4">
            <v-col>
              <b>Report ID</b>
              <p class="mt-4">
                {{ detail.id }}
              </p>
            </v-col>

            <v-col>
              <b>Status</b>
              <p
                class="mt-4"
                :class="{
                  'green--text': detail.status === 'PAID',
                }"
              >
                {{ detail.status }}
              </p>
            </v-col>
          </v-row>

          <v-row>
            <v-col>
              <b>Issue Date</b>
              <p class="mt-4">
                {{ $moment(detail.created_at).format("DD MMMM YYYY") }}
              </p>
            </v-col>

            <v-col>
              <b>Due Date</b>
              <p class="mt-4">
                {{ $moment(detail.due_date).format("DD MMMM YYYY") }}
              </p>
            </v-col>
          </v-row>

          <v-row>
            <v-col>
              <b>Billing Account</b>
              <p class="mt-4">
                {{ detail.user.fullname }}
              </p>
            </v-col>
          </v-row>
        </v-card-text>
      </v-card>

      <v-card v-if="detail" color="white" flat class="mt-8">
        <v-card-text class="pa-8">
          <!-- tbl -->
          <v-data-table
            :hide-default-footer="true"
            :headers="tblHeaders"
            :items-per-page="99999"
            :items="list"
            class="mt-8"
          >
            <template v-slot:item.amount="{ item }">
              <b> IDR {{ item.amount.toLocaleString("en") }}/pc </b>
            </template>

            <template v-slot:item.billed="{ item }">
              <b> IDR {{ item.amount.toLocaleString("en") }} </b>
            </template>
          </v-data-table>

          <!-- totals -->
          <v-row>
            <v-col>
              <!-- empty (for spacing) -->
            </v-col>

            <v-col>
              <v-row class="grey lighten-4">
                <v-col>
                  <b>Subtotal</b>
                </v-col>
                <v-col>
                  <b>IDR {{ detail.amount.toLocaleString("en") }}</b>
                </v-col>
              </v-row>

              <v-row class="grey lighten-4 mt-4">
                <v-col>
                  <b>PPN ({{ detail.ppn }}%)</b>
                </v-col>
                <v-col>
                  <b>IDR {{ detail.ppn_value.toLocaleString("en") }}</b>
                </v-col>
              </v-row>

              <v-row class="mt-4 primary">
                <v-col>
                  <b class="white--text">Total</b>
                </v-col>
                <v-col>
                  <b class="white--text"
                    >IDR {{ detail.billed.toLocaleString("en") }}</b
                  >
                </v-col>
              </v-row>
            </v-col>
          </v-row>
        </v-card-text>
      </v-card>
    </template>
  </div>
</template>

<script>
import api from "@/lib/api";

export default {
  data() {
    return {
      tblHeaders: [
        { text: "Service Name", value: "service_name" },
        { text: "Qty", value: "qty" },
        { text: "Amount", value: "amount" },
        { text: "Total", value: "billed" },
      ],
      list: [],
      detail: null,
      loading: false,
    };
  },
  methods: {
    async getInvoiceDetail() {
      this.loading = true;

      try {
        const id = this.$route.params.id;
        const res = await api.GET("/balance/history/invoice/detail/" + id);
        this.detail = res.data;
        const { description, amount, billed } = res.data;
        this.list = [
          { service_name: description, qty: "1 pc", amount, billed },
        ];
      } catch {
        this.$store.dispatch(
          "HOMEPAGE/showErrorToast",
          "Sorry, an error occurred while displaying invoice detail"
        );
      }

      this.loading = false;
    },
  },
  mounted() {
    this.getInvoiceDetail();
  },
};
</script>
