import localstorage from "./localstorage";
export default (
  Vue: any,
  next: any,
  modules: string,
  role: string,
  validation: boolean = true
) => {
  return async () => {
    const userrole = localstorage.getItem("role");
    if (userrole == 'Superadmin') {
      return Promise.resolve()
    }

    const jsonPrivilages: any = localstorage.getItem("privilages");
    const array: any = JSON.parse(jsonPrivilages);
    const obj = array.find((el: any) => el.name == modules);
    const privilages = Vue.store.state.HOMEPAGE.privilages;
    if (obj && !obj.editor) {
      Vue.store.commit("DASHBOARD/setopendialogdonthavepermission", true);
      return Promise.reject();
    }
    if (privilages[modules] && privilages[modules][role] == validation) {
      return Promise.resolve();
    } else {
      Vue.store.commit("DASHBOARD/setopendialogdonthavepermission", true);
      return Promise.reject();
    }

   

    
  };
};
