<template>
  <v-container fluid>
    <v-row>
      <v-col class="pa-0">
        <v-card class="rounded-lg elevation-0 mb-5">
          <v-card-text class="pb-0 px-7 pt-6 d-flex flex-column">
            <div class="headline font-weight-bold font--text">Image</div>
          </v-card-text>
          <v-card-actions>
            <v-tabs v-model="tab">
              <v-tab to="/image/snapshot"
                ><span class="mx-5">Snapshot</span></v-tab
              >
              <!-- <v-tab to="/image/backups"
                ><span class="mx-5">Backups</span></v-tab
              > -->
              <v-tab
                v-if="isDevMode && isCorporate"
                to="/image/restore"
                ><span class="mx-5">Restore</span></v-tab
              >
              <v-tab to="/image/customimage"
                ><span class="mx-5">Custom Image</span></v-tab
              >
            </v-tabs>
          </v-card-actions>
        </v-card>
      </v-col>
    </v-row>
    <v-row>
      <v-col class="pa-0">
        <v-tabs-items v-model="$route.path">
          <v-tab-item value="/image/snapshot">
            <v-card flat class="rounded-lg elevation-0 mb-5 pt-6 pb-5 px-7">
              <v-card-text class="pa-0">
                <v-row>
                  <v-col class="pb-0 pt-0">
                    <div class="headline mb-3 font-weight-bold font--text">
                      Take a Snapshot for Instance
                    </div>
                    <p>
                      Power-down Instance before taking a snapshot to ensure
                      data consistency.
                    </p>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col cols="4">
                    <p><b>Billing Type</b></p>
                    <v-select
                      id="billingType"
                      placeholder="Choose Instance Billing Type"
                      v-model="selectedBillingType"
                      :items="billingTypes"
                      outlined
                    ></v-select>
                  </v-col>
                  <v-col cols="4" v-if="selectedBillingType == 'Trial'">
                    <p><b>Trial Voucher</b></p>
                    <v-select
                      id="trialVoucher"
                      placeholder="Choose trial voucher to use"
                      v-model="selectedTrialVoucher"
                      :error-messages="
                        createErrorMessage(
                          'selectedTrialVoucher',
                          $vSnapshotInstance
                        )
                      "
                      :items="vouchers"
                      item-text="voucher.name"
                      item-value="id"
                      outlined
                    ></v-select>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col md="4">
                    <v-autocomplete
                      outlined
                      allow-overflow
                      v-model="snapshotFrom"
                      placeholder="Choose an Instance"
                      :items="instanceSelect"
                      hide-no-data
                      item-text="text"
                      item-value="value"
                      :error-messages="
                        createErrorMessage('snapshotFrom', $vSnapshotInstance)
                      "
                    >
                      <template v-slot:selection="data">
                        <v-icon v-if="data.item.value.instanceName" left
                          >mdi-television</v-icon
                        >
                        <v-icon v-else left>mdi-database</v-icon>
                        <div>{{ data.item.text }}</div>
                      </template>
                      <template v-slot:item="data">
                        <div v-if="data.item.value.instanceName">
                          <v-icon left>mdi-television</v-icon>
                        </div>
                        <div v-else>
                          <v-icon left>mdi-database</v-icon>
                        </div>
                        <div>{{ data.item.text }}</div>
                      </template>
                    </v-autocomplete>
                  </v-col>
                  <v-col md="4">
                    <v-row>
                      <v-col class="py-0">
                        <div class="d-flex flex-row align-center">
                          <v-text-field
                            v-model="snapshotName"
                            outlined
                            placeholder="Enter Snapshot Name"
                            :error-messages="
                              createErrorMessage(
                                'snapshotName',
                                $vSnapshotInstance
                              )
                            "
                          ></v-text-field>
                        </div>
                      </v-col>
                    </v-row>
                  </v-col>
                </v-row>

                <v-row>
                  <v-col
                    cols="8"
                    v-if="
                      user.role.toLowerCase() !== 'superadmin' &&
                      agree1_1 === false
                    "
                  >
                    <div class="d-flex align-center">
                      <v-checkbox v-model="agree1">
                        <template v-slot:label>
                          <div>
                            By Creating Instance Snapshot You Agree To The
                          </div>
                        </template>
                      </v-checkbox>

                      <button
                        type="button"
                        class="text-decoration-underline primary--text ml-2 fz-16"
                        @click="getEula1()"
                      >
                        Terms of Service
                      </button>
                    </div>
                  </v-col>
                  <v-col md="2">
                    <v-btn
                      v-if="user.role.toLowerCase() !== 'superadmin'"
                      depressed
                      width="150"
                      height="50"
                      color="secondary"
                      :disabled="agree1 === false && agree1_1 === false"
                      @click="
                        () => {
                          isDevModeSnapshot
                            ? displaySnapshotDialog()
                            : doTakeSnapshot();
                        }
                      "
                    >
                      <beat-loader
                        v-if="snapshotInstanceIsLoading"
                        :loading="snapshotInstanceIsLoading"
                        :color="'white'"
                        :size="'10px'"
                        class="ml-2"
                      ></beat-loader>
                      <span v-else>Take Snapshot</span>
                    </v-btn>
                    <v-btn
                      v-else
                      depressed
                      width="150"
                      height="50"
                      color="secondary"
                      @click="
                        () => {
                          isDevModeSnapshot
                            ? displaySnapshotDialog()
                            : doTakeSnapshot();
                        }
                      "
                    >
                      <beat-loader
                        v-if="snapshotInstanceIsLoading"
                        :loading="snapshotInstanceIsLoading"
                        :color="'white'"
                        :size="'10px'"
                        class="ml-2"
                      ></beat-loader>
                      <span v-else>Take Snapshot</span>
                    </v-btn>
                  </v-col>
                </v-row>

                <v-row>
                  <v-col v-if="instanceSnapshots.length">
                    <v-data-table
                      id="TableSnapshotInstance"
                      :hide-default-footer="true"
                      :headers="headers"
                      :items="instanceSnapshots"
                      :items-per-page="10"
                      class="elevation-0"
                    >
                      <template v-slot:item.name="{ item }">
                        <div class="d-flex">
                          <div
                            class="dotblue mb-1"
                            style="transform: translateY(2px)"
                          ></div>
                          <div>
                            <span class="primary--text">
                              {{ item.name }}
                            </span>
                          </div>
                        </div>
                      </template>

                      <template v-slot:item.size="{ item }">
                        <div>{{ item.size ? `${item.size} GB` : "-" }}</div>
                      </template>

                      <template v-slot:item.status="{ item }">
                        <span
                          v-if="
                            !item.is_upload_success && !item.is_upload_failed
                          "
                        >
                          Not Ready Yet
                        </span>
                        <span
                          v-else-if="item.is_upload_success"
                          class="primary--text mb-0"
                        >
                          Active
                        </span>
                        <span
                          v-else-if="item.is_upload_failed"
                          class="error--text mb-0"
                        >
                          Failed
                        </span>
                        <span v-else>N/A</span>
                      </template>

                      <template v-slot:item.instance="{ item }">
                        <router-link
                          v-if="item.instance"
                          :to="`/project/${projectName}/${item.instance.id}/usage`"
                        >
                          <div class="d-flex align-items-center">
                            <div
                              class="dotblue"
                              style="transform: translateY(2px)"
                            ></div>
                            <div>
                              <span class="primary--text">
                                {{ item.instance.name }}
                              </span>
                            </div>
                          </div>
                        </router-link>
                        <div v-else>-</div>
                      </template>

                      <template v-slot:item.createddate="{ item }">
                        {{ moment(item.createddate).format("DD/MM/YYYY") }}
                      </template>

                      <template v-slot:item.action="{ item }">
                        <popupquote
                          v-model="item.open"
                          :offsetTop="21"
                          :documentid="'popup' + item.id"
                          offset-y
                          allow-overflow
                        >
                          <template v-slot:activator="{ on, attrs }">
                            <label
                              v-bind="attrs"
                              class="primary--text linkpointer"
                              style="position: relative; white-space: nowrap"
                              @click="
                                ($event) => {
                                  on.click($event);
                                  show($event, item);
                                  selectedSnapshot = item;
                                }
                              "
                              >More
                              <v-icon
                                :class="{
                                  rotate: item.open,
                                }"
                                :id="'popup' + item.id"
                                color="primary"
                                >mdi-chevron-down</v-icon
                              >
                            </label>
                          </template>
                          <v-list>
                            <v-list-item
                              :disabled="
                                !item.is_upload_success || disabledField
                              "
                              to="/instance/create"
                              exact
                            >
                              <v-list-item-title
                                >Create Instance</v-list-item-title
                              >
                            </v-list-item>

                            <v-list-item
                              @click="
                                () => {
                                  dialogRestore = true;
                                  selectedSnapshot = item;
                                }
                              "
                              style="border-bottom: 1px lightgrey solid"
                              :disabled="
                                !item.is_upload_success || disabledField
                              "
                            >
                              <v-list-item-title>
                                Restore Instance
                              </v-list-item-title>
                            </v-list-item>

                            <v-list-item
                              :disabled="disabledField"
                              @click="
                                () => {
                                  validateprivilages(['Images', 'editor']).then(
                                    () => {
                                      dialogDel = true;
                                    }
                                  );
                                }
                              "
                            >
                              <v-list-item-title
                                ><span class="error--text"
                                  >Delete</span
                                ></v-list-item-title
                              >
                            </v-list-item>
                          </v-list>
                        </popupquote>
                      </template>
                      <template
                        v-if="instanceSnapshots.length > 5"
                        v-slot:footer="{ props }"
                      >
                        <custom-footer-datatable :props="props" />
                      </template>
                    </v-data-table>
                  </v-col>
                  <v-col
                    v-else
                    md="6"
                    style="padding-top: 77px; padding-bottom: 76px"
                    class="d-flex flex-column text-center ma-auto"
                  >
                    <p style="font-size: 16px" class="font-weight-bold">
                      Looks like you don’t have any instance snapshots.
                    </p>
                    <p style="color: #a5b3bf">
                      Snapshots allow you to manually copy and store an image of
                      your volume. Take one using the form up top or from the
                      “More” dropdown menu on any volume.
                    </p>
                  </v-col>
                </v-row>
              </v-card-text>
            </v-card>
            <v-card flat class="rounded-lg elevation-0 mb-5 pt-6 pb-5 px-7">
              <v-card-text class="pa-0">
                <v-row>
                  <v-col class="pb-0 pt-0">
                    <div
                      class="headline mb-3 font-weight-bold font--text"
                      id="snapshotStorage"
                    >
                      Take a Snapshot for Storage
                    </div>
                    <p>
                      Power-down Instance before taking a snapshot to ensure
                      data consistency.
                    </p>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col cols="4">
                    <p><b>Billing Type</b></p>
                    <v-select
                      id="billingType"
                      placeholder="Choose Snapshot Billing Type"
                      v-model="selectedStorageBillingType"
                      :items="billingTypes"
                      outlined
                    ></v-select>
                  </v-col>
                  <v-col cols="4" v-if="selectedStorageBillingType == 'Trial'">
                    <p><b>Trial Voucher</b></p>
                    <v-select
                      id="trialVoucher"
                      placeholder="Choose trial voucher to use"
                      v-model="selectedStorageTrialVoucher"
                      :error-messages="
                        createErrorMessage(
                          'selectedStorageTrialVoucher',
                          $vStorage
                        )
                      "
                      :items="vouchers"
                      item-text="voucher.name"
                      item-value="id"
                      outlined
                    ></v-select>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col md="4">
                    <v-autocomplete
                      outlined
                      allow-overflow
                      v-model="snapshotStorageFrom"
                      placeholder="Choose a Storage"
                      :items="storageSelect"
                      hide-no-data
                      item-text="text"
                      item-value="value"
                      :error-messages="
                        createErrorMessage('snapshotStorageFrom', $vStorage)
                      "
                    >
                      <template v-slot:selection="data">
                        <v-icon v-if="data.item.value.instanceName" left
                          >mdi-television</v-icon
                        >
                        <v-icon v-else left>mdi-database</v-icon>
                        <div>{{ data.item.text }}</div>
                      </template>
                      <template v-slot:item="data">
                        <div v-if="data.item.value.instanceName">
                          <v-icon left>mdi-television</v-icon>
                        </div>
                        <div v-else>
                          <v-icon left>mdi-database</v-icon>
                        </div>
                        <div>{{ data.item.text }}</div>
                      </template>
                    </v-autocomplete>
                  </v-col>
                  <v-col md="4">
                    <v-row>
                      <v-col class="py-0">
                        <div class="d-flex flex-row align-center">
                          <v-text-field
                            v-model="snapshotStorageName"
                            outlined
                            placeholder="Enter Snapshot Name"
                            :error-messages="
                              createErrorMessage(
                                'snapshotStorageName',
                                $vStorage
                              )
                            "
                          ></v-text-field>
                        </div>
                      </v-col>
                    </v-row>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col
                    cols="8"
                    v-if="
                      user.role.toLowerCase() !== 'superadmin' &&
                      agree2_2 === false
                    "
                  >
                    <div class="d-flex align-center">
                      <v-checkbox v-model="agree2_1">
                        <template v-slot:label>
                          <div>
                            By Creating Storage Snapshot You Agree To The
                          </div>
                        </template>
                      </v-checkbox>

                      <button
                        type="button"
                        class="text-decoration-underline primary--text ml-2 fz-16"
                        @click="getEula2()"
                      >
                        Terms of Service
                      </button>
                    </div>
                  </v-col>
                  <v-col md="2">
                    <v-btn
                      v-if="user.role.toLowerCase() !== 'superadmin'"
                      depressed
                      width="150"
                      :disabled="agree2_1 === false && agree2_2 === false"
                      height="50"
                      color="secondary"
                      @click="
                        isDevModeSnapshot
                          ? displaySnapshotDialog2()
                          : doTakeSnapshotStorage()
                      "
                    >
                      <beat-loader
                        v-if="isLoading"
                        :loading="isLoading"
                        :color="'white'"
                        :size="'10px'"
                        class="ml-2"
                      ></beat-loader>
                      <span v-else>Take Snapshot</span>
                    </v-btn>
                    <v-btn
                      v-else
                      depressed
                      width="150"
                      height="50"
                      color="secondary"
                      @click="
                        isDevModeSnapshot
                          ? displaySnapshotDialog2()
                          : doTakeSnapshotStorage()
                      "
                    >
                      <beat-loader
                        v-if="isLoading"
                        :loading="isLoading"
                        :color="'white'"
                        :size="'10px'"
                        class="ml-2"
                      ></beat-loader>
                      <span v-else>Take Snapshot</span>
                    </v-btn>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col v-if="storageSnapshots.length" key="">
                    <v-data-table
                      id="TablestorageSnapshots"
                      :hide-default-footer="true"
                      :headers="headerstorage"
                      :items="storageSnapshots"
                      :items-per-page="10"
                      class="elevation-0"
                    >
                      <template v-slot:item.name="{ item }">
                        <div class="d-flex align-items-center">
                          <div class="dotblue"></div>
                          <div>
                            <span class="primary--text">
                              {{ item.name }}
                            </span>
                            <!-- <p>create from {{ item.storage.name }}</p> -->
                          </div>
                        </div>
                      </template>

                      <template v-slot:item.size="{ item }">
                        <div>{{ item.size ? `${item.size} GB` : "-" }}</div>
                      </template>

                      <template v-slot:item.status="{ item }">
                        <p
                          class="mb-0 primary--text"
                          v-if="item.is_upload_success"
                        >
                          Active
                        </p>
                        <p
                          class="mb-0 red--text"
                          v-else-if="item.is_upload_failed"
                        >
                          Failed
                        </p>
                        <p class="mb-0" v-else>Not Ready Yet</p>
                      </template>
                      <template v-slot:item.storage="{ item }">
                        <div
                          class="d-flex align-items-center mb-0"
                          v-if="item.storage"
                        >
                          <div class="dotblue"></div>
                          <div>
                            <span class="primary--text">
                              {{ item.storage.name }}
                            </span>
                          </div>
                        </div>
                        <div v-else>
                          <span>-</span>
                        </div>
                      </template>
                      <template v-slot:item.createddate="{ item }">
                        <p class="ma-0">
                          {{ moment(item.createddate).format("DD/MM/YYYY") }}
                        </p>
                      </template>

                      <template v-slot:item.action="{ item }">
                        <div class="mb-1">
                          <router-link
                            tag="button"
                            style="cursor: pointer"
                            :disabled="true"
                            to="/storage/create"
                          >
                            Create Storage
                          </router-link>
                          <span
                            style="
                              height: auto;
                              width: 20px;
                              display: inline-block;
                            "
                          />
                          <label
                            @click="
                              () => {
                                validateprivilages(['Images', 'editor']).then(
                                  () => {
                                    selectedSnapshot = item;
                                    dialogDel = true;
                                  }
                                );
                              }
                            "
                            style="cursor: pointer"
                            ><span class="error--text"> Delete</span></label
                          >
                        </div>
                      </template>
                      <template
                        v-if="storageSnapshots.length > 5"
                        v-slot:footer="{ props }"
                      >
                        <custom-footer-datatable :props="props" />
                      </template>
                    </v-data-table>
                  </v-col>
                  <v-col
                    v-else
                    md="6"
                    style="padding-top: 77px; padding-bottom: 76px"
                    class="d-flex flex-column text-center ma-auto"
                  >
                    <p style="font-size: 16px" class="font-weight-bold">
                      Looks like you don’t have any volume snapshots.
                    </p>
                    <p style="color: #a5b3bf">
                      Snapshots allow you to manually copy and store an image of
                      your volume. Take one using the form up top or from the
                      “More” dropdown menu on any volume.
                    </p>
                  </v-col>
                </v-row>
              </v-card-text>
            </v-card>
          </v-tab-item>
          <v-tab-item value="/image/backups">
            <v-card flat class="rounded-lg elevation-0 mb-5 pt-6 pb-5 px-7">
              <v-card-text class="pa-0">
                <v-row>
                  <v-col>
                    <div class="headline font-weight-bold font--text pb-4">
                      Enable backups
                    </div>
                    <br />
                    <p>
                      A backup is an automatic snapshot of your instance.
                      Backups are made once a week and retained for four weeks.
                      Once enabled, backups add 20% to monthly Instance cost.
                    </p>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col cols="6" md="5">
                    <v-row>
                      <v-col cols="6">
                        <div class="d-flex flex-column">
                          <h4 class="mb-2">Instance</h4>
                          <v-select
                            outlined
                            v-model="selectedInstance"
                            :items="instancecanbackupselected"
                            :error-messages="
                              createErrorMessage('selectedInstance', $vBackup)
                            "
                            placeholder="Choose an Instance"
                          >
                            <template v-slot:selection="{ item }">
                              {{ item.text }}
                            </template>
                          </v-select>
                        </div>
                      </v-col>
                      <v-col cols="6">
                        <div class="d-flex flex-column">
                          <h4 class="mb-2">Backup Time</h4>
                          <v-select
                            outlined
                            placeholder="Choose backup time"
                            :items="timeOptions"
                            v-model="selectedTime"
                            :error-messages="
                              createErrorMessage('selectedTime', $vBackup)
                            "
                          >
                          </v-select>
                        </div>
                      </v-col>
                    </v-row>
                  </v-col>
                  <v-col cols="6" md="5">
                    <v-row>
                      <v-col cols="6">
                        <div class="d-flex flex-column">
                          <h4 class="mb-2">Duration</h4>
                          <v-select
                            outlined
                            placeholder="Choose backup keep duration"
                            :items="durationOptions"
                            v-model="selectedDuration"
                            :error-messages="
                              createErrorMessage('selectedDuration', $vBackup)
                            "
                          >
                          </v-select>
                        </div>
                      </v-col>
                      <v-col cols="6">
                        <div class="d-flex flex-column">
                          <h4 class="mb-2">Backup Name</h4>
                          <v-text-field
                            placeholder="Enter backup scheduler name"
                            outlined
                            v-model="backupName"
                            :error-messages="
                              createErrorMessage('backupName', $vBackup)
                            "
                          >
                            <template v-slot:selection="{ item }">
                              {{ item.text }}
                            </template>
                          </v-text-field>
                        </div>
                      </v-col>
                    </v-row>
                  </v-col>
                  <v-col cols="6" md="2">
                    <div class="d-flex flex-column mt-3">
                      <v-btn
                        :disabled="disabledField"
                        depressed
                        width="150"
                        style="margin-top: 1.8rem"
                        height="50"
                        color="secondary"
                        @click="enablingBackup"
                        >Enable Backups</v-btn
                      >
                    </div>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col class="py-0">
                    <div class="headline font-weight-bold font--text">
                      Backup Scheduler
                    </div>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col>
                    <v-card flat class="tablecard">
                      <v-card-text class="py-0 px-3">
                        <v-row
                          class="rounded-lg"
                          style="
                            background-color: #f1f2f2;
                            font-weight: 700;
                            border-bottom: thin solid rgba(0, 0, 0, 0.12);
                          "
                        >
                          <v-col> Name </v-col>
                          <v-col> Instance </v-col>
                          <v-col> Backup Time </v-col>
                          <v-col> Duration </v-col>
                          <v-col> Number of Backup </v-col>
                          <v-col> Created At </v-col>
                          <v-col> Action </v-col>
                        </v-row>
                        <div
                          class="font-weight-bold text-center mt-4"
                          v-if="!backups.length"
                        >
                          {{
                            backupIsLoading ? "Loading.." : "No data available"
                          }}
                        </div>
                        <div v-else>
                          <div v-for="(item, i) in backups" :key="i">
                            <v-row
                              class="tbody"
                              style="
                                border-bottom: thin solid rgba(0, 0, 0, 0.12);
                              "
                            >
                              <v-col class="d-flex align-center">
                                <p class="mb-0">{{ item.name }}</p>
                              </v-col>
                              <v-col class="d-flex align-center">
                                <router-link
                                  class="mb-0"
                                  v-if="item.instance"
                                  :to="`/project/${item.instance.project.name}/${item.instance.id}/usage`"
                                  >{{ item.instance.name }}</router-link
                                >
                                <p class="mb-0" v-else>-</p>
                              </v-col>
                              <v-col class="d-flex align-center">
                                <p class="mb-0">
                                  {{
                                    item.hour >= 10
                                      ? `${item.hour}:00`
                                      : `0${item.hour}:00`
                                  }}
                                </p>
                              </v-col>
                              <v-col class="d-flex align-center">
                                <p class="mb-0">{{ item.day_duration }} days</p>
                              </v-col>
                              <v-col class="d-flex align-center">
                                <p
                                  @click="
                                    () => {
                                      if (item.images.length) {
                                        if (openListBackup == i)
                                          openListBackup = null;
                                        else openListBackup = i;
                                      }
                                    }
                                  "
                                  class="mb-0"
                                  :class="{ linkpointer: item.images.length }"
                                >
                                  <span
                                    >{{ item.images.length }}
                                    {{
                                      item.images.length > 1
                                        ? "Backups"
                                        : "Backup"
                                    }}</span
                                  >
                                  <v-icon
                                    color="primary"
                                    v-if="
                                      item.images.length && openListBackup == i
                                    "
                                    >mdi-chevron-up</v-icon
                                  >
                                  <v-icon
                                    color="primary"
                                    v-else-if="item.images.length"
                                    >mdi-chevron-down</v-icon
                                  >
                                </p>
                              </v-col>
                              <v-col class="d-flex align-center">
                                <p class="mb-0">
                                  {{
                                    moment(item.created_at).format("DD/MM/YYYY")
                                  }}
                                </p>
                              </v-col>
                              <v-col class="d-flex align-center">
                                <p
                                  class="linkpointer error--text mb-0"
                                  @click="
                                    () => {
                                      validateprivilages([
                                        'Images',
                                        'editor',
                                      ]).then(() => {
                                        showDialogDeleteBackup(item);
                                      });
                                    }
                                  "
                                >
                                  Delete
                                </p>
                              </v-col>
                            </v-row>
                            <transition name="slide-fade">
                              <div v-if="openListBackup == i">
                                <v-row
                                  class="tbody"
                                  v-for="(image, index) in item.images"
                                  :key="index"
                                >
                                  <v-col cols="12" md="10" class="pl-7">
                                    <div class="d-flex">
                                      <div class="dotblue mt-2"></div>
                                      <div style="color: #556272">
                                        {{ image.name }}
                                        <p
                                          class="accent--text"
                                          style="font-weight: 500"
                                        >
                                          Created
                                          {{
                                            moment(image.created_at).fromNow()
                                          }}
                                        </p>
                                      </div>
                                    </div>
                                  </v-col>
                                  <v-col class="d-flex align-center pt-0">
                                    <popupquote
                                      v-model="image.open"
                                      :offsetTop="24"
                                      :documentid="'popup' + image.id"
                                      offset-y
                                      :nudge-left="70"
                                      allow-overflow
                                    >
                                      <template
                                        v-slot:activator="{ on, attrs }"
                                      >
                                        <label
                                          v-bind="attrs"
                                          class="primary--text linkpointer"
                                          style="
                                            position: relative;
                                            white-space: nowrap;
                                          "
                                          @click="
                                            ($event) => {
                                              on.click($event);
                                            }
                                          "
                                          >More
                                          <v-icon
                                            :class="{
                                              rotate: image.open,
                                            }"
                                            :id="'popup' + image.id"
                                            color="primary"
                                            >mdi-chevron-down</v-icon
                                          >
                                        </label>
                                      </template>
                                      <v-list>
                                        <v-list-item
                                          to="/instance/create"
                                          exact
                                        >
                                          <v-list-item-title>
                                            Create Instance
                                          </v-list-item-title>
                                        </v-list-item>
                                        <v-list-item
                                          @click="
                                            () => {
                                              dialogRestore = true;
                                              selectedSnapshot = item;
                                            }
                                          "
                                        >
                                          <v-list-item-title>
                                            Restore Instance
                                          </v-list-item-title>
                                        </v-list-item>
                                      </v-list>
                                    </popupquote>
                                  </v-col>
                                </v-row>
                              </div>
                            </transition>
                          </div>
                        </div>
                      </v-card-text>
                    </v-card>
                  </v-col>
                </v-row>
                <br />
              </v-card-text>
            </v-card>
          </v-tab-item>
          <v-tab-item value="/image/customimage">
            <v-card flat class="rounded-lg elevation-0 mb-5 pt-6 pb-5 px-7">
              <v-card-text class="pa-0">
                <v-row>
                  <v-col>
                    <div class="headline font-weight-bold font--text pb-4">
                      Custom Image
                    </div>
                  </v-col>
                  <v-col class="flex-row text-right">
                    <v-btn
                      style="border: 3px solid"
                      depressed
                      width="150"
                      height="50"
                      class="secondary"
                      :disabled="disabledField"
                      @click="
                        () => {
                          showDialogImportImageURL = true;
                        }
                      "
                      >Import via URL</v-btn
                    >
                    <div
                      style="height: auto; display: inline-block; width: 30px"
                    />
                  </v-col>
                </v-row>

                <v-row
                  ><v-col>
                    <v-data-table
                      hide-default-footer
                      :headers="headercustomimage"
                      :items="customImages.filter((x) => x.is_custom === 1)"
                      :items-per-page="10"
                      class="elevation-0"
                    >
                      <template v-slot:item.image="{ item }">
                        <div class="d-flex">
                          <div class="dotblue mt-2"></div>
                          <div>
                            <span class="primary--text">
                              {{ item.name }}
                            </span>
                            <p>version {{ item.version }}</p>
                          </div>
                        </div>
                      </template>

                      <template v-slot:item.status="{ item }">
                        <p class="mb-0" v-if="item.is_upload_success">Active</p>
                        <p
                          class="mb-0 red--text"
                          v-else-if="item.is_upload_failed"
                        >
                          Failed
                        </p>
                        <p class="mb-0" v-else>Not Ready Yet</p>
                      </template>

                      <template v-slot:item.createddate="{ item }">
                        <p class="mb-0">
                          {{ moment(item.created_at).format("DD/MM/YYYY") }}
                        </p>
                      </template>

                      <template v-slot:item.action="{ item }">
                        <span
                          style="
                            height: auto;
                            width: 20px;
                            display: inline-block;
                          "
                        />
                        <label
                          style="cursor: pointer"
                          @click="
                            () => {
                              validateprivilages(['Images', 'editor']).then(
                                () => {
                                  selectedCustomImage = item;
                                  showDialogDeleteCustomImage = true;
                                }
                              );
                            }
                          "
                          ><span class="error--text">Delete</span></label
                        >
                      </template>
                      <template
                        v-if="customImages.length > 5"
                        v-slot:footer="{ props }"
                      >
                        <custom-footer-datatable :props="props" />
                      </template>
                    </v-data-table>
                  </v-col>
                </v-row>
              </v-card-text>
            </v-card>
          </v-tab-item>
          <v-tab-item
            v-if="isCorporate"
            value="/image/restore"
          >
            <restore />
          </v-tab-item>
        </v-tabs-items>
      </v-col>
    </v-row>
    <dialogDeleteCustomImage
      v-model="showDialogDeleteCustomImage"
      :selectedCustomImage="selectedCustomImage"
    />
    <dialogDeleteBackup
      v-model="openDialogDeleteBackup"
      :selectedBackup="selectedBackup"
      @delete="doDeleteBackup(selectedBackup.id)"
    />
    <!-- <dialogUploadImage
      v-model="dialoguploadimage"
      @close="
        () => {
          dialoguploadimage = false;
        }
      "
    /> -->
    <dialogImportImageURL
      v-model="showDialogImportImageURL"
      :agree2="agree"
      @close="
        () => {
          showDialogImportImageURL = false;
        }
      "
    />
    <dialogBeforeRestoreInstance
      v-model="opendialogbeforerestoreinstance"
      @close="
        ($event) => {
          opendialogbeforerestoreinstance = $event;
        }
      "
      :item="payloadbeforerestore"
    />
    <dialogRestoreInstance
      v-model="dialogRestore"
      :selectedSnapshot="selectedSnapshot"
      @close="
        ($event) => {
          dialogRestore = $event;
        }
      "
      @submit="
        ($event) => {
          dialogRestore = false;
          opendialogbeforerestoreinstance = true;
          payloadbeforerestore = $event;
        }
      "
    />
    <dialogDelete
      v-if="selectedSnapshot"
      :snapshot="selectedSnapshot"
      v-model="dialogDel"
      @close="
        ($event) => {
          dialogDel = $event;
        }
      "
    />
    <dialogSnapshotConfirmation
      v-if="snapshotDialog"
      v-model="snapshotDialog"
      @confirm="doTakeSnapshot"
      :loading="isLoading"
    />
    <dialogSnapshotValidationBalance
      v-if="snapshotValidation"
      v-model="snapshotValidation"
      :data="{ credit: 0, price: 200000 }"
      @confirm="
        () => {
          $router.push('/billing');
        }
      "
      @input="
        () => {
          snapshotValidation = false;
        }
      "
    />
    <dialogSnapshotConfirmation
      v-if="snapshotDialog2"
      v-model="snapshotDialog2"
      @confirm="doTakeSnapshotStorage"
      :loading="isLoading"
    />
    <Tncpopupinstance v-model="tnc1" :data="dataTnc1" />
    <Tncpopupstorage v-model="tnc2" :data="dataTnc2" />
  </v-container>
</template>

<script>
import restore from "./restore";
import dialogBeforeRestoreInstance from "./dialogBeforeRestoreInstance";
import dialogUploadImage from "./dialogUploadImage";
import dialogImportImageURL from "./dialogImportImageURL";
import dialogRenameImage from "./dialogRenameImage";
import dialogAddtoRegion from "./dialogAddtoRegion";
import dialogRestoreInstance from "./dialogRestoreInstance";
import dialogTransfer from "./dialogTransfer";
import dialogDelete from "./dialogDelete";
import { computed, ref, onMounted, watch } from "@vue/composition-api";
import { useCreateStorage } from "../storage/composableapi/usecreatestorage";
import { useCreateSnapshot } from "./composableapi/usecreatesnapshot";
import { useBackupInstance } from "./composableapi/usebackupinstance";
import DialogImportImageURL from "./dialogImportImageURL.vue";
import libProject from "@/lib/project";
import {
  useNamespacedActions,
  useNamespacedState,
} from "vuex-composition-helpers";
import moment from "moment";
import Vue from "vue";
import dialogDeleteCustomImage from "./dialogDeleteCustomImage";
import dialogDeleteBackup from "./dialogDeleteBackup";
import { useVoucher } from "@/modules/voucher/useVoucher";
const { required, requiredIf, helpers } = require("@vuelidate/validators");
import useVuelidate from "@vuelidate/core";
import localstorage from "@/lib/localstorage";
import api from "@/lib/api";
import dialogSnapshotConfirmation from "./dialogSnapshotConfirmation";
import dialogSnapshotValidationBalance from "./dialogSnapshotValidationBalance";
import Tncpopupinstance from "./tncpopupinstance.vue";
import Tncpopupstorage from "./tncpopupstorage.vue";
import { mapGetters } from "vuex";

// const isDevMode = process.env.VUE_APP_DEV_MODE === "fase-1.3";
const isDevMode = true;
// const isDevModeSnapshot = process.env.VUE_APP_DEV_MODE === 'fase-1.3'
const isDevModeSnapshot = true;

export default {
  setup() {
    const snapshotDialog = ref(false);
    const snapshotValidation = ref(false);
    const snapshotDialog2 = ref(false);

    const selectedBillingType = ref("Default");
    const selectedTrialVoucher = ref(null);
    const trialVoucherError = ref("");

    const selectedStorageBillingType = ref("Default");
    const selectedStorageTrialVoucher = ref(null);

    const { isLoading: isInstancesLoading } = useNamespacedState("INSTANCE", [
      "isLoading",
    ]);
    const displaySnapshotValidationDialog = () => {
      snapshotValidation.value = true;
    };
    const displaySnapshotDialog = () => {
      $vSnapshotInstance.value.$touch();

      if (
        $vSnapshotInstance.value.selectedTrialVoucher.$invalid ||
        $vSnapshotInstance.value.snapshotName.$invalid ||
        $vSnapshotInstance.value.snapshotFrom.$invalid
      )
        return false;

      snapshotDialog.value = true;
    };
    const displaySnapshotDialog2 = () => {
      $vStorage.value.snapshotStorageFrom.$touch();
      $vStorage.value.snapshotStorageName.$touch();
      $vStorage.value.selectedStorageTrialVoucher.$touch();

      if (
        $vStorage.value.selectedStorageTrialVoucher.$invalid ||
        $vStorage.value.snapshotStorageName.$invalid ||
        $vStorage.value.snapshotStorageFrom.$invalid
      )
        return false;

      snapshotDialog2.value = true;
    };

    const {
      CreateSnapshot,
      CreateStorageSnapshot,
      isLoading: snapshotInstanceIsLoading,
    } = useCreateSnapshot();

    const {
      selectedDuration,
      selectedTime,
      backupName,
      selectedInstance,
      enableBackup,
      disableBackup,
    } = useBackupInstance();

    const Composable = {
      ...useBackupInstance(),
      ...useCreateStorage(),
      ...useCreateSnapshot(),
    };

    const { storageSelect, instanceSelect, fetchinstances, fetchstorages } =
      Composable;
    const storageAndInstanceSelect = computed(() => {
      const con = instanceSelect.value.concat(storageSelect.value);
      return con;
    });

    const searchinstance = ref("");
    const filteredInstance = ref([]);
    const {
      snapshotFrom,
      snapshotName,
      snapshotStorageFrom,
      snapshotStorageName,
      takeInstanceSnapshot,
      takeStorageSnapshot,
    } = Composable;

    const $vStorage = useVuelidate(
      {
        snapshotStorageName: { required },
        snapshotStorageFrom: { required },
        selectedStorageTrialVoucher: {
          required: helpers.withMessage("The value is required", (val) => {
            if (
              selectedStorageBillingType.value == "Trial" &&
              selectedStorageTrialVoucher.value
            )
              return true;
            else if (selectedStorageBillingType.value != "Trial") return true;
            else return false;
          }),
        },
      },
      {
        snapshotStorageName,
        snapshotStorageFrom,
        selectedStorageTrialVoucher,
      },
      { lazy: true }
    );

    const doTakeSnapshotStorage = async () => {
      $vStorage.value.snapshotStorageFrom.$touch();
      $vStorage.value.snapshotStorageName.$touch();
      $vStorage.value.selectedStorageTrialVoucher.$touch();

      if (
        $vStorage.value.selectedStorageTrialVoucher.$invalid ||
        $vStorage.value.snapshotStorageName.$invalid ||
        $vStorage.value.snapshotStorageFrom.$invalid
      )
        return false;

      const currProj = await libProject.getActiveProject();
      const data = {
        project_id: await libProject.getActiveProjectID(),
        name: snapshotStorageName.value,
        storage_id: snapshotStorageFrom.value?.id,
        billing_type:
          selectedStorageBillingType.value === "Trial"
            ? "Trial"
            : currProj.payment_method,
      };

      if (
        selectedStorageBillingType.value == "Trial" &&
        selectedStorageTrialVoucher.value
      )
        data.voucher_id = selectedStorageTrialVoucher.value;
      const response = await takeStorageSnapshot(data);
      snapshotDialog2.value = false;
      if (response.status == 200) {
        if (
          this.user.role.toLowerCase() !== "superadmin" &&
          this.agree2_2 === false
        ) {
          this.validateEula2();
        }
        if (
          this.user.role.toLowerCase() !== "superadmin" &&
          this.agree2_2 === true
        ) {
          console.log("success");
        }
        if (this.user.role.toLowerCase() === "superadmin") {
          console.log("success");
        }
        snapshotStorageName.value = null;
        snapshotStorageFrom.value = null;
        selectedStorageTrialVoucher.value = null;
        selectedStorageBillingType.value = "Default";
        $vStorage.value.$reset();
      }

      snapshotDialog2.value = false;
    };

    const { vouchers, fetchVouchers } = useVoucher();
    const defaultBillingTypes = [
      {
        text: "Pay Per Use (PPU)",
        value: "PPU",
      },
      {
        text: "Trial",
        value: "Trial",
      },
    ];

    const fixedPostpaid = [
      {
        text: "Fixed",
        value: "Fixed",
      },
      {
        text: "Trial",
        value: "Trial",
      },
    ];

    const projJson = localstorage.getItem("currentProj");
    const projObj =
      projJson && projJson !== "undefined" ? JSON.parse(projJson) : {};
    const postpaid_type = projObj.postpaid_type;

    const billingTypes = ref(
      postpaid_type && postpaid_type === "fixed"
        ? fixedPostpaid
        : defaultBillingTypes
    );

    watch(selectedTrialVoucher, (val) => {
      if (val) trialVoucherError.value = "";
    });

    const $vBackup = useVuelidate(
      {
        selectedDuration: { required, $autoDirty: true },
        selectedTime: { required, $autoDirty: true },
        backupName: { required, $autoDirty: true },
        selectedInstance: { required, $autoDirty: true },
      },
      {
        selectedInstance,
        selectedDuration,
        selectedTime,
        backupName,
      }
    );

    const createErrorMessage = (element, validate) => {
      return validate[element].$errors.length
        ? validate[element].$errors[0].$message
        : "";
    };

    const selectedSnapshot = ref(null);
    const enablingBackup = async () => {
      $vBackup.value.$touch();
      if (
        $vBackup.value.backupName.$invalid &&
        $vBackup.value.selectedDuration.$invalid &&
        $vBackup.value.selectedTime.$invalid &&
        $vBackup.value.selectedTrialVoucher.$invalid
      )
        return false;

      const payload = {
        name: backupName.value,
        day_duration: selectedDuration.value,
        hour: selectedTime.value,
        instance_id: selectedInstance.value.id,
      };

      const response = await enableBackup(payload);
    };

    const $vSnapshotInstance = useVuelidate(
      {
        snapshotName: { required },
        snapshotFrom: { required },
        selectedTrialVoucher: {
          required: requiredIf((val) => {
            return selectedBillingType.value === "Trial";
          }),
        },
      },
      {
        snapshotName,
        snapshotFrom,
        selectedTrialVoucher,
      },
      { lazy: true }
    );

    const disabledField = ref(false);
    const {
      validateprivilages,
      validateprivilagesync,
      validateprivilagesyncnew,
      openDialogDontHavePermition,
    } = useNamespacedActions("HOMEPAGE", [
      "validateprivilages",
      "validateprivilagesync",
      "validateprivilagesyncnew",
      "openDialogDontHavePermition",
    ]);

    validateprivilagesyncnew(["Images", "editor"]).then((res) => {
      disabledField.value = res;
    });

    const doTakeSnapshot = async () => {
      $vSnapshotInstance.value.$touch();
      if (
        $vSnapshotInstance.value.selectedTrialVoucher.$invalid ||
        $vSnapshotInstance.value.snapshotName.$invalid ||
        $vSnapshotInstance.value.snapshotFrom.$invalid
      )
        return false;
      const currProj = await libProject.getActiveProject();
      const activeProjectID = await libProject.getActiveProjectID();
      let payload = {
        project_id: activeProjectID,
        name: snapshotName.value,
        instance_id: snapshotFrom.value.id,
        billing_type:
          selectedBillingType.value === "Trial"
            ? "Trial"
            : currProj.payment_method,
        filterByInstance: false,
      };

      if (selectedBillingType.value == "Trial" && selectedTrialVoucher.value)
        payload.voucher_id = selectedTrialVoucher.value;

      const response = await takeInstanceSnapshot(payload);

      snapshotName.value = null;
      snapshotFrom.value = null;
      selectedTrialVoucher.value = null;
      selectedBillingType.value = "Default";
      snapshotDialog.value = false;

      if (response.status == 200) {
        if (
          this.user.role.toLowerCase() !== "superadmin" &&
          this.agree1_1 === false
        ) {
          this.validateEula1();
        }
        if (
          this.user.role.toLowerCase() !== "superadmin" &&
          this.agree1_1 === true
        ) {
          console.log("success");
        }
        if (this.user.role.toLowerCase() === "superadmin") {
          console.log("success");
        }
        $vSnapshotInstance.value.$reset();
      }
      snapshotDialog.value = false;
    };

    const doDeleteBackup = async (id) => {
      const response = await disableBackup(id);
      if (response.status == 200) {
        openDialogDeleteBackup.value = false;
        selectedBackup.value = null;
      }
    };

    const doRestoreInstance = async (event) => {
      console.log("event : ", event);
      // const response = await disableBackup(id);
      // if (response.status == 200) {
      //   openDialogDeleteBackup.value = false;
      //   selectedBackup.value = null;
      // }
    };

    // Custom Images
    const { customImages, instanceSnapshots, storageSnapshots, backups } =
      useNamespacedState("IMAGES", [
        "customImages",
        "instanceSnapshots",
        "storageSnapshots",
        "backups",
      ]);
    const {
      fetchCustomImage,
      fetchBackups,
      deleteCustomImage,
      fetchInstanceSnapshots,
      fetchStorageSnapshots,
    } = useNamespacedActions("IMAGES", [
      "fetchCustomImage",
      "deleteCustomImage",
      "fetchInstanceSnapshots",
      "fetchStorageSnapshots",
      "fetchBackups",
    ]);

    onMounted(async () => {
      fetchinstances();
      fetchstorages();
      fetchCustomImage();
      fetchInstanceSnapshots();
      fetchStorageSnapshots();
      fetchBackups();
      fetchVouchers();
    });

    const showDialogDeleteCustomImage = false;
    const selectedCustomImage = ref("");
    const openListBackup = ref(null);

    const selectedBackup = ref(null);
    const openDialogDeleteBackup = ref(false);
    const showDialogDeleteBackup = (item) => {
      if (disabledField) {
        openDialogDontHavePermition();
      } else {
        openDialogDeleteBackup.value = true;
        selectedBackup.value = item;
      }
    };

    return {
      displaySnapshotDialog,
      snapshotDialog,
      displaySnapshotDialog2,
      snapshotDialog2,
      ...Composable,
      selectedBackup,
      openDialogDeleteBackup,
      showDialogDeleteBackup,
      openListBackup,
      doDeleteBackup,
      projectName: localStorage.getItem("projectname"),
      showDialogDeleteCustomImage,
      selectedCustomImage,
      moment,
      disabledField,
      validateprivilages,
      validateprivilagesync,
      validateprivilagesyncnew,
      customImages,
      $vStorage,
      selectedSnapshot,
      snapshotStorageFrom,
      snapshotStorageName,
      snapshotFrom,
      snapshotName,
      createErrorMessage,
      searchinstance,
      filteredInstance,
      storageAndInstanceSelect,
      enablingBackup,
      selectedDuration,
      selectedTime,
      backupName,
      selectedInstance,
      $vSnapshotInstance,
      $vBackup,
      vouchers,
      selectedTrialVoucher,
      selectedStorageTrialVoucher,
      selectedBillingType,
      selectedStorageBillingType,
      billingTypes,
      doTakeSnapshot,
      trialVoucherError,
      snapshotInstanceIsLoading,
      instanceSelect,
      doTakeSnapshotStorage,
      doRestoreInstance,
      snapshotValidation,
      displaySnapshotValidationDialog,
    };
  },
  created() {
    this.checkEula1();
    this.checkEula2();
    this.checkEula3();
  },
  beforeRouteUpdate(to, from, next) {
    next();
  },
  watch: {},
  data() {
    const customimage = [];
    return {
      agree1: false,
      agree1_1: false,
      agree2_1: false,
      agree2_2: false,
      agree: false,
      tnc1: false,
      tnc2: false,
      dataTnc1: {},
      dataTnc2: {},
      isDevMode,
      isDevModeSnapshot,
      isRestoreError: true,
      payloadbeforerestore: null,
      opendialogbeforerestoreinstance: false,
      dialogDel: false,
      dialogT: false,
      dialogRestore: false,
      dialogRegion: false,
      dialogRename: false,
      dialoguploadimage: false,
      showDialogImportImageURL: false,
      editfilelisting: false,
      editcdn: false,
      tab: null,
      tab1: null,
      customimage,
      headers: [
        { text: "Name", value: "name", width: 300 },
        { text: "Status", value: "status" },
        { text: "Instance Name", value: "instance", width: 300 },
        { text: "Size", value: "size" },
        { text: "Created Date", value: "createddate" },
        { text: "Action", sortable: false, align: "center", value: "action" },
      ],
      headerstorage: [
        { text: "Name", value: "name", width: 300 },
        { text: "Status", value: "status" },
        { text: "Storage Name", value: "storage", width: 300 },
        { text: "Size", value: "size" },
        { text: "Created Date", value: "createddate" },
        { text: "Action", sortable: false, align: "center", value: "action" },
      ],
      headers1: [
        { text: "Name", value: "name" },
        { text: "Action", value: "action", sortable: false },
      ],
      headercustomimage: [
        { text: "Image", value: "image" },
        { text: "Status", value: "status" },
        { text: "Created Date", value: "createddate" },
        { text: "Action", sortable: false, align: "center", value: "action" },
      ],
      panel: [0],
      showMenu: false,
      x: 0,
      y: 0,
    };
  },
  methods: {
    async validateEula1() {
      var res = await api.POST("/useradmin/validate/eula", {
        form_name: "Form Create Instance Snapshot",
        project_id: this.projectId,
      });
      if (res.code === 200) {
        console.log("success");
      }
    },
    async validateEula2() {
      var res = await api.POST("/useradmin/validate/eula", {
        form_name: "Form Create Storage Snapshot",
        project_id: this.projectId,
      });
      if (res.code === 200) {
        console.log("success");
      }
    },
    async checkEula1() {
      var res = await api.GET(
        "/useradmin/check/eula?project_id=" +
          this.projectId +
          "&form_name=Form Create Instance Snapshot"
      );
      if (res.code === 200) {
        this.agree1_1 = res.data.is_check;
        console.log("agree1 : ", this.agree1_1);
      }
    },
    async checkEula2() {
      var res = await api.GET(
        "/useradmin/check/eula?project_id=" +
          this.projectId +
          "&form_name=Form Create Storage Snapshot"
      );
      if (res.code === 200) {
        this.agree2_2 = res.data.is_check;
        console.log("agree2 : ", this.agree2_2);
      }
    },
    async checkEula3() {
      var res = await api.GET(
        "/useradmin/check/eula?project_id=" +
          this.projectId +
          "&form_name=Form Create Custom Image"
      );
      if (res.code === 200) {
        this.agree = res.data.is_check;
        console.log("agree custom image: ", this.agree);
      }
    },
    async getEula1() {
      var res = await api.GET(
        "/useradmin/eula?form_name=Form Create Instance Snapshot"
      );
      console.log("res : ", res);
      if (res.code === 200) {
        this.dataTnc1 = res.data;
        this.tnc1 = true;
      }
    },
    async getEula2() {
      var res = await api.GET(
        "/useradmin/eula?form_name=Form Create Storage Snapshot"
      );
      console.log("res : ", res);
      if (res.code === 200) {
        this.dataTnc2 = res.data;
        this.tnc2 = true;
      }
    },
    log(e) {
      console.log(e);
    },
    show(e, snapshot) {
      this.selectedSnapshot = snapshot;
      e.preventDefault();
      this.showMenu = false;
      this.x = e.clientX;
      this.y = e.clientY + 10;
      this.$nextTick(() => {
        this.showMenu = true;
      });
    },
  },
  components: {
    Tncpopupstorage,
    Tncpopupinstance,
    dialogSnapshotConfirmation,
    dialogSnapshotValidationBalance,
    restore,
    dialogBeforeRestoreInstance,
    dialogDeleteCustomImage,
    dialogImportImageURL,
    dialogDeleteBackup,
    // dialogUploadImage,
    // dialogRenameImage,
    // dialogAddtoRegion,
    dialogRestoreInstance,
    // dialogTransfer,
    dialogDelete,
  },
  computed: {
    isCorporate() {
      return localstorage.getItem("type") === "C";
    },
    ...mapGetters({
      projectId: "PROJECT/getprojectid",
      user: "ROLEPERMISSION/getuser",
    }),
  },
};
</script>

<style lang="scss" scoped>
.v-window.v-item-group {
  background-color: transparent;
}

.v-input-append {
  ::v-deep .v-input__slot {
    background: lightgray !important;
  }
}
.v-tab:not(.v-tab--active) {
  color: #a5b3bf !important;
}
.mdi-chevron-down.rotate::before {
  transform: rotate(-180deg);
  transition: 100ms linear all;
}
.mdi-chevron-down::before {
  transition: 100ms linear all;
}
.v-data-table {
  ::v-deep table {
    border-bottom: 0px solid #e0e0e0;
  }
  ::v-deep td {
    border-bottom: 1px solid #e0e0e0;
  }
}
.table-status {
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 22px;
  text-transform: capitalize;
  color: #a5b3bf;
  padding-top: 5px;
}
.tablecard {
  .row {
    font-weight: 600;
  }
  .tbody {
    // border-bottom: thin solid rgba(0, 0, 0, 0.12);
    .col {
      padding-top: 20px;
      padding-bottom: 14px;
    }
  }
}
.slide-fade-enter-active,
.slide-fade-leave-active {
  transition: all 100ms ease-in;
  max-height: 300px;
  overflow: hidden;
}
.slide-fade-enter,
.slide-fade-leave-to {
  max-height: 0px;
}
</style>
