import { render, staticRenderFns } from "./breadcrumbs.vue?vue&type=template&id=adc006be&scoped=true&"
import script from "./breadcrumbs.vue?vue&type=script&lang=js&"
export * from "./breadcrumbs.vue?vue&type=script&lang=js&"
import style0 from "./breadcrumbs.vue?vue&type=style&index=0&id=adc006be&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "adc006be",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VBreadcrumbs } from 'vuetify/lib/components/VBreadcrumbs';
import { VBreadcrumbsItem } from 'vuetify/lib/components/VBreadcrumbs';
import { VIcon } from 'vuetify/lib/components/VIcon';
installComponents(component, {VBreadcrumbs,VBreadcrumbsItem,VIcon})
