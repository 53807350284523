<template>
  <v-dialog max-width="1000" v-model="dialog">
    <v-card class="pt-7">
      <v-card-text>
        <div class="headline font-weight-bold font--text">List of Voucher Code</div>
      </v-card-text>
      <v-card-text>
        <v-data-table
          class="elevation-0"
          :hide-default-footer="true"
          :headers="headers"
          :items="voucherDetails.voucher_detail"
          :items-per-page="5"
        >
          <template v-slot:item.code="{ item }">
            {{ item.code }}
          </template>
          <template v-slot:item.name="{ item }">
            {{ item.voucher.name || '-' }}
          </template>
          <template v-slot:item.claim_period="{ item }">
            <span v-if="item.voucher.id">{{ moment(item.voucher.claim_period_start).format('D MMMM Y') }} - {{ moment(item.voucher.claim_period_end).format('D MMMM Y') }}</span>
            <span v-else>-</span>
          </template>
          <template v-slot:item.used_by="{ item }">
            {{ item.organization_id ? item.organization.name : '-' }}
          </template>
        </v-data-table>
      </v-card-text>
      <v-card-text>
        <v-col>
          <v-btn @click="dialog = false" block height="50" depressed class="accent">
            <span style="font-size:14px">Close</span></v-btn>
        </v-col>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>
<script>
import { ref, watch } from '@vue/composition-api'
import { useDialog } from '@/composable/usedialog'
const useVuelidate = require("@vuelidate/core").default
import moment from 'moment'

export default {
  props: ['value', 'voucherDetails'],
  setup(props, { emit, root }) {
    const { dialog, showCommonDialogSuccess, showCommonDialogWarning } = useDialog(props, {emit, root})

    const headers = [
        { text: "Code", value: "code" },
        { text: "Voucher Name", value: "name" },
        { text: "Claim Period", value: "claim_period" },
        { text: "Used By", value: "used_by" },
    ]

    return {
      headers,
      dialog,
      prevent: ref(false),
      moment,
    };
  },
};
</script>
<style lang="scss" scoped>
.titletext {
  font-weight: 700;
  font-size: 12px;
}
.hideinput{
  ::v-deep input{
    position: relative;
    bottom:50px;
    z-index: -2;
  }
}
</style>