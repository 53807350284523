var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-card',{staticClass:"rounded-lg pa-4",attrs:{"flat":""}},[_c('v-card-text',[(!_vm.instance)?_c('div',{staticClass:"headline font-weight-bold font--text"},[_vm._v(" Create Instance ")]):(_vm.instance)?_c('div',{staticClass:"headline font-weight-bold font--text"},[_vm._v(" Edit Flavor ")]):_vm._e()]),_c('v-card-text',[_c('v-row',[_c('v-col',{staticClass:"py-0",attrs:{"cols":"12","md":"6"}},[_c('p',[_c('b',[_vm._v("Choose a Type*")])]),_c('v-select',{attrs:{"placeholder":"Choose a Type","error-messages":_vm.type_idValidation.$errors.length
              ? _vm.type_idValidation.$errors[0].$message
              : '',"item-value":"id","item-text":"name","items":_vm.instancetype,"outlined":""},model:{value:(_vm.type_id),callback:function ($$v) {_vm.type_id=$$v},expression:"type_id"}})],1),_c('v-col',{staticClass:"py-0",attrs:{"cols":"12","md":"6"}},[_c('p',{staticClass:"font-weight-bold"},[_vm._v("Choose Region")]),_c('v-select',{attrs:{"id":"SelectRegion","outlined":"","items":_vm.selectRegions,"placeholder":"Choose Region","error-messages":_vm.instanceFlavorRegionValidation.$errors.length
              ? _vm.instanceFlavorRegionValidation.$errors[0].$message
              : ''},model:{value:(_vm.instanceFlavorRegion),callback:function ($$v) {_vm.instanceFlavorRegion=$$v},expression:"instanceFlavorRegion"}}),(_vm.instanceFlavorRegion)?_c('p',{staticClass:"secondary--text"},[(_vm.instanceFlavorRegion.cpu_limit)?_c('span',[_vm._v("vCPU Resource Remaning: "+_vm._s(_vm.instanceFlavorRegion.cpu_limit - _vm.instanceFlavorRegion.cpu_usage))]):_vm._e(),_c('br'),(_vm.instanceFlavorRegion.ram_limit)?_c('span',[_vm._v("Memory Resource Remaining: "+_vm._s(_vm.instanceFlavorRegion.ram_limit - _vm.instanceFlavorRegion.ram_usage)+" GB")]):_vm._e()]):_vm._e()],1)],1),_c('v-divider',{staticClass:"mt-3 mb-2"}),_c('v-row',[_c('v-col',{attrs:{"cols":"12","md":"6"}},[_c('p',[_c('b',[_vm._v("Flavor Name*")])]),_c('v-text-field',{attrs:{"maxlength":"30","outlined":"","placeholder":"Type Flavor Name","error-messages":_vm.flavor_nameValidation.$errors.length
              ? _vm.flavor_nameValidation.$errors[0].$message
              : ''},model:{value:(_vm.flavor_name),callback:function ($$v) {_vm.flavor_name=$$v},expression:"flavor_name"}})],1),_c('v-col',{attrs:{"cols":"12","md":"6"}}),_c('v-col',{attrs:{"cols":"12","md":"6"}},[_c('p',[_c('b',[_vm._v("vCPU*")])]),_c('v-text-field',{attrs:{"maxlength":"3","id":"cpu","type":"number","error-messages":_vm.vcpuValidation.$errors.length
              ? _vm.vcpuValidation.$errors[0].$message
              : '',"outlined":"","placeholder":"Type vCPU"},scopedSlots:_vm._u([{key:"append",fn:function(){return [_c('span',{staticClass:"font--text"},[_vm._v("vCPU")])]},proxy:true}]),model:{value:(_vm.vcpu),callback:function ($$v) {_vm.vcpu=_vm._n($$v)},expression:"vcpu"}})],1),_c('v-col',{attrs:{"cols":"12","md":"6"}},[_c('p',[_c('b',[_vm._v("Memory*")])]),_c('v-text-field',{attrs:{"maxlength":"3","type":"number","error-messages":_vm.ramValidation.$errors.length
              ? _vm.ramValidation.$errors[0].$message
              : '',"outlined":"","placeholder":"Type Memory"},scopedSlots:_vm._u([{key:"append",fn:function(){return [_c('span',{staticClass:"font--text"},[_vm._v("GB")])]},proxy:true}]),model:{value:(_vm.ram),callback:function ($$v) {_vm.ram=_vm._n($$v)},expression:"ram"}})],1),_c('v-col',{attrs:{"cols":"12","md":"6"}},[_c('p',[_c('b',[_vm._v("GPU (GHz)")])]),_c('v-text-field',{attrs:{"maxlength":"3","type":"number","error-messages":_vm.gpuValidation.$errors.length
              ? _vm.gpuValidation.$errors[0].$message
              : '',"outlined":"","placeholder":"GPU GHz"},scopedSlots:_vm._u([{key:"append",fn:function(){return [_c('span',{staticClass:"font--text"},[_vm._v("GHz")])]},proxy:true}]),model:{value:(_vm.gpu),callback:function ($$v) {_vm.gpu=_vm._n($$v)},expression:"gpu"}})],1)],1)],1),_c('v-card-text',[_c('v-row',[_c('v-col',[_c('v-btn',{attrs:{"height":"58","depressed":"","block":"","color":"accent","to":"/manage-pricing","exact":""}},[_c('span',{staticStyle:{"font-size":"16px"}},[_vm._v(" Cancel")])])],1),(!_vm.flavorbeingedit)?_c('v-col',[_c('v-btn',{attrs:{"height":"58","depressed":"","block":"","color":"secondary"},on:{"click":_vm.createPackage}},[_c('span',{staticStyle:{"font-size":"16px"}},[_vm._v(" Create Package")])])],1):(_vm.flavorbeingedit)?_c('v-col',[_c('v-btn',{attrs:{"height":"58","depressed":"","block":"","color":"secondary"},on:{"click":function () {
              _vm.editPackage(_vm.flavorbeingedit);
            }}},[_c('span',{staticStyle:{"font-size":"16px"}},[_vm._v(" Save")])])],1):_vm._e()],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }