<template>
  <v-container fluid class="pa-0">
    <v-card class="rounded-lg" flat v-if="loading">
      <v-card-text class="d-flex justify-center">
        <v-progress-circular
          style="margin: auto"
          :size="200"
          color="primary"
          indeterminate
        ></v-progress-circular>
      </v-card-text>
    </v-card>
    <v-card  v-else flat class="pa-7 rounded-lg">
      <div v-if="orgType==0">
      <div class="table-title">Instance Deployed</div>
      <v-row>
        <v-col cols="2" class="d-flex flex-column align-center">
          <div style="width: 80px">
            <pie
              v-if="instance_usage"
              :data="{
                datasets: [
                  {
                    data: [
                      instance_usage.instance_usage,
                      instance_usage.maximal_instance - instance_usage.instance_usage,
                    ],
                    backgroundColor: ['#2C94D2', '#C4C4C4'],
                    borderWidth: 0,
                  },
                ],

                // These labels appear in the legend and in the tooltips when hovering different arcs
                labels: ['used', 'remaining'],
              }"
              :options="{
                legend: {
                  display: false,
                },
                tooltips: {
                  enabled: false,
                },
              }"
            />
          </div>

          <div class="usage-title pt-4">Instance</div>
          <div class="usage-detail" v-if="instance_usage">
            Used {{ instance_usage.instance_usage }} of
            {{
              instance_usage.maximal_instance == -1
                ? "unlimited"
                : instance_usage.maximal_instance
            }}
          </div>
        </v-col>
        <v-col cols="2" class="d-flex flex-column align-center">
          <div style="width: 80px">
            <pie
              v-if="instance_usage"
              :data="{
                datasets: [
                  {
                    data: [
                      instance_usage.vcpu_usage,
                      instance_usage.maximal_vcpu - instance_usage.vcpu_usage,
                    ],
                    backgroundColor: ['#2C94D2', '#C4C4C4'],
                    borderWidth: 0,
                  },
                ],

                // These labels appear in the legend and in the tooltips when hovering different arcs
                labels: ['used', 'remaining'],
              }"
              :options="{
                legend: {
                  display: false,
                },
                tooltips: {
                  enabled: false,
                },
              }"
            />
          </div>
          <div class="usage-title pt-4">VCPUs</div>
          <div class="usage-detail" v-if="instance_usage">
            Used {{ instance_usage.vcpu_usage }} of
            {{
              instance_usage.maximal_vcpu == -1
                ? "unlimited"
                : instance_usage.maximal_vcpu
            }}
          </div>
        </v-col>
        <v-col cols="2" class="d-flex flex-column align-center">
          <div style="width: 80px">
            <pie
              v-if="instance_usage"
              :data="{
                datasets: [
                  {
                    data: [
                      instance_usage.ram_usage,
                      instance_usage.maximal_ram -instance_usage.ram_usage,
                    ],
                    backgroundColor: ['#2C94D2', '#C4C4C4'],
                    borderWidth: 0,
                  },
                ],

                // These labels appear in the legend and in the tooltips when hovering different arcs
                labels: ['used', 'remaining'],
              }"
              :options="{
                legend: {
                  display: false,
                },
                tooltips: {
                  enabled: false,
                },
              }"
            />
          </div>
          <div class="usage-title pt-4">Memory</div>
          
          <div class="usage-detail" v-if="instance_usage">
            Used {{ instance_usage.ram_usage }} GB of
            {{
              instance_usage.maximal_ram == -1
                ? "unlimited"
                : instance_usage.maximal_ram
            }}
            GB
          </div>
        </v-col>
      </v-row>
      <div class="table-title">Storage</div>
      <v-row>
        <v-col cols="2" class="d-flex flex-column align-center">
          <div style="width: 80px">
            <pie
              v-if="storage_usage"
              :data="{
                datasets: [
                  {
                    data: [
                      storage_usage.storage_usage,
                      storage_usage.maximal_storage - storage_usage.storage_usage,
                    ],
                    backgroundColor: ['#2C94D2', '#C4C4C4'],
                    borderWidth: 0,
                  },
                ],

                // These labels appear in the legend and in the tooltips when hovering different arcs
                labels: ['used', 'remaining'],
              }"
              :options="{
                legend: {
                  display: false,
                },
                tooltips: {
                  enabled: false,
                },
              }"
            />
          </div>
          <div class="usage-title pt-4">Storage</div>
          <div class="usage-detail" v-if="storage_usage">
            Used {{ storage_usage.storage_usage }} GB of
            {{
              storage_usage.maximal_storage == -1
                ? "unlimited"
                : storage_usage.maximal_storage
            }} GB
          </div>
        </v-col>
      </v-row>
      </div>

      <div v-if="orgType==1">
         <h3 style="color:rgb(31 96 168)">Service List For Premium Organization</h3>  

        <v-card-text class="d-flex flex-row align-center">
        <div class="table-title">Instance</div>
        <v-spacer />
        <div style="height: auto; width: 10px" />
         <v-autocomplete
            default="All"
            v-model="projected"
            :items="projectss"
            outlined
            item-text="text"
            item-value="value"
            placeholder="Select Project"
            attach="#SelectProjectAttachs"
            clearable
          >
        
            <template v-slot:append-outer>
              <div id="SelectProjectAttachs"></div>
            </template>
             
          </v-autocomplete>
       
  
       
      </v-card-text>



        <v-row>
             <v-col cols="12" sm="6" md="4" class="">
                <v-card
                 
                  :style="'2px solid #eb5757; height:80px'"
                  class="rounded-lg d-flex flex-grow-1"
                  flat
                  outlined
                >
                  <v-card-title class="d-flex flex-nowrap">
                    <div>
                        <v-icon style="height:100px; color:rgb(31 96 168)" >
                           $vuetify.icons.instance
                       </v-icon>
                    </div>
                    <div class="ml-2">
                      
                      <p style="font-size: 20px" class="my-0 font-weight-bold">
                        Instance
                      </p>

                      <p style="font-size: 22px; color:rgb(31 96 168)" class="my-0 font-weight-bold">
                        {{instance_usage.instance_usage}}
                      </p>
                    
                    </div>
                  </v-card-title>
                </v-card>
              </v-col>

               <v-col cols="12" sm="6" md="4" class="">
                <v-card
                 
                  :style="'2px solid #eb5757;height:80px'"
                  class="rounded-lg d-flex flex-grow-1"
                  flat
                  outlined
                >
                  <v-card-title class="d-flex flex-nowrap">
                    <div>
                        <v-icon style="height:100px; color:rgb(31 96 168)" >
                           $vuetify.icons.cpuIcons
                       </v-icon>
                    </div>
                    <div class="ml-2">
                      
                      <p style="font-size: 20px" class="my-0 font-weight-bold">
                        vCPUs
                      </p>

                      <p style="font-size: 22px; color:rgb(31 96 168)" class="my-0 font-weight-bold">
                        {{instance_usage.vcpu_usage}}
                      </p>
                    
                    </div>
                  </v-card-title>
                </v-card>
              </v-col>


               <v-col cols="12" sm="6" md="4" class="">
                <v-card
                 
                  :style="'2px solid #eb5757;height:80px'"
                  class="rounded-lg d-flex flex-grow-1"
                  flat
                  outlined
                >
                  <v-card-title class="d-flex flex-nowrap">
                    <div>
                        <v-icon style="height:100px; color:rgb(31 96 168)" >
                           $vuetify.icons.cpuIcons
                       </v-icon>
                    </div>
                    <div class="ml-2">
                      
                      <p style="font-size: 20px" class="my-0 font-weight-bold">
                        Memory (GB)
                      </p>

                      <p style="font-size: 22px; color:rgb(31 96 168)" class="my-0 font-weight-bold">
                        {{instance_usage.ram_usage}}
                      </p>
                    
                    </div>
                  </v-card-title>
                </v-card>
              </v-col>
        </v-row>


        <div class="table-title" style="font-size: 22px; ">Storage</div>
        <v-row>
             <v-col cols="12" sm="6" md="4" class="">
                <v-card
                 
                  :style="'2px solid #eb5757;height:80px'"
                  class="rounded-lg d-flex flex-grow-1"
                  flat
                  outlined
                >
                  <v-card-title class="d-flex flex-nowrap">
                    <div>
                        <v-icon style="height:100px; color:rgb(31 96 168)" >
                           $vuetify.icons.storagess
                       </v-icon>
                    </div>
                    <div class="ml-2">
                      
                      <p style="font-size: 20px" class="my-0 font-weight-bold">
                        Storage
                      </p>

                      <p style="font-size: 22px; color:rgb(31 96 168)" class="my-0 font-weight-bold">
                         {{storage_usage.data_usage}}
                      </p>
                    
                    </div>
                  </v-card-title>
                </v-card>
              </v-col>

               <v-col cols="12" sm="6" md="4" class="">
                <v-card
                 
                  :style="'2px solid #eb5757;height:80px'"
                  class="rounded-lg d-flex flex-grow-1"
                  flat
                  outlined
                >
                  <v-card-title class="d-flex flex-nowrap">
                    <div>
                        <v-icon style="height:100px; color:rgb(31 96 168)" >
                           $vuetify.icons.objectstoragesIcons
                       </v-icon>
                    </div>
                    <div class="ml-2">
                      
                      <p style="font-size: 20px" class="my-0 font-weight-bold">
                        Data Disk Size (GB)
                      </p>

                      <p style="font-size: 22px; color:rgb(31 96 168)" class="my-0 font-weight-bold">
                         {{storage_usage.storage_usage}}
                      </p>
                    
                    </div>
                  </v-card-title>
                </v-card>
              </v-col>


               <v-col cols="12" sm="6" md="4" class="">
                <v-card
                 
                  :style="'2px solid #eb5757;height:80px'"
                  class="rounded-lg d-flex flex-grow-1"
                  flat
                  outlined
                >
                  <v-card-title class="d-flex flex-nowrap">
                    <div>
                        <v-icon style="height:100px; color:rgb(31 96 168)" >
                           $vuetify.icons.imagess
                       </v-icon>
                    </div>
                    <div class="ml-2">
                      
                      <p style="font-size: 20px" class="my-0 font-weight-bold">
                        Snapshots
                      </p>

                      <p style="font-size: 22px; color:rgb(31 96 168)" class="my-0 font-weight-bold">
                        {{storage_usage.snapshot_usage}}
                      </p>
                    
                    </div>
                  </v-card-title>
                </v-card>
              </v-col>
        </v-row>


        <div class="table-title" style="font-size: 22px; ">Network</div>
        <v-row>
             <v-col cols="12" sm="6" md="4" class="">
                <v-card
                 
                  :style="'2px solid #eb5757;height:80px'"
                  class="rounded-lg d-flex flex-grow-1"
                  flat
                  outlined
                >
                  <v-card-title class="d-flex flex-nowrap">
                    <div>
                        <v-icon style="height:100px; color:rgb(31 96 168)" >
                           $vuetify.icons.ipnetwork
                       </v-icon>
                    </div>
                    <div class="ml-2">
                      
                      <p style="font-size: 20px" class="my-0 font-weight-bold">
                        Floating IPs
                      </p>

                      <p style="font-size: 22px; color:rgb(31 96 168)" class="my-0 font-weight-bold">
                        {{network_usage.floatingip_usage}}
                      </p>
                    
                    </div>
                  </v-card-title>
                </v-card>
              </v-col>

               <v-col cols="12" sm="6" md="4" class="">
                <v-card
                 
                  :style="'2px solid #eb5757;height:80px'"
                  class="rounded-lg d-flex flex-grow-1"
                  flat
                  outlined
                >
                  <v-card-title class="d-flex flex-nowrap">
                    <div>
                        <v-icon style="height:100px; color:rgb(31 96 168)" >
                           $vuetify.icons.securitysIcon
                       </v-icon>
                    </div>
                    <div class="ml-2">
                      
                      <p style="font-size: 20px" class="my-0 font-weight-bold">
                        Security Groups
                      </p>

                      <p style="font-size: 22px; color:rgb(31 96 168)" class="my-0 font-weight-bold">
                        {{network_usage.security_usage}}
                      </p>
                    
                    </div>
                  </v-card-title>
                </v-card>
              </v-col>


              <v-col cols="12" sm="6" md="4" class="">
                <v-card
                 
                  :style="'2px solid #eb5757;height:80px'"
                  class="rounded-lg d-flex flex-grow-1"
                  flat
                  outlined
                >
                  <v-card-title class="d-flex flex-nowrap">
                    <div>
                        <v-icon style="height:100px; color:rgb(31 96 168)" >
                           $vuetify.icons.networkIcon
                       </v-icon>
                    </div>
                    <div class="ml-2">
                      
                      <p style="font-size: 20px" class="my-0 font-weight-bold">
                        VPC
                      </p>

                      <p style="font-size: 22px; color:rgb(31 96 168)" class="my-0 font-weight-bold">
                        {{network_usage.vpc_usage}}
                      </p>
                    
                    </div>
                  </v-card-title>
                </v-card>
              </v-col>

              <v-col cols="12" sm="6" md="4" class="">
                <v-card
                 
                  :style="'2px solid #eb5757;height:80px'"
                  class="rounded-lg d-flex flex-grow-1"
                  flat
                  outlined
                >
                  <v-card-title class="d-flex flex-nowrap">
                    <div>
                        <v-icon style="height:100px; color:rgb(31 96 168)" >
                           $vuetify.icons.localarea
                       </v-icon>
                    </div>
                    <div class="ml-2">
                      
                      <p style="font-size: 20px" class="my-0 font-weight-bold">
                        Load Balancer
                      </p>

                      <p style="font-size: 22px; color:rgb(31 96 168)" class="my-0 font-weight-bold">
                        {{network_usage.loadbalancer_usage}}
                      </p>
                    
                    </div>
                  </v-card-title>
                </v-card>
              </v-col>
        </v-row>

      </div>
    </v-card>
  </v-container>
</template>
<script >

import {
  useNamespacedActions,
  useNamespacedGetters,
  useNamespacedState,
} from "vuex-composition-helpers";
import pie from "./pie.vue";
import { LIMITSERVICE } from "./namespace";

import { defineComponent,computed, onMounted, ref, watch  } from "@vue/composition-api";
import localstorage from "@/lib/localstorage";
import { useProjects } from "@/modules/project/useprojects";


export default defineComponent({
  components: { pie },
  
  setup(props, context) {
    const Composable = {
      ...useProjects(),
    };

    const { fetchlimitservice } = useNamespacedActions(LIMITSERVICE, [
      "fetchlimitservice",
    ]);
    const { instance_usage, storage_usage, network_usage } = useNamespacedGetters(
      LIMITSERVICE,
      ["instance_usage", "storage_usage", "network_usage"]
    );
  

    const {selectprojectsDownloads,fetchprojects, projectsselected } = Composable;
    
   
   const options = ref({});
   const projected = ref();


    const loading = ref(true);
    const locals = JSON.parse(localStorage.getItem('currentOrg') || '{}');
    const orgType=locals.OrgType;
    onMounted(() => {
      fetchprojects({id:'', itemsPerPage:-1, page:1});    

      fetchlimitservice()
        .then(() => {
          loading.value = false;
        })
        .catch(() => {
          loading.value = false;
        });
    });

    const projectss = computed({
      get: () => {
        const array2 = [{
          text:'All',
          value:'',
        }];
        return array2.concat(selectprojectsDownloads.value);
      },
    });


    watch(projected, (val) => {
      console.log(val);
      // if (val) {
      //   options.value.project = val.value;
      // }else {
      //   options.value.project = ""
      // }
      fetchlimitservice(val)
      // console.log(options.value.project);
    });

    return {
      ...Composable,
      projected,
      projectss,
      orgType,
      loading,
      instance_usage,
      storage_usage,
      network_usage,
    };
  }
});
</script>
<style scoped>
.table-title {
  font-style: normal;
  font-weight: bold;
  font-size: 21px;
  line-height: 140%;
  color: #556272;
  padding-top: 20px;
  padding-bottom: 30px;
}

.usage-title {
  font-style: normal;
  font-weight: bold;
  font-size: 16px;
  line-height: 140%;
  /* or 22px */

  text-align: center;

  /* Primary/Font */

  color: #556272;
}

.usage-detail {
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 140%;
  width: 400px;
  /* identical to box height, or 20px */

  text-align: center;

  /* Primary/ Blue Grey */

  color: #a5b3bf;
}

.pie {
  position: absolute;
  top: 344px;
  left: 92px;
}
</style>
