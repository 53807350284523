<template>
  <v-card flat class="rounded-lg pa-4 mt-3">
    <v-card-text>
      <p class="fz-21 font-weight-bold">
        History Top Up
      </p>
    </v-card-text>

    <v-data-table :headers="headers" :items="topups" :items-per-page="10" class="elevation-0" hide-default-footer>
      <!-- amount -->
      <template v-slot:item.amount="{ item }">
        <span :style="item.type === 'plus' ? 'color: #27AE60' : 'color: red'">
          {{ item.type === "plus" ? "+" : "-" }}
          IDR {{ item.amount.toLocaleString("en-US") }}
        </span>
      </template>

      <!-- date -->
      <template v-slot:item.created_at="{ item }">
        <span>
          {{ $moment(item.created_at).format("DD/MM/YYYY") }}
        </span>
      </template>

      <!-- pagination -->
      <template v-slot:footer="{ props }">
        <custom-footer-datatable :props="props" />
      </template>
    </v-data-table>
  </v-card>
</template>

<script>
import api from "@/lib/api";
import localstorage from '@/lib/localstorage';

export default {
  data() {
    return {
      headers: [
        { text: "Transaction ID", value: "transaction_id" },
        { text: "Source", value: "source" },
        { text: "Amount", value: "amount" },
        { text: "Date", value: "created_at" },
        { text: "By", value: "created_by" },
      ],
      topups: [],
    };
  },
  methods: {
    async getBalanceHistory() {
      try {
        const role = localstorage.getItem("role");
        const currentOrg = localstorage.getItem("currentOrg");
        const org = JSON.parse(currentOrg);
        const orgId = org ? org.id : ''
        let endpoint = "/balance/history"

        if (role === 'Superadmin') {
          endpoint = '/superadmin/balance/history/' + orgId
        }

        const res = await api.GET(endpoint);

        if (res.data && res.data.length) {
          this.topups = res.data
            .map((topup) => {
              const { transaction_id, source, amount, created_at, type, created_by } = topup;

              return {
                transaction_id,
                source,
                amount,
                created_at,
                type,
                created_by,
              };
            })
            .sort((a, b) => new Date(b.date) - new Date(a.date));
        }
      } catch {
        this.$store.dispatch("HOMEPAGE/showErrorToast", "Sorry, an error occurred while displaying History Top Up");
      }
    },
  },
  mounted() {
    this.getBalanceHistory();
  },
};
</script>
