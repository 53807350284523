<template>
  <div>
    <p class="font-weight-bold fz-21">
      Instance Detail
    </p>

    <v-data-table
      class="datatable-services"
      :headers="tblHeaders"
      :items="tblItems"
      hide-default-footer
    >
      <template v-slot:item.name="{ item }">
        <span class="linkpointer">{{ item.name || "N/A" }}</span>
      </template>

      <template v-slot:item.price="{ item }">
        IDR {{ item.price.value.toLocaleString("en") }} / {{ item.price.per }}
      </template>

      <template v-slot:item.billed="{ item }">
        IDR {{ item.billed.toLocaleString("en") }}
      </template>

      <!--
      <template v-slot:item.usage="{ item }">
        <div class="d-flex align-center">
          <v-tooltip
            v-if="item.shift_to_monthly"
            top
            color="#333"
            transition="slide-y-reverse-transition"
          >
            <template v-slot:activator="{ on, attrs }">
              <div v-on="on" v-bind="attrs">
                <v-icon class="mr-3" v-on="on" v-bind="attrs" color="primary"
                  >mdi-alert-circle-outline</v-icon
                >
              </div>
            </template>
            <span
              >Service usage has exceeded <b>95%</b> in one month, payment will
              be shifted from hourly to monthly</span
            >
          </v-tooltip>
          <span v-if="item.usage_hours">{{
            item.usage_hours > 1
              ? `${item.usage_hours} hours`
              : `${item.usage_hours} hour`
          }}</span>
          <span v-else>-</span>
        </div>
      </template> -->

      <template v-if="!isPostpaidFixed" v-slot:body.append>
        <tr style="border:none">
          <td class="border-bottom-none"></td>
          <td class="border-bottom-none"></td>
          <td class="border-bottom-none"></td>
          <td class="border-bottom-none"></td>
          <td class="border-bottom-none">
            <div>
              <p class="font-weight-bold">Total</p>
            </div>
            <div class="d-flex flex-row justify-start">
              {{
                tblItems.length
                  ? "IDR " +
                    tblItems
                      .reduce((total, item) => total + item.billed, 0)
                      .toLocaleString("en")
                  : "0"
              }}
            </div>
          </td>
        </tr>
      </template>

      <template v-slot:footer="{ props }" v-if="tblItems.length > 10">
        <custom-footer-datatable :props="props" />
      </template>
    </v-data-table>
  </div>
</template>

<script>
export default {
  props: ["tblItems", "isPostpaidFixed"],
  computed: {
    tblHeaders() {
      return [
        { text: "Service Name", value: "name" },
        { text: "Specification", value: "specification" },
        { text: "Price", value: "price" },
        { text: "Usage", value: "usage" },
        !this.isPostpaidFixed && { text: "Billed", value: "billed" },
      ];
    },
  },
};
</script>
