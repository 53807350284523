




















































import Vue from "vue";
import { useDialog } from "@/composable/usedialog";
import { ref } from "@vue/composition-api";
import useVuelidate from "@vuelidate/core";
import { required } from "@vuelidate/validators";
import { useNamespacedActions } from "vuex-composition-helpers";
import { ORGANIZATION, ROLEPERMISSION } from "./namespace";
const module = Vue.extend({
  props: ["value"],
  setup(props: any, context) {
    const { dialog } = useDialog(props, context);
    const npwp_corporate = ref("");
    const $v = useVuelidate(
      {
        npwp_corporate: { required },
      },
      { npwp_corporate }
    );
    const { upgradeorg } = useNamespacedActions(ROLEPERMISSION, ["upgradeorg"]);
    const upgrade = () => {
      $v.value.$touch();
      if ($v.value.$errors.length) return;
      upgradeorg({
        npwp_corporate: npwp_corporate.value
          .replaceAll(".", "")
          .replaceAll("-", ""),
      }).then(() => {
        context.emit("success");
      });
    };
    return {
      upgrade,
      dialog,
      npwp_corporate,
      $v,
    };
  },
});
export default module;
