


























































































































































































































































































































































































































import { defineComponent, ref, Ref, watch } from "@vue/composition-api";
import { useMember } from "./useMember";
import dialogNpwpKtp from "./dialogNpwpKtp.vue";
import dialogEditUser from "./dialogEditUser.vue";
import dialogAddUser from "./dialogAddUser.vue";
import dialogDeleteUser from "./dialogDeleteUser.vue";
import dialogSuccess from "@/modules/costumedialog/dialogSuccess.vue";
import dialogRecendInviteUser from "./dialogResendInviteUser.vue";

import dialogProjectList from "./dialogProjectList.vue";
import dialogDetailUser from "./dialogDetailUser.vue";
import dialogTransferRoleUser from "./dialogTransferRoleUser.vue";
import { useRoles } from "@/modules/organization/userole";
import FileDownload from "js-file-download";
const { Parser, transforms: { unwind, flatten } } = require("json2csv");
import {
  useNamespacedActions,
  useNamespacedState,
} from "vuex-composition-helpers";
import { SUPERADMINMANAGEORG } from "../superadmin-manageorganization/namespace";
import { SUPERADMINMANAGEUSER } from "./namespace";
import dialogActivateUser from './dialogActivateUser.vue'
// import Dialogsuccess from "../emailverification/dialogsuccess.vue";
export default defineComponent({
  components: {
    dialogNpwpKtp,
    dialogEditUser,
    dialogAddUser,
    dialogDeleteUser,
    dialogProjectList,
    dialogDetailUser,
    dialogActivateUser,
    dialogTransferRoleUser,
    dialogRecendInviteUser,
    dialogSuccess,
    // Dialogsuccess
  },
  setup() {
    const memberselectedvalue: Ref<any> = ref("");
    const Composable = { ...useMember(), ...useRoles() };
    const { membersselected, members } = Composable;
    const headers = ref([
      {
        text: "Email",
        value: "email",
        sortable: false,
      },
      {
        text: "Organization",
        value: "organization",
      },
      {
        text: "Role",
        value: "role",
      },
      {
        text: "Status NPWP & KTP",
        value: "is_identity_verified",
      },
      {
        text: "Join Date",
        value: "joindate",
      },
      {
        text: "User Status",
        value: "statususer",
        sortable: false,
      },
      {
        text: "Status",
        value: "status",
      },
      {
        text: "Action",
        value: "action",
        sortable: false,
        align: 'center',
      },
    ]);
    const Download = () => {
      // const fields = ['id',	'organization_id',	'fullname',	'firstname',	'lastname',	'email',	'phone_number',	'npwp_privacy',	'role_id',	'photo',	'status',	'created_at',	'updated_at',	'deleted_at',	'organization',	'userproject',	'role']
      const json2csvParser = new Parser( { quote: '', transforms:[unwind({ paths:['userproject'], blankOut: true }),flatten('__')]});
      const csv = json2csvParser.parse(membersselected.value);
      FileDownload(csv, 'users.csv');
    };
    const { organizations } = useNamespacedState(SUPERADMINMANAGEORG, [
      "organizations",
    ]);
    const { changestatus } = useNamespacedActions(SUPERADMINMANAGEUSER, [
      "changestatus",
    ]);

    const options = ref({ page: 1, itemsPerPage: 10, rowsPerPage: 10, search: "", sortBy: [], sortDesc: [], is_created: [] });
    
    const opendialogactivateuser = ref(false)

    const opendialogsuccess = ref(false)

    return {
      dialogetitlesuccess:ref(""),
      dialogemsgsuccess:ref(""),
      opendialogsuccess,
      changestatus,
      opendialogactivateuser,
      organizations,
      headers,
      Download,
      projects: ref([]),
      opendialognpwpktp: ref(false),
      opendialogdetailuser: ref(false),
      opendialogprojectlist: ref(false),
      opendialogdeleteuser: ref(false),
      opendialogtransferuser: ref(false),
      opendialogresendinviteuser: ref(false),
      opendialogadduser: ref(false),
      memberselectedvalue,
      opendialogedituser: ref(false),
      log(e: any) {
      },
      ...Composable,
      members,
      show: ($event: any, item: any) => {
        memberselectedvalue.value = item;
      },
    };
  },
  created() {
    this.fetchmembers();
    //FIXME:
    // this.fetchRoles();
  },
});
