import axios from '@/lib/axios'
import {ActionContext, Module} from 'vuex'
interface State {
    vouchers: Array<any>,
    pagination: any,
    isLoading: Boolean,
    voucher: Object,
    voucherDetails: Object,
}
const store: Module<State,any>= {
    namespaced: true,
    state:{
        vouchers: [],
        pagination: {},
        voucher: {},
        isLoading: false,
        voucherDetails: {},
    },
    mutations:{
        setVouchers:(state:State,payload:any) => state.vouchers = payload,
        setVoucher:(state:State,payload:any) => state.voucher = payload,
        setPagination:(state:State,payload:any) => state.pagination = payload,
        setIsLoading: (state:State, payload:any) => state.isLoading = payload,
        setVoucherDetails: (state, payload) => state.voucherDetails = payload,
    },
    actions:{
        addvoucher:async(context: ActionContext<State, any>, payload:any)=>{
            try {
                context.commit('setIsLoading', true)
                const response =  await axios.instance.post('/superadmin/voucher', payload)
                context.commit('setIsLoading', false) 
                context.dispatch('fetchvouchers')
                context.dispatch('HOMEPAGE/showSuccessToast', 'Voucher has been successfully created!', { root: true }) 
                return response.data
            } catch (e) { 
                let errorMessage = 'A problem encountered while create new voucher'
                const error = e.response.data.data
                
                if (error.includes('voucher name or prefix code has already taken')) errorMessage = 'Voucher name or prefix code has already taken!'

                context.commit('setIsLoading', false) 
                context.dispatch('HOMEPAGE/showErrorToast', errorMessage, { root: true }) 
            }
        },
        fetchvouchers: async(context: ActionContext<State, any>,payload: any={ page:1, itemsPerPage:10 })=>{
            let data : any = []
            context.commit('setIsLoading', true)
            const response:any = await axios.instance.get('/superadmin/voucher',{params:{page:payload.page,limit:payload.itemsPerPage, search:payload.search, sortBy:payload.sortBy, sortDesc:payload.sortDesc}})
            if(response.status === 200) {
               const {voucher, pagination} = response.data.data
               context.commit('setVouchers', voucher)
               context.commit('setPagination', pagination)
            }
            context.commit('setIsLoading', false)
            return data
        },
        fetchvoucher: async(context: ActionContext<State, any>, payload: any)=>{
            return await axios.instance.get(`/user/voucher/${payload.id}`, ).then(response=>{
                const voucher =  response.data.data
                context.commit('setVoucher', voucher)
            })
        },
        fetchVoucherDetails: async({ commit, dispatch }, id) => {
            commit('setIsLoading', true)
            const response = await axios.instance.get(`/superadmin/voucher-detail/${id}`)
            commit('setVoucherDetails', response.data.data)
        },
        deleteVoucher: async ({ commit, dispatch }, id) => {
            try {
                commit('setIsLoading', true)
                const response = axios.instance.delete(`/superadmin/voucher/${id}`)
                dispatch('fetchvouchers')
                dispatch('HOMEPAGE/showSuccessToast', 'Trial voucher has been successfully deleted!', { root: true }) 
                commit('setIsLoading', false)
                return response
            } catch (e) {
                const err = e.response.data.data
                let errorMessage = 'A problem encountered while trying to delete trial voucher'
                if (err.includes(`can't delete voucher which already claimed`)) errorMessage = 'Voucher is already being used.'
                dispatch('HOMEPAGE/showErrorToast', errorMessage, { root: true }) 
                return
            }
        }
    }
    
}

export default store