<template>
  <v-card-text class="d-flex">
    <div class="headline font-weight-bold font--text d-flex align-center">
      Manage Sales
    </div>

    <v-spacer />

    <v-text-field
      width="300"
      height="50"
      placeholder="Search"
      outlined
      v-model="search"
      @keyup="onEnter"
    />

    <div style="height: auto; width: 20px" />

    <v-btn
      depressed
      height="50"
      width="200"
      class="secondary"
      @click="$emit('add')"
    >
      <span class="fz-14">Create New Sales</span>
    </v-btn>
  </v-card-text>
</template>

<script>
export default {
  data() {
    return {
      search: "",
    };
  },
  methods: {
    onEnter(e) {
      if (e.keyCode === 13) {
        this.$emit("onSearch", this.search);
      }
    },
  },
};
</script>
