


























































































import { defineComponent, ref, Ref, watch } from "@vue/composition-api";
// import { useMember } from "./useMember";
// import dialogEditUser from "./dialogEditUser.vue";
// import dialogAddUser from "./dialogAddUser.vue";
// import dialogDeleteUser from "./dialogDeleteUser.vue";

// import dialogRecendInviteUser from "./dialogResendInviteUser.vue";

// import dialogProjectList from "./dialogProjectList.vue";
// import dialogDetailUser from "./dialogDetailUser.vue";

const { Parser, transforms: { unwind, flatten } } = require("json2csv");
import {
  useNamespacedActions,
  useNamespacedState,
} from "vuex-composition-helpers";
import { SUPERADMINMANAGEOS } from "./namespace";
// import dialogActivateUser from './dialogActivateUser.vue'
export default defineComponent({
  components: {
    // dialogEditUser,
    // dialogAddUser,
    // dialogDeleteUser,
    // dialogProjectList,
    // dialogDetailUser,
    // dialogActivateUser,
    // dialogRecendInviteUser
  },
  setup() {
    // const memberselectedvalue: Ref<any> = ref("");
    // const Composable = { ...useMember() };
    // const { membersselected, members } = Composable;
    const headers = ref([

      {
        text: "Name",
        value: "name",
      },
      {
        text: "Description",
        value: "description",
      },

      {
        text: "Action",
        value: "action",
      },
    ]);
   
  
    // const { changestatus } = useNamespacedActions(SUPERADMINMANAGEOS, [
    //   "changestatus",
    // ]);
    
    const opendialogactivateuser = ref(false)
    return {
     
      headers,
      search:[],
      members:[],
      pagination:[],

      // Download,
    
      
    };
  },
  created() {
    

  },
});
