<template>
  <v-dialog v-model="value" persistent max-width="500">
    <v-card>
      <v-card-title>
        <div class="headline font-weight-bold font--text pb-4">
          Import an Image via URL
        </div>
      </v-card-title>
      <v-card-text>
        <p class="font-weight-bold">Image Name</p>
        <v-text-field
          hide-details
          outlined
          placeholder="Enter your Custom Image name"
          v-model="imageName"
          :error-messages="
            $v.imageName.$errors.length
              ? $v.versionNumber.$errors[0].$message
              : ''
          "
        ></v-text-field>
        <small class="red--text">{{
          $v.imageName.$errors.length ? $v.imageName.$errors[0].$message : ""
        }}</small>
      </v-card-text>
      <v-card-text>
        <p class="font-weight-bold">Version Number</p>
        <v-text-field
          hide-details
          outlined
          placeholder="Enter your Custom Image version number"
          v-model="versionNumber"
          :error-messages="
            $v.versionNumber.$errors.length
              ? $v.versionNumber.$errors[0].$message
              : ''
          "
        ></v-text-field>
        <small class="red--text">{{
          $v.versionNumber.$errors.length
            ? $v.versionNumber.$errors[0].$message
            : ""
        }}</small>
      </v-card-text>
      <v-card-text>
        <p class="font-weight-bold">Image URL</p>
        <v-text-field
          hide-details
          outlined
          placeholder="Enter your Custom Image URL"
          v-model="imageURL"
          :error-messages="
            $v.imageURL.$errors.length ? $v.imageURL.$errors[0].$message : ''
          "
        ></v-text-field>
        <small class="red--text">{{
          $v.imageURL.$errors.length ? $v.imageURL.$errors[0].$message : ""
        }}</small>
      </v-card-text>
      <div
        v-if="user.role.toLowerCase() !== 'superadmin' && agree2 === false"
        class="d-flex align-center"
        style="margin-left:20px;margin-bottom:20px;"
      >
        <v-checkbox v-model="agree">
          <template v-slot:label>
            <div>
              By Importing Custom Image You Agree To The
            </div>
          </template>
        </v-checkbox>
        <button
          type="button"
          style="position:absolute;top:445px;left:45px;"
          class="text-decoration-underline primary--text ml-2 fz-16"
          @click="getEula()"
        >
          Terms of Service
        </button>
      </div>
      <v-card-actions>
        <v-row class="ma-2">
          <v-col cols="6">
            <v-btn
              color="accent"
              block
              depressed
              height="50"
              @click="
                () => {
                  $emit('close');
                }
              "
            >
              Cancel
            </v-btn>
          </v-col>
          <v-col cols="6">
            <v-btn
              v-if="user.role.toLowerCase() !== 'superadmin'"
              color="secondary"
              block
              :disabled="agree === false && agree2 === false"
              height="50"
              depressed
              @click="doImport()"
            >
              <beat-loader
                v-if="isLoading"
                :loading="isLoading"
                color="white"
                size="10px"
                class="ml-2"
              ></beat-loader>
              <span v-else>Import</span>
            </v-btn>
            <v-btn
              v-else
              color="secondary"
              block
              height="50"
              depressed
              @click="doImport()"
            >
              <beat-loader
                v-if="isLoading"
                :loading="isLoading"
                color="white"
                size="10px"
                class="ml-2"
              ></beat-loader>
              <span v-else>Import</span>
            </v-btn>
          </v-col>
        </v-row>
      </v-card-actions>
    </v-card>
    <Tncpopup v-model="tnc" :data="dataTnc" />
  </v-dialog>
</template>
<script>
import {
  useNamespacedActions,
  useNamespacedState
} from "vuex-composition-helpers";
import { ref } from "@vue/composition-api";
const useVuelidate = require("@vuelidate/core").default;
const { required } = require("@vuelidate/validators");
import Tncpopup from "./tncpopupcustom.vue";
import api from "@/lib/api";
import { mapGetters } from "vuex";

export default {
  props: ["value", "agree2"],
  components: {
    Tncpopup
  },
  setup(props, context) {
    const imageName = ref("");
    const versionNumber = ref("");
    const imageURL = ref("");
    const { isLoading } = useNamespacedState("IMAGES", ["isLoading"]);
    const { importImageViaURL } = useNamespacedActions("IMAGES", [
      "importImageViaURL"
    ]);

    const $v = useVuelidate(
      {
        imageName: { required },
        versionNumber: { required },
        imageURL: { required }
      },
      {
        imageName,
        versionNumber,
        imageURL
      }
    );

    // const doImport = async () => {
    async function doImport() {
      console.log(this.user);
      $v.value.$touch();
      if ($v.value.$invalid) return;

      const payload = {
        name: imageName.value,
        version: versionNumber.value,
        url: imageURL.value
      };
      const response = await importImageViaURL(payload);
      if (response.status === 200) {
        if (
          this.user.role.toLowerCase() !== "superadmin" &&
          this.agree2 === false
        ) {
          imageName.value = "";
          versionNumber.value = "";
          imageURL.value = "";
          $v.value.$reset();
          this.validateEula();
          context.emit("close");
          this.$forceUpdate();
        }
        if (
          this.user.role.toLowerCase() !== "superadmin" &&
          this.agree2 === true
        ) {
          imageName.value = "";
          versionNumber.value = "";
          imageURL.value = "";
          $v.value.$reset();
          context.emit("close");
          this.$router.push("/image/customimage");
          this.$forceUpdate();
        }
        if (this.user.role.toLowerCase() === "superadmin") {
          imageName.value = "";
          versionNumber.value = "";
          imageURL.value = "";
          $v.value.$reset();
          context.emit("close");
          this.$router.push("/image/customimage");
          this.$forceUpdate();
        }
      }
    }
    return {
      $v,
      isLoading,
      imageName,
      versionNumber,
      imageURL,
      doImport
    };
  },
  // async created() {
  //   console.log("testing");
  //   await this.checkEula();
  // },
  data() {
    return {
      agree: false,
      tnc: false,
      dataTnc: {}
    };
  },
  computed: {
    ...mapGetters({
      projectId: "PROJECT/getprojectid",
      user: "ROLEPERMISSION/getuser"
    })
  },
  methods: {
    async validateEula() {
      var res = await api.POST("/useradmin/validate/eula", {
        form_name: "Form Create Custom Image",
        project_id: this.projectId
      });
      if (res.code === 200) {
        this.$router.push("/image/customimage");
        this.$forceUpdate();
        // this.checkEula();
      }
    },
    async getEula() {
      var res = await api.GET(
        "/useradmin/eula?form_name=Form Create Custom Image"
      );
      console.log("res : ", res);
      if (res.code === 200) {
        this.dataTnc = res.data;
        this.tnc = true;
      }
    }
  }
};
</script>
