



import Vue from 'vue'
export default Vue.extend({
    mounted(){
        this.$store.commit('settopbarloading',this.$refs.topProgress)
    }
})
