






































import { defineComponent } from "@vue/composition-api";
import { useDialog } from "@/composable/usedialog";
import { useNamespacedActions } from "vuex-composition-helpers";
import { MANAGEFLAVOR } from "./namespace";
const module = defineComponent({
  props: ["value", "objectStorage"],
  setup(props, { emit }) {
    const { dialog } = useDialog(props, { emit });
    const { deleteS3Flavor } = useNamespacedActions(MANAGEFLAVOR, [
      'deleteS3Flavor',
    ]);
    
    const doDelete = async (objectStorage:any) => {
      const { id } = objectStorage
      const response = await deleteS3Flavor({ id })
      if(response.status === 200){
        dialog.value = false;
      }
    };

    return {
      dialog,
      doDelete,
    };
  },
});
export default module;
