import * as lodash from 'lodash'
import axios from '../../lib/axios'
import moment from 'moment'
import localstorage from "@/lib/localstorage";
import { Promise } from 'core-js';

export default {
  namespaced: true,
  state: {
    user: {},
    userprofile: {},
    usertoken: '',
    member: {},
    permissions: [],
    userrole: '',
    userpermissions: [],
    menumodulepermission: [],
    roles: [],
    rolespagination: {},
    privilleges: [],
    myprivilleges: [],
    leftnavmenu: [],
    custommenu: [],
    navCustomMenu: [],
    selectedrole: null
  },
  mutations: {
    setuser: (state, user) => state.user = user,
    setusertoken: (state, token) => state.usertoken = token,
    setmember: (state, member) => state.member = member,
    addpermissions: (state, permission) => state.permissions.push(permission),
    setuserrole: (state, role) => state.userrole = role,
    setuserpermissions: (state, permissions) => state.userpermissions = permissions,
    addmenumodulepermission: (state, menu) => state.menumodulepermission.push(menu),
    changemenumodulepermission: (state, menu) => {
      const index = state.menumodulepermission.findIndex((m) => m.link === menu.link)
      state.menumodulepermission[index] = menu
    },
    setroles: (state, roles) => state.roles = roles,
    setrolespagination: (state, pagination) => state.rolespagination = pagination,
    setprivilleges: (state, privilleges) => state.privilleges = privilleges,
    setmyprivilleges: (state, privilleges) => state.myprivilleges = privilleges,
    addLeftNavMenu: (state, menu) => state.leftnavmenu.push(menu),
    addcustommenu: (state, menu) => state.custommenu.push(menu),
    addNavCustomMenu: (state, menu) => state.navCustomMenu.push(menu),
    setuserprofile: (state, payload) => state.userprofile = payload,
    setselectedrole: (state, payload) => state.selectedrole = payload
  },
  getters: {
    getUserProfile: state => state.userprofile, // TODO: Fase 1 - Ferry
    getuser: state => state.user,
    getcustommenu: state => {
      return state.custommenu.slice().filter(menu => {
        let res = menu.permission.filter((permission) => {
          return state.userpermissions.includes(permission)
        })
        return res.length
      })
    },
    getnavcustommenu: state => {
      return state.navCustomMenu.slice().filter(menu => {
        if (!menu.permission) return true
        let res = menu.permission.filter((permission) => {
          return state.userpermissions.includes(permission)
        })
        return res.length
      })
    },
    getleftnavmenu: state => {
      return state.leftnavmenu.slice().filter(menu => {
        let res = menu.permission.filter((permission) => {
          return state.userpermissions.includes(permission)
        })
        return res.length
      })
    },
    getmenu: state => {
      const filtered = state.menumodulepermission.filter((menu) => {
        let res = menu.permission.filter((permission) => {
          return state.userpermissions.includes(permission)
        })
        return res.length
      })
      // const filtered = state.menumodulepermission
      const sortMenu = filtered.sort((a, b) => a.headerno - b.headerno)
      const group = lodash.groupBy(sortMenu, 'header')
      const menu = []
      for (const [key, value] of Object.entries(group)) {
        menu.push({
          header: key,
          list: value.slice().sort((a, b) => a.sort - b.sort),
        })
      }
      return menu

    },
    getroles: state => state.roles,
    getprivilleges: state => state.privilleges
  },
  actions: {
    changestatus: async (context, payload) => {
      if (payload.status == 'active') {
        return await axios.instance.put(`/user/manageuser/deactive/${payload.id}`, payload)
      } else {
        return await axios.instance.put(`/user/manageuser/active/${payload.id}`, payload)
      }
    },
    createRole: async (context, data) => {
      return await axios.instance.post('/user/organization/role', data)
    },
    editRole: async (context, data) => {
      return await axios.instance.put(`/user/organization/role/${data.id}`, data)
    },
    deleteRole: async (context, payload) => {
      return await axios.instance.delete(`/user/organization/role/${payload.id}`)
    },
    fetchRoles: async (context, payload = { page: 1, itemsPerPage: 10 }) => {
      return await axios.instance.get('/user/organization/role', { params: { page: payload.page, limit: payload.itemsPerPage, search: payload.search, sortBy: payload.sortBy, sortDesc: payload.sortDesc } }).then(response => {
        const { organization_roles, pagination } = response.data.data
        context.commit('setroles', organization_roles)
        context.commit('setrolespagination', pagination)
      })
    },
    fetchUserPrivileges: async (context, user) => {
      const enduser = ["project-view", "instance-view", "instance-sidemenu", "storage-view", "storage-sidemenu", "objectstorage-view", "objectstorage-sidemenu", "images-view", "images-sidemenu", "network-view", "network-sidemenu", "security-view", "security-sidemenu", "alerting-view", 'alerting-sidemenu', "billing-view", "billing-sidemenu", "organization-view", "ticket-view", "docs-view", "notification-view", "profile-view", "rdbms-view", "rdbms-sidemenu"]
      const superadmin = ["superadmin-sidemenu", "profile-view", "instance-view", "storage-view", "objectstorage-view", "images-view", "network-view", "security-view", "allerting-view", "billing-view", "billing-sidemenu", "broadcast-view"]
      const customerCare = ["ticket-sidemenu", "profile-view"]
      const godmode = ["godmod-sidemenu", "profile-view", "instance-view", "storage-view", "objectstorage-view", "images-view", "network-view", "security-view", "billing-view"]

      //const menu="";
      if (user.role == 'Superadmin') {
        return context.commit('setuserpermissions', superadmin)
      } else if (user.role == 'Customer-Care') {
        return context.commit('setuserpermissions', customerCare)
      } else if (user.role == 'GodMode') {
        return context.commit('setuserpermissions', godmode)
      } else {
        return context.commit('setuserpermissions', enduser)
      }

    },

    fetchUserProfile: async (context) => {
      //get user profile
      return new Promise((resolve, reject) => {
        axios.instance.get('user/profile').then(response => {
          const data = response.data.data
          const profile = {
            organization_id: data.organization_id,
            id: data.id,
            username: "username",
            fullname: data.fullname,
            email: data.email,
            phone: data.phone_number,
            address: data.address ? data.address : "-",
            createdate: moment(new Date(data.created_at)).format('MM/DD/YYYY'),
          }
          context.commit('setuserprofile', profile)
          const user = {
            id: data.id,
            fullname: data.fullname,
          }
          localStorage.setItem("userId", JSON.stringify(user.id));
          resolve(profile)
        }).catch((e) => {
          context.dispatch('HOMEPAGE/showErrorToast', 'ERROR ON GET USER PROFILE', { root: true })
          reject(e)
        })
      })
    },
    upgradeorg: async (context, payload,) => {
      const currentOrg = context.rootState.SUPERADMIN.currentOrg
      return await axios.instance.put(`/user/manageorg/${currentOrg.id}/npwp`, payload)
    },
  }
}