













































































































import { defineComponent, ref, Ref, watch, onMounted } from "@vue/composition-api";
import dialogAdd from "./dialogAdd.vue";
import dialogDelete from "./dialogDelete.vue";
import { useNamespacedState, useNamespacedActions } from "vuex-composition-helpers";
import { MANAGEINSTANCEREGION } from '../namespace'

export default defineComponent({
  components: {
    dialogAdd,
    dialogDelete,
  },
  setup(props,  { root }) {
    const headers = ref([
      {
        text: 'Name',
        value: 'name',
      },
      {
        text: 'Description',
        value: 'description',
      },
      {
        text: 'CPU Limit',
        value: 'cpu_limit',
      },
      {
        text: 'Memory Limit',
        value: 'ram_limit',
      },
      {
        text: 'GPU Limit',
        value: 'gpu_limit',
      },
      {
        text: 'Action',
        value: 'action',
      },
    ])
    const {
      regions,
      regionPaginator,
    } = useNamespacedState(MANAGEINSTANCEREGION, [
      'regions',
      'regionPaginator',
    ])

    const {
      fetchRegions,
      createRegion,
      updateRegion,
      deleteRegionByName,
    } = useNamespacedActions(MANAGEINSTANCEREGION, [
      'fetchRegions',
      'createRegion',
      'updateRegion',
      'deleteRegionByName',
    ])

    const openDialogAdd = ref(false)
    const openDialogDelete = ref(false)
    const selectedRegion = ref(null)
    const search = ref(null)
    const options = ref(null)

    const doCreate = async (payload:any) => {
      const response = await createRegion(payload)
      if (response.status == 200) {
        openDialogAdd.value = false
        root.$emit('resetFormRegion')
      }
    }

    const doUpdate = async (payload:any) => {
      const response = await updateRegion(payload)
      if (response.status == 200) openDialogAdd.value = false
    }
    
    const doDelete = async (regionName:any) => {
      const response = await deleteRegionByName(regionName)
      openDialogDelete.value = false
    }

    onMounted(() => {
      fetchRegions()
    })

    watch(options, async (val:any) => {
      let params = {
        limit: val.itemsPerPage,
        page: val.page,
        order_column: val.sortBy[0],
        order_by: val.sortDesc[0] ? 'desc' : 'asc'
      }
      await fetchRegions(params)
    })

    return {
      doCreate,
      doUpdate,
      doDelete,
      openDialogAdd,
      openDialogDelete,
      regions,
      regionPaginator,
      selectedRegion,
      search,
      options,
      headers,
    }
  },
  created() {
  },
});
