import {ActionContext, Module} from 'vuex'
import axios from '../../lib/axios'
import libProject from '@/lib/project'
import { async } from 'rxjs'

interface State {
    securities: [],
    security: {},
    isLoading: Boolean,
}
const store: Module<State,any>= {
    namespaced: true,
    state:{
        securities: [],
        security: {},
        isLoading: false,
    },
    mutations: {
        setsecurities: ( state:State, payload:any ) => { state.securities = payload },
        setsecurity: ( state:State, payload:any ) => { state.security = payload },
        setIsLoading: ( state:State, payload:any ) => { state.isLoading = payload },
    },
    actions:{
        addsecurity: async(context, payload:any) => {
            try {
                const activeProjectID = await libProject.getActiveProjectID()
                delete payload.by
                delete payload.created
                payload.project_id = activeProjectID
                const response = await axios.instance.post(`/user/security`, payload)
                const response2 = await axios.instance.post(`/useradmin/validate/eula`, {
                    form_name: "Form Create Security Group",
                    project_id: payload.project_id
                  })
                context.dispatch('HOMEPAGE/showSuccessToast', 'Firewall has been successfully created.', { root: true })
                return response
            } catch (e) {
                const err = e.response.data.data
                let errorMessage = 'A problem encountered while tring to create firewall'

                if(err.includes('security name has already exist')) errorMessage = 'Security name already exists'
                else if(err.includes('Security group has exceeded the limit')) errorMessage = 'Security group has exceeded the limit. Please contact the administrator.'
                else if(err.includes('Security group rules has exceeded the limit')) errorMessage = 'Security group rules has exceeded the limit. Please contact the administrator.'

                context.dispatch('HOMEPAGE/showErrorToast', errorMessage, { root: true })
                context.commit('setIsLoading', false)
            }
        },
        fetchsecurity: async({commit, dispatch}) => {
            const activeProjectID = await libProject.getActiveProjectID()
            const response = await axios.instance.get(`/service/project/${activeProjectID}/security`)
            commit('setsecurities', response.data.data)
        },
        FETCH_SECURITY_DETAIL: async({ commit, dispatch }, payload) => {
            try {
                const { id } = payload
                const response = await axios.instance.get(`/user/security/${id}`)
                if(response.data.code === 200){
                    commit('setsecurity', response.data.data)
                    return response.data
                } else dispatch('HOMEPAGE/showErrorToast', 'Failed to get firewall data.', { root: true })
            } catch (e) {
                dispatch('HOMEPAGE/showErrorToast', 'Failed to get firewall data.', { root: true })
            }
        },
        UPDATE_SECURITY: async (context, payload:any) => {
            try {
                const { id } = payload
                delete payload.id
                const response = await axios.instance.put(`/user/security/${id}`, payload)
                context.dispatch('HOMEPAGE/showSuccessToast', 'Firewall has been successfully updated.', { root: true })
                return response
            } catch (e) {
                const err = e.response.data.data
                let errorMessage = 'A problem encountered while trying to update firewall'
                if (err.includes("Quota exceeded for resources: ['security_group_rule']")) errorMessage = 'Security group rules has exceeded the limit. Please contact the administrator.'

                context.commit('setIsLoading', false)
                context.dispatch('HOMEPAGE/showErrorToast', errorMessage, { root: true })
            }
        },
        DELETE_SECURITY: async({commit, dispatch}, payload) => {
            try {
                const { id } = payload
                commit('setIsLoading', true)
                const response = await axios.instance.delete(`/user/security/${id}`)
                dispatch('HOMEPAGE/showSuccessToast', 'Firewall has been successfully deleted.', { root: true })
                commit('setIsLoading', false)
                dispatch('fetchsecurity')
                return response
            } catch (e){
                commit('setIsLoading', false)
                dispatch('HOMEPAGE/showErrorToast', 'Failed to delete firewall', { root: true })
            }
        }
    }
}

export default store