var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-dialog',{attrs:{"persistent":"","max-width":"800"},model:{value:(_vm.value),callback:function ($$v) {_vm.value=$$v},expression:"value"}},[_c('v-card',[_c('v-card-title',[_c('div',{staticClass:"headline font-weight-bold font--text"},[_vm._v("Increase Storage Size")])]),_c('v-card-text',[(!_vm.isAttached)?_c('p',{staticClass:"font-weight-bold"},[_vm._v(" Select size for your volume. Must be greater than "+_vm._s(_vm.storage && _vm.storage.size)+". This change is not reversible. ")]):_vm._e()]),(!_vm.isAttached)?_c('v-card-text',[_c('v-row',[_c('v-col',{staticClass:"pb-0",attrs:{"cols":"12"}},[_c('p',[_c('span',{staticClass:"font-weight-bold mb-0"},[_vm._v("Select Volume Size")]),(_vm.$v.volumeSelected.$errors.length || _vm.$v.customSize.$errors.length)?_c('span',{staticClass:"ml-2 error--text",staticStyle:{"font-size":"10px"}},[_vm._v(" *"+_vm._s(_vm.$v.volumeSelected.$errors[0] ? _vm.$v.volumeSelected.$errors[0].$message : _vm.$v.customSize.$errors[0].$message))]):_vm._e()])]),_c('v-slide-group',{staticClass:"py-2 pt-0 ma-0",attrs:{"active-class":"success","show-arrows":""}},[_c('v-col',{staticClass:"d-flex pt-0 flex-wrap"},[_c('v-card',{staticClass:"rounded-lg select-instance mb-4",class:{
                'highlight-error': _vm.$v.customSize.$errors.length,
                'hightlighted selected': _vm.$v.customSize.$errors.length < 1 && _vm.isCustomSizeHighlighted,
              },staticStyle:{"text-align":"center","margin-right":"10px"},attrs:{"width":"170","border-color":"primary","outlined":""}},[_c('v-card-text',{staticClass:"pb-0 pt-4 d-flex flex-column m-0"},[_c('p',{staticClass:"font-weight-bold mb-0"},[(_vm.storage && _vm.defaultstoragesize.length > 0)?[_vm._v(" "+_vm._s(_vm.IDRformat.format( (_vm.storage.storage_type === _vm.defaultstoragesize[0].type.name ? _vm.defaultstoragesize[0].price_per_month : _vm.defaultstoragesize[1].price_per_month) * (_vm.customSize ? Number(_vm.customSize.slice(0, _vm.customSize.length - 3)) : 0) ))+"/mo ")]:_vm._e()],2),_c('p',[(_vm.storage && _vm.defaultstoragesize.length > 0)?[_vm._v(" "+_vm._s(_vm.IDRformat.format( (_vm.storage.storage_type === _vm.defaultstoragesize[0].type.name ? _vm.defaultstoragesize[0].price_per_hours : _vm.defaultstoragesize[1].price_per_hours) * (_vm.customSize ? Number(_vm.customSize.slice(0, _vm.customSize.length - 3)) : 0) ))+"/hour ")]:_vm._e()],2)]),_c('v-divider'),_c('v-card-text',{staticClass:"pa-0"},[_c('v-text-field',{staticClass:"px-4 pt-2",staticStyle:{"width":"170px"},attrs:{"max":"100","flat":"","label":"Enter Size in GB"},on:{"keydown":function (ev) { return _vm.customSize = _vm.addGBText(ev, _vm.customSize); },"click":function () {
                      _vm.volumeSelected = null
                      _vm.isCustomSizeHighlighted = true
                    },"blur":function () { return _vm.isCustomSizeHighlighted = !_vm.customSize ? false : true; }},model:{value:(_vm.customSize),callback:function ($$v) {_vm.customSize=$$v},expression:"customSize"}})],1)],1),_vm._l((_vm.filteredVolumeSize),function(storageCard){return _c('div',{key:storageCard.id},[(storageCard.volume_size && _vm.storage.storage_type === storageCard.type.name)?_c('v-card',{staticClass:"rounded-lg select-instance mb-4",class:{ 
                    highlight: _vm.volumeSelected ? _vm.is(_vm.volumeSelected.id, storageCard.id) : false,
                    'highlight-error': _vm.$v.volumeSelected.$errors.length
                  },staticStyle:{"text-align":"center","margin-right":"10px"},attrs:{"width":"170","border-color":"primary","outlined":""},on:{"click":function () {
                    _vm.volumeSelected = storageCard;
                    _vm.customSize = null
                    _vm.isCustomSizeHighlighted = false
                  }}},[_c('v-card-text',{staticClass:"pb-0 pt-4 px-2"},[_c('p',{staticClass:"font-weight-bold mb-0"},[(_vm.storage && _vm.defaultstoragesize.length > 0)?[_vm._v(" "+_vm._s(_vm.IDRformat.format( (storageCard.type_id === _vm.defaultstoragesize[0].type_id ? _vm.defaultstoragesize[0].price_per_month : _vm.defaultstoragesize[1].price_per_month) * storageCard.volume_size ))+"/mo ")]:_vm._e()],2),_c('p',[(_vm.storage && _vm.defaultstoragesize.length > 0)?[_vm._v(" "+_vm._s(_vm.IDRformat.format( (storageCard.type_id === _vm.defaultstoragesize[0].type_id ? _vm.defaultstoragesize[0].price_per_hours : _vm.defaultstoragesize[1].price_per_hours) * storageCard.volume_size ))+"/hour ")]:_vm._e()],2)]),_c('v-divider'),_c('v-card-text',{staticClass:"flavor-card"},[_c('p',{staticClass:"font-weight-bold mb-0"},[_vm._v(_vm._s(storageCard.volume_size)+" GB")])])],1):_vm._e()],1)})],2)],1)],1)],1):_vm._e(),(_vm.storage && _vm.isAttached)?_c('v-card-text',[_vm._v(" Your storage is attached to "),_c('b',{staticClass:"primary--text"},[_vm._v(_vm._s(_vm.storage.instance.instanceName))]),_vm._v(". Please detach selected storage from "),_c('b',{staticClass:"primary--text"},[_vm._v(_vm._s(_vm.storage.instance.instanceName))]),_vm._v(" before increasing volume size. ")]):_vm._e(),_c('v-card-actions',[_c('v-row',{staticClass:"ma-2"},[_c('v-col',{attrs:{"cols":"6"}},[_c('v-btn',{attrs:{"depressed":"","height":"50","color":"accent","block":""},on:{"click":function () {
                _vm.$emit('close');
              }}},[_vm._v(" Cancel ")])],1),_c('v-col',{attrs:{"cols":"6"}},[_c('v-btn',{attrs:{"depressed":"","height":"50","color":"secondary","block":"","disabled":_vm.isLoading || !_vm.volumeSize.length || _vm.isAttached},on:{"click":function () {
                _vm.resizeVolume(_vm.storage)
              }}},[(_vm.isLoading)?_c('div',[_c('beat-loader',{attrs:{"loading":_vm.isLoading,"color":"white","size":"10px"}})],1):_c('span',[_vm._v(" Resize Volume ")])])],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }