
































































































































































































































































































import { onMounted, ref, watch } from "@vue/composition-api";
import Vue from "vue";
import {
  useNamespacedActions,
  useNamespacedMutations,
  useNamespacedState,
} from "vuex-composition-helpers";
import dialogAddOrg from "./dialogAddOrg.vue";
import { SUPERADMINMANAGEORG } from "./namespace";
import dialogUpgradeOrg from "./dialogUpgradeOrg.vue";
import dialogDeleteOrg from "./dialogDeleteOrg.vue";
import dialogDowngradeOrg from "./dialogDowngradeOrg.vue";
import dialogErrorDelete from "./dialogErrorDelete.vue";
import dialogAreYouSure from "./dialogAreYouSure.vue";
import dialogActivateUser from "../superadmin-manageuser/dialogActivateUser.vue";
import dialogUpgradePermiumSucces from "./dialogUpgradePremiumSuccess.vue";
import { database } from "faker/locale/es_MX";

const isDevModePrepaid = true

export default Vue.extend({
  components: {
    dialogAddOrg,
    dialogDeleteOrg,
    dialogUpgradeOrg,
    dialogDowngradeOrg,
    dialogActivateUser,
    dialogErrorDelete,
    dialogAreYouSure,
    dialogUpgradePermiumSucces,
  },
  setup() {
    const headers = [
      {
        text: "Name",
        value: "name",
      },
      {
        text: "Address",
        value: "address",
      },
      {
        text: "Type",
        value: isDevModePrepaid ? "is_personal" : "type",
        width: 100,
      },
      {
        text: "Phone Number",
        value: "phone_number_org",
      },
      {
        text: "Created Date",
        value: "created_at",
        width: 150,
      },
      {
        text: "Status",
        value: "status",
        width: 100,
      },
      {
        text: "Action",
        value: "action",
        align: "center",
      },
    ];
    const { fetchorganizations,checkdeleteorg } = useNamespacedActions(SUPERADMINMANAGEORG, [
      "fetchorganizations",
      "checkdeleteorg",
    ]);
    onMounted(() => {
      fetchorganizations();
    });
    const {
      organizations,
      pagination,
    } = useNamespacedState(SUPERADMINMANAGEORG, [
      "organizations",
      "pagination",
    ]);
    const orgselected = ref();
    const options = ref({ page: 1, itemsPerPage: 10, rowsPerPage: 10, search: "", sortBy: [], sortDesc: [] });
    const footerProps = ref({'items-per-page-options': [10, 20, 30, 50, 100]})
    const loading = ref(false);
    watch(
      options,
      async (val: any) => {
        loading.value = true;
        try {
          await fetchorganizations(val);
        } finally {
          loading.value = false;
        }
      },
      { deep: true }
    );
    const search = ref("");
    let searchtimeout: any = setTimeout(() => {}, 500);
    watch(search, (val) => {
      clearTimeout(searchtimeout);
      searchtimeout = setTimeout(() => {
        options.value.page = 1;
        options.value.search = val;
      }, 500);
    });
    const {
      changeorgstatus,
      upgradeorg,
      downgradeorg,
    } = useNamespacedActions(SUPERADMINMANAGEORG, [
      "changeorgstatus",
      "upgradeorg",
      "downgradeorg",
    ]);
    const dialogerrormsg = ref("");
    const opendialogupgradeorg = ref(false);
    const opendialogdowngrade = ref(false);
    const opendialogactivateorg = ref(false);
    const opendialogdelete=ref(false);
    const opendialogerror = ref(false);
    const opendialogareyousure = ref(false);
    const {
      setopendialoghavebeensubmitted,
    } = useNamespacedMutations("DASHBOARD", ["setopendialoghavebeensubmitted"]);
    const opendialogupgradepremiumsuccess = ref(false);
    const { setopendialogcommonsuccess } = useNamespacedMutations("DASHBOARD", [
      "setopendialogcommonsuccess",
    ]);

  

    const hapusORG = () => {
      const Value = {
        id: orgselected.value.id,
        name:orgselected.value.name
      };

      checkdeleteorg(Value).then((e:any) => {
        
        opendialogdelete.value=true;
        //context.root.$router.replace(`/`);
        //opendialogareyousure=true;
        //opendialogareyousure(true);
        // updateCurrentProj(Value);
        // setopendialoghavebeensubmitted(true);
        // watch(opendialoghavebeensubmitted, (val) => {
        //   if (val == false) {
        //     context.root.$router.replace(`/project/${name.value}`);
        //   }
        // });
      }).catch((e:any)=>{
        // const {message} = e.response.message
        opendialogerror.value=true;
        dialogerrormsg.value=e.response.data.message
      });

    }

    return {
      dialogerrormsg,
      opendialogdelete,
      opendialogerror,
      hapusORG,
      opendialogareyousure,
      setopendialogcommonsuccess,
      opendialogupgradepremiumsuccess,
      setopendialoghavebeensubmitted,
      changeorgstatus,
      opendialogactivateorg,
      opendialogdowngrade,
      upgradeorg,
      downgradeorg,
      opendialogupgradeorg,
      search,
      orgselected,
      pagination,
      options,
      opendialogadd: ref(false),
      opendialogedit: ref(false),
      headers,
      organizations,
      fetchorganizations,
      footerProps,
    };
  },
  data() {
    return {
      isDevModePrepaid,
    }
  },
  methods: {
    uppercaseFirstLetter(string: String) {
      return string.charAt(0).toUpperCase() + string.slice(1)
    }
  }
});
