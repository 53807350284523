





















































import { defineComponent } from "@vue/composition-api";
import { ref } from "@vue/composition-api";
import { EMATERAI } from "./namespace";
import {
  useNamespacedState,
  useNamespacedActions,
} from "vuex-composition-helpers";

const module = defineComponent({
  props: {
    value: {
      type: Boolean,
      default: false,
    },
    item: {
      type: Object,
      required: true,
      default: () => {},
    },
  },
  setup(props, { emit }) {
    const { deleteStamp, fetchStamps } = useNamespacedActions(EMATERAI, [
      "deleteStamp",
      "fetchStamps",
    ]);
    const {
      showErrorToast,
      showSuccessToast,
    } = useNamespacedActions("HOMEPAGE", [
      "showErrorToast",
      "showSuccessToast",
    ]);
    const { isLoading } = useNamespacedState(EMATERAI, ["isLoading"]);
    const deleteMaterai = (params: any) => {
      if (params.status === 1) {
        showErrorToast("Can not delete e-materai because it is still actived");
        emit("close");
      } else {
        deleteStamp(params.id).then(() => {
          fetchStamps();
          emit("close");
        });
      }
    };
    return {
      deleteMaterai,
      isLoading,
    };
  },
});
export default module;
