<template>
  <div>
    <v-card v-if="loading" color="white" flat>
      <v-card-text class="pa-8">
        <p class="text-center">
          <beat-loader color="gray" size="10px" class="mr-2" />
        </p>
      </v-card-text>
    </v-card>

    <template v-else>
      <v-card v-if="detail" color="white" flat>
        <v-card-text class="pa-8">
          <h6 class="text-h6 font-weight-bold">Summary Monthly</h6>

          <v-row class="mt-4">
            <v-col>
              <b>Report ID</b>
              <p class="mt-4">
                {{ detail.report_id }}
              </p>
            </v-col>

            <v-col>
              <b>Billing Account</b>
              <p class="mt-4">
                {{ detail.billing_account }}
              </p>
            </v-col>

            <v-col>
              <b>Status</b>
              <p
                class="mt-4"
                :class="{
                  'green--text': detail.status === 'PAID',
                }"
              >
                {{ detail.status }}
              </p>
            </v-col>

            <v-col>
              <b>Project Name</b>
              <p class="mt-4">
                {{ detail.project.name }}
              </p>
            </v-col>
          </v-row>
        </v-card-text>
      </v-card>

      <v-card v-if="detail" color="white" flat class="mt-8">
        <v-card-text class="pa-8">
          <!-- tbl -->
          <v-data-table
            :hide-default-footer="true"
            :headers="tblHeaders"
            :items-per-page="99"
            :items="list"
            class="mt-8"
          >
            <template v-slot:item.qty="{ item }"> {{ item.qty }} h </template>

            <template v-slot:item.amount="{ item }">
              <b> IDR {{ item.unit.toLocaleString("en") }}/h </b>
            </template>

            <template v-slot:item.total="{ item }">
              <b> IDR {{ item.billed.toLocaleString("en") }} </b>
            </template>
          </v-data-table>

          <!-- totals -->
          <v-row>
            <v-col>
              <!-- empty (for spacing) -->
            </v-col>

            <v-col>
              <v-row class="grey lighten-4">
                <v-col>
                  <b>Subtotal</b>
                </v-col>
                <v-col>
                  <b> IDR {{ detail.subtotal.toLocaleString("en") }} </b>
                </v-col>
              </v-row>

              <v-row class="grey lighten-4 mt-4">
                <v-col>
                  <b>
                    Credit
                  </b>
                </v-col>
                <v-col>
                  <b class="red--text">
                    IDR {{ detail.credit.toLocaleString("en") }}
                  </b>
                </v-col>
              </v-row>

              <v-row class="mt-4 primary">
                <v-col>
                  <b class="white--text">Total</b>
                </v-col>
                <v-col>
                  <b class="white--text">
                    IDR {{ detail.total.toLocaleString("en") }}
                  </b>
                </v-col>
              </v-row>
            </v-col>
          </v-row>
        </v-card-text>
      </v-card>
    </template>
  </div>
</template>

<script>
import api from "@/lib/api";
import localstorage from "@/lib/localstorage";

export default {
  data() {
    return {
      tblHeaders: [
        { text: "Service Name", value: "service_name" },
        { text: "Service Type", value: "service_type" },
        { text: "Usage", value: "qty" },
        { text: "Price", value: "amount" },
        { text: "Total", value: "total" },
      ],
      list: [],
      detail: null,
      loading: false,
    };
  },
  computed: {
    org() {
      return JSON.parse(localstorage.getItem("currentOrg"));
    },
  },
  methods: {
    async getSummaryMonthlyDetail() {
      this.loading = true;

      if (this.org && this.org.id) {
        try {
          const id = this.$route.params.id;
          const res = await api.GET(
            `/billing/${this.org.id}/summary/monthly/${id}`
          );
          this.detail = res.data;
          this.list = res.data.services || [];
        } catch {
          this.$store.dispatch(
            "HOMEPAGE/showErrorToast",
            "Sorry, an error occurred while displaying summary monthly detail detail"
          );
        }
      } else {
        this.$store.dispatch(
          "HOMEPAGE/showErrorToast",
          "Sorry, organization id not found"
        );
      }

      this.loading = false;
    },
  },
  mounted() {
    this.getSummaryMonthlyDetail();
  },
};
</script>
