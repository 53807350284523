<template>
  <v-dialog v-model="value" persistent max-width="800">
    <v-card>
      <v-card-title>
        <div class="headline font-weight-bold font--text">Increase Storage Size</div>
      </v-card-title>
      <v-card-text>
        <p class="font-weight-bold" v-if="!isAttached">
          Select size for your volume. Must be greater than {{ storage && storage.size }}. This change is
          not reversible.
        </p>
      </v-card-text>
      <v-card-text v-if="!isAttached">
        <v-row>
          <v-col class="pb-0" cols="12">
            <p>
              <span class="font-weight-bold mb-0">Select Volume Size</span>
              <span
                  class="ml-2 error--text"
                  style="font-size: 10px;"
                  v-if="$v.volumeSelected.$errors.length || $v.customSize.$errors.length"
              >
                *{{
                  $v.volumeSelected.$errors[0] ? $v.volumeSelected.$errors[0].$message : $v.customSize.$errors[0].$message
                }}</span
              >
            </p>
          </v-col>
          <v-slide-group
              class="py-2 pt-0 ma-0"
              active-class="success"
              show-arrows
          >
            <v-col class="d-flex pt-0 flex-wrap">
              <v-card
                  width="170"
                  border-color="primary"
                  outlined
                  class="rounded-lg select-instance mb-4"
                  :class="{
                  'highlight-error': $v.customSize.$errors.length,
                  'hightlighted selected': $v.customSize.$errors.length < 1 && isCustomSizeHighlighted,
                }"
                  style="text-align: center; margin-right: 10px"
              >
                <v-card-text class="pb-0 pt-4 d-flex flex-column m-0">
                  <p class="font-weight-bold mb-0">
                    <template v-if="storage && defaultstoragesize.length > 0">
                      {{
                        IDRformat.format(
                            (storage.storage_type === defaultstoragesize[0].type.name ? defaultstoragesize[0].price_per_month : defaultstoragesize[1].price_per_month) * (customSize ? Number(customSize.slice(0, customSize.length - 3)) : 0)
                        )
                      }}/mo
                    </template>
                  </p>
                  <p>
                    <template v-if="storage && defaultstoragesize.length > 0">
                      {{
                        IDRformat.format(
                            (storage.storage_type === defaultstoragesize[0].type.name ? defaultstoragesize[0].price_per_hours : defaultstoragesize[1].price_per_hours) * (customSize ? Number(customSize.slice(0, customSize.length - 3)) : 0)
                        )
                      }}/hour
                    </template>
                  </p>
                </v-card-text>
                <v-divider/>

                <v-card-text class="pa-0">
                  <v-text-field
                      v-model="customSize"
                      class="px-4 pt-2"
                      @keydown="(ev) => customSize = addGBText(ev, customSize)"
                      style="width: 170px"
                      max="100"
                      flat
                      label="Enter Size in GB"
                      @click="() => {
                        volumeSelected = null
                        isCustomSizeHighlighted = true
                      }"
                      @blur="() => isCustomSizeHighlighted = !customSize ? false : true"
                  ></v-text-field>
                </v-card-text>
              </v-card>
              <div
                  v-for="storageCard in filteredVolumeSize"
                  :key="storageCard.id"
              >
                <v-card
                    v-if="storageCard.volume_size && storage.storage_type === storageCard.type.name"
                    width="170"
                    :class="{ 
                      highlight: volumeSelected ? is(volumeSelected.id, storageCard.id) : false,
                      'highlight-error': $v.volumeSelected.$errors.length
                    }"
                    @click="
                    () => {
                      volumeSelected = storageCard;
                      customSize = null
                      isCustomSizeHighlighted = false
                    }
                  "
                    border-color="primary"
                    outlined
                    class="rounded-lg select-instance mb-4"
                    style="text-align: center; margin-right: 10px"
                >
                  <v-card-text class="pb-0 pt-4 px-2">
                    <p class="font-weight-bold mb-0">
                      <template v-if="storage && defaultstoragesize.length > 0">
                        {{
                          IDRformat.format(
                              (storageCard.type_id === defaultstoragesize[0].type_id ? defaultstoragesize[0].price_per_month : defaultstoragesize[1].price_per_month) * storageCard.volume_size
                          )
                        }}/mo
                      </template>
                    </p>
                    <p>
                      <template v-if="storage && defaultstoragesize.length > 0">
                        {{
                          IDRformat.format(
                              (storageCard.type_id === defaultstoragesize[0].type_id ? defaultstoragesize[0].price_per_hours : defaultstoragesize[1].price_per_hours) * storageCard.volume_size
                          )
                        }}/hour
                      </template>
                    </p>
                  </v-card-text>
                  <v-divider/>
                  <v-card-text class="flavor-card">
                    <p class="font-weight-bold mb-0">{{ storageCard.volume_size }} GB</p>
                  </v-card-text>
                </v-card>
              </div>
            </v-col>
          </v-slide-group>
        </v-row>
      </v-card-text>
      <v-card-text v-if="storage && isAttached">
        Your storage is attached to <b class="primary--text">{{ storage.instance.instanceName }}</b>. Please detach
        selected storage from <b class="primary--text">{{ storage.instance.instanceName }}</b> before increasing volume
        size.
      </v-card-text>
      <v-card-actions>
        <v-row class="ma-2">
          <v-col cols="6">
            <v-btn
                depressed
                height="50"
                color="accent"
                block
                @click="
                () => {
                  $emit('close');
                }
              "
            >
              Cancel
            </v-btn>
          </v-col>
          <v-col cols="6">
            <v-btn
                depressed
                height="50"
                color="secondary"
                block
                :disabled="isLoading || !volumeSize.length || isAttached"
                @click="
                () => {
                  resizeVolume(storage)
                }
              "
            >
              <div v-if="isLoading">
                <beat-loader :loading="isLoading" color="white" size="10px"></beat-loader>
              </div>
              <span v-else>
                Resize Volume
              </span>
            </v-btn>
          </v-col>
        </v-row>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>
<script>

import { onMounted, ref, watch } from "@vue/composition-api";
import { useNamespacedActions, useNamespacedState } from "vuex-composition-helpers";
import { IDRformat } from "../../lib/formatter";
import { useStorage } from "../instance/compositionapi/usestorage";

const {required, requiredIf, helpers} = require("@vuelidate/validators");
const useVuelidate = require("@vuelidate/core").default;
import { addGBText, removeGBText } from '@/lib/formatter'

export default {
  props: {
    value: Boolean,
    storage: Object,
  },
  setup(props, context) {
    const {isLoading} = useNamespacedState('STORAGE', ['isLoading'])
    let {volumeSize} = useNamespacedState('STORAGE', ['volumeSize'])

    let isAttached = ref(false)
    const volumeSelected = ref(null)
    const isCustomSizeHighlighted = ref(false)
    const customSize = ref(null)
    let filteredVolumeSize = ref([])

    const {defaultstoragesize, fetchdefaultstoragesize} = useStorage()

    isAttached.value = !!(props.storage && props.storage.instance)
    const filterBiggerVolumeSize = (currentStorage, flavorSize) => {
      const currentStorageSize = Object.keys(currentStorage).length ? currentStorage.size.split(' ')[0] : 0
      if (flavorSize > currentStorageSize) return true
      return false
    }

    const $v = useVuelidate(
        {
          volumeSelected: {
            required: requiredIf((val) => {
              return customSize.value === null && volumeSelected.value === null;
            }),
            autoDirty: true,
          },
          customSize: {
            autoDirty: true,
            required: requiredIf((val) => {
              return customSize.value === null && volumeSelected.value === null;
            }),
            bigger: helpers.withMessage(`Value must be greater than current storage size`, (val) => {
              if (customSize.value === null) return true
              let size = val ? removeGBText(val) : null
              let currentSize = val ? removeGBText(props.storage.size) : null
              return size > currentSize
            }),
          },
        },
        {
          volumeSelected,
          customSize,
        }
    );


    watch(() => props.storage, async storage => {
      isAttached.value = props.storage && props.storage.instance
      await fetchVolumeSize({limit: 999})
    })

    watch(volumeSize, size => {
      filteredVolumeSize.value = volumeSize.value.filter(x => filterBiggerVolumeSize(props.storage || {}, x.volume_size))
    })

    const {fetchVolumeSize, RESIZE_VOLUME} = useNamespacedActions('STORAGE', ['fetchVolumeSize', 'RESIZE_VOLUME'])
    onMounted(async () => {
      await fetchVolumeSize({limit: 999})
      await fetchdefaultstoragesize()
    })

    return {
      defaultstoragesize,
      $v,
      addGBText,
      removeGBText,
      isCustomSizeHighlighted,
      customSize,
      isAttached,
      filteredVolumeSize,
      isLoading,
      IDRformat,
      volumeSize,
      volumeSelected,
      is(a, b) {
        return a == b;
      },
      isSelected: (id) => {
        return id == volumeSelected.id;
      },
      resizeVolume: async (storage) => {
        $v.value.$touch()
        if ($v.value.$invalid) return;

        const payload = {
          storage_id: storage.id || null,
        }

        if (volumeSelected.value) payload.package_storage_id = volumeSelected.value.id
        if (customSize.value) {
          const flavorSameSize = volumeSize.value.filter(x => parseInt(x.volume_size) === parseInt(removeGBText(customSize.value)))
          if (flavorSameSize.length > 0) payload.package_storage_id = flavorSameSize[0].id
          else payload.custom_size = parseInt(removeGBText(customSize.value))
        }

        const response = await RESIZE_VOLUME(payload)
        if (response.status == 200) {
          customSize.value = null
          volumeSelected.value = null
          $v.value.$reset()
          context.emit('close')
        }
      },
      logging: () => console.log('DIALOG', props),
      filterBiggerVolumeSize
    }
  }
}
</script>
<style lang="scss" scoped>
.select-instance,
.select-storage {
  cursor: pointer;

  &.selected {
    border-color: #2c94d2;

    .v-divider {
      border-color: #2c94d2;
    }
  }
}

.v-card.select-storage {
  p {
    margin-bottom: 0px;
    text-align: left;
  }
}

.v-card__title {
  line-height: 1.5rem;
}

.v-btn {
  ::v-deep .v-btn__content {
    font-size: 14px;
    font-weight: 700;
  }
}
</style>