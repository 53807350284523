



































































import { defineComponent } from "@vue/composition-api";
import { useDialog } from "@/composable/usedialog";

const module = defineComponent({
  props: ["value", "loading", "title", "desc"],
  setup(props, { emit }) {
    const { dialog } = useDialog(props, { emit });

    return {
      dialog,
    };
  },
});
export default module;
