var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-card',{attrs:{"color":"white","flat":""}},[_c('v-card-text',[_c('div',{staticClass:"d-flex justify-space-between align-center"},[_c('h6',{staticClass:"text-h6 font-weight-bold"},[_vm._v(" Daily Cost All Projects Estimated ")]),_c('div',[_c('v-select',{style:({ width: '200px' }),attrs:{"items":_vm.projectNames,"label":"Project","outlined":""},model:{value:(_vm.projectName),callback:function ($$v) {_vm.projectName=$$v},expression:"projectName"}})],1)]),(_vm.loading)?_c('p',{staticClass:"text-center"},[_c('beat-loader',{staticClass:"mr-2",attrs:{"color":"gray","size":"10px"}})],1):_c('v-data-table',{staticClass:"mt-8",attrs:{"hide-default-footer":true,"headers":_vm.tblHeaders,"items-per-page":10,"items":_vm.list},scopedSlots:_vm._u([{key:"item.date",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.$moment(item.date).format("DD/MM/YYYY"))+" ")]}},{key:"item.project",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.project.name)+" ")]}},{key:"item.service_name",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.service_name)+" ")]}},{key:"item.status",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.status)+" ")]}},{key:"item.charge",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.charge ? "Yes" : "No")+" ")]}},{key:"item.bill_estimate",fn:function(ref){
var item = ref.item;
return [_c('b',[_vm._v("IDR "+_vm._s(item.bill_estimate.toLocaleString("en")))])]}},{key:"item.expanded",fn:function(ref){
var item = ref.item;
return [(item.expanded)?_c('v-btn',{attrs:{"color":"white primary--text","elevation":"0"},on:{"click":function () {
              _vm.actionPopupOn = true;
              _vm.expanded = item.expanded;
              _vm.service_name = item.service_name;
            }}},[_vm._v(" Detail ")]):_vm._e()]}},{key:"body.append",fn:function(){return [(_vm.list.length)?_c('tr',{staticStyle:{"border":"none"}},[_c('td',{staticClass:"border-bottom-none"}),_c('td',{staticClass:"border-bottom-none"}),_c('td',{staticClass:"border-bottom-none"}),_c('td',{staticClass:"border-bottom-none"}),_c('td',{staticClass:"border-bottom-none"}),_c('td',{staticClass:"border-bottom-none"},[_c('div',[_c('p',{staticClass:"font-weight-bold"},[_vm._v("Total")])]),_c('div',{staticClass:"d-flex flex-row justify-start"},[_vm._v(" IDR "+_vm._s(_vm.total.toLocaleString("en"))+" ")])]),_c('td',{staticClass:"border-bottom-none"})]):_vm._e()]},proxy:true},(_vm.list.length)?{key:"footer",fn:function(ref){
            var props = ref.props;
return [_c('custom-footer-datatable',{attrs:{"props":props}})]}}:null],null,true)})],1),(_vm.expanded)?_c('ActionPopup',{attrs:{"item":_vm.expanded,"service-name":_vm.service_name},model:{value:(_vm.actionPopupOn),callback:function ($$v) {_vm.actionPopupOn=$$v},expression:"actionPopupOn"}}):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }