


























import { ref, onMounted } from "@vue/composition-api";
import localstorage from "@/lib/localstorage";
import { useNamespacedActions } from "vuex-composition-helpers";

export default {
  setup() {
    const { loginRDBMS } = useNamespacedActions("RDBMS", ["loginRDBMS"]);
    const urlRdbms = ref("");
    onMounted(() => {
      const userId = localStorage.getItem("userId");
      const projectId = localStorage.getItem("projectid");
      const username = localStorage.getItem("username");
      const curOrg = localStorage.getItem("currentOrg");
      if (username && curOrg && projectId && userId) {
        const currOrg = JSON.parse(curOrg);
        const data = {
          organizationID: currOrg.id,
          projectID: projectId,
          userID: JSON.parse(userId),
          username,
        };
        loginRDBMS(data).then((res: any) => {
          const token = res.data.data.token;
          const id = res.data.data.uniqueID;
          urlRdbms.value = `https://dbaas.cloudeka.id/api/auth/jwt-login?issuer=lintasarta&jwt=${token}&uniqueID=${id}`;
          console.log("login ke rdbms success");
          return Promise.resolve();
        });
      }
    });
    const onLoad = () => {
      console.log(urlRdbms.value);
      console.log("onload");
    };
    return {
      urlRdbms,
      onLoad,
    };
  },
};
